export const COMMITTEE_MEMBER_NEW_REQUEST = 'COMMITTEE_MEMBER_NEW_REQUEST';
export const COMMITTEE_MEMBER_NEW_SUCCESS = 'COMMITTEE_MEMBER_NEW_SUCCESS';
export const COMMITTEE_MEMBER_NEW_FAIL = 'COMMITTEE_MEMBER_NEW_FAIL';
export const COMMITTEE_MEMBER_NEW_RESET = 'COMMITTEE_MEMBER_NEW_RESET';

export const COMMITTEE_MEMBER_ALL_REQUEST = 'COMMITTEE_MEMBER_ALL_REQUEST';
export const COMMITTEE_MEMBER_ALL_SUCCESS = 'COMMITTEE_MEMBER_ALL_SUCCESS';
export const COMMITTEE_MEMBER_ALL_FAIL = 'COMMITTEE_MEMBER_ALL_FAIL';

export const COMMITTEE_MEMBER_BY_ID_REQUEST = 'COMMITTEE_MEMBER_BY_ID_REQUEST';
export const COMMITTEE_MEMBER_BY_ID_SUCCESS = 'COMMITTEE_MEMBER_BY_ID_SUCCESS';
export const COMMITTEE_MEMBER_BY_ID_FAIL = 'COMMITTEE_MEMBER_BY_ID_FAIL';
export const COMMITTEE_MEMBER_BY_ID_RESET = 'COMMITTEE_MEMBER_BY_ID_RESET';

export const COMMITTEE_MEMBER_UPDATE_BY_ID_REQUEST =
  'COMMITTEE_MEMBER_UPDATE_BY_ID_REQUEST';
export const COMMITTEE_MEMBER_UPDATE_BY_ID_SUCCESS =
  'COMMITTEE_MEMBER_UPDATE_BY_ID_SUCCESS';
export const COMMITTEE_MEMBER_UPDATE_BY_ID_FAIL =
  'COMMITTEE_MEMBER_UPDATE_BY_ID_FAIL';
export const COMMITTEE_MEMBER_UPDATE_BY_ID_RESET =
  'COMMITTEE_MEMBER_UPDATE_BY_ID_RESET';

export const COMMITTEE_MEMBER_DELETE_REQUEST =
  'COMMITTEE_MEMBER_DELETE_REQUEST';
export const COMMITTEE_MEMBER_DELETE_SUCCESS =
  'COMMITTEE_MEMBER_DELETE_SUCCESS';
export const COMMITTEE_MEMBER_DELETE_FAIL = 'COMMITTEE_MEMBER_DELETE_FAIL';
export const COMMITTEE_MEMBER_DELETE_RESET = 'COMMITTEE_MEMBER_DELETE_RESET';

///////////////////////SESSION////////////////////////////

export const COMMITTEE_SESSION_NEW_REQUEST = 'COMMITTEE_SESSION_NEW_REQUEST';
export const COMMITTEE_SESSION_NEW_SUCCESS = 'COMMITTEE_SESSION_NEW_SUCCESS';
export const COMMITTEE_SESSION_NEW_FAIL = 'COMMITTEE_SESSION_NEW_FAIL';
export const COMMITTEE_SESSION_NEW_RESET = 'COMMITTEE_SESSION_NEW_RESET';

export const COMMITTEE_SESSION_ALL_REQUEST = 'COMMITTEE_SESSION_ALL_REQUEST';
export const COMMITTEE_SESSION_ALL_SUCCESS = 'COMMITTEE_SESSION_ALL_SUCCESS';
export const COMMITTEE_SESSION_ALL_FAIL = 'COMMITTEE_SESSION_ALL_FAIL';

export const COMMITTEE_SESSION_BY_ID_REQUEST =
  'COMMITTEE_SESSION_BY_ID_REQUEST';
export const COMMITTEE_SESSION_BY_ID_SUCCESS =
  'COMMITTEE_SESSION_BY_ID_SUCCESS';
export const COMMITTEE_SESSION_BY_ID_FAIL = 'COMMITTEE_SESSION_BY_ID_FAIL';
export const COMMITTEE_SESSION_BY_ID_RESET = 'COMMITTEE_SESSION_BY_ID_RESET';

export const COMMITTEE_SESSION_UPDATE_BY_ID_REQUEST =
  'COMMITTEE_SESSION_UPDATE_BY_ID_REQUEST';
export const COMMITTEE_SESSION_UPDATE_BY_ID_SUCCESS =
  'COMMITTEE_SESSION_UPDATE_BY_ID_SUCCESS';
export const COMMITTEE_SESSION_UPDATE_BY_ID_FAIL =
  'COMMITTEE_SESSION_UPDATE_BY_ID_FAIL';
export const COMMITTEE_SESSION_UPDATE_BY_ID_RESET =
  'COMMITTEE_SESSION_UPDATE_BY_ID_RESET';

export const COMMITTEE_SESSION_DELETE_REQUEST =
  'COMMITTEE_SESSION_DELETE_REQUEST';
export const COMMITTEE_SESSION_DELETE_SUCCESS =
  'COMMITTEE_SESSION_DELETE_SUCCESS';
export const COMMITTEE_SESSION_DELETE_FAIL = 'COMMITTEE_SESSION_DELETE_FAIL';
export const COMMITTEE_SESSION_DELETE_RESET = 'COMMITTEE_SESSION_DELETE_RESET';

// //////////////////////////BOD//////////////////////////
export const BOD_REQUEST = 'BOD_REQUEST';
export const BOD_SUCCESS = 'BOD_SUCCESS';
export const BOD_FAIL = 'BOD_FAIL';
