import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Carousel, Image } from "react-bootstrap";
import Loader from "../Loader";
import Message from "../Message";
import { getHomeScreenImage } from "../../actions/imageActions";
import "./ImageCarousel.css";

const ImageCarousel = ({ subDomain }) => {
  const dispatch = useDispatch();

  const imageHomeScreen = useSelector((state) => state.imageHomeScreen);
  const { loading, error, homeScreenImages } = imageHomeScreen;

  const checkChapter = window.location.host;

  useEffect(() => {
    dispatch(getHomeScreenImage(checkChapter));
  }, [dispatch, checkChapter]);

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant="danger">{error}</Message>
  ) : (
    <Carousel pause="hover" controls={false} indicators={false} fade>
      {homeScreenImages &&
        homeScreenImages.map((image, index) => (
          <Carousel.Item key={index} style={{ backgroundColor: "white" }}>
            {subDomain === "aabea" ? (
              <div style={{ position: "relative" }}>
                {/* Image with useMap attribute */}
                <Image
                  src={image.image}
                  alt={image.imageDescription}
                  fluid
                  style={{
                    height: "90%",
                    width: "auto",
                    display: "block",
                    margin: "0 auto",
                  }}
                  useMap="#imageMap"
                />

                {/* Clickable area overlay */}
                <div
                  className="clickable-area-overlay"
                  style={{
                    position: "absolute",
                    top: "4%",
                    left: "16%",
                    width: "16%",
                    height: "12.5%",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open(
                      "https://aabea.org/event/register/42042",
                      "_self"
                    );
                  }}
                >
                  {/* Hover effect is applied via CSS */}
                </div>
              </div>
            ) : (
              <Image
                src={image.image}
                alt={image.imageDescription}
                fluid
                style={{
                  height: "500px",
                  width: "100%",
                }}
              />
            )}
          </Carousel.Item>
        ))}
    </Carousel>
  );
};

export default ImageCarousel;
