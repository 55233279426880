import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { useState } from "react";
import {
  Col,
  Form,
  ListGroup,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";

const AddCommitteeMember = ({
  sessionId,
  users,
  addCommitteeMember,
  subDomain,
}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [position, setPosition] = useState("");
  const [cMemberId, setCMemberId] = useState("");
  const [bio, setBio] = useState("");

  return (
    <>
      <OverlayTrigger
        overlay={<Tooltip id="tooltip-disabled">Add Committee Member</Tooltip>}
      >
        <span onClick={handleShow}>
          <i
            className="fas fa-plus action mr-2 text-info"
            style={{
              cursor: "pointer",
            }}
          ></i>
        </span>
      </OverlayTrigger>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center text-info">
            New Member:
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <Form.Row>
                <Form.Group as={Col} md="2">
                  <Form.Label>Position Name :</Form.Label>
                </Form.Group>
                <Col>
                  {subDomain === "aabea" ? (
                    <Form.Group controlId="postion">
                      <Form.Control
                        as="select"
                        value={position}
                        onChange={(e) => setPosition(e.target.value)}
                      >
                        <option>Please Select Position</option>
                        <option value="president">President</option>
                        <option value="president-elect">President-Elect</option>
                        <option value="secretary">Secretary</option>
                        <option value="treasurer">Treasurer</option>
                        <option value="executive-member">
                          Executive-Member
                        </option>
                        <option value="Chapter-Rep-DC">Chapter Rep-DC</option>
                        <option value="Chapter-Rep-SV">Chapter Rep-SV</option>
                        <option value="Chapter-Rep-SC">Chapter Rep-SC</option>
                        <option value="Chapter-Rep-NY">Chapter Rep-NY</option>
                        <option value="Chapter-Rep-MI">Chapter Rep-MI</option>
                        <option value="Chapter-Rep-NE">Chapter Rep-NE</option>
                      </Form.Control>
                    </Form.Group>
                  ) : (
                    <Form.Group controlId="postion">
                      <Form.Control
                        as="select"
                        value={position}
                        onChange={(e) => setPosition(e.target.value)}
                      >
                        <option>Please Select Position</option>
                        <option value="president">President</option>
                        <option value="president-elect">President-Elect</option>
                        <option value="secretary">Secretary</option>
                        <option value="treasurer">Treasurer</option>
                        <option value="executive-member">
                          Executive-Member
                        </option>
                      </Form.Control>
                    </Form.Group>
                  )}
                </Col>
              </Form.Row>
            </ListGroup.Item>

            <ListGroup.Item>
              <Form.Row>
                <Form.Group as={Col} md="2">
                  <Form.Label>Member Id :</Form.Label>
                </Form.Group>
                <Col>
                  <Form.Group controlId="memberId">
                    <Form.Control
                      as="select"
                      value={cMemberId}
                      onChange={(e) => setCMemberId(e.target.value)}
                    >
                      <option>Select member Id</option>
                      {users &&
                        users.length !== 0 &&
                        users.map((user, index) => (
                          <option key={index} value={user.memberId}>
                            {user.memberId}
                          </option>
                        ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Form.Row>
            </ListGroup.Item>

            <ListGroup.Item>
              <Form.Row>
                <Col>
                  <Form.Group controlId="bio">
                    <Form.Label>Bio :</Form.Label>

                    <CKEditor
                      editor={ClassicEditor}
                      data={bio}
                      onChange={(e, editor) => {
                        const data = editor.getData();
                        setBio(data);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
            </ListGroup.Item>
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Link
            className="btn btn-outline-danger btn-sm rounded"
            onClick={handleClose}
            to="#"
          >
            Cancel
          </Link>

          <Link
            className="btn btn-outline-info btn-sm rounded"
            onClick={() => {
              addCommitteeMember(sessionId, cMemberId, position, bio);
              handleClose();
            }}
            to="#"
          >
            Create
          </Link>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddCommitteeMember;
