import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import swal from 'sweetalert';
import { JOB_NEW_RESET } from '../../constants/careerConstants';
import { newJob, updateJobById } from '../../actions/careerActions';
import Message from '../Message';
import Loader from '../Loader';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Link } from 'react-router-dom';

const JobCreateEdit = ({ history, post }) => {
  const dispatch = useDispatch();

  const checkChapter = window.location.host;

  const [validated, setValidated] = useState(false);

  const [id, setId] = useState('');
  const [title, setTitle] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [web, setWeb] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [city, setCity] = useState('');
  const [body, setBody] = useState('');
  const [state, setState] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [approveStatus, setApproveStatus] = useState('');
  const [rejectStatus, setRejectStatus] = useState('');

  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState([]);

  const jobNew = useSelector((state) => state.jobNew);
  const { loading, error, success } = jobNew;

  useEffect(() => {
    if (success) {
      swal('Success!', success, 'success').then((value) => {
        dispatch({ type: JOB_NEW_RESET });
        history.push('/jobs');
      });
    } else if (error) {
      swal('Error!', error, 'error');
    }
    if (post) {
      setId(post.jobId);
      setTitle(post.title);
      setCompanyName(post.companyName);
      setWeb(post.web);
      setEmail(post.email);
      setPhone(post.phone);
      setCity(post.city);
      setBody(post.body);
      setState(post.state);
      setAttachments(post.attachments);
      setApproveStatus(post.approved);
      setRejectStatus(post.rejected);
    }
  }, [dispatch, history, success, error, post]);

  const uploadFileHandler = async (e) => {
    const file = e.target.files;

    for (let i = 0; i < file.length; i++) {
      const formData = new FormData();
      formData.append(`image`, file[i]);
      setUploading(true);

      try {
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };

        const { data } = await axios.post('/api/upload', formData, config);
        console.log(data);
        attachments.push(data);
        setUploading(false);

        setMessage((prevMessage) => [
          ...prevMessage,
          'Uploaded the file successfully: ' + file[i].name,
        ]);
      } catch (error) {
        console.error(error);
        setUploading(false);
        setMessage((prevMessage) => [
          ...prevMessage,
          'Could not upload the file: ' + file[i].name,
        ]);
      }
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      if (post) {
        dispatch(
          updateJobById({
            id,
            title,
            companyName,
            web,
            email,
            phone,
            city,
            state,
            body,
            attachments,
            checkChapter,
            approveStatus,
            rejectStatus,
          })
        );
      } else {
        dispatch(
          newJob({
            title,
            companyName,
            web,
            email,
            phone,
            city,
            state,
            body,
            attachments,
            checkChapter,
          })
        );
      }
    }

    setValidated(true);
  };

  return (
    <>
      {/* Card Start */}
      <Card border='info'>
        <Card.Header className='text-center text-info' as='h2'>
          {post ? <>Edit Job Post</> : <>New Job Post</>}
        </Card.Header>
        <Card.Body>
          {/* {message && <Message variant='danger'>{message}</Message>} */}
          {error && <Message variant='danger'>{error}</Message>}
          {loading && <Loader />}
          {success ? (
            <Message variant='success'>{success}</Message>
          ) : (
            <>
              <Form noValidate validated={validated} onSubmit={submitHandler}>
                <Form.Row>
                  {/* <Row> */}
                  <Form.Group as={Col} md='2'>
                    <Form.Label>Job Title**</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} md='10' controlId='title'>
                    <Form.Control
                      required
                      type='text'
                      placeholder='Job Title'
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} md='2'>
                    <Form.Label>Company Name**</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} md='10' controlId='companyName'>
                    <Form.Control
                      required
                      type='text'
                      placeholder='Please enter company name'
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} md='2'>
                    <Form.Label>Company Website</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} md='10' controlId='companyWeb'>
                    <Form.Control
                      type='text'
                      placeholder='Please enter company website address'
                      value={web}
                      onChange={(e) => setWeb(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} md='2'>
                    <Form.Label>Contact Email**</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} md='10' controlId='email'>
                    <Form.Control
                      required
                      type='email'
                      placeholder='Please enter contact email'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} md='2'>
                    <Form.Label>Contact Phone</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} md='10' controlId='phone'>
                    <Form.Control
                      type='text'
                      placeholder='Please enter contact phone number (optional)'
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} md='2'>
                    <Form.Label>City</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} md='10' controlId='city'>
                    <Form.Control
                      type='text'
                      placeholder='Please enter city'
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} md='2'>
                    <Form.Label>State</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} md='10' controlId='state'>
                    <Form.Control
                      type='text'
                      placeholder='Please enter state'
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} md='2'>
                    <Form.Label>Job Details**</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} md='10' controlId='body'>
                    <CKEditor
                      editor={ClassicEditor}
                      data={body}
                      onChange={(e, editor) => {
                        const data = editor.getData();
                        setBody(data);
                      }}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md='2' controlId='attachments'>
                    <Form.Label>Attachment/s</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} md='10' controlId='photo'>
                    <Form.File
                      custom
                      id='image-file'
                      label='Choose File'
                      multiple
                      onChange={uploadFileHandler}
                    ></Form.File>
                    {uploading && <Loader />}

                    {message.length > 0 && (
                      <div className='alert alert-secondary' role='alert'>
                        <ul>
                          {message.map((item, i) => {
                            return <li key={i}>{item}</li>;
                          })}
                        </ul>
                      </div>
                    )}
                  </Form.Group>{' '}
                  ** Required
                </Form.Row>
                <br />
                {/* */}
                <Form.Group as={Row}>
                  {/* md={{ span: 10, offset: 2 }} */}

                  {post ? (
                    <Col>
                      <Button
                        type='submit'
                        className='btn btn-outline-info btn-sm btn-block rounded'
                        variant='transparent'
                      >
                        {post ? <>Update Post</> : <>Post Job</>}
                      </Button>

                      <Link
                        to='/career'
                        className='btn btn-outline-danger btn-sm btn-block rounded'
                        // variant='transparent'
                      >
                        {post ? <>cancel</> : <>Post Job</>}
                      </Link>
                    </Col>
                  ) : (
                    <Col>
                      <Button
                        type='submit'
                        className='btn btn-outline-info btn-sm btn-block rounded'
                        variant='transparent'
                      >
                        {post ? <>Update Post</> : <>Post Job</>}
                      </Button>
                    </Col>
                  )}
                </Form.Group>
              </Form>
            </>
          )}
        </Card.Body>
      </Card>
      {/* Card End */}
    </>
  );
};

export default JobCreateEdit;
