import React, { useState } from 'react';
import {
  Col,
  Form,
  ListGroup,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

const EditProgram = ({ programId, programName, updateProgram }) => {
  const [show, setShow] = useState(false);
  const [editProgramName, setEditProgramName] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setEditProgramName(programName);
    setShow(true);
  };

  return (
    <>
      <OverlayTrigger
        overlay={<Tooltip id='tooltip-disabled'>Edit Program Name</Tooltip>}
      >
        <span onClick={handleShow}>
          <i
            className='far fa-edit action'
            style={{
              color: '#4285F4',
              cursor: 'pointer',
            }}
            // style={{ cursor: 'pointer' }}
          ></i>
        </span>
      </OverlayTrigger>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        size='lg'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className='text-center text-info'>
            New Program for Event
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={{ span: 10 }}>
              <ListGroup variant='flush'>
                <ListGroup.Item>
                  <Form.Row>
                    <Form.Group as={Col} md='2'>
                      <Form.Label>Program Name :</Form.Label>
                    </Form.Group>
                    <Col>
                      <Form.Group as={Col} md='10' controlId='programName'>
                        <Form.Control
                          required
                          type='text'
                          placeholder='New Program Name..'
                          value={editProgramName}
                          onChange={(e) => setEditProgramName(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Link
            className='btn btn-outline-danger btn-sm rounded'
            to='#'
            onClick={handleClose}
          >
            Cancel
          </Link>

          <Link
            className='btn btn-outline-info btn-sm rounded'
            to='#'
            onClick={() => {
              updateProgram(programId, editProgramName);
              handleClose();
            }}
          >
            Update
          </Link>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditProgram;
