import React from 'react';
import _ from 'lodash';
import parse from 'html-react-parser';

import { Accordion, Card, ListGroup, Media } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Bods = ({ bods }) => {
  const groups = _(bods)
    .groupBy('tenure')
    .map((committees, tenure) => {
      const grouped = committees.map((Committee) => Committee.committees);
      return {
        tenure,
        grouped,
      };
    })
    .value();
  console.log(groups);

  return (
    <div>
      <Accordion defaultActiveKey={'0'}>
        {groups.map((tenure, idx) => (
          <Card key={idx}>
            <Accordion.Toggle
              as={Card.Header}
              eventKey={tenure.tenure}
              className='text-center text-info h5'
              style={{ cursor: 'pointer' }}
            >
              {tenure.tenure}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={tenure.tenure}>
              <>
                {tenure.grouped.map((chapter, index) => (
                  <div key={index}>
                    {chapter.map((cMember, index) => (
                      <div key={index}>
                        <Card.Body>
                          <ListGroup variant='flush'>
                            <Media>
                              <img
                                width={164}
                                height={164}
                                className='mr-3'
                                src={cMember.member.profilePicture}
                                alt={cMember.member.firstName}
                              />
                              <Media.Body>
                                <h5 className='text-info'>
                                  {cMember.member.mInit}{' '}
                                  {cMember.member.firstName}{' '}
                                  {cMember.member.lastName}
                                  {','} {cMember.chapter.chapterName}
                                </h5>
                                <hr />
                                <span className='text-justify'>
                                  {parse(cMember.bio.substring(0, 100))}
                                  ...
                                  <Link to={`/committee/${cMember.cId}`}>
                                    Read more
                                  </Link>
                                </span>
                              </Media.Body>
                            </Media>
                          </ListGroup>
                        </Card.Body>
                      </div>
                    ))}
                  </div>
                ))}
              </>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    </div>
  );
};

export default Bods;
