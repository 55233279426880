import React, { useEffect } from "react";
import { Card, Media, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import { allHelps } from "../../actions/helpActions";

const HomeHelp = () => {
  const dispatch = useDispatch();

  const helpAll = useSelector((state) => state.helpAll);
  const {
    loading: helpAllLoading,
    error: helpAllError,
    helpContacts,
  } = helpAll;

  const checkChapter = window.location.host;
  // const subDomain = window.location.host.split('.')[0];

  useEffect(() => {
    dispatch(allHelps(checkChapter)); // done
  }, [dispatch, checkChapter]);
  return (
    <>
      {helpAllLoading ? (
        <Loader />
      ) : helpAllError ? (
        <Message variant="danger">{helpAllError}</Message>
      ) : helpContacts && helpContacts.length !== 0 ? (
        <Card className="mb-2">
          <Card.Header className="text-info text-center" as="h4">
            For Help
          </Card.Header>
          <Card.Body>
            {helpContacts.map((helpContact, index) => (
              <div key={index}>
                <Media>
                  <Media.Body className="text-center">
                    {/* <img
                    width={54}
                    height={54}
                    className='mr-1 align-items-center'
                    src={helpContact.profilePicture}
                    alt=''
                  /> */}
                    <Image
                      width={64}
                      height={64}
                      src={helpContact.profilePicture}
                      roundedCircle
                    />
                    <h5>{helpContact.contactName}</h5>
                    <p>{helpContact.helpFor}</p>
                    <p>
                      Email:{" "}
                      <a href={`mailTo: ${helpContact.contactEmail}`}>
                        {helpContact.contactEmail}
                      </a>
                    </p>

                    <p>
                      Phone:{" "}
                      <a href={`tel: ${helpContact.contactPhone}`}>
                        {helpContact.contactPhone}
                      </a>
                    </p>
                  </Media.Body>
                </Media>
                <hr />
              </div>
            ))}
          </Card.Body>
        </Card>
      ) : null}
    </>
  );
};

export default HomeHelp;
