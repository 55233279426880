import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  userAddReducer,
  userAllListReducer,
  userApproveReducer,
  userCreateAdminReducer,
  userDeleteAdminReducer,
  userDeleteReducer,
  userDetailsByIdReducer,
  userDetailsReducer,
  userDonateReducer,
  userDonationDetailsReducer,
  userEmailVerificationReducer,
  userListReducer,
  userLoginReducer,
  userPasswordResetReducer,
  userPasswordUpdateReducer,
  userPaymentDetailsReducer,
  userPayReducer,
  userPendingDeleteReducer,
  userPendingDetailsReducer,
  userPendingListReducer,
  userRegisterReducer,
  userUpdateProfileReducer,
  userUptadeReducer,
  userVerificationEmailResendReducer,
} from "./reducers/userReducers";
import {
  chapterByIdReducer,
  chapterBySubDomainReducer,
  chapterDeleteReducer,
  chapterDonationAllReducer,
  chapterListReducer,
  chapterPaymentAllReducer,
  chapterRegisterReducer,
  chapterSettingsNewReducer,
  chapterSettingsReducer,
  chapterSettingsUpdateReducer,
  chapterUpdateReducer,
} from "./reducers/chapterReducers";
import {
  paymentTypeDeleteReducer,
  paymentTypeListReducer,
  paymentTypeRegisterReducer,
} from "./reducers/paymentTypeReducers";
import {
  announcementAllReducer,
  announcementByIdReducer,
  announcementDeleteReducer,
  announcementNewReducer,
  announcementUpdateReducer,
} from "./reducers/announcementReducers";
import {
  missionAllReducer,
  missionByIdReducer,
  missionDeleteReducer,
  missionNewReducer,
  missionUpdateReducer,
} from "./reducers/missionReducers";
import {
  vissionAllReducer,
  vissionByIdReducer,
  vissionDeleteReducer,
  vissionNewReducer,
  vissionUpdateReducer,
} from "./reducers/vissionReducers";
import {
  historyAllReducer,
  historyByIdReducer,
  historyDeleteReducer,
  historyNewReducer,
  historyUpdateReducer,
} from "./reducers/historyReducers";
import {
  imageAllReducer,
  imageByEventReducer,
  imageByIdReducer,
  imageDeleteReducer,
  imageHomeScreenReducer,
  imageNavbarReducer,
  imageNewReducer,
} from "./reducers/imageReducers";
import {
  bodReducer,
  cMemberAllReducer,
  cMemberByIdReducer,
  cMemberDeleteReducer,
  cMemberNewReducer,
  cMemberUpdateReducer,
  sessionByIdReducer,
  sessionDeleteReducer,
  sessionNewReducer,
  sessionUpdateReducer,
} from "./reducers/committeeReducers";
import {
  eventAllPastReducer,
  eventAllReducer,
  eventAllUpcomingReducer,
  eventByIdReducer,
  eventContactAllReducer,
  eventContactByIdReducer,
  eventContactDeleteReducer,
  eventContactNewReducer,
  eventContactUpdateReducer,
  eventDeleteFlyer,
  eventDeleteReducer,
  eventGroupAllReducer,
  eventGroupByIdReducer,
  eventGroupDeleteReducer,
  eventGroupNewReducer,
  eventGroupUpdateReducer,
  eventHotelAllReducer,
  eventHotelByIdReducer,
  eventHotelDeleteReducer,
  eventHotelNewReducer,
  eventHotelUpdateReducer,
  eventNewReducer,
  eventProgramAllReducer,
  eventProgramByIdReducer,
  eventProgramDeleteReducer,
  eventProgramNewReducer,
  eventProgramUpdateReducer,
  eventPublishReducer,
  eventRegisterReducer,
  eventRoomAllReducer,
  eventRoomByIdReducer,
  eventRoomDeleteReducer,
  eventRoomNewReducer,
  eventRoomUpdateReducer,
  eventUnpublishReducer,
  eventUpdateReducer,
} from "./reducers/eventReducers";
import {
  emailAllReducer,
  emailByIdReducer,
  emailNewReducer,
} from "./reducers/emailReducers";
import {
  categoryAllReducer,
  categoryByIdReducer,
  categoryDeleteReducer,
  categoryNewReducer,
  categoryUpdateReducer,
} from "./reducers/categoryReducers";
import {
  blogAllReducer,
  blogByIdReducer,
  blogDeleteReducer,
  blogNewReducer,
  blogUpdateReducer,
} from "./reducers/blogReducers";
import {
  commentDeleteReducer,
  commentNewReducer,
  commentUpdateReducer,
} from "./reducers/commentReducers";
import {
  replyDeleteReducer,
  replyNewReducer,
  replyUpdateReducer,
} from "./reducers/replyReducers";
import {
  donationTypeDeleteReducer,
  donationTypeListReducer,
  donationTypeRegisterReducer,
} from "./reducers/donationTypeReducers";
import {
  helpAllReducer,
  helpByIdReducer,
  helpDeleteReducer,
  helpNewReducer,
  helpUpdateReducer,
} from "./reducers/helpContactReducers";
import {
  linkAllReducer,
  linkByIdReducer,
  linkDeleteReducer,
  linkNewReducer,
  linkUpdateReducer,
} from "./reducers/quickLinkReducers";
import { cartReducer } from "./reducers/cartReducers";
import {
  documentAllReducer,
  documentByIdReducer,
  documentDeleteReducer,
  documentNewReducer,
} from "./reducers/documentReducers";
import {
  activityAllReducer,
  activityByIdReducer,
  activityDeleteReducer,
  activityNewReducer,
  activityUpdateReducer,
} from "./reducers/activityReducers";
import {
  sponsorAllReducer,
  sponsorByIdReducer,
  sponsorDeleteReducer,
  sponsorNewReducer,
  sponsorUpdateReducer,
} from "./reducers/sponsorReducer";
import {
  advAllReducer,
  advByIdReducer,
  advDeleteReducer,
  advNewReducer,
  advsByEventReducer,
  advUpdateReducer,
} from "./reducers/advertisementReducer";
import {
  memberTypeAllReducer,
  memberTypeByIdReducer,
  memberTypeDeleteReducer,
  memberTypeNewReducer,
  memberTypeUpdateReducer,
} from "./reducers/memberTypeReducer";
import {
  jobAllReducer,
  jobByIdReducer,
  jobDeleteReducer,
  jobNewReducer,
  jobUpdateReducer,
} from "./reducers/careerReducers";
import {
  newsletterSubReducer,
  newsletterUnSubReducer,
} from "./reducers/newsletterReducers";

const reducer = combineReducers({
  userRegister: userRegisterReducer,
  userEmailVerify: userEmailVerificationReducer,
  userVerifyEmailResend: userVerificationEmailResendReducer,

  userLogin: userLoginReducer,
  userPasswordReset: userPasswordResetReducer,
  userPasswordUpdate: userPasswordUpdateReducer,

  userDetails: userDetailsReducer,
  userDetailsById: userDetailsByIdReducer,
  userUpdateProfile: userUpdateProfileReducer,

  userList: userListReducer,
  userAllList: userAllListReducer,

  userPendingList: userPendingListReducer,
  userPendingDetails: userPendingDetailsReducer,
  userApprove: userApproveReducer,
  userPendingDelete: userPendingDeleteReducer,
  userAdd: userAddReducer,

  userCreateAdmin: userCreateAdminReducer,
  userDeleteAdmin: userDeleteAdminReducer,

  userDelete: userDeleteReducer,
  userUpdate: userUptadeReducer,

  userPaymentDetails: userPaymentDetailsReducer,
  userPay: userPayReducer,
  userDonate: userDonateReducer,
  userDonateDetails: userDonationDetailsReducer,

  chapterRegister: chapterRegisterReducer,
  chapterAll: chapterListReducer,
  chapterById: chapterByIdReducer,
  chapterUpdate: chapterUpdateReducer,
  chapterDelete: chapterDeleteReducer,

  sponsorNew: sponsorNewReducer,
  sponsorAll: sponsorAllReducer,
  sponsorById: sponsorByIdReducer,
  sponsorUpdate: sponsorUpdateReducer,
  sponsorDelete: sponsorDeleteReducer,

  advNew: advNewReducer,
  advAll: advAllReducer,
  advsByEvent: advsByEventReducer,
  advById: advByIdReducer,
  advUpdate: advUpdateReducer,
  advDelete: advDeleteReducer,

  memberTypeNew: memberTypeNewReducer,
  memberTypeAll: memberTypeAllReducer,
  memberTypeById: memberTypeByIdReducer,
  memberTypeUpdate: memberTypeUpdateReducer,
  memberTypeDelete: memberTypeDeleteReducer,

  announcementNew: announcementNewReducer,
  announcementAll: announcementAllReducer,
  announcementById: announcementByIdReducer,
  announcementUpdate: announcementUpdateReducer,
  announcementDelete: announcementDeleteReducer,

  missionNew: missionNewReducer,
  missionAll: missionAllReducer,
  missionById: missionByIdReducer,
  missionUpdate: missionUpdateReducer,
  missionDelete: missionDeleteReducer,

  vissionNew: vissionNewReducer,
  vissionAll: vissionAllReducer,
  vissionById: vissionByIdReducer,
  vissionUpdate: vissionUpdateReducer,
  vissionDelete: vissionDeleteReducer,

  historyNew: historyNewReducer,
  historyAll: historyAllReducer,
  historyById: historyByIdReducer,
  historyUpdate: historyUpdateReducer,
  historyDelete: historyDeleteReducer,

  imageNew: imageNewReducer,
  imageById: imageByIdReducer,
  imageNavbar: imageNavbarReducer,
  imageHomeScreen: imageHomeScreenReducer,
  imageByEvent: imageByEventReducer,
  imageAll: imageAllReducer,
  imageDelete: imageDeleteReducer,

  paymentTypeRegister: paymentTypeRegisterReducer,
  paymentTypeList: paymentTypeListReducer,
  paymentTypeDelete: paymentTypeDeleteReducer,

  donationTypeRegister: donationTypeRegisterReducer,
  donationTypeList: donationTypeListReducer,
  donationTypeDelete: donationTypeDeleteReducer,

  cMemberNew: cMemberNewReducer,
  cMemberAll: cMemberAllReducer,
  cMemberById: cMemberByIdReducer,
  cMemberUpdate: cMemberUpdateReducer,
  cMemberDelete: cMemberDeleteReducer,

  bod: bodReducer,

  sessionNew: sessionNewReducer,
  sessionById: sessionByIdReducer,
  sessionUpdate: sessionUpdateReducer,
  sessionDelete: sessionDeleteReducer,

  eventNew: eventNewReducer,
  eventAll: eventAllReducer,
  eventPastAll: eventAllPastReducer,
  eventUpcomingAll: eventAllUpcomingReducer,
  eventById: eventByIdReducer,
  eventUpdate: eventUpdateReducer,
  eventDelete: eventDeleteReducer,
  flyerDelete: eventDeleteFlyer,

  eventContactNew: eventContactNewReducer,
  eventContactAll: eventContactAllReducer,
  eventContactById: eventContactByIdReducer,
  eventContactUpdate: eventContactUpdateReducer,
  eventContactDelete: eventContactDeleteReducer,

  eventProgramNew: eventProgramNewReducer,
  eventProgramAll: eventProgramAllReducer,
  eventProgramById: eventProgramByIdReducer,
  eventProgramUpdate: eventProgramUpdateReducer,
  eventProgramDelete: eventProgramDeleteReducer,

  eventGroupNew: eventGroupNewReducer,
  eventGroupAll: eventGroupAllReducer,
  eventGroupById: eventGroupByIdReducer,
  eventGroupUpdate: eventGroupUpdateReducer,
  eventGroupDelete: eventGroupDeleteReducer,

  eventHotelNew: eventHotelNewReducer,
  eventHotelAll: eventHotelAllReducer,
  eventHotelById: eventHotelByIdReducer,
  eventHotelUpdate: eventHotelUpdateReducer,
  eventHotelDelete: eventHotelDeleteReducer,

  eventRoomNew: eventRoomNewReducer,
  eventRoomAll: eventRoomAllReducer,
  eventRoomById: eventRoomByIdReducer,
  eventRoomUpdate: eventRoomUpdateReducer,
  eventRoomDelete: eventRoomDeleteReducer,

  eventPublish: eventPublishReducer,
  eventUnpublish: eventUnpublishReducer,

  eventRegister: eventRegisterReducer,
  cart: cartReducer,

  chapterSettingsNew: chapterSettingsNewReducer,
  chapterSettingsAll: chapterSettingsReducer,
  chapterSettingsUpdate: chapterSettingsUpdateReducer,
  chapterBySubDomain: chapterBySubDomainReducer,
  chapterPaymentAll: chapterPaymentAllReducer,
  chapterDonationAll: chapterDonationAllReducer,

  emailNew: emailNewReducer,
  emailAll: emailAllReducer,
  emailById: emailByIdReducer,

  categoryNew: categoryNewReducer,
  categoryAll: categoryAllReducer,
  categoryById: categoryByIdReducer,
  categoryUpdate: categoryUpdateReducer,
  categoryDelete: categoryDeleteReducer,

  activityNew: activityNewReducer,
  activityAll: activityAllReducer,
  activityById: activityByIdReducer,
  activityUpdate: activityUpdateReducer,
  activityDelete: activityDeleteReducer,

  blogNew: blogNewReducer,
  blogAll: blogAllReducer,
  blogById: blogByIdReducer,
  blogUpdate: blogUpdateReducer,
  blogDelete: blogDeleteReducer,

  commentNew: commentNewReducer,
  commentUpdate: commentUpdateReducer,
  commentDelete: commentDeleteReducer,

  replyNew: replyNewReducer,
  replyUpdate: replyUpdateReducer,
  replyDelete: replyDeleteReducer,

  helpNew: helpNewReducer,
  helpAll: helpAllReducer,
  helpById: helpByIdReducer,
  helpUpdate: helpUpdateReducer,
  helpDelete: helpDeleteReducer,

  linkNew: linkNewReducer,
  linkAll: linkAllReducer,
  linkById: linkByIdReducer,
  linkUpdate: linkUpdateReducer,
  linkDelete: linkDeleteReducer,

  documentNew: documentNewReducer,
  documentAll: documentAllReducer,
  documentById: documentByIdReducer,
  documentDelete: documentDeleteReducer,

  jobNew: jobNewReducer,
  jobAll: jobAllReducer,
  jobById: jobByIdReducer,
  jobUpdate: jobUpdateReducer,
  jobDelete: jobDeleteReducer,

  newsletterSub: newsletterSubReducer,
  newsletterUnSub: newsletterUnSubReducer,
});

const cartItemsFromStorage = localStorage.getItem("cartItems")
  ? JSON.parse(localStorage.getItem("cartItems"))
  : {};

const programsFromStorage = localStorage.getItem("programs")
  ? JSON.parse(localStorage.getItem("programs"))
  : [];

const hotelsFromStorage = localStorage.getItem("hotels")
  ? JSON.parse(localStorage.getItem("hotels"))
  : [];

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  cart: {
    cartItems: cartItemsFromStorage,
    programs: programsFromStorage,
    hotels: hotelsFromStorage,
  },
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

let enhancer = applyMiddleware(...middleware);

// Check if the environment is development and Redux DevTools extension is available
if (process.env.NODE_ENV === "development") {
  enhancer = composeWithDevTools(applyMiddleware(...middleware));
}

const store = createStore(
  reducer,
  initialState,
  enhancer
  // composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
