import React, { useEffect, useState } from "react";

import {
  Form,
  Row,
  Col,
  Card,
  Container,
  ListGroup,
  Button,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../Message";
import Loader from "../../Loader";
import { getEventById, registerEvent } from "../../../actions/eventActions";

import premiumPackages from "./2024PremiumPackages.json";

import PersonNumberInput from "../BiAnnualEvent2022/PersonNumberInput";
import swal from "sweetalert";
import { EVENT_REGISTER_RESET } from "../../../constants/eventConstants";
import axios from "axios";
import { PayPalButton } from "react-paypal-button-v2";
import ZellePayment from "./ZellePayment";

const getFormattedPrice = (price) => `$${price.toFixed(2)}`;

const CentralEventRegistration2024 = ({ match, history }) => {
  const { id } = match.params;

  const [isMember] = useState(false);
  const [programs] = useState([]);
  const [memberId] = useState("");

  const [mInit, setMInit] = useState("Mr");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [contributionAmount, setContributionAmount] = useState(0);

  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPackageSelected, setTotalPackageSelected] = useState("");
  const [finalSelection, setFinalSelection] = useState([]);

  const [readyToPay, setReadyToPay] = useState(false);

  const [totalPerson, setTotalPerson] = useState(0);
  const [sdkReady, setSdkReady] = useState(true);

  const dispatch = useDispatch();

  const eventById = useSelector((state) => state.eventById);
  const { loading, error, event } = eventById;

  const eventRegister = useSelector((state) => state.eventRegister);
  const {
    loading: eventRegisterLoading,
    error: eventRegisterError,
    success: eventRegisterSuccess,
  } = eventRegister;

  const [selectedPackages, setSelectedPackages] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
  });
  const [packageNumbers, setPackageNumbers] = useState({
    1: 0,
    2: 0,
    3: 0,
    4: 0,
  });

  const [isCheckboxDisabled, setCheckboxDisabled] = useState(false);
  const [maxUserInput, setMaxUserInput] = useState(null);

  const handlePackageSelection = (packageId) => {
    setSelectedPackages((prevState) => {
      const newState = { ...prevState };

      if (packageId === "1") {
        newState[1] = !prevState[1];

        newState[2] = newState[1];
      } else if (packageId === "2") {
        if (prevState[1]) {
          return prevState;
        } else {
          newState[2] = !prevState[2];
        }
      } else {
        newState[packageId] = !prevState[packageId];
      }

      return newState;
    });
  };

  const handleNumberChange = (packageId, qty) => {
    setPackageNumbers((prevNumbers) => {
      const newNumbers = { ...prevNumbers, [packageId]: qty };

      if (packageId === "2" && selectedPackages[1]) {
        newNumbers[1] = qty;
      }

      return newNumbers;
    });
  };

  useEffect(() => {
    const calculateSummary = () => {
      const summaryArray = [];

      premiumPackages.forEach((packageType) => {
        if (selectedPackages[packageType.packageId]) {
          const numberOfPersons = packageNumbers[packageType.packageId] || 1;

          const totalPrice = packageType.price * numberOfPersons;

          const summaryItem = {
            ...packageType,
            numberOfPersons,
            totalPrice,
          };

          summaryArray.push(summaryItem);
        }
      });

      setFinalSelection(summaryArray);

      if (summaryArray.length !== 0) {
        let packageName = "";
        const totalSelected = summaryArray.map((item, index) => {
          if (packageName !== "") {
            return (packageName = ` + ${item.name}`);
          } else {
            return (packageName = `${item.name}`);
          }
        });

        setTotalPackageSelected(totalSelected);
      } else {
        setTotalPackageSelected(["none"]);
      }

      const totalPrice = summaryArray.reduce((sum, item, index) => {
        return Number(sum) + Number(item.totalPrice);
      }, 0);

      setTotalPrice(totalPrice);

      const totalPerson = summaryArray.reduce((sum, item, index) => {
        return Number(sum) + Number(item.numberOfPersons);
      }, 0);

      setTotalPerson(totalPerson);
    };

    calculateSummary();
  }, [selectedPackages, packageNumbers]);

  useEffect(() => {
    dispatch(getEventById(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (
      !!event &&
      event?.eventRegistrations?.length &&
      event?.eventRegistrations?.length !== 0
    ) {
      const totalNumberOfAdults = event?.eventRegistrations?.reduce(
        (sum, registration) => {
          if (registration.note.includes("Friday (Sept 6th)")) {
            return sum + registration.numberOfAdults;
          }
          return sum;
        },
        0
      );

      // console.log({ totalNumberOfAdults });
      // console.log("event.minorFee: ", event.minorFee);

      if (
        totalNumberOfAdults === "undefined" ||
        totalNumberOfAdults >= event.minorFee
      ) {
        setCheckboxDisabled(true);
      } else {
        setCheckboxDisabled(false);
        setMaxUserInput(event.minorFee - totalNumberOfAdults);
      }
    } else {
      setMaxUserInput(event.minorFee);
    }
  }, [event, event.minorFee, event.eventRegistrations]);

  const submitHandler = (e) => {
    e.preventDefault();

    setReadyToPay(true);
  };

  const checkChapter = window.location.host;

  useEffect(() => {
    if (eventRegisterSuccess) {
      swal("Success!", "Registration successfull", "success").then(() => {
        dispatch({ type: EVENT_REGISTER_RESET });
        history.push("/");
      });
    } else if (eventRegisterError) {
      swal("Error!", eventRegisterError, "error");
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const addPaypalScript = async () => {
      const { data: clientId } = await axios.get(
        `/api/chapters/paypal/${checkChapter}`,
        config
      );

      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&disable-funding=paylater`;
      script.async = true;
      script.onload = () => {
        setSdkReady(true);
      };
      document.body.appendChild(script);
    };

    if (!window.paypal) {
      addPaypalScript();
    } else {
      setSdkReady(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    history,
    checkChapter,
    eventRegisterSuccess,
    eventRegisterError,
  ]);

  const successPaymentHandler = (paymentResult) => {
    try {
      dispatch(
        registerEvent({
          eventId: id,
          eventName: event.eventName,
          mInit,
          firstName,
          lastName,
          isMember,
          memberId,
          email,
          phone,
          paymentResult,
          programs,
          totalAmount: totalPrice + contributionAmount,
          subEventFee: 0,
          hotelRent: 0,
          contributionAmount,
          selectedPackages: finalSelection,
        })
      );
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <Container>
      <>
        <Card border="info" className="mt-2">
          <Card.Header className="text-center text-info" as="h5">
            Event Registration
          </Card.Header>
          <Card.Body>
            {eventRegisterLoading ? (
              <Loader />
            ) : (
              <Form onSubmit={submitHandler}>
                <Row>
                  {loading ? (
                    <Loader />
                  ) : error ? (
                    <Message variant="danger">{error}</Message>
                  ) : event ? (
                    event && (
                      <>
                        <Col md={8}>
                          <ListGroup variant="flush">
                            <ListGroup.Item>
                              <Row>
                                <Col md={3} className="text-info p-0" as="h5">
                                  Name{" "}
                                </Col>
                                <Col>
                                  <>
                                    <Row>
                                      <Col md={3}>Title: </Col>
                                      <Col>
                                        <Form.Group controlId="mInit">
                                          <Form.Control
                                            required
                                            as="select"
                                            type="text"
                                            value={mInit}
                                            onChange={(e) =>
                                              setMInit(e.target.value)
                                            }
                                          >
                                            <option value="Mr">Mr</option>
                                            <option value="Mrs">Mrs</option>
                                            <option value="Miss">Ms</option>
                                          </Form.Control>
                                        </Form.Group>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col md={3}>First Name: </Col>
                                      <Col>
                                        <Form.Group controlId="firstName">
                                          <Form.Control
                                            type="firstName"
                                            placeholder="Please Enter Your First Name.."
                                            value={firstName}
                                            onChange={(e) =>
                                              setFirstName(e.target.value)
                                            }
                                          ></Form.Control>
                                        </Form.Group>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col md={3}>Last Name: </Col>
                                      <Col>
                                        <Form.Group controlId="lastName">
                                          <Form.Control
                                            type="lastName"
                                            placeholder="Please Enter Your Last Name.."
                                            value={lastName}
                                            onChange={(e) =>
                                              setLastName(e.target.value)
                                            }
                                          ></Form.Control>
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </>
                                </Col>
                              </Row>
                            </ListGroup.Item>

                            {/* Contact Details */}
                            <ListGroup.Item>
                              <Row>
                                <Col md={3} className="text-info p-0" as="h5">
                                  Contact Details:{" "}
                                </Col>
                                <Col>
                                  <>
                                    <Row>
                                      <Col md={3}>Phone Number :</Col>
                                      <Col>
                                        <Form.Group controlId="phone">
                                          <Form.Control
                                            type="phone"
                                            placeholder="Enter Your Phone Number.."
                                            value={phone}
                                            onChange={(e) =>
                                              setPhone(e.target.value)
                                            }
                                          ></Form.Control>
                                        </Form.Group>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col md={3}>Email :</Col>
                                      <Col>
                                        <Form.Group controlId="email">
                                          <Form.Control
                                            required
                                            type="email"
                                            placeholder="Enter Email.."
                                            value={email}
                                            onChange={(e) =>
                                              setEmail(e.target.value)
                                            }
                                          ></Form.Control>
                                          <Form.Control.Feedback type="invalid">
                                            **Required
                                          </Form.Control.Feedback>
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </>
                                </Col>
                              </Row>
                            </ListGroup.Item>

                            <>
                              <ListGroup.Item>
                                <Row>
                                  <Col md={3} className="text-info p-0" as="h5">
                                    Packages:
                                  </Col>
                                  <Col>
                                    <Form.Group controlId="packageType">
                                      {premiumPackages.map((packageType, i) => (
                                        <Form.Check
                                          key={i}
                                          className="m-3"
                                          type="checkbox"
                                          id={`package-${packageType.packageId}`}
                                        >
                                          <Form.Check.Input
                                            type="checkbox"
                                            name={packageType.name}
                                            value={packageType.name}
                                            checked={
                                              selectedPackages[
                                                packageType.packageId
                                              ]
                                            }
                                            onChange={() =>
                                              handlePackageSelection(
                                                packageType.packageId
                                              )
                                            }
                                            disabled={
                                              !!(
                                                packageType.name.includes(
                                                  "Friday (Sept 6th)"
                                                ) && isCheckboxDisabled
                                              ) ||
                                              packageType.packageId === "2" ||
                                              packageType.packageId === "3" ||
                                              packageType.packageId === "4"
                                            }
                                          />
                                          <Form.Check.Label>
                                            <strong
                                              style={{
                                                color: `${packageType.color}`,
                                              }}
                                            >
                                              {packageType.name}
                                            </strong>
                                            :{" "}
                                            {packageType.packageId ===
                                            "9" ? null : (
                                              <>${packageType.price}</>
                                            )}{" "}
                                            ({packageType.description})
                                            {!!(
                                              packageType.name.includes(
                                                "Friday (Sept 6th)"
                                              ) && isCheckboxDisabled
                                            ) && (
                                              <h6 className="text-danger">
                                                SOLD OUT - To be in waiting list
                                                please call at 562-916-6433
                                              </h6>
                                            )}
                                            {!!(
                                              packageType.packageId === "2" ||
                                              packageType.packageId === "3" ||
                                              packageType.packageId === "4"
                                            ) && (
                                              <h6 className="text-danger">
                                                SOLD OUT
                                              </h6>
                                            )}
                                          </Form.Check.Label>

                                          {packageType.packageId !== "1" &&
                                            selectedPackages[
                                              packageType.packageId
                                            ] && (
                                              <PersonNumberInput
                                                person={
                                                  packageNumbers[
                                                    packageType.packageId
                                                  ]
                                                }
                                                setNumberInput={(qty) =>
                                                  handleNumberChange(
                                                    packageType.packageId,
                                                    qty
                                                  )
                                                }
                                                maxUserInput={
                                                  packageType.packageId ===
                                                    "2" && selectedPackages[1]
                                                    ? maxUserInput
                                                    : null
                                                }
                                              />
                                            )}
                                        </Form.Check>
                                      ))}
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </ListGroup.Item>
                            </>

                            {/* Extra Contribution (optional): */}
                            <ListGroup.Item>
                              <Row>
                                <Col md={3} className="text-info p-0" as="h5">
                                  Extra Contribution (optional):
                                </Col>
                                <Col>
                                  <>
                                    <Row>
                                      <Col md={3}>USD :</Col>
                                      <Col>
                                        <Form.Group controlId="contributionAmount">
                                          <Form.Control
                                            type="number"
                                            min="0"
                                            placeholder="Contibute any amount"
                                            value={contributionAmount}
                                            onChange={(e) =>
                                              setContributionAmount(
                                                e.target.value
                                              )
                                            }
                                          ></Form.Control>
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </>
                                </Col>
                              </Row>
                            </ListGroup.Item>
                          </ListGroup>
                        </Col>

                        {/* Summary */}
                        <Col md={4}>
                          <Card>
                            <ListGroup variant="flush">
                              <ListGroup.Item
                                style={{ backgroundColor: "#FFD700" }}
                              >
                                <h5 className="text-info text-center">
                                  Summary
                                </h5>
                              </ListGroup.Item>

                              <ListGroup.Item
                                style={{ backgroundColor: "#e6eaf3" }}
                              >
                                <Row>
                                  <Col md={3} className="p-0 m-0">
                                    Event Name:
                                  </Col>
                                  <Col>{event.eventName}</Col>
                                </Row>
                              </ListGroup.Item>

                              <ListGroup.Item
                                style={{ backgroundColor: "#e6eaf3" }}
                              >
                                <Row>
                                  <Col>Selected Package/s:</Col>
                                  <Col>
                                    <strong>{totalPackageSelected}</strong>
                                  </Col>
                                </Row>
                              </ListGroup.Item>

                              <ListGroup.Item
                                style={{ backgroundColor: "#e6eaf3" }}
                              >
                                <Row>
                                  <Col>Total No. Of Tickets/Pass:</Col>
                                  <Col>{Number(totalPerson)}</Col>
                                </Row>
                              </ListGroup.Item>

                              <ListGroup.Item
                                style={{ backgroundColor: "#e6eaf3" }}
                              >
                                <Row>
                                  <Col>Extra Contribution :</Col>

                                  <Col>
                                    {getFormattedPrice(contributionAmount * 1)}
                                  </Col>
                                </Row>
                              </ListGroup.Item>

                              <ListGroup.Item
                                style={{ backgroundColor: "#e6eaf3" }}
                              >
                                <Row>
                                  <Col>Total :</Col>

                                  <Col>
                                    {getFormattedPrice(
                                      totalPrice + contributionAmount * 1
                                    )}
                                  </Col>
                                </Row>
                              </ListGroup.Item>

                              <ListGroup.Item
                                style={{ backgroundColor: "#e6eaf3" }}
                              >
                                <Row>
                                  <Col>
                                    Please proceed to payment to complete the
                                    registration
                                  </Col>
                                </Row>
                              </ListGroup.Item>

                              <ListGroup.Item
                                style={{ backgroundColor: "#e6eaf3" }}
                              >
                                {!sdkReady ? (
                                  <Loader />
                                ) : readyToPay ? (
                                  <>
                                    <PayPalButton
                                      amount={
                                        Number(totalPrice) +
                                        Number(contributionAmount)
                                      }
                                      onSuccess={successPaymentHandler}
                                    />

                                    <hr />

                                    <ZellePayment
                                      ZellePayment={successPaymentHandler}
                                    />
                                  </>
                                ) : (
                                  <Button
                                    type="submit"
                                    variant="info"
                                    disabled={
                                      finalSelection.length === 0 ? true : false
                                    }
                                    block
                                  >
                                    Pay
                                  </Button>
                                )}
                              </ListGroup.Item>
                            </ListGroup>
                          </Card>
                        </Col>
                      </>
                    )
                  ) : null}
                </Row>
              </Form>
            )}
          </Card.Body>
        </Card>
      </>
    </Container>
  );
};

export default CentralEventRegistration2024;
