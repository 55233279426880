import React, { useEffect, useState } from 'react';
import { Card, ListGroup, Pagination } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { allAnnouncements } from '../../actions/announcementAction';
import Loader from '../Loader';
import Message from '../Message';
import Paginate3 from './Paginate3';

const HomeAnnouncements = () => {
  // const keyword = match.params.keyword;
  // const pageNumber = match.params.pageNumber || 0;
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(0);

  const announcementAll = useSelector((state) => state.announcementAll);
  const { loading, error, announcements, totalPages, currentPage } =
    announcementAll;

  const checkChapter = window.location.host;

  useEffect(() => {
    dispatch(allAnnouncements(checkChapter, '', pageNumber)); // Done
  }, [dispatch, checkChapter, pageNumber]);
  // console.log(cMembers);
  const changePageNumber = (changePageNumber) => {
    setPageNumber(changePageNumber);
  };

  return (
    <Card className='mb-2'>
      <Card.Header className='text-info text-center' as='h4'>
        Announcements
      </Card.Header>

      <>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <>
            <ListGroup variant='flush'>
              {announcements.map((announcement, index) => (
                <ListGroup.Item key={index}>
                  <Link to={`/announcements/${announcement.announcementId}`}>
                    <span className='text-info'> {announcement.title}</span>
                  </Link>
                </ListGroup.Item>
              ))}
            </ListGroup>
            {/* <HomePaginate totalPages={totalPages} currentPage={currentPage} /> */}
            <div className='align-middle'>
              {totalPages > 1 && (
                <Pagination size='sm' className='justify-content-center my-1'>
                  <Paginate3
                    totalPages={totalPages}
                    currentPage={currentPage}
                    changePageNumber={changePageNumber}
                  />
                </Pagination>
              )}
            </div>
          </>
        )}
      </>
    </Card>
  );
};

export default HomeAnnouncements;
