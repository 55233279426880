export const SPONSOR_NEW_REQUEST = 'SPONSOR_NEW_REQUEST';
export const SPONSOR_NEW_SUCCESS = 'SPONSOR_NEW_SUCCESS';
export const SPONSOR_NEW_FAIL = 'SPONSOR_NEW_FAIL';
export const SPONSOR_NEW_RESET = 'SPONSOR_NEW_RESET';

export const SPONSOR_ALL_REQUEST = 'SPONSOR_ALL_REQUEST';
export const SPONSOR_ALL_SUCCESS = 'SPONSOR_ALL_SUCCESS';
export const SPONSOR_ALL_FAIL = 'SPONSOR_ALL_FAIL';

export const SPONSOR_BY_ID_REQUEST = 'SPONSOR_BY_ID_REQUEST';
export const SPONSOR_BY_ID_SUCCESS = 'SPONSOR_BY_ID_SUCCESS';
export const SPONSOR_BY_ID_FAIL = 'SPONSOR_BY_ID_FAIL';
export const SPONSOR_BY_ID_RESET = 'SPONSOR_BY_ID_RESET';

export const SPONSOR_UPDATE_BY_ID_REQUEST = 'SPONSOR_UPDATE_BY_ID_REQUEST';
export const SPONSOR_UPDATE_BY_ID_SUCCESS = 'SPONSOR_UPDATE_BY_ID_SUCCESS';
export const SPONSOR_UPDATE_BY_ID_FAIL = 'SPONSOR_UPDATE_BY_ID_FAIL';
export const SPONSOR_UPDATE_BY_ID_RESET = 'SPONSOR_UPDATE_BY_ID_RESET';

export const SPONSOR_DELETE_REQUEST = 'SPONSOR_DELETE_REQUEST';
export const SPONSOR_DELETE_SUCCESS = 'SPONSOR_DELETE_SUCCESS';
export const SPONSOR_DELETE_FAIL = 'SPONSOR_DELETE_FAIL';
