import {
  CART_ADD_HOTEL_ROOM,
  CART_ADD_PROGRAM_GROUP,
  CART_NEW_FAIL,
  CART_NEW_REQUEST,
  CART_NEW_SUCCESS,
  CART_REMOVE_HOTEL_ROOM,
  CART_REMOVE_PROGRAM_GROUP,
} from "../constants/cartConstants";

export const addToCart =
  ({
    eventId,
    eventName,
    mInit,
    firstName,
    lastName,
    isMember,
    memberId,
    email,
    phone,
    numberOfAdults,
    numberOfMinors,
    contributionAmount,
    donateAmount,
  }) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: CART_NEW_REQUEST,
      });

      // const config = {
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      // };

      // const { data } = await axios.get(
      //   `/api/events/register/${memberId}`,

      //   config
      // );

      dispatch({
        type: CART_NEW_SUCCESS,
        payload: {
          eventId,
          eventName,
          mInit,
          firstName,
          lastName,
          isMember,
          memberId,
          email,
          phone,
          numberOfAdults,
          numberOfMinors,
          donateAmount,
          contributionAmount,
        },
      });

      localStorage.setItem(
        "cartItems",
        JSON.stringify(getState().cart.cartItems)
      );
    } catch (error) {
      dispatch({
        type: CART_NEW_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

/////////////new/////////////////////

/////////////////////////// Program Group///////////////
// groupId, programId, eventId, groupName, participantName, age, grade, fee

export const saveProgramGroup = (data) => async (dispatch, getState) => {
  dispatch({
    type: CART_ADD_PROGRAM_GROUP,
    payload: data,
  });
  localStorage.setItem("programs", JSON.stringify(getState().cart.programs));
};

export const removeProgramGroup =
  (groupId, participantName) => async (dispatch, getState) => {
    dispatch({
      type: CART_REMOVE_PROGRAM_GROUP,
      payload: { groupId, participantName },
    });
    localStorage.setItem("programs", JSON.stringify(getState().cart.programs));
  };

//////////// Hotel Room///////////////
// eventHotelRoomId, eventHotelId, eventId, roomType, roomDescription, accomodation, rent, qty
export const saveHotelRoom = (data) => async (dispatch, getState) => {
  dispatch({
    type: CART_ADD_HOTEL_ROOM,
    payload: data,
  });
  localStorage.setItem("hotels", JSON.stringify(getState().cart.hotels));
};

export const removeHotelRoom =
  (eventHotelRoomId, roomType) => async (dispatch, getState) => {
    dispatch({
      type: CART_REMOVE_HOTEL_ROOM,
      payload: { eventHotelRoomId, roomType },
    });
    localStorage.setItem("hotels", JSON.stringify(getState().cart.hotels));
  };
