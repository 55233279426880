import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { format } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';

import {
  allSponsors,
  deleteSponsor,
  getSponsorById,
  newSponsor,
  updateSponsorById,
} from '../../actions/sponsorAction';
import { ANNOUNCEMENT_UPDATE_BY_ID_RESET } from '../../constants/announcementConstants';
import {
  SPONSOR_BY_ID_RESET,
  SPONSOR_NEW_RESET,
} from '../../constants/sponsorConstant';
import Loader from '../Loader';
import Message from '../Message';
import ColumnFilter from '../Table/ColumnFilter';
import RTable from '../Table/RTable';

const ManageSponsor = ({ history }) => {
  const dispatch = useDispatch();

  const [addSponsor, setAddSponsor] = useState(false);
  const [editSponsor, setEditSponsor] = useState(false);
  const [sponsorName, setSponsorName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [description, setDescription] = useState('');
  const [id, setId] = useState('');

  const sponsorsRef = useRef();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const sponsorAll = useSelector((state) => state.sponsorAll);
  const { loading, error, sponsors } = sponsorAll;

  sponsorsRef.current = sponsors;

  const sponsorNew = useSelector((state) => state.sponsorNew);
  const {
    loading: sponsorNewLoading,
    error: sponsorNewError,
    success,
  } = sponsorNew;

  const sponsorById = useSelector((state) => state.sponsorById);
  const { success: sponsorByIdSuccess, sponsor } = sponsorById;

  const sponsorUpdate = useSelector((state) => state.sponsorUpdate);
  const { error: sponsorUpdateError, success: sponsorUpdateSuccess } =
    sponsorUpdate;

  const sponsorDelete = useSelector((state) => state.sponsorDelete);
  const { success: successDelete } = sponsorDelete;

  const checkChapter = window.location.host.split('.')[0];

  useEffect(() => {
    if (
      userInfo &&
      (userInfo.userRole === 'systemAdmin' || userInfo.userRole === 'admin')
    ) {
      // setId(userInfo.memberId);
      dispatch(allSponsors(checkChapter));
      dispatch({ type: SPONSOR_NEW_RESET });
    } else {
      history.push('/login');
    }
    if (success || sponsorUpdateSuccess) {
      setAddSponsor(false);
      setEditSponsor(false);

      setSponsorName('');
      setEmail('');
      setPhone('');
      setAddress('');
      setDescription('');
      dispatch({ type: SPONSOR_BY_ID_RESET });
    }
    if (sponsorByIdSuccess) {
      setAddSponsor(true);
      setEditSponsor(true);
      setSponsorName(sponsor.sponsorName);
      setEmail(sponsor.email);
      setPhone(sponsor.phone);
      setAddress(sponsor.address);
      setDescription(sponsor.description);
      setId(sponsor.sponsorId);

      // setId(sponsor.sponsorId);
    }
  }, [
    dispatch,
    history,
    userInfo,
    checkChapter,
    success,
    sponsorByIdSuccess,
    sponsor,
    sponsorUpdateSuccess,
    successDelete,
  ]);

  useEffect(() => {
    if (sponsorNewError || sponsorUpdateError) {
      swal('Error!', sponsorNewError || sponsorUpdateError, 'error');
      dispatch({ type: SPONSOR_NEW_RESET });
    }
  }, [dispatch, sponsorNewError, sponsorUpdateError]);

  const editSponsorHandler = (rowIndex) => {
    const id = sponsorsRef.current[rowIndex].sponsorId;
    dispatch({ type: ANNOUNCEMENT_UPDATE_BY_ID_RESET });
    // console.log(rowIndex);
    // console.log(id);
    dispatch(getSponsorById(id));
  };

  const deleteSponsorHandler = (rowIndex) => {
    const id = sponsorsRef.current[rowIndex].sponsorId;

    if (window.confirm('Are You Sure?')) {
      dispatch(deleteSponsor(id));
    }
  };

  const addNewSponsor = (e) => {
    e.preventDefault();

    setAddSponsor(!addSponsor);
    setSponsorName('');
    setEmail('');
    setPhone('');
    setAddress('');
    setDescription('');
    setEditSponsor(false);
    dispatch({ type: SPONSOR_BY_ID_RESET });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (editSponsor) {
      dispatch(
        updateSponsorById({
          id,
          sponsorName,
          email,
          phone,
          address,
          description,
        })
      );
    } else {
      dispatch(newSponsor({ sponsorName, description, email, phone, address }));
    }
  };

  const columnsAdmin = [
    {
      Header: 'No.',
      accessor: (row, i) => i + 1,
    },
    {
      Header: 'Sponsor Name',
      accessor: 'sponsorName',
      Filter: ColumnFilter,
    },
    {
      Header: 'Email',
      accessor: 'email',
      Filter: ColumnFilter,
    },
    {
      Header: 'Phone',
      accessor: 'phone',
      Filter: ColumnFilter,
    },
    {
      Header: 'Address',
      accessor: 'address',
      Filter: ColumnFilter,
    },

    // {
    //   Header: 'Details',
    //   accessor: 'description',
    //   Filter: ColumnFilter,
    //   Cell: ({ value }) => {
    //     return parse(value);
    //   },
    // },
    {
      Header: 'Create Date',
      accessor: 'createdAt',
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        return format(new Date(value), 'dd/mm/yyyy');
      },
    },

    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: (props) => {
        const rowIdx = props.row.id;
        return (
          <div>
            <span onClick={() => editSponsorHandler(rowIdx)}>
              <i
                className='far fa-edit action mr-2'
                style={{ color: '#4285F4', cursor: 'pointer' }}
              ></i>
            </span>

            <span onClick={() => deleteSponsorHandler(rowIdx)}>
              <i
                className='fas fa-trash action'
                style={{ color: 'red', cursor: 'pointer' }}
              ></i>
            </span>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Row>
        <Col
          md={{ span: 12, order: 1 }}
          lg={{ span: 12, order: 1 }}
          // style={{ padding: 0 }}
          className='mb-2 p-0'
        >
          <Card border='info'>
            <Card.Header className='text-center' as='h2'>
              <Button
                variant='transparent'
                className='btn btn-outline-info btn-sm btn-block rounded'
                // onClick={() => setAddSponsor(!addSponsor)}
                onClick={addNewSponsor}
              >
                Add New Sponsor
              </Button>
            </Card.Header>

            <Card.Body>
              {addSponsor
                ? (sponsorNewError && (
                    <Message variant='danger'>{sponsorNewError}</Message>
                  )) ||
                  (sponsorNewLoading && <Loader />) ||
                  (success ? (
                    <Message variant='success'>{success}</Message>
                  ) : (
                    <Form onSubmit={submitHandler}>
                      <Form.Group controlId='sponsorName'>
                        <Form.Label>Sponsor Name</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Please Enter A sponsorName..'
                          value={sponsorName}
                          onChange={(e) => setSponsorName(e.target.value)}
                        ></Form.Control>
                      </Form.Group>

                      <Form.Group controlId='email'>
                        <Form.Label>Contact email</Form.Label>
                        <Form.Control
                          type='email'
                          placeholder='Please Enter contact email'
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        ></Form.Control>
                      </Form.Group>

                      <Form.Group controlId='phone'>
                        <Form.Label>Contact phone number</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Please Enter contact phone number'
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        ></Form.Control>
                      </Form.Group>

                      <Form.Group controlId='address'>
                        <Form.Label>Contact address</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Please Enter contact address'
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        ></Form.Control>
                      </Form.Group>

                      <Form.Group controlId='description'>
                        <Form.Label>Sponsor Details</Form.Label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={description}
                          onChange={(e, editor) => {
                            const data = editor.getData();
                            setDescription(data);
                          }}
                        />
                      </Form.Group>

                      {editSponsor ? (
                        <Button type='submit' variant='info' block>
                          <i className='fas fa-plus' /> Update
                        </Button>
                      ) : (
                        <Button type='submit' variant='info' block>
                          <i className='fas fa-plus' /> Add
                        </Button>
                      )}
                    </Form>
                  ))
                : null}
              {/* {message && <Message variant='danger'>{message}</Message>} */}
              {/* {registerError && (
                        <Message variant='danger'>{registerError}</Message>
                      )}
                      {registerLoading && <Loader />}
                      {} */}
            </Card.Body>
          </Card>
        </Col>

        <Col
          md={{ span: 12, order: 12 }}
          lg={{ span: 12, order: 12 }}
          className='mb-2 p-0'
          id='all-chapter'
        >
          <Card border='info'>
            <Card.Header as='h5' className='text-center text-info'>
              Sponsor List
            </Card.Header>

            <>
              {loading ? (
                <Loader />
              ) : error ? (
                <Message variant='danger'>{error}</Message>
              ) : (
                <>
                  <RTable users={sponsors} COLUMNS={columnsAdmin} />
                </>
              )}
            </>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ManageSponsor;
