export const HELP_CONTACT_NEW_REQUEST = 'HELP_CONTACT_NEW_REQUEST';
export const HELP_CONTACT_NEW_SUCCESS = 'HELP_CONTACT_NEW_SUCCESS';
export const HELP_CONTACT_NEW_FAIL = 'HELP_CONTACT_NEW_FAIL';
export const HELP_CONTACT_NEW_RESET = 'HELP_CONTACT_NEW_RESET';

export const HELP_CONTACT_ALL_REQUEST = 'HELP_CONTACT_ALL_REQUEST';
export const HELP_CONTACT_ALL_SUCCESS = 'HELP_CONTACT_ALL_SUCCESS';
export const HELP_CONTACT_ALL_FAIL = 'HELP_CONTACT_ALL_FAIL';

export const HELP_CONTACT_BY_ID_REQUEST = 'HELP_CONTACT_BY_ID_REQUEST';
export const HELP_CONTACT_BY_ID_SUCCESS = 'HELP_CONTACT_BY_ID_SUCCESS';
export const HELP_CONTACT_BY_ID_FAIL = 'HELP_CONTACT_BY_ID_FAIL';
export const HELP_CONTACT_BY_ID_RESET = 'HELP_CONTACT_BY_ID_RESET';

export const HELP_CONTACT_UPDATE_BY_ID_REQUEST =
  'HELP_CONTACT_UPDATE_BY_ID_REQUEST';
export const HELP_CONTACT_UPDATE_BY_ID_SUCCESS =
  'HELP_CONTACT_UPDATE_BY_ID_SUCCESS';
export const HELP_CONTACT_UPDATE_BY_ID_FAIL = 'HELP_CONTACT_UPDATE_BY_ID_FAIL';
export const HELP_CONTACT_UPDATE_BY_ID_RESET =
  'HELP_CONTACT_UPDATE_BY_ID_RESET';

export const HELP_CONTACT_DELETE_REQUEST = 'HELP_CONTACT_DELETE_REQUEST';
export const HELP_CONTACT_DELETE_SUCCESS = 'HELP_CONTACT_DELETE_SUCCESS';
export const HELP_CONTACT_DELETE_FAIL = 'HELP_CONTACT_DELETE_FAIL';
