import React, { useState } from 'react';
import {
  Col,
  Form,
  ListGroup,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

const CreateGroup = ({
  eventType,
  programId,
  eventFrom,
  eventTo,
  createGroup,
}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [groupName, setGroupName] = useState('');
  const [ageFrom] = useState(0);
  const [ageTo] = useState(0);
  const [gradeFrom, setGradeFrom] = useState('X');
  const [gradeTo, setGradeTo] = useState('Y');
  const [programDateFrom, setProgramDateFrom] = useState(
    new Date(eventFrom).toISOString().split('T')[0]
  );
  const [programDateTo, setProgramDateTo] = useState(
    new Date(eventTo).toISOString().split('T')[0]
  );
  const [programTimeFrom, setProgramTimeFrom] = useState(
    `${new Date(eventFrom).toISOString().split('T')[1].split(':')[0]}:${
      new Date(eventFrom).toISOString().split('T')[1].split(':')[1]
    }`
  );
  const [programTimeTo, setProgramTimeTo] = useState(
    `${new Date(eventTo).toISOString().split('T')[1].split(':')[0]}:${
      new Date(eventTo).toISOString().split('T')[1].split(':')[1]
    }`
  );
  const [fee, setFee] = useState(0);

  // var tomorrow = new Date();
  console.log('programTimeFrom: ', programTimeFrom);
  console.log('programTimeTo: ', programTimeTo);

  return (
    <>
      <OverlayTrigger
        overlay={
          <Tooltip id='tooltip-disabled'>
            {eventType === 'election' ? 'Add Position' : 'Add Group to Program'}
          </Tooltip>
        }
      >
        <span onClick={handleShow}>
          <i
            className='fas fa-plus action mr-2 text-info'
            style={{
              cursor: 'pointer',
            }}
          ></i>
        </span>
      </OverlayTrigger>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        size='lg'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className='text-center text-info'>
            {eventType === 'election'
              ? 'New Position for the Election:'
              : 'New Group for Event:'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup variant='flush'>
            <ListGroup.Item>
              <Form.Row>
                <Form.Group as={Col} md='2'>
                  <Form.Label>
                    {eventType === 'election' ? 'Position' : 'Group'} Name :
                  </Form.Label>
                </Form.Group>
                <Col>
                  <Form.Group as={Col} controlId='groupName'>
                    <Form.Control
                      required
                      type='text'
                      placeholder={
                        eventType === 'election'
                          ? 'New Position Name..'
                          : 'New Group Name..'
                      }
                      value={groupName}
                      onChange={(e) => setGroupName(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Form.Row>
            </ListGroup.Item>

            {eventType !== 'election' && (
              <>
                <ListGroup.Item>
                  <Form.Row>
                    <Form.Group as={Col} md='2'>
                      <Form.Label>Grade Range :</Form.Label>
                    </Form.Group>
                    <Col>
                      <Row>
                        <Form.Group as={Col} controlId='gradeFrom'>
                          <Form.Label>From: Grade X</Form.Label>
                          <Form.Control
                            required
                            type='text'
                            // placeholder='Grade Number / A / O'
                            value={gradeFrom}
                            onChange={(e) => setGradeFrom(e.target.value)}
                          ></Form.Control>
                        </Form.Group>

                        <Form.Group as={Col} controlId='gradeTo'>
                          <Form.Label>To: Grade Y</Form.Label>
                          <Form.Control
                            required
                            type='text'
                            value={gradeTo}
                            onChange={(e) => setGradeTo(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                      </Row>
                    </Col>
                  </Form.Row>
                </ListGroup.Item>

                <ListGroup.Item>
                  <Form.Row>
                    <Form.Group as={Col} md='2'>
                      <Form.Label>Date :</Form.Label>
                    </Form.Group>
                    <Col>
                      <Row>
                        <Form.Group as={Col} controlId='programDateFrom'>
                          <Form.Label>From: </Form.Label>
                          <Form.Control
                            required
                            type='date'
                            value={programDateFrom}
                            onChange={(e) => setProgramDateFrom(e.target.value)}
                            // min={tomorrow.setDate(tomorrow.getDate()+1)}
                            min={
                              new Date(eventFrom).toISOString().split('T')[0]
                            }
                            max={new Date(eventTo).toISOString().split('T')[0]}
                          ></Form.Control>
                        </Form.Group>

                        <Form.Group as={Col} controlId='programDateTo'>
                          <Form.Label>To: </Form.Label>
                          <Form.Control
                            required
                            type='date'
                            value={programDateTo}
                            onChange={(e) => setProgramDateTo(e.target.value)}
                            min={programDateFrom}
                            max={new Date(eventTo).toISOString().split('T')[0]}
                          ></Form.Control>
                        </Form.Group>
                      </Row>
                    </Col>
                  </Form.Row>
                </ListGroup.Item>

                <ListGroup.Item>
                  <Form.Row>
                    <Form.Group as={Col} md='2'>
                      <Form.Label>Time :</Form.Label>
                    </Form.Group>
                    <Col>
                      <Row>
                        <Form.Group as={Col} controlId='programTimeFrom'>
                          <Form.Label>From: </Form.Label>
                          <Form.Control
                            required
                            type='time'
                            value={programTimeFrom}
                            onChange={(e) => setProgramTimeFrom(e.target.value)}
                          ></Form.Control>
                        </Form.Group>

                        <Form.Group as={Col} controlId='programTimeTo'>
                          <Form.Label>To: </Form.Label>
                          <Form.Control
                            required
                            type='time'
                            value={programTimeTo}
                            onChange={(e) => setProgramTimeTo(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                      </Row>
                    </Col>
                  </Form.Row>
                </ListGroup.Item>
              </>
            )}

            <ListGroup.Item>
              <Form.Row>
                <Form.Group as={Col} md='2'>
                  <Form.Label>
                    {eventType === 'election'
                      ? 'Registration'
                      : 'Participation'}{' '}
                    Fee:
                  </Form.Label>
                </Form.Group>
                <Col>
                  <Form.Group as={Col} controlId='fee'>
                    <Form.Control
                      required
                      type='number'
                      // placeholder='New Group Name..'
                      value={fee}
                      onChange={(e) => setFee(e.target.value)}
                      min='0'
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Form.Row>
            </ListGroup.Item>
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Link
            className='btn btn-outline-danger btn-sm rounded'
            to='#'
            onClick={handleClose}
          >
            Cancel
          </Link>

          <Link
            className='btn btn-outline-info btn-sm rounded'
            to='#'
            onClick={() => {
              const programStart = new Date(
                programDateFrom + 'T' + programTimeFrom
              );
              const programEnd = new Date(programDateTo + 'T' + programTimeTo);
              createGroup(
                programId,
                groupName,
                ageFrom,
                ageTo,
                gradeFrom,
                gradeTo,
                programStart,
                programEnd,
                fee
              );
              handleClose();
            }}
          >
            Create
          </Link>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateGroup;
