export const HISTORY_NEW_REQUEST = 'HISTORY_NEW_REQUEST';
export const HISTORY_NEW_SUCCESS = 'HISTORY_NEW_SUCCESS';
export const HISTORY_NEW_FAIL = 'HISTORY_NEW_FAIL';
export const HISTORY_NEW_RESET = 'HISTORY_NEW_RESET';

export const HISTORY_ALL_REQUEST = 'HISTORY_ALL_REQUEST';
export const HISTORY_ALL_SUCCESS = 'HISTORY_ALL_SUCCESS';
export const HISTORY_ALL_FAIL = 'HISTORY_ALL_FAIL';

export const HISTORY_BY_ID_REQUEST = 'HISTORY_BY_ID_REQUEST';
export const HISTORY_BY_ID_SUCCESS = 'HISTORY_BY_ID_SUCCESS';
export const HISTORY_BY_ID_FAIL = 'HISTORY_BY_ID_FAIL';
export const HISTORY_BY_ID_RESET = 'HISTORY_BY_ID_RESET';

export const HISTORY_UPDATE_BY_ID_REQUEST = 'HISTORY_UPDATE_BY_ID_REQUEST';
export const HISTORY_UPDATE_BY_ID_SUCCESS = 'HISTORY_UPDATE_BY_ID_SUCCESS';
export const HISTORY_UPDATE_BY_ID_FAIL = 'HISTORY_UPDATE_BY_ID_FAIL';
export const HISTORY_UPDATE_BY_ID_RESET = 'HISTORY_UPDATE_BY_ID_RESET';

export const HISTORY_DELETE_REQUEST = 'HISTORY_DELETE_REQUEST';
export const HISTORY_DELETE_SUCCESS = 'HISTORY_DELETE_SUCCESS';
export const HISTORY_DELETE_FAIL = 'HISTORY_DELETE_FAIL';
