import React, { useEffect, useState } from "react";
import { Card, ListGroup, Pagination } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import { allEvents } from "../../actions/eventActions";
import Paginate3 from "./Paginate3";

const HomeEvents = () => {
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(0);

  const eventAll = useSelector((state) => state.eventAll);
  const {
    loading: eventsLoading,
    error: eventsError,
    events,
    totalPages,
    currentPage,
  } = eventAll;

  const checkChapter = window.location.host;
  // const subDomain = window.location.host.split('.')[0];

  useEffect(() => {
    dispatch(allEvents(checkChapter, "", pageNumber)); // done
  }, [dispatch, checkChapter, pageNumber]);

  const changePageNumber = (changePageNumber) => {
    setPageNumber(changePageNumber);
  };

  return (
    <Card className="mb-2">
      <Card.Header className="text-info text-center" as="h4">
        Events
      </Card.Header>
      <>
        {eventsLoading ? (
          <Loader />
        ) : eventsError ? (
          <Message variant="danger">{eventsError}</Message>
        ) : (
          <ListGroup variant="flush">
            {events.map((event, index) => (
              <ListGroup.Item key={index}>
                <Link to={`/event/${event.eventId}`}>
                  <span className="text-info d-flex justify-content-between align-items-center">
                    {event.eventName}
                    {event.eventStatus ? (
                      <>
                        <span className="badge badge-info badge-pill blink_me">
                          Upcoming
                        </span>
                      </>
                    ) : null}
                  </span>
                </Link>
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}

        <div className="align-middle">
          {totalPages > 1 && (
            <Pagination size="sm" className="justify-content-center my-1">
              <Paginate3
                totalPages={totalPages}
                currentPage={currentPage}
                changePageNumber={changePageNumber}
              />
            </Pagination>
          )}
        </div>
      </>
    </Card>
  );
};

export default HomeEvents;
