import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Route } from 'react-router-dom';
import { Card, Col, ListGroup, Row } from 'react-bootstrap';
import parse from 'html-react-parser';

import { getJobById } from '../../actions/careerActions';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import JobSearchBox from '../../components/Career/JobSearchBox';
import JobNew from '../../components/Career/JobNew';
import Courtesy from '../../components/Career/Courtesy';

const JobByIdScreen = ({ match, history }) => {
  const id = match.params.id;

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const jobById = useSelector((state) => state.jobById);
  const { loading, error, job } = jobById;

  const checkChapter = window.location.host;

  useEffect(() => {
    dispatch(getJobById(id));
  }, [dispatch, history, id, userInfo]);

  return (
    <>
      <Courtesy />
      {checkChapter.replace('www.', '').split('.')[0] !== 'aabea' ? (
        <Link className='btn my-3 btn-sm btn-outline-info m-1' to='/jobs'>
          Go Back To Chapter Jobs
        </Link>
      ) : null}
      <Link className='btn my-3 btn-sm btn-outline-info m-1' to='/jobsall'>
        Go Back To ALL Jobs
      </Link>
      <Row className='content'>
        <Col md={{ span: 8, order: 1 }} className='m-0 p-1'>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            job && (
              <Card className='text-justify'>
                <Card.Header className='text-info' as='h2'>
                  {job.title}
                  <Card.Title className='text-muted'>
                    {' '}
                    Published Date: {'  '}
                    {`${job.createdAt}`.substring(0, 10)}
                    <Card.Title className='text-muted'>
                      Post by{' '}
                      <span>
                        {job.member.firstName} {job.member.lastName}
                      </span>
                    </Card.Title>
                  </Card.Title>
                </Card.Header>

                {/* <hr /> */}

                <ListGroup variant='flush'>
                  <ListGroup.Item>
                    <strong>Organization:</strong> {job.companyName}
                  </ListGroup.Item>
                  {job.web ? (
                    <>
                      <ListGroup.Item>
                        <strong>Website:</strong> {job.web}
                      </ListGroup.Item>
                    </>
                  ) : null}

                  {job.email ? (
                    <>
                      <ListGroup.Item>
                        <strong>Contact Email:</strong> {job.email}
                      </ListGroup.Item>
                    </>
                  ) : null}

                  {job.phone ? (
                    <>
                      <ListGroup.Item>
                        <strong>Contact phone:</strong> {job.phone}
                      </ListGroup.Item>
                    </>
                  ) : null}

                  {job.city ? (
                    <>
                      <ListGroup.Item>
                        <strong>Job location city:</strong> {job.city}
                      </ListGroup.Item>
                    </>
                  ) : null}

                  {job.state ? (
                    <>
                      <ListGroup.Item>
                        <strong>Job location state:</strong> {job.state}
                      </ListGroup.Item>
                    </>
                  ) : null}
                </ListGroup>

                {/* Post Content */}
                <Card.Body className='lead'>
                  {parse(`<div>${job.body}</div>`)}
                </Card.Body>

                {job.attachments && job.attachments.length !== 0 ? (
                  <Card.Footer>
                    <span>
                      <strong>Attachments: </strong>
                    </span>
                    {job.attachments.map((Attachment, idx) => {
                      return (
                        // <span key={idx} className='badge'>
                        //   {Attachment}
                        // </span>
                        <a
                          key={idx}
                          href={Attachment}
                          className='badge'
                          target='_blank'
                          rel='noreferrer'
                        >
                          {Attachment.split('/image-')[1]}
                        </a>
                      );
                    })}
                  </Card.Footer>
                ) : null}
              </Card>
            )
          )}
        </Col>

        <Col md={{ span: 4, order: 12 }} className='m-0 p-1'>
          <>
            <Route render={({ history }) => <JobNew history={history} />} />
            {/* <!-- Blog Search Well --> */}
            <Route
              render={({ history }) => <JobSearchBox history={history} />}
            />
          </>
        </Col>
      </Row>
    </>
  );
};

export default JobByIdScreen;
