import React, { useState } from 'react';
import { Col, Form, ListGroup, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const CreateHotel = ({ createHotel }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [hotelName, setHotelName] = useState('');
  const [hotelDescription, setHotelDescription] = useState('');
  const [hotelAddress, setHotelAddress] = useState('');
  const [hotelSiteLink, setHotelSiteLink] = useState('');

  return (
    <>
      {/* <Button variant='primary' onClick={handleShow}>
        Launch static backdrop modal
      </Button> */}
      <Link
        className='btn btn-outline-info btn-sm btn-block  rounded'
        to='#'
        onClick={handleShow}
      >
        Add Hotel
      </Link>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        size='lg'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className='text-center text-info'>
            Add Hotel Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Row>
            <Col md={{ span: 10 }}> */}
          <ListGroup variant='flush'>
            <ListGroup.Item>
              <Form.Row>
                <Form.Group as={Col} md='3'>
                  <Form.Label>Hotel Name :</Form.Label>
                </Form.Group>
                <Col>
                  <Form.Group as={Col} controlId='hotelName'>
                    <Form.Control
                      required
                      type='text'
                      placeholder='New Hotel Name..'
                      value={hotelName}
                      onChange={(e) => setHotelName(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Form.Row>
            </ListGroup.Item>

            <ListGroup.Item>
              <Form.Row>
                <Form.Group as={Col} md='3'>
                  <Form.Label>Hotel Description :</Form.Label>
                </Form.Group>
                <Col>
                  <Form.Group as={Col} controlId='hotelDescription'>
                    <Form.Control
                      required
                      type='text'
                      placeholder='Description..'
                      value={hotelDescription}
                      onChange={(e) => setHotelDescription(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Form.Row>
            </ListGroup.Item>

            <ListGroup.Item>
              <Form.Row>
                <Form.Group as={Col} md='3'>
                  <Form.Label>Address :</Form.Label>
                </Form.Group>
                <Col>
                  <Form.Group as={Col} controlId='hotelAddress'>
                    <Form.Control
                      required
                      type='text'
                      placeholder='Hotel Address..'
                      value={hotelAddress}
                      onChange={(e) => setHotelAddress(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Form.Row>
            </ListGroup.Item>

            <ListGroup.Item>
              <Form.Row>
                <Form.Group as={Col} md='3'>
                  <Form.Label>Website :</Form.Label>
                </Form.Group>
                <Col>
                  <Form.Group as={Col} controlId='hotelSiteLink'>
                    <Form.Control
                      required
                      type='text'
                      value={hotelSiteLink}
                      onChange={(e) => setHotelSiteLink(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Form.Row>
            </ListGroup.Item>
          </ListGroup>
          {/* </Col>
          </Row> */}
        </Modal.Body>
        <Modal.Footer>
          <Link
            className='btn btn-outline-danger btn-sm rounded'
            to='#'
            onClick={handleClose}
          >
            Cancel
          </Link>

          <Link
            className='btn btn-outline-info btn-sm rounded'
            to='#'
            onClick={() => {
              createHotel(
                hotelName,
                hotelDescription,
                hotelAddress,
                hotelSiteLink
              );
              handleClose();
            }}
          >
            Add
          </Link>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateHotel;
