export const REPLY_NEW_REQUEST = 'REPLY_NEW_REQUEST';
export const REPLY_NEW_SUCCESS = 'REPLY_NEW_SUCCESS';
export const REPLY_NEW_FAIL = 'REPLY_NEW_FAIL';
export const REPLY_NEW_RESET = 'REPLY_NEW_RESET';

export const REPLY_UPDATE_BY_ID_REQUEST = 'REPLY_UPDATE_BY_ID_REQUEST';
export const REPLY_UPDATE_BY_ID_SUCCESS = 'REPLY_UPDATE_BY_ID_SUCCESS';
export const REPLY_UPDATE_BY_ID_FAIL = 'REPLY_UPDATE_BY_ID_FAIL';
export const REPLY_UPDATE_BY_ID_RESET = 'REPLY_UPDATE_BY_ID_RESET';

export const REPLY_DELETE_REQUEST = 'REPLY_DELETE_REQUEST';
export const REPLY_DELETE_SUCCESS = 'REPLY_DELETE_SUCCESS';
export const REPLY_DELETE_FAIL = 'REPLY_DELETE_FAIL';
