import React, { useEffect } from 'react';
import { Row, Card, Container } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import { allBod } from '../../actions/committeeActions';
import Bods from '../../components/Bods/Bods';

const BodScreen = ({ history }) => {
  const dispatch = useDispatch();

  const bod = useSelector((state) => state.bod);
  const { loading, error, bods } = bod;

  const checkChapter = window.location.host;
  let subDomain;
  if (checkChapter.split('.')[0] === 'www') {
    subDomain = checkChapter.split('.')[1];
  } else {
    subDomain = checkChapter.split('.')[0];
  }

  useEffect(() => {
    if (subDomain !== 'aabea') {
      history.push('/');
    }

    dispatch(allBod());
  }, [dispatch, history, subDomain]);

  return (
    <Row className='content'>
      <Container>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Card>
            <Card.Header className='text-center text-info' as='h4'>
              AABEA Board of Directors
            </Card.Header>

            {bods && bods.length !== 0 ? <Bods bods={bods} /> : null}
          </Card>
        )}
      </Container>
    </Row>
  );
};

export default BodScreen;
