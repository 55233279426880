import React from 'react';

const Courtesy = () => {
  return (
    <section
      id='subscription'
      className='text-center d-flex align-content-between'
    >
      <div className='p-2 m-auto'>
        <h1 className='text-info'>COURTESY TO SOUTHERN CALIFORNIA CHAPTER</h1>
      </div>
    </section>
  );
};

export default Courtesy;
