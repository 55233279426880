import React, { useEffect, useState } from "react";

import {
  Form,
  Row,
  Col,
  Card,
  Container,
  ListGroup,
  Button,
  Accordion,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../../components/Message";
import Loader from "../../../components/Loader";
import { getEventById, registerEvent } from "../../../actions/eventActions";

import premiumPackages from "./2022PremiumPackages.json";
import generalPackages from "./2022GeneralPackages.json";
import boatPackages from "./2022BoatPackages.json";
import hotelPackages from "./Hotels.json";

import PersonNumberInput from "./PersonNumberInput";
import NumberInput from "../../Events/NumberInput";
import swal from "sweetalert";
import { EVENT_REGISTER_RESET } from "../../../constants/eventConstants";
import axios from "axios";
import { PayPalButton } from "react-paypal-button-v2";

const getFormattedPrice = (price) => `$${price.toFixed(2)}`;

const CentralEventRegistration2022 = ({ match, history }) => {
  const { id } = match.params;

  const [isMember] = useState(false);
  const [programs] = useState([]);
  const [memberId] = useState("");

  const [mInit, setMInit] = useState("Mr");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [numberOfAdults, setNumberOfAdults] = useState(1);
  const [numberOfMinors, setNumberOfMinors] = useState(0);
  // const [donateAmount, setDonateAmount] = useState(5);
  const [contributionAmount, setContributionAmount] = useState(0);

  // const [selectedPackage, setSelectedPackage] = useState('6');
  const [selectedGeneralPackage, setSelectedGeneralPackage] = useState({
    packageId: "0",
    color: "",
    name: "",
    description: "",
    price: "",
    person: "",
    room: "",
    nights: "",
  });
  const [selectedBoatPackage, setSelectedBoatPackage] = useState({
    packageId: "0",
    color: "",
    name: "",
    description: "",
    price: "",
    person: "",
    room: "",
    nights: "",
  });

  const { packageId, color } = selectedGeneralPackage;

  const [packageCheckedState, setPackageCheckedState] = useState(
    new Array(premiumPackages.length).fill(false)
  );
  const [generalCheckedState, setGeneralCheckedState] = useState(
    new Array(generalPackages.length).fill(false)
  );
  const [boatCheckedState, setBoatCheckedState] = useState(
    new Array(boatPackages.length).fill(false)
  );

  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPackageSelected, setTotalPackageSelected] = useState(0);
  const [selectedPremiumPackages, setSelectedPremiumPackages] = useState([]);
  const [finalSelection, setFinalSelection] = useState([]);

  const [numberOfGuest] = useState(1);

  // const [hotelRent] = useState(0);
  const [readyToPay, setReadyToPay] = useState(false);

  // central 2022 specific hotel modification
  const [newHotelarray, setNewHotelarray] = useState();
  const [newHotelarray2, setNewHotelarray2] = useState();
  const [showMore, setShowMore] = useState(false);
  const [totalPremiumRoom] = useState(0);
  const [totalExtraRoom, setTotalExtraRoom] = useState(0);
  const [hotels, setHotels] = useState(hotelPackages);
  const [totalPerson, setTotalPerson] = useState(0);
  const [extraHotelCost, setExtraHotelCost] = useState(0);
  const [hotelOption, setHotelOption] = useState(false);
  const [sdkReady, setSdkReady] = useState(true);

  // const [checkTotalPackage, setCheckTotalPackage] = useState(0);

  const dispatch = useDispatch();

  const eventById = useSelector((state) => state.eventById);
  const { loading, error, event } = eventById;

  const eventRegister = useSelector((state) => state.eventRegister);
  const {
    loading: eventRegisterLoading,
    error: eventRegisterError,
    success: eventRegisterSuccess,
  } = eventRegister;

  // const cart = useSelector((state) => state.cart);
  // const { success, cartItems, programs, hotels, error: cartError } = cart;

  useEffect(() => {
    dispatch(getEventById(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (hotels && hotels.length !== 0) {
      // const ary = event.eventHotels[0].eventHotelRooms.find(
      //   (room) => room.roomType === '7th October 2022' || room.roomType === '8th October 2022'
      // );
      const ary1 = hotels.filter((element) => {
        return (
          element.roomType === "7th October 2022" ||
          element.roomType === "8th October 2022"
          // element.roomType === '7th October 2022' ||
          // element.roomType === '8th October 2022'
        );
      });
      setNewHotelarray(ary1);
      const ary2 = hotels.filter((element) => {
        return (
          // element.roomType !== '7th October 2022' && element.roomType !== '8th October 2022'
          element.roomType !== "7th October 2022" &&
          element.roomType !== "8th October 2022"
        );
      });
      setNewHotelarray2(ary2);
    }

    const totalRoom = hotels.reduce((sum, item, index) => {
      return Number(sum) + Number(item.qty);
    }, 0);

    setTotalExtraRoom(totalRoom); // number of extra rooms

    const totalExtraCost = hotels.reduce((sum, item, index) => {
      return Number(sum) + Number(item.qty) * Number(item.rent);
    }, 0);

    setExtraHotelCost(totalExtraCost);

    // let tempFianlHotel = hotels.filter((item) => item.qty > 0);
  }, [hotels]);

  useEffect(() => {
    if (packageId === "6") {
      setNumberOfAdults(numberOfGuest);
      setNumberOfMinors(0);
    }

    if (packageId === "7") {
      setNumberOfAdults(0);
      setNumberOfMinors(numberOfGuest);
    }

    if (packageId !== "6" && packageId !== "7" && packageId !== "8") {
      setNumberOfAdults(totalPerson);
    }
  }, [
    history,
    numberOfGuest,
    packageId,
    selectedGeneralPackage.nights,
    selectedGeneralPackage.room,
    totalPerson,
  ]);

  const submitHandler = (e) => {
    e.preventDefault();

    setReadyToPay(true);

    // if (packageId === '6' || packageId === '7' || packageId === '8') {
    //   history.push(`/event/registration/payment`);
    // }
  };

  const handlePremiumPackageChange = (position) => {
    setReadyToPay(false);
    const updatedCheckedState = packageCheckedState.map((item, index) =>
      index === position ? !item : item
    );

    setPackageCheckedState(updatedCheckedState);

    // const totalRoom = updatedCheckedState.reduce((sum, currentState, index) => {
    //   if (currentState === true) {
    //     return Number(sum) + Number(premiumPackages[index].room);
    //   }
    //   return sum;
    // }, 0);

    // setTotalPremiumRoom(totalRoom);

    // SelectedPremiumPackages
    let selected = [];
    updatedCheckedState.forEach((currentState, index) => {
      if (currentState === true) {
        let tempSelected = premiumPackages[index];
        selected.push(tempSelected);
        return selected;
      }
      return selected;
    });
    setSelectedPremiumPackages(selected);
  };

  const handleGeneralPackageChange = (position) => {
    setReadyToPay(false);

    const updatedCheckedState = generalCheckedState.map((item, index) =>
      index === position ? !item : item === true ? !item : item
    );

    setGeneralCheckedState(updatedCheckedState);

    const idx = updatedCheckedState.findIndex(
      (currentState) => currentState === true
    );

    if (idx !== -1) {
      setSelectedGeneralPackage(() => ({
        packageId: generalPackages[idx].packageId,
        color: generalPackages[idx].color,
        name: generalPackages[idx].name,
        description: generalPackages[idx].description,
        price: generalPackages[idx].price,
        person: generalPackages[idx].person,
        room: generalPackages[idx].room,
        nights: generalPackages[idx].nights,
      }));
    } else {
      setSelectedGeneralPackage(() => ({
        packageId: "0",
        color: "",
        name: "",
        description: "",
        price: "",
        person: "",
        room: "",
        nights: "",
      }));
    }
  };
  const handleBoatPackageChange = (position) => {
    setReadyToPay(false);

    const updatedCheckedState = boatCheckedState.map((item, index) =>
      index === position ? !item : item === true ? !item : item
    );

    setBoatCheckedState(updatedCheckedState);

    const idx = updatedCheckedState.findIndex(
      (currentState) => currentState === true
    );

    if (idx !== -1) {
      setSelectedBoatPackage(() => ({
        packageId: boatPackages[idx].packageId,
        color: boatPackages[idx].color,
        name: boatPackages[idx].name,
        description: boatPackages[idx].description,
        price: boatPackages[idx].price,
        person: boatPackages[idx].person,
        room: boatPackages[idx].room,
        nights: generalPackages[idx].nights,
      }));
    } else {
      setSelectedBoatPackage(() => ({
        packageId: "0",
        color: "",
        name: "",
        description: "",
        price: "",
        person: "",
        room: "",
        nights: "",
      }));
    }

    // const packageContribution = price - (person * 150 + room * 250 * nights);
    // setNumberOfAdults(e.target.value.split(',')[5]);
    // if (
    //   e.target.value.split(',')[0] !== '6' &&
    //   e.target.value.split(',')[0] !== '7'
    // ) {
    //   setContributionAmount(
    //     e.target.value.split(',')[4] -
    //       (e.target.value.split(',')[5] * 150 +
    //         e.target.value.split(',')[6] * 250 * e.target.value.split(',')[7])
    //   );
    // } else {
    //   // setNumberOfAdults(1);
    //   setContributionAmount(0);
    // }
  };

  useEffect(() => {
    let final = [];
    if (selectedGeneralPackage.packageId !== "0") {
      final.push(selectedGeneralPackage);
    }

    if (selectedBoatPackage.packageId !== "0") {
      final.push(selectedBoatPackage);
    }

    if (selectedPremiumPackages.length !== 0) {
      selectedPremiumPackages.forEach((item, index) => {
        final.push(item);
        return final;
      });
    }
    setFinalSelection(final);

    if (final.length !== 0) {
      let packageName = "";
      const totalSelected = final.map((item, index) => {
        if (packageName !== "") {
          return (packageName = ` + ${item.name}`);
        } else {
          return (packageName = `${item.name}`);
        }
      });

      setTotalPackageSelected(totalSelected);
    } else {
      setTotalPackageSelected(["none"]);
    }

    const totalPrice = final.reduce((sum, item, index) => {
      return Number(sum) + Number(item.price);
    }, 0);

    setTotalPrice(totalPrice);

    const totalPerson = final.reduce((sum, item, index) => {
      return Number(sum) + Number(item.person);
    }, 0);

    setTotalPerson(totalPerson);

    if (
      selectedGeneralPackage.packageId === "0" &&
      // selectedBoatPackage.packageId === '0' &&
      selectedPremiumPackages.length === 0
    ) {
      setHotelOption(false);
      let temtHotel = [];
      hotels.forEach((item, index) => {
        item.qty = 0;
        temtHotel.push(item);
        return temtHotel;
      });
      setHotels(temtHotel);
    } else {
      setHotelOption(true);
    }
  }, [
    selectedGeneralPackage,
    selectedBoatPackage,
    selectedPremiumPackages,
    hotels,
  ]);

  const generalPackageAttendees = (qty) => {
    const originalPrice = generalPackages.find(
      (item) => item.packageId === selectedGeneralPackage.packageId
    );
    setSelectedGeneralPackage((item) => ({
      ...item,
      person: qty,
      price: originalPrice.price * qty,
    }));
  };

  const boatPackageAttendees = (qty) => {
    const originalPrice = boatPackages.find(
      (item) => item.packageId === selectedBoatPackage.packageId
    );
    setSelectedBoatPackage((item) => ({
      ...item,
      person: qty,
      price: originalPrice.price * qty,
    }));
  };

  // hotel
  const showMoreDates = (e) => {
    e.preventDefault();

    setShowMore(!showMore);
  };

  const registerRoom = (hotelData) => {
    setReadyToPay(false);
    // console.log('register room function:', hotelData.qty);
    if (hotels && hotels.length === 0) {
      hotels.push(hotelData);
    } else {
      const hotelExists = hotels.find(
        (x) => x.eventHotelRoomId === hotelData.eventHotelRoomId
      );

      if (hotelExists) {
        // const objIndex = hotels.findIndex(
        //   (obj) => obj.eventHotelRoomId === hotelData.eventHotelRoomId
        // );
        const lehotel = hotels.map((x) =>
          x.eventHotelRoomId === hotelExists.eventHotelRoomId ? hotelData : x
        );

        setHotels(lehotel);
        // hotels[objIndex].qty = hotelData.qty;
      } else {
        hotels.push(hotelData);
      }
    }
  };

  const checkChapter = window.location.host;

  useEffect(() => {
    if (eventRegisterSuccess) {
      swal("Success!", "Registration successfull", "success").then(() => {
        dispatch({ type: EVENT_REGISTER_RESET });
        history.push("/");
      });
    } else if (eventRegisterError) {
      swal("Error!", eventRegisterError, "error");
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const addPaypalScript = async () => {
      const { data: clientId } = await axios.get(
        `/api/chapters/paypal/${checkChapter}`,
        config
      );
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}`;
      script.async = true;
      script.onload = () => {
        setSdkReady(true);
      };
      document.body.appendChild(script);
    };

    if (!window.paypal) {
      addPaypalScript();
    } else {
      setSdkReady(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    history,
    checkChapter,
    eventRegisterSuccess,
    eventRegisterError,
  ]);

  const successPaymentHandler = (paymentResult) => {
    // const successPaymentHandler = (e) => {
    // e.preventDefault();

    // setSuccessRegistration(true);
    // let selected = hotels;
    // finalSelection.forEach((item, index) => {
    //   if (item.packageId !== '8') {
    //     const objIndex1 = finalHotel.findIndex(
    //       (obj) => obj.roomType === '8th October 2022'
    //     );
    //     finalHotel[objIndex1].qty =
    //       Number(finalHotel[objIndex1].qty) + Number(item.room);

    //     const objIndex2 = finalHotel.findIndex(
    //       (obj) => obj.roomType === '7th October 2022'
    //     );
    //     finalHotel[objIndex2].qty =
    //       Number(finalHotel[objIndex2].qty) + Number(item.room);
    //   }
    // });
    // let tempFianlHotel = finalHotel.filter((item) => item.qty > 0);
    // setFinalHotel(tempFianlHotel);

    // console.log('1. id', id);
    // console.log('2. EventName', event.eventName);
    // console.log('3. mInit', mInit);
    // console.log('4. firstName', firstName);
    // console.log('5. lastName', lastName);
    // console.log('6. isMember', isMember);
    // console.log('7. memberId', memberId);
    // console.log('8. email', email);
    // console.log('9. phone', phone);
    // console.log('10. numberOfAdults', numberOfAdults);
    // console.log('11. numberOfMinors', numberOfMinors);
    // console.log('12. paymentResult', paymentResult);
    // console.log('13. programs', programs);
    // // console.log('14. hotels', finalHotel);
    // console.log('14. hotels', hotels);
    // // console.log('14. hotels', tempFianlHotel);
    // console.log(
    //   '15. TotalAmount',
    //   totalPrice + extraHotelCost + contributionAmount
    // );
    // console.log('16. subEventFee', 0);
    // console.log('17. hotelRent', extraHotelCost);
    // console.log('18. contributionAmount', contributionAmount);
    // console.log('19. selectedPackages', finalSelection);
    // console.log(numberOfMinors);

    dispatch(
      registerEvent({
        eventId: id,
        eventName: event.eventName,
        mInit,
        firstName,
        lastName,
        isMember,
        memberId,
        email,
        phone,
        numberOfAdults,
        numberOfMinors,
        paymentResult,
        programs,
        hotels: hotels,
        totalAmount: totalPrice + extraHotelCost + contributionAmount,
        subEventFee: 0,
        hotelRent: extraHotelCost,
        contributionAmount,
        selectedPackages: finalSelection,
      })
    );
  };

  return (
    <Container>
      <>
        <Card border="info" className="mt-2">
          <Card.Header className="text-center text-info" as="h5">
            Event Registration
          </Card.Header>
          <Card.Body>
            {eventRegisterLoading ? (
              <Loader />
            ) : (
              <Form onSubmit={submitHandler}>
                <Row>
                  {loading ? (
                    <Loader />
                  ) : error ? (
                    <Message variant="danger">{error}</Message>
                  ) : event ? (
                    event && (
                      <>
                        <Col md={8}>
                          <ListGroup variant="flush">
                            <ListGroup.Item>
                              <Row>
                                <Col md={3} className="text-info p-0" as="h5">
                                  Name{" "}
                                </Col>
                                <Col>
                                  <>
                                    <Row>
                                      <Col md={3}>Title: </Col>
                                      <Col>
                                        <Form.Group controlId="mInit">
                                          <Form.Control
                                            required
                                            as="select"
                                            type="text"
                                            value={mInit}
                                            onChange={(e) =>
                                              setMInit(e.target.value)
                                            }
                                          >
                                            <option value="Mr">Mr</option>
                                            <option value="Mrs">Mrs</option>
                                            <option value="Miss">Ms</option>
                                          </Form.Control>
                                        </Form.Group>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col md={3}>First Name: </Col>
                                      <Col>
                                        <Form.Group controlId="firstName">
                                          <Form.Control
                                            type="firstName"
                                            placeholder="Please Enter Your First Name.."
                                            value={firstName}
                                            onChange={(e) =>
                                              setFirstName(e.target.value)
                                            }
                                          ></Form.Control>
                                        </Form.Group>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col md={3}>Last Name: </Col>
                                      <Col>
                                        <Form.Group controlId="lastName">
                                          <Form.Control
                                            type="lastName"
                                            placeholder="Please Enter Your Last Name.."
                                            value={lastName}
                                            onChange={(e) =>
                                              setLastName(e.target.value)
                                            }
                                          ></Form.Control>
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </>
                                </Col>
                              </Row>
                            </ListGroup.Item>

                            {/* Contact Details */}
                            <ListGroup.Item>
                              <Row>
                                <Col md={3} className="text-info p-0" as="h5">
                                  Contact Details:{" "}
                                </Col>
                                <Col>
                                  <>
                                    <Row>
                                      <Col md={3}>Phone Number :</Col>
                                      <Col>
                                        <Form.Group controlId="phone">
                                          <Form.Control
                                            type="phone"
                                            placeholder="Enter Your Phone Number.."
                                            value={phone}
                                            onChange={(e) =>
                                              setPhone(e.target.value)
                                            }
                                          ></Form.Control>
                                        </Form.Group>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col md={3}>Email :</Col>
                                      <Col>
                                        <Form.Group controlId="email">
                                          <Form.Control
                                            required
                                            type="email"
                                            placeholder="Enter Email.."
                                            value={email}
                                            onChange={(e) =>
                                              setEmail(e.target.value)
                                            }
                                          ></Form.Control>
                                          <Form.Control.Feedback type="invalid">
                                            **Required
                                          </Form.Control.Feedback>
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </>
                                </Col>
                              </Row>
                            </ListGroup.Item>

                            <>
                              <ListGroup.Item>
                                <Row>
                                  <Col md={3} className="text-info p-0" as="h5">
                                    Premium Packages:
                                  </Col>
                                  <Col>
                                    <Form.Group controlId="packageType">
                                      {premiumPackages &&
                                        premiumPackages.map(
                                          (packageType, i) => (
                                            <Form.Check
                                              key={i}
                                              className="m-3"
                                              type="checkbox"
                                              id={`package-${packageType.packageId}`}
                                            >
                                              <Form.Check.Input
                                                type="checkbox"
                                                name={packageType.name}
                                                value={packageType.name}
                                                checked={packageCheckedState[i]}
                                                onChange={() =>
                                                  handlePremiumPackageChange(i)
                                                }
                                              />
                                              <Form.Check.Label>
                                                <strong
                                                  style={{
                                                    color: `${packageType.color}`,
                                                  }}
                                                >
                                                  {packageType.name}
                                                </strong>
                                                :{" "}
                                                {packageType.packageId ===
                                                "9" ? null : (
                                                  <>${packageType.price}</>
                                                )}{" "}
                                                ({packageType.description})
                                              </Form.Check.Label>
                                            </Form.Check>
                                          )
                                        )}
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </ListGroup.Item>
                            </>

                            <ListGroup.Item>
                              <Row>
                                <Col md={3} className="text-info p-0" as="h5">
                                  General Packages:
                                </Col>
                                <Col>
                                  <Form.Group controlId="generalPackageType">
                                    {generalPackages &&
                                      generalPackages.map((packageType, i) => (
                                        <Form.Check
                                          key={i}
                                          className="m-3"
                                          type="checkbox"
                                          id={`generalPackage-${packageType.packageId}`}
                                        >
                                          <Form.Check.Input
                                            type="checkbox"
                                            name={packageType.name}
                                            value={packageType.name}
                                            checked={generalCheckedState[i]}
                                            onChange={() =>
                                              handleGeneralPackageChange(i)
                                            }
                                          />
                                          <Form.Check.Label>
                                            <strong
                                              style={{
                                                color: `${packageType.color}`,
                                              }}
                                            >
                                              {packageType.name}
                                            </strong>
                                            {packageType.packageId ===
                                            "0" ? null : (
                                              <>
                                                : ${packageType.price} (
                                                {packageType.description})
                                              </>
                                            )}
                                          </Form.Check.Label>
                                        </Form.Check>
                                      ))}
                                  </Form.Group>
                                </Col>
                              </Row>

                              {(selectedGeneralPackage.packageId === "6" ||
                                selectedGeneralPackage.packageId === "7") && (
                                <Row>
                                  {/* Number of Attendees */}
                                  <Col md={3} className="text-info p-0" as="h5">
                                    Number Of Attendees:
                                  </Col>
                                  <Col md={{ offset: 1 }}>
                                    {/* <>
                                      <Form.Group controlId='numberOfGuest'>
                                        <Form.Control
                                          type='number'
                                          value={numberOfGuest}
                                          onChange={(e) =>
                                            setNumberOfGuest(e.target.value)
                                          }
                                          min='1'
                                        ></Form.Control>
                                      </Form.Group>
                                    </> */}
                                    <PersonNumberInput
                                      person={selectedGeneralPackage.person}
                                      setNumberInput={generalPackageAttendees}
                                    />
                                  </Col>
                                </Row>
                              )}
                            </ListGroup.Item>

                            {/* Boat */}
                            <ListGroup.Item>
                              <Row>
                                <Col md={3} className="text-info p-0" as="h5">
                                  Boat Cruise Package:
                                </Col>
                                <Col>
                                  <Form.Group controlId="boatPackageType">
                                    {boatPackages &&
                                      boatPackages.map((packageType, i) => (
                                        <Form.Check
                                          key={i}
                                          className="m-3"
                                          type="checkbox"
                                          id={`boatPackage-${packageType.packageId}`}
                                        >
                                          <Form.Check.Input
                                            type="checkbox"
                                            name={`boatPackage-${packageType.name}`}
                                            value={packageType.name}
                                            checked={boatCheckedState[i]}
                                            onChange={() =>
                                              handleBoatPackageChange(i)
                                            }
                                          />
                                          <Form.Check.Label>
                                            <strong
                                              style={{
                                                color: `${packageType.color}`,
                                              }}
                                            >
                                              {packageType.name}
                                            </strong>
                                            {packageType.packageId ===
                                            "0" ? null : (
                                              <>
                                                : ${packageType.price} (
                                                {packageType.description})
                                              </>
                                            )}
                                          </Form.Check.Label>
                                        </Form.Check>
                                      ))}
                                  </Form.Group>
                                </Col>
                              </Row>

                              {selectedBoatPackage.packageId === "8" && (
                                <Row>
                                  {/* Number of Boat Attendees */}
                                  <Col md={3} className="text-info p-0" as="h5">
                                    Number Of Attendees:
                                  </Col>
                                  <Col md={{ offset: 1 }}>
                                    <PersonNumberInput
                                      person={selectedBoatPackage.person}
                                      setNumberInput={boatPackageAttendees}
                                    />
                                  </Col>
                                </Row>
                              )}
                            </ListGroup.Item>

                            {/* Extra Contribution (optional): */}
                            <ListGroup.Item>
                              <Row>
                                <Col md={3} className="text-info p-0" as="h5">
                                  Extra Contribution (optional):
                                </Col>
                                <Col>
                                  <>
                                    <Row>
                                      <Col md={3}>USD :</Col>
                                      <Col>
                                        <Form.Group controlId="contributionAmount">
                                          <Form.Control
                                            type="number"
                                            min="0"
                                            placeholder="Contibute any amount"
                                            value={contributionAmount}
                                            onChange={(e) =>
                                              setContributionAmount(
                                                e.target.value
                                              )
                                            }
                                          ></Form.Control>
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </>
                                </Col>
                              </Row>
                            </ListGroup.Item>

                            {/* Hotel */}

                            {hotelOption ? (
                              <Accordion
                                defaultActiveKey={
                                  event.eventHotels &&
                                  event.eventHotels.length !== 0
                                    ? event.eventHotels[0].eventHotelId
                                    : "0"
                                }
                                className="mb-2"
                              >
                                {event.eventHotels &&
                                  event.eventHotels.length !== 0 &&
                                  event.eventHotels.map((hotel, index) => (
                                    <Card key={index}>
                                      <Accordion.Toggle
                                        as={Card.Header}
                                        eventKey={hotel.eventHotelId}
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        className="btn-primary-yellow btn-xl text-uppercase"
                                      >
                                        <Row>
                                          <Col className="mr-auto text-info">
                                            {hotel.hotelName}
                                          </Col>

                                          <Col
                                            className="ml-auto text-info"
                                            md={3}
                                            xs={6}
                                          >
                                            Book additional hotel ?
                                          </Col>
                                        </Row>
                                      </Accordion.Toggle>
                                      <Accordion.Collapse
                                        eventKey={hotel.eventHotelId}
                                      >
                                        <>
                                          {/* Extra dates */}
                                          <div className="text-right m-5">
                                            {!showMore ? (
                                              <Button
                                                className="btn btn-info btn-sm rounded"
                                                variant="transparent"
                                                onClick={showMoreDates}
                                              >
                                                More Dates
                                              </Button>
                                            ) : (
                                              <Button
                                                className="btn btn-info btn-sm rounded"
                                                variant="transparent"
                                                onClick={showMoreDates}
                                              >
                                                Less Dates
                                              </Button>
                                            )}
                                          </div>

                                          <Card.Body>
                                            {newHotelarray &&
                                            newHotelarray.length !== 0
                                              ? newHotelarray.map(
                                                  (room, index) => (
                                                    <Card.Body
                                                      key={index}
                                                      className="mb-2"
                                                    >
                                                      <ListGroup variant="flush">
                                                        <Row>
                                                          <Col
                                                            md={3}
                                                            className="text-info p-0"
                                                            as="h6"
                                                          >
                                                            Date:
                                                          </Col>
                                                          <Col className="mr-auto">
                                                            {room.roomType}
                                                          </Col>
                                                        </Row>

                                                        <Row>
                                                          <Col
                                                            md={3}
                                                            className="text-info p-0"
                                                            as="h6"
                                                          >
                                                            Description :
                                                          </Col>
                                                          <Col>
                                                            {
                                                              room.roomDescription
                                                            }
                                                          </Col>
                                                        </Row>

                                                        <Row>
                                                          <Col
                                                            md={3}
                                                            className="text-info p-0"
                                                            as="h6"
                                                          >
                                                            Accomodation:
                                                          </Col>
                                                          <Col>
                                                            {room.accomodation}{" "}
                                                            Person(s)
                                                          </Col>
                                                        </Row>

                                                        <Row>
                                                          <Col
                                                            md={3}
                                                            className="text-info p-0"
                                                            as="h6"
                                                          >
                                                            Rent:
                                                          </Col>
                                                          <Col>
                                                            {" "}
                                                            ${room.rent}
                                                          </Col>
                                                        </Row>

                                                        <Row>
                                                          <Col
                                                            md={3}
                                                            className="text-info p-0"
                                                            as="h6"
                                                          >
                                                            Number of room
                                                            reserved:
                                                          </Col>
                                                          <Col>
                                                            <NumberInput
                                                              roomNumber={
                                                                totalPremiumRoom
                                                              }
                                                              room={room}
                                                              hotelName={
                                                                hotel.hotelName
                                                              }
                                                              registerRoom={
                                                                registerRoom
                                                              }
                                                            />
                                                          </Col>
                                                        </Row>
                                                      </ListGroup>
                                                      <hr />
                                                    </Card.Body>
                                                  )
                                                )
                                              : null}
                                          </Card.Body>

                                          {showMore ? (
                                            <Card.Body>
                                              {newHotelarray2 &&
                                              newHotelarray2.length !== 0
                                                ? newHotelarray2.map(
                                                    (room, index) => (
                                                      <Card.Body
                                                        key={index}
                                                        className="mb-2"
                                                      >
                                                        <ListGroup variant="flush">
                                                          <Row>
                                                            <Col
                                                              md={3}
                                                              className="text-info p-0"
                                                              as="h6"
                                                            >
                                                              Date:
                                                            </Col>
                                                            <Col className="mr-auto">
                                                              {room.roomType}
                                                            </Col>
                                                          </Row>

                                                          <Row>
                                                            <Col
                                                              md={3}
                                                              className="text-info p-0"
                                                              as="h6"
                                                            >
                                                              Description :
                                                            </Col>
                                                            <Col>
                                                              {
                                                                room.roomDescription
                                                              }
                                                            </Col>
                                                          </Row>

                                                          <Row>
                                                            <Col
                                                              md={3}
                                                              className="text-info p-0"
                                                              as="h6"
                                                            >
                                                              Accomodation:
                                                            </Col>
                                                            <Col>
                                                              {
                                                                room.accomodation
                                                              }{" "}
                                                              Person(s)
                                                            </Col>
                                                          </Row>

                                                          <Row>
                                                            <Col
                                                              md={3}
                                                              className="text-info p-0"
                                                              as="h6"
                                                            >
                                                              Rent:
                                                            </Col>
                                                            <Col>
                                                              {" "}
                                                              ${room.rent}
                                                            </Col>
                                                          </Row>

                                                          <Row>
                                                            <Col
                                                              md={3}
                                                              className="text-info p-0"
                                                              as="h6"
                                                            >
                                                              Number of room
                                                              reserved:
                                                            </Col>
                                                            <Col>
                                                              <NumberInput
                                                                roomNumber={
                                                                  room.qty
                                                                }
                                                                room={room}
                                                                hotelName={
                                                                  hotel.hotelName
                                                                }
                                                                registerRoom={
                                                                  registerRoom
                                                                }
                                                              />
                                                            </Col>
                                                          </Row>
                                                        </ListGroup>
                                                        <hr />
                                                      </Card.Body>
                                                    )
                                                  )
                                                : null}
                                            </Card.Body>
                                          ) : null}
                                        </>
                                      </Accordion.Collapse>
                                    </Card>
                                  ))}
                              </Accordion>
                            ) : null}
                          </ListGroup>
                        </Col>
                        {/* Summary */}
                        <Col md={4}>
                          <Card>
                            <ListGroup variant="flush">
                              <ListGroup.Item>
                                <h5 className="text-info">Summary</h5>
                              </ListGroup.Item>

                              <ListGroup.Item>
                                <Row>
                                  <Col md={3} className="p-0 m-0">
                                    Event Name:
                                  </Col>
                                  <Col>{event.eventName}</Col>
                                </Row>
                              </ListGroup.Item>

                              <ListGroup.Item>
                                <Row>
                                  <Col>Selected Package/s:</Col>
                                  <Col style={{ color: `${color}` }}>
                                    <strong>{totalPackageSelected}</strong>
                                  </Col>
                                </Row>
                              </ListGroup.Item>

                              <ListGroup.Item>
                                <Row>
                                  <Col>Extra Hotel Room:</Col>
                                  <Col>{Number(totalExtraRoom)}</Col>
                                </Row>
                              </ListGroup.Item>

                              <ListGroup.Item>
                                <Row>
                                  <Col>Extra Hotel Room Cost:</Col>
                                  <Col>{getFormattedPrice(extraHotelCost)}</Col>
                                </Row>
                              </ListGroup.Item>

                              <ListGroup.Item>
                                <Row>
                                  <Col>Total No. Of Attendees:</Col>
                                  <Col>
                                    {/* {numberOfAdults > 0
                                      ? numberOfAdults
                                      : numberOfMinors > 0
                                      ? numberOfMinors
                                      : numberOfAdults} */}
                                    {Number(
                                      Number(totalPerson) -
                                        Number(selectedBoatPackage.person)
                                    )}
                                  </Col>
                                </Row>
                              </ListGroup.Item>

                              {/* <ListGroup.Item>
                              <Row>
                                <Col>Registration Fee :</Col>
                                {event.cap <=
                                numberOfAdults * event.adultFee +
                                  numberOfMinors * event.minorFee ? (
                                  <Col>${event.cap}</Col>
                                ) : (
                                  <Col>
                                    $
                                    {numberOfAdults * event.adultFee +
                                      numberOfMinors * event.minorFee}
                                  </Col>
                                )}
                              </Row>
                            </ListGroup.Item> */}

                              <ListGroup.Item>
                                <Row>
                                  <Col>Boat Cruise Attendees :</Col>

                                  <Col>{selectedBoatPackage.person}</Col>
                                </Row>
                              </ListGroup.Item>

                              <ListGroup.Item>
                                <Row>
                                  <Col>Extra Contribution :</Col>

                                  <Col>
                                    {getFormattedPrice(contributionAmount * 1)}
                                  </Col>
                                </Row>
                              </ListGroup.Item>

                              <ListGroup.Item>
                                <Row>
                                  <Col>Total :</Col>

                                  <Col>
                                    {getFormattedPrice(
                                      totalPrice +
                                        extraHotelCost +
                                        contributionAmount * 1
                                    )}
                                  </Col>
                                </Row>
                              </ListGroup.Item>

                              <ListGroup.Item>
                                <Row>
                                  <Col>
                                    Please proceed to payment to complete the
                                    registration
                                  </Col>
                                </Row>
                              </ListGroup.Item>

                              <ListGroup.Item>
                                {!sdkReady ? (
                                  <Loader />
                                ) : readyToPay ? (
                                  <PayPalButton
                                    amount={
                                      Number(totalPrice) +
                                      Number(extraHotelCost) +
                                      Number(contributionAmount)
                                    }
                                    onSuccess={successPaymentHandler}
                                  />
                                ) : (
                                  // <Button onClick={successPaymentHandler}>
                                  //   test register
                                  // </Button>
                                  // <Button onClick={successPaymentHandler}>
                                  //   test pay
                                  // </Button>
                                  // <Button
                                  //   onClick={successPaymentHandler}
                                  //   variant='info'
                                  //   block
                                  // >
                                  //   Pay check
                                  // </Button>
                                  <Button
                                    type="submit"
                                    variant="info"
                                    disabled={
                                      finalSelection.length === 0 ? true : false
                                    }
                                    block
                                  >
                                    Pay
                                  </Button>
                                )}
                              </ListGroup.Item>
                            </ListGroup>
                          </Card>
                        </Col>
                      </>
                    )
                  ) : null}
                </Row>
              </Form>
            )}
          </Card.Body>
        </Card>
      </>
    </Container>
  );
};

export default CentralEventRegistration2022;
