export const ANNOUNCEMENT_NEW_REQUEST = 'ANNOUNCEMENT_NEW_REQUEST';
export const ANNOUNCEMENT_NEW_SUCCESS = 'ANNOUNCEMENT_NEW_SUCCESS';
export const ANNOUNCEMENT_NEW_FAIL = 'ANNOUNCEMENT_NEW_FAIL';
export const ANNOUNCEMENT_NEW_RESET = 'ANNOUNCEMENT_NEW_RESET';

export const ANNOUNCEMENT_ALL_REQUEST = 'ANNOUNCEMENT_ALL_REQUEST';
export const ANNOUNCEMENT_ALL_SUCCESS = 'ANNOUNCEMENT_ALL_SUCCESS';
export const ANNOUNCEMENT_ALL_FAIL = 'ANNOUNCEMENT_ALL_FAIL';

export const ANNOUNCEMENT_BY_ID_REQUEST = 'ANNOUNCEMENT_BY_ID_REQUEST';
export const ANNOUNCEMENT_BY_ID_SUCCESS = 'ANNOUNCEMENT_BY_ID_SUCCESS';
export const ANNOUNCEMENT_BY_ID_FAIL = 'ANNOUNCEMENT_BY_ID_FAIL';
export const ANNOUNCEMENT_BY_ID_RESET = 'ANNOUNCEMENT_BY_ID_RESET';

export const ANNOUNCEMENT_UPDATE_BY_ID_REQUEST =
  'ANNOUNCEMENT_UPDATE_BY_ID_REQUEST';
export const ANNOUNCEMENT_UPDATE_BY_ID_SUCCESS =
  'ANNOUNCEMENT_UPDATE_BY_ID_SUCCESS';
export const ANNOUNCEMENT_UPDATE_BY_ID_FAIL = 'ANNOUNCEMENT_UPDATE_BY_ID_FAIL';
export const ANNOUNCEMENT_UPDATE_BY_ID_RESET =
  'ANNOUNCEMENT_UPDATE_BY_ID_RESET';

export const ANNOUNCEMENT_DELETE_REQUEST = 'ANNOUNCEMENT_DELETE_REQUEST';
export const ANNOUNCEMENT_DELETE_SUCCESS = 'ANNOUNCEMENT_DELETE_SUCCESS';
export const ANNOUNCEMENT_DELETE_FAIL = 'ANNOUNCEMENT_DELETE_FAIL';
