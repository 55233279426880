import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import NewsletterUnSubscription from '../../components/Newsletter/NewsletterUnSubscription';

const NewsLetterUnSubScreen = ({ history }) => {
  const chapterBySubDomain = useSelector((state) => state.chapterBySubDomain);
  const { chapterByDomain } = chapterBySubDomain;

  useEffect(() => {
    if (chapterByDomain && !chapterByDomain.jobPortal) {
      history.push('/');
    }
  }, [history, chapterByDomain]);

  return (
    <div className='text-center'>
      {chapterByDomain && chapterByDomain.jobPortal ? (
        <NewsletterUnSubscription
          history={history}
          chapterName={chapterByDomain.chapterName}
        />
      ) : null}
    </div>
  );
};

export default NewsLetterUnSubScreen;
