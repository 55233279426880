import {
  NEWSLETTER_SUB_FAIL,
  NEWSLETTER_SUB_REQUEST,
  NEWSLETTER_SUB_RESET,
  NEWSLETTER_SUB_SUCCESS,
  NEWSLETTER_UNSUB_FAIL,
  NEWSLETTER_UNSUB_REQUEST,
  NEWSLETTER_UNSUB_RESET,
  NEWSLETTER_UNSUB_SUCCESS,
} from '../constants/newsletterConstants';

export const newsletterSubReducer = (state = {}, action) => {
  switch (action.type) {
    case NEWSLETTER_SUB_REQUEST:
      return { loading: true };
    case NEWSLETTER_SUB_SUCCESS:
      return { loading: false, success: action.payload };
    case NEWSLETTER_SUB_FAIL:
      return { loading: false, error: action.payload };
    case NEWSLETTER_SUB_RESET:
      return {};
    default:
      return state;
  }
};

export const newsletterUnSubReducer = (state = {}, action) => {
  switch (action.type) {
    case NEWSLETTER_UNSUB_REQUEST:
      return { loading: true };
    case NEWSLETTER_UNSUB_SUCCESS:
      return { loading: false, success: action.payload };
    case NEWSLETTER_UNSUB_FAIL:
      return { loading: false, error: action.payload };
    case NEWSLETTER_UNSUB_RESET:
      return {};
    default:
      return state;
  }
};
