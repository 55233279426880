import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';

import { Button, Row, Col, Card, Form, Accordion } from 'react-bootstrap';
import Sidebar from '../../components/Sidebar/Sidebar';

import Message from '../../components/Message';
import Loader from '../../components/Loader';

import {
  allDocuments,
  deleteDocument,
  newDocument,
} from '../../actions/documentAction';
import {
  DOCUMENT_BY_ID_RESET,
  DOCUMENT_NEW_RESET,
} from '../../constants/documentConstants';
import RTable from '../../components/Table/RTable';
import ColumnFilter from '../../components/Table/ColumnFilter';

const UploadScreen = ({ history }) => {
  const dispatch = useDispatch();
  const checkChapter = window.location.host;

  const [docColumns, setDocColumns] = useState([]);

  const documentsRef = useRef();

  const [addDocument, setAddDocument] = useState(false);
  const [documentType, setDocumentType] = useState('');
  const [newCategory, setNewCategory] = useState('');
  const [documentName, setDocumentName] = useState('');
  const [documentDescription, setDocumentDescription] = useState('');
  const [document, setDocument] = useState('');
  const [uploading, setUploading] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const documentAll = useSelector((state) => state.documentAll);
  const { loading, error, documents } = documentAll;

  documentsRef.current = documents;

  const documentNew = useSelector((state) => state.documentNew);
  const {
    loading: documentNewLoading,
    error: documentNewError,
    success,
  } = documentNew;

  const documentDelete = useSelector((state) => state.documentDelete);
  const { success: successDelete } = documentDelete;

  const deleteDocHandler = useCallback(
    (rowIndex) => {
      console.log(rowIndex);
      if (window.confirm('Are you sure about deleting this Document?')) {
        dispatch(deleteDocument(rowIndex));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (checkChapter.split('.')[0] !== 'aabea' && !userInfo) {
      history.push('/login');
    }
    dispatch(allDocuments(checkChapter));
    if (success) {
      setAddDocument((addDocument) => !addDocument);
      setDocumentType('');
      setDocumentName('');
      setDocumentDescription('');
      setDocument('');
      dispatch({ type: DOCUMENT_BY_ID_RESET });
      dispatch({ type: DOCUMENT_NEW_RESET });
    }

    if (
      userInfo &&
      (userInfo.userRole === 'admin' || userInfo.userRole === 'systemAdmin')
    ) {
      setDocColumns([
        {
          Header: 'No.',
          accessor: (row, i) => i + 1,
        },
        {
          Header: 'Document Name',
          id: 'link',
          accessor: (d) => d.document,
          Cell: ({ row }) => (
            <a
              href={`/doc/${row.original.documentId}`}
              name={row.original.documentName}
              target='_blank'
              rel='noreferrer'
            >
              {row.original.documentName}
            </a>
          ),
          Filter: ColumnFilter,
        },
        {
          Header: 'Category',
          accessor: 'documentType',
          Filter: ColumnFilter,
        },
        {
          Header: 'Description',
          accessor: 'documentDescription',
          Filter: ColumnFilter,
        },
        {
          Header: 'Upload Date',
          accessor: 'createdAt',
          Filter: ColumnFilter,
          Cell: ({ value }) => {
            // return format(new Date(value), 'dd/mm/yyyy');
            return <Moment format='DD/MM/YYYY'>{value}</Moment>;
          },
        },

        {
          Header: 'Actions',
          accessor: 'actions',
          Cell: ({ row }) => {
            const rowIndex = row.original.documentId;
            return (
              <>
                <span onClick={() => deleteDocHandler(rowIndex)}>
                  <i
                    className='fas fa-trash action ml-2'
                    style={{ color: 'red', cursor: 'pointer' }}
                  ></i>
                </span>
              </>
            );
          },
        },
      ]);
    } else {
      setDocColumns([
        {
          Header: 'No.',
          accessor: (row, i) => i + 1,
        },
        {
          Header: 'Document Name',
          // accessor: 'documentName',
          id: 'link',
          accessor: (d) => d.document,
          Cell: ({ row }) => (
            <a
              // onClick={() => openDocHandler(row.original.documentId)}
              href={`/doc/${row.original.documentId}`}
              name={row.original.documentName}
              target='_blank'
              rel='noreferrer'
            >
              {row.original.documentName}
            </a>
          ),
          Filter: ColumnFilter,
        },
        {
          Header: 'Category',
          accessor: 'documentType',
          Filter: ColumnFilter,
        },
        {
          Header: 'Description',
          accessor: 'documentDescription',
          Filter: ColumnFilter,
        },
        {
          Header: 'Upload Date',
          accessor: 'createdAt',
          Filter: ColumnFilter,
          Cell: ({ value }) => {
            // return format(new Date(value), 'dd/mm/yyyy');
            return <Moment format='DD/MM/YYYY'>{value}</Moment>;
          },
        },
      ]);
    }
  }, [
    dispatch,
    deleteDocHandler,
    history,
    userInfo,
    checkChapter,
    success,
    successDelete,
  ]);

  const addNewDocument = (e) => {
    e.preventDefault();

    setAddDocument(!addDocument);
  };

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);
    setUploading(true);

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const { data } = await axios.post('/api/upload', formData, config);
      setDocument(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (documentType === 'newDocCategory') {
      dispatch(
        newDocument(
          newCategory,
          documentName,
          documentDescription,
          document,
          checkChapter
        )
      );
    } else {
      dispatch(
        newDocument(
          documentType,
          documentName,
          documentDescription,
          document,
          checkChapter
        )
      );
    }
  };
  return (
    <>
      <Row className='content'>
        {/* Sidebar */}
        {userInfo ? (
          <>
            <Col
              md={{ span: 3, order: 1 }}
              lg={{ span: 3, order: 1 }}
              id='sidebar-wrapper'
              className='m-0 p-1'
            >
              <Sidebar />
            </Col>
            {/* Sidebar End */}

            <Col
              md={{ span: 9, order: 12 }}
              lg={{ span: 9, order: 12 }}
              id='page-content-wrapper'
              className='m-0 p-1'
            >
              <>
                {/* <CardColumns> */}

                <Row>
                  {userInfo &&
                    (userInfo.userRole === 'admin' ||
                      userInfo.userRole === 'systemAdmin') && (
                      <Col
                        md={{ span: 12, order: 1 }}
                        lg={{ span: 12, order: 1 }}
                        // style={{ padding: 0 }}
                        className='mb-2 p-0'
                      >
                        <Card border='info'>
                          <Card.Header className='text-center' as='h2'>
                            <Link
                              className='btn btn-outline-info btn-sm btn-block rounded'
                              onClick={addNewDocument}
                              to=''
                            >
                              Add Document
                            </Link>
                          </Card.Header>
                          <Card.Body>
                            {addDocument
                              ? (documentNewError && (
                                  <Message variant='danger'>
                                    {documentNewError}
                                  </Message>
                                )) ||
                                (documentNewLoading && <Loader />) ||
                                (success ? (
                                  <Message variant='success'>{success}</Message>
                                ) : (
                                  <Form onSubmit={submitHandler}>
                                    <Form.Group controlId='documentType'>
                                      <Form.Label>Document Category</Form.Label>
                                      <Form.Control
                                        required
                                        as='select'
                                        type='text'
                                        value={documentType}
                                        onChange={(e) =>
                                          setDocumentType(e.target.value)
                                        }
                                      >
                                        <option value=''>
                                          Please select a document category
                                        </option>

                                        <option value='newDocCategory'>
                                          Add new document category
                                        </option>
                                        <option disabled>
                                          ── Existing Document Categories: ──
                                        </option>

                                        {documents &&
                                          documents.length !== 0 &&
                                          documents.map((document, index) => (
                                            <option
                                              key={index}
                                              value={document.name}
                                            >
                                              {document.name}
                                            </option>
                                          ))}
                                      </Form.Control>

                                      {documentType &&
                                      documentType === 'newDocCategory' ? (
                                        <Form.Control
                                          required
                                          type='text'
                                          placeholder='Please Enter New Document Category'
                                          value={newCategory}
                                          onChange={(e) =>
                                            setNewCategory(e.target.value)
                                          }
                                        ></Form.Control>
                                      ) : null}
                                    </Form.Group>

                                    <Form.Group controlId='documentName'>
                                      <Form.Label>Document Name</Form.Label>
                                      <Form.Control
                                        required
                                        type='text'
                                        placeholder='Please Enter Document Name'
                                        value={documentName}
                                        onChange={(e) =>
                                          setDocumentName(e.target.value)
                                        }
                                      ></Form.Control>
                                    </Form.Group>

                                    <Form.Group controlId='documentDescription'>
                                      <Form.Label>
                                        Document Description
                                      </Form.Label>
                                      <Form.Control
                                        required
                                        type='text'
                                        placeholder='Please enter document description'
                                        value={documentDescription}
                                        onChange={(e) =>
                                          setDocumentDescription(e.target.value)
                                        }
                                      ></Form.Control>
                                    </Form.Group>

                                    <Form.Group controlId='document'>
                                      <Form.Label>Document</Form.Label>
                                      <Form.Control
                                        required
                                        type='text'
                                        placeholder='Enter document url..'
                                        value={document}
                                        onChange={(e) =>
                                          setDocument(e.target.value)
                                        }
                                      ></Form.Control>
                                      <Form.File
                                        id='image-file'
                                        label='Choose File'
                                        custom
                                        onChange={uploadFileHandler}
                                      ></Form.File>
                                      {uploading && <Loader />}
                                    </Form.Group>

                                    <Button type='submit' variant='info' block>
                                      <i className='fas fa-plus' /> Load
                                    </Button>
                                  </Form>
                                ))
                              : null}
                          </Card.Body>
                        </Card>
                      </Col>
                    )}

                  <Col
                    md={{ span: 12, order: 12 }}
                    lg={{ span: 12, order: 12 }}
                    className='mb-2 p-0'
                    id='all-chapter'
                  >
                    {/* All Documents */}
                    <Card className='text-center mb-2' border='info'>
                      <Card.Header as='h3' className='text-info'>
                        All Documents
                      </Card.Header>

                      <>
                        {loading ? (
                          <Loader />
                        ) : error ? (
                          <Message variant='danger'>{error}</Message>
                        ) : (
                          <>
                            {documents && documents.length !== 0 && (
                              <>
                                <Accordion defaultActiveKey='0'>
                                  {documents.map((document, index) => (
                                    <Card key={index} border='info'>
                                      <Accordion.Toggle
                                        as={Card.Header}
                                        eventKey={document.docCategoryId}
                                        className='text-center text-info h5'
                                        style={{ cursor: 'pointer' }}
                                      >
                                        {document.name}
                                      </Accordion.Toggle>
                                      <Accordion.Collapse
                                        eventKey={document.docCategoryId}
                                      >
                                        <>
                                          {document.documents &&
                                            document.documents.length !== 0 && (
                                              <RTable
                                                users={document.documents}
                                                COLUMNS={docColumns}
                                              />
                                            )}
                                        </>
                                      </Accordion.Collapse>
                                    </Card>
                                  ))}
                                </Accordion>
                              </>
                            )}
                          </>
                        )}
                      </>
                    </Card>
                  </Col>
                </Row>
              </>
            </Col>
          </>
        ) : (
          <>
            <Col
              md={{ span: 12, order: 12 }}
              lg={{ span: 12, order: 12 }}
              className='mb-2 p-0'
              id='all-chapter'
            >
              {/* All Documents */}
              <Card className='text-center mb-2' border='info'>
                <Card.Header as='h3' className='text-info'>
                  All Documents
                </Card.Header>

                <>
                  {loading ? (
                    <Loader />
                  ) : error ? (
                    <Message variant='danger'>{error}</Message>
                  ) : (
                    <>
                      {documents && documents.length !== 0 && (
                        <>
                          <Accordion defaultActiveKey='0'>
                            {documents.map((document, index) => (
                              <Card key={index} border='info'>
                                <Accordion.Toggle
                                  as={Card.Header}
                                  eventKey={document.docCategoryId}
                                  className='text-center text-info h5'
                                  style={{ cursor: 'pointer' }}
                                >
                                  {document.name}
                                </Accordion.Toggle>
                                <Accordion.Collapse
                                  eventKey={document.docCategoryId}
                                >
                                  <>
                                    {document.documents &&
                                      document.documents.length !== 0 && (
                                        <RTable
                                          users={document.documents}
                                          COLUMNS={docColumns}
                                        />
                                      )}
                                  </>
                                </Accordion.Collapse>
                              </Card>
                            ))}
                          </Accordion>
                        </>
                      )}
                    </>
                  )}
                </>
              </Card>
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

export default UploadScreen;
