import React from 'react';
import { Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import { deleteImage } from '../../actions/imageActions';

const ImageLightBox = ({ images }) => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const deleteImageHandler = (e, imageId) => {
    e.preventDefault();
    console.log(imageId);
    if (window.confirm('Are You Sure?')) {
      dispatch(deleteImage(imageId));
    }
  };

  return (
    <div className='p-1 image-lightbox'>
      <SimpleReactLightbox>
        <SRLWrapper>
          {images &&
            images.map((image, i) => (
              <a key={i} href={image.image} className='m-1 p-0 image-lightbox'>
                <Image
                  src={image.image}
                  alt={image.imageDescription}
                  width={150}
                  height={150}
                  srl_gallery_image='true'
                />{' '}
                {userInfo &&
                  (userInfo.userRole === 'systemAdmin' ||
                    userInfo.userRole === 'admin') && (
                    <span
                      className='image-delete'
                      onClick={(e) => {
                        deleteImageHandler(e, image.imageId);
                      }}
                    >
                      <i className='fas fa-trash action'></i>
                    </span>
                  )}{' '}
              </a>
            ))}
        </SRLWrapper>
      </SimpleReactLightbox>
    </div>
  );
};

export default ImageLightBox;
