import React, { useState } from 'react';
import {
  Button,
  Col,
  Form,
  ListGroup,
  Modal,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

// { groupId, ageFrom, ageTo, registerGroup }
const GroupRegistration = ({ group, programName, registerGroup }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [participantName, setParticipantName] = useState('');
  const [age] = useState(0);
  const [grade, setGrade] = useState('');
  const [schoolName, setSchoolName] = useState('');

  return (
    <>
      <OverlayTrigger
        overlay={<Tooltip id='tooltip-disabled'>Click to Register</Tooltip>}
      >
        <Link
          className='btn btn-outline-info btn-sm rounded'
          to='#'
          onClick={handleShow}
        >
          <i
            className='fas fa-plus action'
            style={{
              cursor: 'pointer',
            }}
          ></i>
        </Link>
      </OverlayTrigger>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        size='lg'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className='text-center text-info'>
            Add participant : {group.groupName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup variant='flush'>
            <ListGroup.Item>
              <Form.Row>
                <Form.Group as={Col} md='3'>
                  <Form.Label>Name :</Form.Label>
                </Form.Group>
                <Col>
                  <Form.Group as={Col} controlId='participantName'>
                    <Form.Control
                      required
                      type='text'
                      placeholder='Name of the participant..'
                      value={participantName}
                      onChange={(e) => setParticipantName(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Form.Row>
            </ListGroup.Item>

            {/* <ListGroup.Item>
              <Form.Row>
                <Form.Group as={Col} md='3'>
                  <Form.Label>Age :</Form.Label>
                </Form.Group>
                <Col>
                  <Form.Group as={Col} controlId='age'>
                    <Form.Control
                      required
                      type='number'
                      value={age}
                      onChange={(e) => setAge(e.target.value)}
                      min={group.ageFrom}
                      max={group.ageTo}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Form.Row>
            </ListGroup.Item> */}

            <ListGroup.Item>
              <Form.Row>
                <Form.Group as={Col} md='3'>
                  <Form.Label>Grade :</Form.Label>
                </Form.Group>
                <Col>
                  <Form.Group as={Col} controlId='grade'>
                    <Form.Control
                      required
                      type='text'
                      placeholder='grade'
                      value={grade}
                      onChange={(e) => setGrade(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Form.Row>
            </ListGroup.Item>

            <ListGroup.Item>
              <Form.Row>
                <Form.Group as={Col} md='3'>
                  <Form.Label>Name of School :</Form.Label>
                </Form.Group>
                <Col>
                  <Form.Group as={Col} controlId='schoolName'>
                    <Form.Control
                      required
                      type='text'
                      placeholder='School Name..'
                      value={schoolName}
                      onChange={(e) => setSchoolName(e.target.value)}
                      maxlength='49'
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Form.Row>
            </ListGroup.Item>
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Link
            className='btn btn-outline-danger btn-sm rounded'
            to='#'
            onClick={handleClose}
          >
            Cancel
          </Link>

          <Button
            className='btn-info btn-sm rounded'
            disabled={
              participantName &&
              // age >= group.ageFrom &&
              // age <= group.ageTo &&
              grade &&
              schoolName
                ? false
                : true
            }
            onClick={() => {
              const groupId = group.eventGroupId;
              const programId = group.eventProgramId;
              const eventId = group.eventId;
              const groupName = group.groupName;
              const fee = group.fee;

              registerGroup(
                groupId,
                programId,
                eventId,
                programName,
                groupName,
                participantName,
                age,
                grade,
                fee,
                schoolName
              );
              handleClose();
            }}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default GroupRegistration;
