export const QUICK_LINK_NEW_REQUEST = 'QUICK_LINK_NEW_REQUEST';
export const QUICK_LINK_NEW_SUCCESS = 'QUICK_LINK_NEW_SUCCESS';
export const QUICK_LINK_NEW_FAIL = 'QUICK_LINK_NEW_FAIL';
export const QUICK_LINK_NEW_RESET = 'QUICK_LINK_NEW_RESET';

export const QUICK_LINK_ALL_REQUEST = 'QUICK_LINK_ALL_REQUEST';
export const QUICK_LINK_ALL_SUCCESS = 'QUICK_LINK_ALL_SUCCESS';
export const QUICK_LINK_ALL_FAIL = 'QUICK_LINK_ALL_FAIL';

export const QUICK_LINK_BY_ID_REQUEST = 'QUICK_LINK_BY_ID_REQUEST';
export const QUICK_LINK_BY_ID_SUCCESS = 'QUICK_LINK_BY_ID_SUCCESS';
export const QUICK_LINK_BY_ID_FAIL = 'QUICK_LINK_BY_ID_FAIL';
export const QUICK_LINK_BY_ID_RESET = 'QUICK_LINK_BY_ID_RESET';

export const QUICK_LINK_UPDATE_BY_ID_REQUEST =
  'QUICK_LINK_UPDATE_BY_ID_REQUEST';
export const QUICK_LINK_UPDATE_BY_ID_SUCCESS =
  'QUICK_LINK_UPDATE_BY_ID_SUCCESS';
export const QUICK_LINK_UPDATE_BY_ID_FAIL = 'QUICK_LINK_UPDATE_BY_ID_FAIL';
export const QUICK_LINK_UPDATE_BY_ID_RESET = 'QUICK_LINK_UPDATE_BY_ID_RESET';

export const QUICK_LINK_DELETE_REQUEST = 'QUICK_LINK_DELETE_REQUEST';
export const QUICK_LINK_DELETE_SUCCESS = 'QUICK_LINK_DELETE_SUCCESS';
export const QUICK_LINK_DELETE_FAIL = 'QUICK_LINK_DELETE_FAIL';
