import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Courtesy from '../../components/Career/Courtesy';
import JobCreateEdit from '../../components/Career/JobCreateEdit';

const CreateJobScreen = ({ history }) => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      history.push('/login?redirect=/job/new');
    }
  }, [dispatch, history, userInfo]);

  return (
    <Container>
      <Courtesy />
      <Link className='btn  my-3 btn-sm btn-outline-danger' to='/jobs'>
        Cancel
      </Link>

      <JobCreateEdit history={history} />
    </Container>
  );
};

export default CreateJobScreen;
