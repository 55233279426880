import React, { useEffect, useState } from 'react';
import RTableTest from './RTableTest';

const ModTableData = ({ data }) => {
  const [registrations, setRegistrations] = useState([]);

  // useEffect to set registrations ---
  useEffect(() => {
    if (data && data.length > 0) {
      const showProducts = [];

      data.map((registration) => {
        if (
          registration.eventHotelRegistrations &&
          registration.eventHotelRegistrations.length !== 0
        ) {
          registration.eventHotelRegistrations.map((hotelRegistration) => {
            showProducts.push({
              registrationId: registration.registrationId,
              createdAt: registration.createdAt,
              firstName: registration.firstName,
              lastName: registration.lastName,
              memberId: registration.memberId,
              email: registration.email,
              phone: registration.phone,
              numberOfAdults: registration.numberOfAdults,
              numberOfMinors: registration.numberOfMinors,
              payment: registration.eventPayment.amount,
              note: registration.note,
              hotelRegistration: hotelRegistration,
            });
          });
        }
        if (
          registration.eventGroupRegistrations &&
          registration.eventGroupRegistrations.length !== 0
        ) {
          registration.eventGroupRegistrations.map((groupRegistration) => {
            showProducts.push({
              registrationId: registration.registrationId,
              createdAt: registration.createdAt,
              firstName: registration.firstName,
              lastName: registration.lastName,
              memberId: registration.memberId,
              email: registration.email,
              phone: registration.phone,
              numberOfAdults: registration.numberOfAdults,
              numberOfMinors: registration.numberOfMinors,
              payment: registration.eventPayment.amount,
              groupRegistration: groupRegistration,
            });
          });
        }
        if (
          registration.eventGroupRegistrations.length === 0 &&
          registration.eventHotelRegistrations.length === 0
        ) {
          showProducts.push({
            registrationId: registration.registrationId,
            createdAt: registration.createdAt,
            firstName: registration.firstName,
            lastName: registration.lastName,
            memberId: registration.memberId,
            email: registration.email,
            phone: registration.phone,
            numberOfAdults: registration.numberOfAdults,
            numberOfMinors: registration.numberOfMinors,
            payment: registration.eventPayment.amount,
            note: registration.note,
          });
        }
      });
      setRegistrations(showProducts);
    }
  }, [data]);

  return (
    <>
      <RTableTest users={registrations} />
    </>
  );
};

export default ModTableData;
