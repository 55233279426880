import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Form,
  ListGroup,
  Modal,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { allMTypes } from '../../actions/memberTypeAction';
import { addUser } from '../../actions/userActions';
import { USER_ADD_RESET } from '../../constants/userConstants';
import Loader from '../Loader';
import Message from '../Message';

const AddMember = () => {
  const dispatch = useDispatch();

  const checkChapter = window.location.host;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [validated, setValidated] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [mInit, setMInit] = useState('Mr');
  const [lastName, setLastName] = useState('');
  const [address1, setAddress1] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [primaryPhone, setPrimaryPhone] = useState('');
  const [degree, setDegree] = useState('');
  const [degreeYear, setDegreeYear] = useState(2000);
  const [major, setMajor] = useState('');
  const [collegeName, setCollegeName] = useState('');
  const [email, setEmail] = useState('');
  const [memberType, setMemberType] = useState('');

  const memberTypeAll = useSelector((state) => state.memberTypeAll);
  const { mTypes } = memberTypeAll;

  const userAdd = useSelector((state) => state.userAdd);
  const { loading, error, success } = userAdd;

  useEffect(() => {
    if (!mTypes || mTypes.length === 0) {
      dispatch(allMTypes(checkChapter));
    }
    if (success) {
      swal('Success!', success, 'success').then((value) => {
        dispatch({ type: USER_ADD_RESET });
        setEmail('');
        setFirstName('');
        setMInit('');
        setLastName('');
        setAddress1('');
        setCity('');
        setState('');
        setZipcode('');
        setPrimaryPhone('');
        setDegree('');
        setDegreeYear(2000);
        setMajor('');
        setCollegeName('');
        setMemberType('');
        handleClose();
      });
    } else if (error) {
      console.log(error);
      swal('Error!', error, 'error').then((value) => {
        dispatch({ type: USER_ADD_RESET });
      });
    }
  }, [dispatch, success, error, mTypes, checkChapter]);

  const addMember = () => {
    // e.preventDefault();

    // const captcha = document.querySelector('#g-recaptcha-response').value;

    // const captcha = await reRef.current.getValue();
    // console.log(captcha);
    // Dispatch Register
    dispatch(
      addUser(
        email,
        firstName,
        mInit,
        lastName,
        address1,
        city,
        state,
        zipcode,
        primaryPhone,
        degree,
        degreeYear,
        major,
        collegeName,
        memberType
      )
    );
    // await reRef.current.reset();

    setValidated(true);
  };

  return (
    <>
      <OverlayTrigger
        overlay={<Tooltip id='tooltip-disabled'>Add Member</Tooltip>}
      >
        <span onClick={handleShow}>
          <i
            className='fas fa-plus action mr-2 text-info'
            style={{
              cursor: 'pointer',
            }}
          ></i>
        </span>
      </OverlayTrigger>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        size='lg'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className='text-center text-info'>
            New Member:
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            {error && <Message variant='danger'>{error}</Message>}
            {loading && <Loader />}
            {success ? (
              <Message variant='success'>{success}</Message>
            ) : (
              <ListGroup variant='flush'>
                <>
                  <Form
                    noValidate
                    validated={validated}
                    // onSubmit={submitHandler}
                  >
                    <Form.Row>
                      {/* <Row> */}
                      <Form.Group as={Col} md='2'>
                        <Form.Label>Name**</Form.Label>
                      </Form.Group>
                      {/* <Col md={10}> */}
                      {/* <Row> */}
                      {/* <Col md={2}> */}
                      <Form.Group as={Col} md='2' controlId='mInit'>
                        <Form.Control
                          required
                          as='select'
                          type='text'
                          value={mInit}
                          onChange={(e) => setMInit(e.target.value)}
                        >
                          <option value='Mr'>Mr</option>
                          <option value='Mrs'>Mrs</option>
                          <option value='Miss'>Ms</option>
                        </Form.Control>
                      </Form.Group>
                      {/* </Col> */}
                      {/* <Col md={5}> */}
                      <Form.Group as={Col} md='4' controlId='firstName'>
                        <Form.Control
                          required
                          type='text'
                          placeholder='First Name'
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          maxLength='20'
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>
                          **Required
                        </Form.Control.Feedback>
                      </Form.Group>
                      {/* </Col> */}
                      {/* <Col md={5}> */}
                      <Form.Group as={Col} md='4' controlId='lastName'>
                        <Form.Control
                          required
                          placeholder='Last Name'
                          type='text'
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          maxLength='20'
                          minLength='3'
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>
                          **Required
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group as={Col} md='2'>
                        <Form.Label>Address</Form.Label>
                      </Form.Group>

                      <Form.Group as={Col} md='10' controlId='address1'>
                        <Form.Control
                          type='text'
                          placeholder='1234 Main St...'
                          value={address1}
                          onChange={(e) => setAddress1(e.target.value)}
                          maxLength='50'
                        ></Form.Control>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md={{ span: 10, offset: 2 }}
                        controlId='state'
                      >
                        <Form.Control
                          type='text'
                          placeholder='Enter State..'
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                          maxLength='20'
                        ></Form.Control>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md={{ span: 10, offset: 2 }}
                        controlId='city'
                      >
                        <Form.Control
                          type='text'
                          placeholder='Enter City..'
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          maxLength='30'
                        ></Form.Control>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md={{ span: 10, offset: 2 }}
                        controlId='zipcode'
                      >
                        <Form.Control
                          type='text'
                          placeholder='Enter Zipcode..'
                          value={zipcode}
                          onChange={(e) => setZipcode(e.target.value)}
                          maxLength='15'
                        ></Form.Control>
                      </Form.Group>

                      <Form.Group as={Col} md='2'>
                        <Form.Label>Phone Number</Form.Label>
                      </Form.Group>
                      <Form.Group as={Col} md='10' controlId='primaryPhone'>
                        <Form.Control
                          type='number'
                          placeholder='Enter Phone Number..'
                          value={primaryPhone}
                          onChange={(e) => setPrimaryPhone(e.target.value)}
                          maxLength='20'
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>
                          **Required
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group as={Col} md='2'>
                        <Form.Label>Education</Form.Label>
                      </Form.Group>
                      <Form.Group as={Col} md='5' controlId='degree'>
                        <Form.Control
                          type='text'
                          placeholder='Enter Last Degree Received..'
                          value={degree}
                          onChange={(e) => setDegree(e.target.value)}
                          maxLength='50'
                        ></Form.Control>
                      </Form.Group>

                      <Form.Group as={Col} md='5' controlId='degreeYear'>
                        <Form.Control
                          type='number'
                          placeholder='Enter The Year of Degree Awarded..'
                          value={degreeYear}
                          onChange={(e) => setDegreeYear(e.target.value)}
                          min='1970'
                          max='2099'
                        ></Form.Control>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md={{ span: 5, offset: 2 }}
                        controlId='major'
                      >
                        <Form.Control
                          type='text'
                          placeholder='Enter Your Major..'
                          value={major}
                          onChange={(e) => setMajor(e.target.value)}
                          maxLength='30'
                        ></Form.Control>
                      </Form.Group>

                      <Form.Group as={Col} md='5' controlId='collegeName'>
                        <Form.Control
                          type='text'
                          placeholder='Enter Your University/College Name..'
                          value={collegeName}
                          onChange={(e) => setCollegeName(e.target.value)}
                          maxLength='50'
                        ></Form.Control>
                      </Form.Group>

                      <Form.Group as={Col} md='2'>
                        <Form.Label>Email Address**</Form.Label>
                      </Form.Group>
                      <Form.Group as={Col} md='10' controlId='email'>
                        <Form.Control
                          required
                          type='email'
                          placeholder='Enter Email..'
                          value={email}
                          onChange={(e) =>
                            setEmail(e.target.value.toLowerCase())
                          }
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>
                          **Required
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group as={Col} md='2'>
                        <Form.Label>Member Type**</Form.Label>
                      </Form.Group>
                      <Form.Group as={Col} md='10' controlId='memberType'>
                        <Form.Control
                          as='select'
                          onChange={(e) => setMemberType(e.target.value)}
                        >
                          {' '}
                          <option>Select a Member Type</option>
                          {mTypes &&
                            mTypes.map((mType, index) => (
                              <option key={index} value={mType.memberType}>
                                {mType.memberType}
                              </option>
                            ))}
                        </Form.Control>

                        <Form.Control.Feedback type='invalid'>
                          **Required
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>

                    <Row className='justify-content-md-center mb-2'>
                      <Col className='mb-2'>
                        {/* <div
                      className='g-recaptcha'
                      data-sitekey='6LeVuS0bAAAAANZ8QwazNTaMGFPl3kXkR7aP0uR4'
                    ></div> */}
                        {/* <ReCAPTCHA
                    sitekey='6LeVuS0bAAAAANZ8QwazNTaMGFPl3kXkR7aP0uR4'
                    ref={reRef}
                    id='widgetId2'
                  /> */}
                        <p>**required</p>
                      </Col>
                    </Row>
                  </Form>
                </>
              </ListGroup>
            )}
          </>
        </Modal.Body>
        <Modal.Footer>
          <Link
            className='btn btn-outline-danger btn-sm rounded'
            onClick={handleClose}
            to='#'
          >
            Cancel
          </Link>

          <Link
            className='btn btn-outline-info btn-sm rounded'
            onClick={() => {
              addMember();
              // handleClose();
            }}
            to='#'
          >
            Create
          </Link>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddMember;
