export const COMMENT_NEW_REQUEST = 'COMMENT_NEW_REQUEST';
export const COMMENT_NEW_SUCCESS = 'COMMENT_NEW_SUCCESS';
export const COMMENT_NEW_FAIL = 'COMMENT_NEW_FAIL';
export const COMMENT_NEW_RESET = 'COMMENT_NEW_RESET';

export const COMMENT_UPDATE_BY_ID_REQUEST = 'COMMENT_UPDATE_BY_ID_REQUEST';
export const COMMENT_UPDATE_BY_ID_SUCCESS = 'COMMENT_UPDATE_BY_ID_SUCCESS';
export const COMMENT_UPDATE_BY_ID_FAIL = 'COMMENT_UPDATE_BY_ID_FAIL';
export const COMMENT_UPDATE_BY_ID_RESET = 'COMMENT_UPDATE_BY_ID_RESET';

export const COMMENT_DELETE_REQUEST = 'COMMENT_DELETE_REQUEST';
export const COMMENT_DELETE_SUCCESS = 'COMMENT_DELETE_SUCCESS';
export const COMMENT_DELETE_FAIL = 'COMMENT_DELETE_FAIL';
