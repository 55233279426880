import React, { useEffect } from 'react';
import Moment from 'react-moment';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { allEvents } from '../../actions/eventActions';
import { allAdv, updateAdvById } from '../../actions/advAction';
import {
  deleteImage,
  getHomeScreenImage,
  getImageByEvent,
} from '../../actions/imageActions';
import {
  ADVERTISEMENT_BY_ID_RESET,
  ADVERTISEMENT_NEW_RESET,
  ADVERTISEMENT_UPDATE_BY_ID_RESET,
} from '../../constants/advertisementConstant';
import Loader from '../Loader';
import Message from '../Message';
import swal from 'sweetalert';
import VideoPlayer from './Display/VideoPlayer';

const HomeVideoBox = ({ history, advType }) => {
  const dispatch = useDispatch();
  const checkChapter = window.location.host;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const advAll = useSelector((state) => state.advAll);
  const { loading, error, advs } = advAll;

  const advUpdate = useSelector((state) => state.advUpdate);
  const { error: advUpdateError, adv } = advUpdate;

  const imageDelete = useSelector((state) => state.imageDelete);
  const { success: successDelete } = imageDelete;

  useEffect(() => {
    if (
      userInfo &&
      (userInfo.userRole === 'systemAdmin' || userInfo.userRole === 'admin')
    ) {
      dispatch(allAdv({ checkChapter, advType }));
      dispatch(getHomeScreenImage(checkChapter));
      dispatch(getImageByEvent(checkChapter));
      dispatch(allEvents(checkChapter));
      dispatch({ type: ADVERTISEMENT_NEW_RESET });
      dispatch({ type: ADVERTISEMENT_BY_ID_RESET });
    } else {
      history.push('/login');
    }

    if (adv) {
      swal('Success!', adv, 'success');
      dispatch({ type: ADVERTISEMENT_UPDATE_BY_ID_RESET });
    }

    if (advUpdateError) {
      swal('Error!', advUpdateError, 'error');
      // .then((value) => {
      dispatch({ type: ADVERTISEMENT_UPDATE_BY_ID_RESET });
      // });
    }
  }, [
    dispatch,
    history,
    advType,
    adv,
    advUpdateError,
    userInfo,
    checkChapter,
    successDelete,
  ]);

  const activeStatusHandler = (id, active) => {
    // e.preventDefault()
    if (window.confirm('Are You Sure?')) {
      dispatch(updateAdvById({ id, checkChapter, active, advType }));
    }
  };

  const deleteImageHandler = (id) => {
    if (window.confirm('Are You Sure?')) {
      dispatch(deleteImage(id));
    }
  };

  console.log(advs);

  return (
    <>
      <Row>
        <Col
          md={{ span: 12, order: 12 }}
          lg={{ span: 12, order: 12 }}
          className='mb-2 p-0'
          id='all-chapter'
        >
          {/* All Images */}
          <Card className='text-center mb-2' border='info'>
            <Card.Header as='h3' className='text-info'>
              All Side Box Contents
            </Card.Header>

            <>
              {loading ? (
                <Loader />
              ) : error ? (
                <Message variant='danger'>{error}</Message>
              ) : advs && advs.length !== 0 ? (
                <Table striped bordered hover responsive className='table-sm'>
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>Sponsor</th>
                      <th>Contact</th>
                      <th>Start Date</th>
                      <th>Expire Date</th>
                      <th>Status</th>

                      {userInfo &&
                        (userInfo.userRole === 'systemAdmin' ||
                          userInfo.userRole === 'admin') && <th>ACTION</th>}
                    </tr>
                  </thead>

                  <tbody>
                    {advs.map((image) => (
                      <tr key={image.imageId}>
                        {/* // <td>{announcement.chapterId}</td> */}
                        {/* <td>
                                {' '}
                                <Image src={user.image} thumbnail />
                              </td> */}
                        <td>
                          {' '}
                          {/* <Image
                            src={image.image}
                            alt={image.image}
                            fluid
                            rounded
                            style={{ height: '165px', width: '200px' }}
                          /> */}
                          {/* <Card.Img src={image.image} variant='top' /> */}
                          <VideoPlayer adv={image.image} play={false} />
                        </td>
                        <td>{image.sponsor.sponsorName}</td>
                        <td>
                          <span>
                            Email:{' '}
                            <a href={`mailto: ${image.sponsor.email}`}>
                              {image.sponsor.email}
                            </a>
                          </span>
                          <hr />
                          <span>
                            Phone:{' '}
                            <a href={`tel: ${image.sponsor.phone}`}>
                              {image.sponsor.phone}
                            </a>
                          </span>
                        </td>
                        <td>
                          <Moment>{image.startDate}</Moment>
                        </td>
                        <td>
                          <Moment>{image.expireDate}</Moment>
                        </td>
                        <td> {image.active ? 'LIVE' : 'EXPIRED'}</td>

                        {userInfo &&
                          (userInfo.userRole === 'systemAdmin' ||
                            userInfo.userRole === 'admin') && (
                            <td>
                              <Button
                                variant='transparent'
                                className='btn btn-outline-warning btn-sm ml-2 rounded'
                                onClick={() =>
                                  activeStatusHandler(
                                    image.imageId,
                                    !image.active
                                  )
                                }
                              >
                                {image.active ? 'Deactivate' : 'Make Live'}
                              </Button>
                              <hr />

                              <span
                                onClick={() =>
                                  deleteImageHandler(image.imageId)
                                }
                              >
                                <i
                                  className='fas fa-trash action ml-2'
                                  style={{
                                    color: 'red',
                                    cursor: 'pointer',
                                  }}
                                ></i>
                              </span>
                            </td>
                          )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <h1>No Banner</h1>
              )}
            </>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default HomeVideoBox;
