import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { CART_RESET } from "../../../constants/cartConstants";
// import portfolio from './Portfolio.json';

const CentralEvent2022 = ({ history }) => {
  const dispatch = useDispatch();

  const EventRegisterHandler = (e) => {
    e.preventDefault();

    dispatch({ type: CART_RESET });
    history.push(`/event/register/42141`);
  };

  return (
    <body id="page-top">
      {/* Masthead*/}
      <header className="masthead">
        <div className="container">
          <div className="masthead-subheading">
            American Association of Bangladeshi Engineers &amp; Architects
          </div>
          <div className="masthead-heading text-uppercase">
            AABEA Biennial Convention 2022
          </div>
          <h1 className="text-white">October 7th, 8th and 9th</h1>
          <h2 className="mb-4 text-white">
            Gaylord National Resort, Washington, DC
          </h2>
          {/* <a
            className='btn btn-primary-yellow btn-xl text-uppercase'
            href='https://aabea.org/event/register/42141'
          >
            Register Now
          </a> */}
          <Button
            className="btn btn-primary-yellow btn-xl text-uppercase"
            variant="transparent"
            onClick={EventRegisterHandler}
          >
            Register Now
          </Button>
          <h3 className="mt-4 text-white">
            For STEM FAIR registration please{" "}
            <a
              href="https://dc.aabea.org/event/30340"
              target="_blank"
              rel="noreferrer"
              className="btn btn-primary-yellow btn-sm text-uppercase"
            >
              Click here
            </a>
          </h3>
          <h4 className="mt-4 text-white">
            For complete program agenda(seminar) please{" "}
            <a
              href="https://aabea.org/uploads/image-1664516006489.pdf"
              target="_blank"
              rel="noreferrer"
            >
              click here
            </a>
          </h4>
        </div>
      </header>
      {/* Services*/}
      <section className="page-section" id="tickets">
        <div className="container">
          <div className="text-center">
            <h2 className="section-heading text-uppercase text-info">
              Tickets
            </h2>
            <h3 className="section-subheading text-info">
              Gaylord National Resort, Washington, DC
              <p className="text-info">Rooms per night $356.00</p>
              <p className="text-info">
                Discounted price for AABEA Convention attendees per night: $250
              </p>
            </h3>
            {/* <h3 className='section-subheading text-dark'>
              Discounted price for AABEA Convention attendees per night: $250
            </h3> */}
          </div>
          <div className="row text-center">
            <div className="col-md-6 mx-auto">
              <span className="fa-stack fa-4x">
                <i className="fas fa-circle fa-stack-2x text-primary-yellow" />
                <i className="fa-solid fa-address-card fa-stack-1x fa-inverse" />
              </span>
              <h4 className="my-3 text-info">Standard Registration Rates</h4>
              <ul className="list-group list-group-flush">
                <li className="list-group-item list-group-item-success">
                  Full 3-Day Event: per person including Grand dinner: $200
                </li>
                <li className="list-group-item list-group-item-danger">
                  Just Grand Dinner with Cultural Show: $150
                </li>
                <li className="list-group-item list-group-item-info">
                  Luxury & Exclusive AABEA Yacht Cruise with Lunch and
                  Entertainment: $100 Per Person
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* Extra packages */}
        <div className="container">
          <div className="text-center">
            {/* <h2 className='section-heading text-uppercase text-info'>
              Other Premium Packages
            </h2> */}
            <div className="row text-center">
              <div className="col-md-12">
                {/* <span className='fa-stack fa-4x'>
                  <i className='fas fa-circle fa-stack-2x text-primary-yellow' />
                  <i className='fa-solid fa-user-check fa-stack-1x fa-inverse' />
                </span> */}
                <h4 className="my-3 text-info">Other Premium Packages</h4>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item list-group-item-bronze">
                    <strong>Bronze Package:</strong> $1200 (Hotel: 1 room; 2
                    nights; 1 full event Admission; Quarter Page Ad on the
                    Magazine)
                  </li>
                  <li className="list-group-item list-group-item-silver">
                    <strong>Silver Package:</strong> $2000 (Hotel: 1 room; 2
                    nights; 2 full event Admission; Quarter Page Ad on the
                    Magazine)
                  </li>
                  <li className="list-group-item list-group-item-gold">
                    <strong>Gold Package:</strong> $5000 (Hotel: 1 room; 2
                    nights; 2 full event Admission; Banner; Bottle with Logo;
                    Half Page Ad on the Magazine; Stage time on Friday Show)
                  </li>
                  <li className="list-group-item list-group-item-platinum">
                    <strong>Platinum Package:</strong> $10000 (Hotel: 2 rooms; 2
                    nights; 8 full event Admission; Banner; Bottle with Logo;
                    One page Ad on the Magazine; Stage time on Saturday Grand
                    Show)
                  </li>
                  <li className="list-group-item list-group-item-diamond">
                    <strong>Diamond Package - Grand Sponsor Package:</strong>{" "}
                    $20000 (Hotel: 2 rooms; 2 nights; 16 full event Admission;
                    Banner; T-shirt with Logo; One page Ad on the Magazine;
                    Stage time on Saturday Grand Show)
                  </li>
                  <li className="list-group-item list-group-item-info">
                    Luxury & Exclusive AABEA Yacht Cruise with Lunch and
                    Entertainment: $100 Per Person
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Extra packages */}
      {/* <section id='packages'>
        
      </section> */}
      {/* Portfolio Grid*/}
      <section className="page-section bg-light" id="portfolio">
        <div className="container">
          <div className="text-center">
            <h2 className="section-heading text-uppercase text-info">
              Attractions for 8th October
            </h2>
            <h3 className="section-subheading text-muted">JOIN THE EVENT</h3>
          </div>
          <div className="row justify-content-center"></div>
          <img
            className="img-fluid"
            src="/uploads/img/portfolio/8.jpg"
            alt="..."
          />
          <img
            className="img-fluid mt-3"
            src="/uploads/img/portfolio/9.jpg"
            alt="..."
          />
        </div>
      </section>
      {/* Emerging Tech*/}
      <section className="page-section bg-light" id="portfolio">
        <div className="container">
          <div className="text-center">
            <h2 className="section-heading text-uppercase text-info">
              Emerging Technology with People, Process and Entrepreneurship
            </h2>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-sm-9 mb-4 mx-3 mt-5 bg-light">
              <h4 className="text-info">
                Key note speakers from IEEE, Engineering Universities, High Tech
                Companies, BD Government, BD Embassy, US Federal, State &amp;
                Local Government
              </h4>
              <h4 className="mt-3 text-info">
                Seminars on the following critical topics:
              </h4>
              <ul>
                <li className="tech-list">Emerging Technology</li>
                <li className="tech-list">Cybersecurity</li>
                <li className="tech-list">Nanotechnology, Biotechnology</li>
                <li className="tech-list">
                  Silicon, Semiconductors, and Fiberoptic
                </li>
                <li className="tech-list">
                  Environmental/Structural Engineering
                </li>
                <li className="tech-list">
                  Mechanical Engineering, Electrical Engineering, Chemical
                  Engineering, Civil Engineering, and others
                </li>
                <li className="tech-list">Information Technology</li>
                <li className="tech-list">
                  IoT, IoNT, IoBT, IoAT, IoNBT, etc.
                </li>
                <li className="tech-list">
                  Technology and products Management
                </li>
                <li className="tech-list">Entrepreneurship</li>
                <li className="tech-list">Water resources and management</li>
                <a
                  href="https://aabea.org/uploads/pdfs/Agenda-in-Full-AABEA-Biennial-Convention-2022-DC.pdf"
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-primary-yellow btn-sm text-uppercase mt-3"
                >
                  Get full seminar schedule
                </a>
              </ul>
              <h4 className="mt-3 text-info">
                Job Fair with Government Agencies and High Tech Companies
              </h4>
              <ul>
                <li className="tech-list">
                  Great opportunity for the candidates to get offers on the spot
                </li>
                <li className="tech-list">
                  Great opportunity for the employer to recruit highly talented
                  Technology professionals and save thousands of dollars on
                  recruiting fees
                </li>
              </ul>
              <h4 className="mt-3 text-info">
                Science Fair for K12 STEM Students
              </h4>
              <h4 className="mt-3 text-info">
                Showcase Technological innovations by our next generation
              </h4>
            </div>
          </div>
        </div>
      </section>

      {/* About*/}
      <section className="page-section" id="attraction">
        <div className="container">
          <div className="text-center">
            <h2 className="section-heading text-uppercase mb-5 text-info">
              ATTRACTION
            </h2>
          </div>
          <ul className="timeline">
            <li className="timeline-inverted">
              <div className="timeline-image">
                <img
                  className="rounded-circle img-fluid"
                  src="/uploads/img/about/1.jpg"
                  alt="..."
                />
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <h4 className="subheading text-info">
                    Networking Session and Cultural show
                  </h4>
                  <h5 className="subheading text-info">7th October, 2022</h5>
                  <h5 className="subheading text-info">7pm - 12am</h5>
                </div>
              </div>
            </li>
            <li>
              <div className="timeline-image">
                <img
                  className="rounded-circle img-fluid"
                  src="/uploads/img/about/2.jpg"
                  alt="..."
                />
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <h4 className="subheading text-info">
                    Seminars on critical topics
                  </h4>
                  <h5 className="subheading text-info">8th October, 2022</h5>
                  <h5 className="subheading text-info">10am - 5pm</h5>
                </div>
              </div>
            </li>
            <li className="timeline-inverted">
              <div className="timeline-image">
                <img
                  className="rounded-circle img-fluid"
                  src="/uploads/img/about/3.jpg"
                  alt="..."
                />
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <h4 className="subheading text-info">
                    Job Fair and Job Placement
                  </h4>
                  <h5 className="subheading text-info">8th October, 2022</h5>
                  <h5 className="subheading text-info">10am - 5pm</h5>
                </div>
              </div>
            </li>
            <li>
              <div className="timeline-image">
                <img
                  className="rounded-circle img-fluid"
                  src="/uploads/img/about/4.jpg"
                  alt="..."
                />
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <h4 className="subheading text-info">Science Fair</h4>
                  <h5 className="subheading text-info">8th October, 2022</h5>
                  <h5 className="subheading text-info">10am - 2pm</h5>
                </div>
              </div>
            </li>
            <li className="timeline-inverted">
              <div className="timeline-image">
                <img
                  className="rounded-circle img-fluid"
                  src="/uploads/img/about/5.jpg"
                  alt="..."
                />
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <h4 className="subheading text-info">
                    Math and trivia competition for K12 kids
                  </h4>
                  <h5 className="subheading text-info">8th October, 2022</h5>
                  <h5 className="subheading text-info">3pm - 5pm</h5>
                </div>
              </div>
            </li>
            <li>
              <div className="timeline-image">
                <img
                  className="rounded-circle img-fluid"
                  src="/uploads/img/about/6.jpg"
                  alt="..."
                />
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <h4 className="subheading text-info">
                    Catered dinner with breathtaking cultural show
                  </h4>
                  <h5 className="subheading text-info">8th October, 2022</h5>
                  <h5 className="subheading text-info">7pm - 1am</h5>
                </div>
              </div>
            </li>
            <li className="timeline-inverted">
              <div className="timeline-image">
                <img
                  className="rounded-circle img-fluid"
                  src="/uploads/img/about/7.jpg"
                  alt="..."
                />
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <h4 className="subheading text-info">
                    Annual General Meeting with breakfast
                  </h4>
                  <h5 className="subheading text-info">9th October, 2022</h5>
                  <h5 className="subheading text-info">9am - 11am</h5>
                </div>
              </div>
            </li>
            <li>
              <div className="timeline-image">
                <img
                  className="rounded-circle img-fluid"
                  src="/uploads/img/about/8.jpg"
                  alt="..."
                />
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <h4 className="subheading text-info">
                    Fun family events including a boat cruise with lunch and
                    music on the Potomac
                  </h4>
                  <h5 className="subheading text-info">9th October, 2022</h5>
                  <h5 className="subheading text-info">12pm - 4pm</h5>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
      {/* Contact*/}
      <section className="page-section" id="contact">
        <div className="container text-center text-light">
          <div className="text-center">
            <h2 className="section-heading text-uppercase mb-5">Contact</h2>
          </div>
          <div className="row row-cols-7 py-3">
            <div className="col mx-auto">
              <ul className="nav flex-column">
                <li className="nav-item mb-3">Faisal Quader: (301) 526 7888</li>
                <li className="nav-item mb-3">Mizan Rahman: (216) 346 5520</li>
                <li className="nav-item mb-3">Shah Ahmed: (202) 297 8442</li>
                <li className="nav-item mb-3">Quazi Zaman: (703) 989 0633</li>
                <li className="nav-item mb-3">Moin Hassan: (301) 346 8837</li>
                <li className="nav-item mb-3">Shaheda Abdin: (917) 326 9876</li>
                <li className="nav-item mb-3">Ali Khan: (502) 714 1225</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* Clients*/}
      <header>
        <div className="container text-center mt-5 mb-5">
          <Button
            className="btn btn-primary-yellow btn-xl text-uppercase"
            variant="transparent"
            onClick={EventRegisterHandler}
          >
            Register Now
          </Button>
          <h3 className="mt-4 text-info">
            For STEM FAIR registration please{" "}
            <a
              href="https://dc.aabea.org/event/30340"
              target="_blank"
              rel="noreferrer"
              className="btn btn-primary-yellow btn-sm text-uppercase"
            >
              Click here
            </a>
          </h3>
        </div>
      </header>
      {/* Footer*/}
      {/* <footer className='footer py-4'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-lg-4 text-lg-start'>
                Copyright © AABEA 2022
              </div>
              <div className='col-lg-4 my-3 my-lg-0' />
              <div className='col-lg-4 text-lg-end'>
                <a className='link-dark text-decoration-none me-3' href='#!'>
                  Privacy Policy
                </a>
                <a className='link-dark text-decoration-none' href='#!'>
                  Terms of Use
                </a>
              </div>
            </div>
          </div>
        </footer> */}

      <div>
        {/* Portfolio Modals*/}
        {/* Portfolio item 1 modal popup*/}
        <div
          className="portfolio-modal modal fade"
          id="portfolioModal1"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="close-modal" data-bs-dismiss="modal">
                <img src="/uploads/img/close-icon.svg" alt="Close modal" />
              </div>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-8">
                    <div className="modal-body">
                      {/* Project details*/}
                      <h2 className="text-uppercase text-dark">
                        Manisha Kammakar
                      </h2>
                      <p className="item-intro text-muted">
                        Rock Queen of Indian Idol and SA RE GA MA PA
                      </p>
                      <img
                        className="img-fluid d-block mx-auto"
                        src="/uploads/img/portfolio/1.jpg"
                        alt="..."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Portfolio item 2 modal popup*/}
        <div
          className="portfolio-modal modal fade"
          id="portfolioModal2"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="close-modal" data-bs-dismiss="modal">
                <img src="/uploads/img/close-icon.svg" alt="Close modal" />
              </div>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-8">
                    <div className="modal-body">
                      {/* Project details*/}
                      <h2 className="text-uppercase text-dark">Tahsan Khan</h2>
                      <p className="item-intro text-muted">
                        Super Star Performer, Actor, Model &amp; a versatile
                        personality
                      </p>
                      <img
                        className="img-fluid d-block mx-auto"
                        src="/uploads/img/portfolio/2.jpg"
                        alt="..."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Portfolio item 3 modal popup*/}
        <div
          className="portfolio-modal modal fade"
          id="portfolioModal3"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="close-modal" data-bs-dismiss="modal">
                <img src="/uploads/img/close-icon.svg" alt="Close modal" />
              </div>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-8">
                    <div className="modal-body">
                      {/* Project details*/}
                      <h2 className="text-uppercase text-dark">
                        Subhasree Devnath
                      </h2>
                      <p className="item-intro text-muted">
                        1st Runner-up of SA RE GA MA PA and the most versatile
                        playback singer
                      </p>
                      <img
                        className="img-fluid d-block mx-auto"
                        src="/uploads/img/portfolio/3.jpg"
                        alt="..."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Portfolio item 4 modal popup*/}
        <div
          className="portfolio-modal modal fade"
          id="portfolioModal4"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="close-modal" data-bs-dismiss="modal">
                <img src="/uploads/img/close-icon.svg" alt="Close modal" />
              </div>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-8">
                    <div className="modal-body">
                      {/* Project details*/}
                      <h2 className="text-uppercase text-dark">
                        Anila Chowdhury
                      </h2>
                      <p className="item-intro text-muted">
                        Renowned vocalist in modern Bengali songs including
                        bands
                      </p>
                      <img
                        className="img-fluid d-block mx-auto"
                        src="/uploads/img/portfolio/4.jpg"
                        alt="..."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Portfolio item 5 modal popup*/}
        <div
          className="portfolio-modal modal fade"
          id="portfolioModal5"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="close-modal" data-bs-dismiss="modal">
                <img src="/uploads/img/close-icon.svg" alt="Close modal" />
              </div>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-8">
                    <div className="modal-body">
                      {/* Project details*/}
                      <h2 className="text-uppercase text-dark">Elita Karim</h2>
                      <p className="item-intro text-muted">
                        Acclaimed singer, journalist,performer, anchor and voice
                        artist
                      </p>
                      <img
                        className="img-fluid d-block mx-auto"
                        src="/uploads/img/portfolio/5.jpg"
                        alt="..."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Portfolio item 6 modal popup*/}
        <div
          className="portfolio-modal modal fade"
          id="portfolioModal6"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="close-modal" data-bs-dismiss="modal">
                <img src="/uploads/img/close-icon.svg" alt="Close modal" />
              </div>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-8">
                    <div className="modal-body">
                      {/* Project details*/}
                      <h2 className="text-uppercase text-dark">
                        Krishna Tithi
                      </h2>
                      <p className="item-intro text-muted">
                        Top contender, adviser of Channel I Shera Kontho
                      </p>
                      <img
                        className="img-fluid d-block mx-auto"
                        src="/uploads/img/portfolio/6.jpg"
                        alt="..."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Portfolio item 6 modal popup*/}
        <div
          className="portfolio-modal modal fade"
          id="portfolioModal7"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="close-modal" data-bs-dismiss="modal">
                <img src="/uploads/img/close-icon.svg" alt="Close modal" />
              </div>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-8">
                    <div className="modal-body">
                      {/* Project details*/}
                      <h2 className="text-uppercase text-dark">
                        Progga Laboni
                      </h2>
                      <p className="item-intro text-muted">
                        One of the best talents in poetry recitation from our
                        Subcontinent
                      </p>
                      <img
                        className="img-fluid d-block mx-auto"
                        src="/uploads/img/portfolio/7.jpg"
                        alt="..."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
};

export default CentralEvent2022;
