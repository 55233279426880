export const DOCUMENT_NEW_REQUEST = 'DOCUMENT_NEW_REQUEST';
export const DOCUMENT_NEW_SUCCESS = 'DOCUMENT_NEW_SUCCESS';
export const DOCUMENT_NEW_FAIL = 'DOCUMENT_NEW_FAIL';
export const DOCUMENT_NEW_RESET = 'DOCUMENT_NEW_RESET';

export const DOCUMENT_ALL_REQUEST = 'DOCUMENT_ALL_REQUEST';
export const DOCUMENT_ALL_SUCCESS = 'DOCUMENT_ALL_SUCCESS';
export const DOCUMENT_ALL_FAIL = 'DOCUMENT_ALL_FAIL';

export const DOCUMENT_BY_ID_REQUEST = 'DOCUMENT_BY_ID_REQUEST';
export const DOCUMENT_BY_ID_SUCCESS = 'DOCUMENT_BY_ID_SUCCESS';
export const DOCUMENT_BY_ID_FAIL = 'DOCUMENT_BY_ID_FAIL';
export const DOCUMENT_BY_ID_RESET = 'DOCUMENT_BY_ID_RESET';

export const DOCUMENT_DELETE_REQUEST = 'DOCUMENT_DELETE_REQUEST';
export const DOCUMENT_DELETE_SUCCESS = 'DOCUMENT_DELETE_SUCCESS';
export const DOCUMENT_DELETE_FAIL = 'DOCUMENT_DELETE_FAIL';
