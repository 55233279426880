import axios from "axios";

import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Card, Form, Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import parse from "html-react-parser";

import Sidebar from "../../components/Sidebar/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import ColumnFilter from "../../components/Table/ColumnFilter";
import RTable from "../../components/Table/RTable";
import {
  allactivities,
  deleteActivity,
  getActivityById,
  newActivity,
  updateActivityById,
} from "../../actions/activityAction";
import {
  ACTIVITY_BY_ID_RESET,
  ACTIVITY_NEW_RESET,
  ACTIVITY_UPDATE_BY_ID_RESET,
} from "../../constants/activityConstant";
import { allcategories } from "../../actions/categoryAction";

const ActivityScreen = ({ history }) => {
  const dispatch = useDispatch();

  const [addActivity, setAddActivity] = useState(false);
  const [editActivity, setEditActivity] = useState(false);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [category, setCategory] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const [date, setDate] = useState("");
  const [id, setId] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState([]);

  const activitiesRef = useRef();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const categoryAll = useSelector((state) => state.categoryAll);
  const { categories } = categoryAll;

  const activityAll = useSelector((state) => state.activityAll);
  const { loading, error, activities } = activityAll;

  activitiesRef.current = activities;

  const activityNew = useSelector((state) => state.activityNew);
  const {
    loading: activityNewLoading,
    error: activityNewError,
    success,
  } = activityNew;

  const activityById = useSelector((state) => state.activityById);
  const { success: activityByIdSuccess, activity } = activityById;

  const activityUpdate = useSelector((state) => state.activityUpdate);
  const { success: activityUpdateSuccess } = activityUpdate;

  const activityDelete = useSelector((state) => state.activityDelete);
  const { success: successDelete } = activityDelete;

  const checkChapter = window.location.host;

  useEffect(() => {
    if (
      userInfo &&
      (userInfo.userRole === "systemAdmin" || userInfo.userRole === "admin")
    ) {
      setId(userInfo.memberId);
      dispatch(allcategories());
      dispatch(allactivities(checkChapter));
      dispatch({ type: ACTIVITY_NEW_RESET });
    } else {
      history.push("/login");
    }
    if (success || activityUpdateSuccess) {
      setAddActivity(false);
      setEditActivity(false);

      setTitle("");
      setBody("");
      setDate("");
      setCategory("");
      setUploadedFiles([]);
      dispatch({ type: ACTIVITY_BY_ID_RESET });
    }
    if (activityByIdSuccess) {
      setAddActivity(true);
      setEditActivity(true);
      setDate(activity.date);
      setCategory(activity.category);
      setTitle(activity.title);
      setBody(activity.body);
      setId(activity.activityId);
      setUploadedFiles(activity.activityPhoto);

      // setId(announcement.announcementId);
    }
  }, [
    dispatch,
    history,
    userInfo,
    checkChapter,
    success,
    activityByIdSuccess,
    activity,
    activityUpdateSuccess,
    successDelete,
  ]);

  const editActivityHandler = (rowIndex) => {
    const id = activitiesRef.current[rowIndex].activityId;
    dispatch({ type: ACTIVITY_UPDATE_BY_ID_RESET });
    // console.log(rowIndex);
    // console.log(id);
    dispatch(getActivityById(id));
  };

  const deleteActivityHandler = (rowIndex) => {
    const id = activitiesRef.current[rowIndex].activityId;

    if (window.confirm("Are You Sure?")) {
      dispatch(deleteActivity(id));
    }
  };

  const addNewActivity = (e) => {
    e.preventDefault();

    setAddActivity(!addActivity);
    setTitle("");
    setBody("");
    setDate("");
    setUploadedFiles([]);
    setMessage([]);
    setEditActivity(false);
    dispatch({ type: ACTIVITY_BY_ID_RESET });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (editActivity) {
      if (category === "newCategory") {
        dispatch(
          updateActivityById(id, title, body, newCategory, uploadedFiles, date)
        );
      } else {
        dispatch(
          updateActivityById(id, title, body, category, uploadedFiles, date)
        );
      }
    } else {
      if (category === "newCategory") {
        dispatch(newActivity(title, body, newCategory, uploadedFiles, date));
      } else {
        dispatch(newActivity(title, body, category, uploadedFiles, date));
      }
    }
  };

  const ActivityPhotos = ({ values }) => {
    // Loop through the array and create a badge-like component instead of a comma-separated string
    return (
      <Row>
        {values.map((photo, idx) => {
          return (
            <Col>
              <a
                key={idx}
                href={photo}
                className="badge"
                target="_blank"
                rel="noreferrer"
              >
                {/* {photo.split('/image-')[1]} */}
                {/* {photo} */}
                {/* <Card.Img
                variant='top'
                src={photo}
                alt='activity picture'
                style={{ height: '171px', width: '180px' }}
              /> */}
                <Image
                  src={photo}
                  // style={{ height: '171px', width: '180px' }}
                  fluid
                />
              </a>
            </Col>
          );
        })}
      </Row>
    );
  };

  const columnsAdmin = [
    {
      Header: "No.",
      accessor: (row, i) => i + 1,
    },
    {
      Header: "Picture/s",
      accessor: "activityPhoto",
      Filter: ColumnFilter,
      Cell: ({ cell: { value } }) => <ActivityPhotos values={value} />,
    },
    {
      Header: "Title",
      accessor: "title",
      Filter: ColumnFilter,
    },
    // {
    //   Header: 'Name',
    //   accessor: 'userName',
    // },
    {
      Header: "Activity",
      accessor: "body",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        return parse(value);
      },
    },
    {
      Header: "Publish Date",
      accessor: "createdAt",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        return format(new Date(value), "MM/dd/yyyy");
      },
    },

    {
      Header: "Actions",
      accessor: "actions",
      Cell: (props) => {
        const rowIdx = props.row.id;
        return (
          <div>
            <span onClick={() => editActivityHandler(rowIdx)}>
              <i
                className="far fa-edit action mr-2"
                style={{ color: "#4285F4", cursor: "pointer" }}
              ></i>
            </span>

            <span onClick={() => deleteActivityHandler(rowIdx)}>
              <i
                className="fas fa-trash action"
                style={{ color: "red", cursor: "pointer" }}
              ></i>
            </span>
          </div>
        );
      },
    },
  ];

  const uploadFileHandler = async (e) => {
    const file = e.target.files;

    for (let i = 0; i < file.length; i++) {
      const formData = new FormData();
      formData.append(`image`, file[i]);
      setUploading(true);

      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        const { data } = await axios.post("/api/upload", formData, config);
        uploadedFiles.push(data);
        setUploading(false);

        setMessage((prevMessage) => [
          ...prevMessage,
          "Uploaded the file successfully: " + file[i].name,
        ]);
      } catch (error) {
        console.error(error);
        setUploading(false);
        setMessage((prevMessage) => [
          ...prevMessage,
          "Could not upload the file: " + file[i].name,
        ]);
      }
    }
  };

  return (
    <>
      <Row className="content">
        {/* Sidebar */}
        <Col
          md={{ span: 3, order: 1 }}
          lg={{ span: 3, order: 1 }}
          id="sidebar-wrapper"
          className="m-0 p-1"
        >
          <Sidebar />
        </Col>
        {/* Sidebar End */}
        <Col
          md={{ span: 9, order: 12 }}
          lg={{ span: 9, order: 12 }}
          id="page-content-wrapper"
          className="m-0 p-1"
        >
          <>
            {/* <CardColumns> */}

            <Row>
              <Col
                md={{ span: 12, order: 1 }}
                lg={{ span: 12, order: 1 }}
                // style={{ padding: 0 }}
                className="mb-2 p-0"
              >
                <Card border="info">
                  <Card.Header className="text-center" as="h2">
                    <Link
                      className="btn btn-outline-info btn-sm btn-block rounded"
                      // onClick={() => setAddActivity(!addActivity)}
                      onClick={addNewActivity}
                    >
                      New Activity
                    </Link>
                  </Card.Header>

                  <Card.Body>
                    {addActivity
                      ? (activityNewError && (
                          <Message variant="danger">{activityNewError}</Message>
                        )) ||
                        (activityNewLoading && <Loader />) ||
                        (success ? (
                          <Message variant="success">{success}</Message>
                        ) : (
                          <Form onSubmit={submitHandler}>
                            <Form.Group controlId="date">
                              <Form.Label>Date of Activity:</Form.Label>
                              <Form.Control
                                type="date"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                              ></Form.Control>
                            </Form.Group>

                            <Form.Group controlId="category">
                              <Form.Label>Category</Form.Label>
                              <Form.Control
                                className="mb-2"
                                required
                                as="select"
                                type="text"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                              >
                                <option value="">
                                  Please select a category
                                </option>

                                <option value="newCategory">
                                  Add new category
                                </option>
                                <option disabled>
                                  ── Existing Categories: ──
                                </option>

                                {categories &&
                                  categories.length !== 0 &&
                                  categories.map((category, index) => (
                                    <option
                                      key={index}
                                      value={category.categoryId}
                                    >
                                      {category.name}
                                    </option>
                                  ))}
                              </Form.Control>

                              {category && category === "newCategory" ? (
                                <Form.Control
                                  required
                                  type="text"
                                  placeholder="Please Enter New Category"
                                  value={newCategory}
                                  onChange={(e) =>
                                    setNewCategory(e.target.value)
                                  }
                                ></Form.Control>
                              ) : null}
                            </Form.Group>

                            <Form.Group controlId="title">
                              <Form.Label>Title</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Please Enter A Title.."
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                              ></Form.Control>
                            </Form.Group>

                            <Form.Group controlId="body">
                              <Form.Label>Activity Details</Form.Label>
                              <CKEditor
                                editor={ClassicEditor}
                                data={body}
                                onChange={(e, editor) => {
                                  const data = editor.getData();
                                  setBody(data);
                                }}
                              />
                            </Form.Group>

                            <Form.Group controlId="activityPhotos">
                              <Form.Label>Picture/s</Form.Label>
                              {/* <Form.Control
                                required
                                type='text'
                                placeholder='Enter image url..'
                                value={uploadedFiles}
                                onChange={(e) =>
                                  setUploadedFiles(e.target.value)
                                }
                              ></Form.Control> */}
                              <Form.File
                                custom
                                id="image-file"
                                label="Choose File"
                                multiple
                                onChange={uploadFileHandler}
                              ></Form.File>
                              {uploading && <Loader />}
                            </Form.Group>

                            {message.length > 0 && (
                              <div
                                className="alert alert-secondary"
                                role="alert"
                              >
                                <ul>
                                  {message.map((item, i) => {
                                    return <li key={i}>{item}</li>;
                                  })}
                                </ul>
                              </div>
                            )}

                            {editActivity ? (
                              <Button type="submit" variant="info" block>
                                <i className="fas fa-plus" /> Update
                              </Button>
                            ) : (
                              <Button type="submit" variant="info" block>
                                <i className="fas fa-plus" /> Add
                              </Button>
                            )}
                          </Form>
                        ))
                      : null}
                    {/* {message && <Message variant='danger'>{message}</Message>} */}
                    {/* {registerError && (
                        <Message variant='danger'>{registerError}</Message>
                      )}
                      {registerLoading && <Loader />}
                      {} */}
                  </Card.Body>
                </Card>
              </Col>

              <Col
                md={{ span: 12, order: 12 }}
                lg={{ span: 12, order: 12 }}
                className="mb-2 p-0"
                id="all-chapter"
              >
                <Card border="info">
                  <Card.Header as="h5" className="text-center text-info">
                    Activities
                  </Card.Header>

                  <>
                    {loading ? (
                      <Loader />
                    ) : error ? (
                      <Message variant="danger">{error}</Message>
                    ) : (
                      <>
                        <RTable users={activities} COLUMNS={columnsAdmin} />
                      </>
                    )}
                  </>
                </Card>
              </Col>
            </Row>
          </>
        </Col>
      </Row>
    </>
  );
};

export default ActivityScreen;
