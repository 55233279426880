import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import ReCAPTCHA from 'react-google-recaptcha';
import { Form, Button } from 'react-bootstrap';
import { newSubscription } from '../../actions/newsletterActions';
import { NEWSLETTER_SUB_RESET } from '../../constants/newsletterConstants';

const NewsletterSubscription = ({ chapterName }) => {
  const checkChapter = window.location.host;

  const reRef = useRef();
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');

  const newsletterSub = useSelector((state) => state.newsletterSub);
  const { error, success } = newsletterSub;

  useEffect(() => {
    if (success) {
      swal('Success!', success, 'success').then(() => {
        setEmail('');
        dispatch({ type: NEWSLETTER_SUB_RESET });
      });
    }
    if (error) {
      swal('Errpr!', error, 'error').then(() => {
        setEmail('');
        dispatch({ type: NEWSLETTER_SUB_RESET });
      });
    }
  }, [dispatch, success, error]);

  const submitHandler = async (e) => {
    e.preventDefault();

    const captcha = await reRef.current.executeAsync();
    dispatch(newSubscription({ captcha, email, checkChapter }));
    await reRef.current.reset();
  };

  return (
    <>
      <section
        id='subscription'
        className='bg-main  text-center d-flex align-content-between'
      >
        <div className='p-4 m-auto'>
          {chapterName === 'AABEA CENTRAL' ? (
            <h1 className='text-info'>
              Subscribe to {chapterName}'s job alert service
            </h1>
          ) : (
            <h1 className='text-info'>
              Subscribe to AABEA {chapterName}'s job alert service
            </h1>
          )}
        </div>
        <div className='p-4 m-auto'>
          <Form onSubmit={submitHandler} inline>
            <Form.Control
              required
              type='email'
              placeholder='Enter your email'
              className='mr-sm-2'
              value={email}
              onChange={(e) => setEmail(e.target.value.toLowerCase())}
            />
            <ReCAPTCHA
              sitekey='6LennzEfAAAAACe1LSYvKB9Tf51-I-d_OAP49JyP'
              ref={reRef}
              id='widgetId2'
              size='invisible'
            />
            <Button type='submit' className='m-3' variant='outline-info'>
              SUBSCRIBE
            </Button>
          </Form>
        </div>
      </section>
    </>
  );
};

export default NewsletterSubscription;
