import React, { useEffect } from 'react';
import parse from 'html-react-parser';

import { Card, Col, Row } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { allblogs } from '../../actions/blogAction';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import { BLOG_BY_ID_RESET } from '../../constants/blogConstants';
import Paginate from '../../components/Pagination/Paginate';

const Blogs = ({ match }) => {
  const keyword = match.params.keyword;
  const pageNumber = match.params.pageNumber || 0;
  const dispatch = useDispatch();

  const blogAll = useSelector((state) => state.blogAll);
  const { loading, error, blogs, totalPages, currentPage } = blogAll;

  // const checkChapter = window.location.host.split('.')[0];

  useEffect(() => {
    dispatch(allblogs(keyword, pageNumber));
    dispatch({ type: BLOG_BY_ID_RESET });
  }, [dispatch, keyword, pageNumber]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : blogs && blogs.length !== 0 ? (
        <>
          {blogs.map((blog, index) => (
            <>
              <Row key={index}>
                <Col md={6}>
                  {/* Preview Image */}
                  <Card.Img variant='top' src={blog.photoId} alt='pic' />
                </Col>

                <Col md={6}>
                  {/* Title */}
                  <h1>{blog.title}</h1>

                  {/* Author */}
                  <p className='lead'>
                    by <span>{blog.userName}</span>
                  </p>

                  <hr />

                  {/* Date/Time */}
                  <p>
                    <span className='glyphicon glyphicon-time'></span> Posted on{' '}
                    {blog.createdAt.substring(0, 10)}
                  </p>

                  <hr />

                  {/* Post Content */}
                  <p className='lead'>
                    {parse(blog.body.substring(0, 100))}...
                    <Link to={`/blogs/${blog.blogId}`}>read more</Link>
                  </p>
                </Col>
              </Row>
              <hr />
            </>
          ))}
          <Paginate
            totalPages={totalPages}
            currentPage={currentPage}
            keyword={keyword ? keyword : ''}
          />
        </>
      ) : (
        <p>No Blog Available!</p>
      )}
    </>
  );
};

export default Blogs;
