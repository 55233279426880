import React, { useEffect, useState } from "react";
import { Col, Row, Card, Pagination } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import parse from "html-react-parser";
import { allactivities } from "../../actions/activityAction";
import ActivityImageCarousel from "../../components/ImageCarousel/ActivityImageCarousel";
import EmbedVideo from "../../components/Video/EmbedVideo";
import Paginate3 from "./Paginate3";

const HomeRecentActivities = ({ subDomain }) => {
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(0);

  const activityAll = useSelector((state) => state.activityAll);
  const {
    loading: activityLoading,
    error: activityError,
    activities,
    totalPages,
    currentPage,
  } = activityAll;

  const checkChapter = window.location.host;
  // const subDomain = window.location.host.split('.')[0];

  useEffect(() => {
    dispatch(allactivities(checkChapter, "", pageNumber)); // done
  }, [dispatch, checkChapter, pageNumber]);

  const changePageNumber = (changePageNumber) => {
    setPageNumber(changePageNumber);
  };

  return (
    <Card className="text-justify mb-2">
      <Card.Header className="text-info text-center" as="h4">
        {subDomain ? "Event Details" : "Recent Activities"}
      </Card.Header>
      <Card.Body>
        {activityLoading ? (
          <Loader />
        ) : activityError ? (
          <Message variant="danger">{activityError}</Message>
        ) : (
          <>
            {activities.map((activity, index) => (
              <div key={index}>
                <Row>
                  <Col>
                    {activity.activityPhoto &&
                      activity.activityPhoto.length !== 0 && (
                        <ActivityImageCarousel
                          images={activity.activityPhoto}
                        />
                      )}

                    {activity.body && activity.body.indexOf("oembed") !== -1 ? (
                      <EmbedVideo />
                    ) : null}
                  </Col>
                </Row>
                {/* Date/Time */}
                <hr />
                <p>Date: {activity.date.substring(0, 10)}</p>

                <hr />
                <>
                  {parse(activity.body.substring(0, 300))}...
                  <Link to={`/activity/${activity.activityId}`}>read more</Link>
                </>
                <hr />
                <hr className="border-info" />
              </div>
            ))}

            <div className="align-middle">
              {totalPages > 1 && (
                <Pagination size="sm" className="justify-content-center my-1">
                  <Paginate3
                    totalPages={totalPages}
                    currentPage={currentPage}
                    changePageNumber={changePageNumber}
                  />
                </Pagination>
              )}
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default HomeRecentActivities;
