import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../../components/Sidebar/Sidebar';

import * as S from './ImagesScreen.Styles';
import AllJobPosts from '../../components/Career/AllJobPosts';

const CareerScreen = ({ history, match }) => {
  const dispatch = useDispatch();
  const checkChapter = window.location.host;

  const chapterBySubDomain = useSelector((state) => state.chapterBySubDomain);
  const { chapterByDomain } = chapterBySubDomain;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (chapterByDomain && chapterByDomain.jobPortal) {
      if (
        !userInfo ||
        (userInfo &&
          !(
            userInfo.userRole === 'systemAdmin' || userInfo.userRole === 'admin'
          ))
      ) {
        history.push('/login');
      }
    } else {
      history.push('/');
    }
  }, [dispatch, history, chapterByDomain, userInfo, checkChapter]);

  const pagetitle = match.params.pagetitle;
  console.log(pagetitle);
  // console.log(match.params.advgroup);
  // const query = history.location.search
  //   ? history.location.search.split('=')
  //   : null;

  return (
    <>
      <Row className='content'>
        {/* Sidebar */}
        <Col
          md={{ span: 3, order: 1 }}
          lg={{ span: 3, order: 1 }}
          id='sidebar-wrapper'
          className='m-0 p-1'
        >
          <Sidebar jobPortalMatch={match} jobPortalKey='1' />
        </Col>
        {/* Sidebar End */}
        <Col
          md={{ span: 9, order: 12 }}
          lg={{ span: 9, order: 12 }}
          id='page-content-wrapper'
          className='m-0 p-1'
        >
          <S.CardDeck>
            {/* <CardColumns> */}
            {chapterByDomain && chapterByDomain.jobPortal ? (
              <>
                {match.params.pagetitle === 'approved' ? (
                  <AllJobPosts
                    history={history}
                    pageType={match.params.pagetitle}
                  />
                ) : match.params.pagetitle === 'rejected' ? (
                  <AllJobPosts
                    history={history}
                    pageType={match.params.pagetitle}
                  />
                ) : (
                  // <AllPendingPosts
                  //   history={history}
                  //   pageType={match.params.pagetitle}
                  // />
                  <AllJobPosts history={history} pageType='pending' />
                )}
              </>
            ) : null}
          </S.CardDeck>
        </Col>
      </Row>
    </>
  );
};

export default CareerScreen;
