import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Form,
  ListGroup,
  Modal,
  OverlayTrigger,
  Tooltip,
  Card,
  Button,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { listUsers, payUser } from '../../actions/userActions';
import { USER_PAY_RESET } from '../../constants/userConstants';
import Loader from '../Loader';
import Message from '../Message';

const AddPayment = ({ types }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const userList = useSelector((state) => state.userList);
  const { users } = userList;

  const [paymentTypeName, setPaymentTypeName] = useState('');
  const [paymentTypeAmount, setPaymentTypeAmount] = useState('');
  // const [paymentTypePrimary, setPaymentTypePrimary] = useState(false);
  const [memberType, setMemberType] = useState('');
  const [fee, setFee] = useState(0);
  const [duration, setDuration] = useState(1);
  const [qty, setQty] = useState(1);

  const [memberId, setMemberId] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [status, setStatus] = useState('');

  const [paymentResult, setPaymentResult] = useState({
    amount: 0,
    payerId: '',
    paymentId: '',
    paymentStatus: '',
    paymentTime: new Date(),
  });

  const [validated, setValidated] = useState(false);

  const userPay = useSelector((state) => state.userPay);
  const { loading, success: successPay, error: errorPay } = userPay;

  const checkChapter = window.location.host;

  useEffect(() => {
    dispatch(listUsers(checkChapter));
    if (successPay) {
      swal('Success!', successPay, 'success').then((value) => {
        dispatch({ type: USER_PAY_RESET });
        handleClose();
      });
    } else if (errorPay) {
      swal('Error!', errorPay, 'error').then((value) => {
        dispatch({ type: USER_PAY_RESET });
      });
    }
  }, [dispatch, checkChapter, successPay, errorPay]);

  const addPayment = () => {
    // e.preventDefault();

    console.log(memberId, memberType, paymentTypeName, qty, paymentResult);
    dispatch(
      payUser(memberId, memberType, paymentTypeName, qty, paymentResult)
    );
    setValidated(true);
  };

  const memberIdHandler = (e) => {
    e.preventDefault();

    setMemberId(e.target.value.split(',')[0]);
    setContactEmail(e.target.value.split(',')[1]);
    setStatus(e.target.value.split(',')[2]);
    setMemberType(e.target.value.split(',')[3]);
    setFee(e.target.value.split(',')[4]);
    setDuration(e.target.value.split(',')[5]);

    setPaymentResult({
      ...paymentResult,
      payerId: e.target.value.split(',')[1],
      paymentId: e.target.value.split(',')[1],
    });
  };

  const paymentTypeChangeHandler = (e) => {
    e.preventDefault();

    if (status === 'inactive') {
      setMemberType(e.target.value.split(',')[0]);
      setPaymentTypeAmount(e.target.value.split(',')[1]);
    } else {
      setPaymentTypeName(e.target.value.split(',')[0]);
      setPaymentTypeAmount(e.target.value.split(',')[1]);
    }
    setPaymentResult({
      ...paymentResult,
      amount: e.target.value.split(',')[1] * qty,
    });
  };

  const qtyChangeHandler = (e) => {
    e.preventDefault();

    setQty(e.target.value);

    setPaymentResult({
      ...paymentResult,
      amount: e.target.value * paymentTypeAmount,
    });
  };

  return (
    <>
      <OverlayTrigger
        overlay={<Tooltip id='tooltip-disabled'>Add Payment</Tooltip>}
      >
        <span onClick={handleShow}>
          <i
            className='fas fa-plus action mr-2 text-info'
            style={{
              cursor: 'pointer',
            }}
          ></i>
        </span>
      </OverlayTrigger>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        size='lg'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className='text-center text-info'>
            Add Payment:
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            {errorPay && <Message variant='danger'>{errorPay}</Message>}
            {loading && <Loader />}
            {successPay ? (
              <Message variant='success'>{successPay}</Message>
            ) : (
              <ListGroup variant='flush'>
                <>
                  <Form
                    noValidate
                    validated={validated}
                    // onSubmit={submitHandler}
                  >
                    <Form.Row>
                      <Form.Group as={Col} md='2'>
                        <Form.Label>Member Id**</Form.Label>
                      </Form.Group>
                      <Form.Group as={Col} md='10' controlId='memberId'>
                        <Form.Control as='select' onChange={memberIdHandler}>
                          <option>Select member Id</option>
                          {users &&
                            users.length !== 0 &&
                            users.map((user, i) => (
                              <option
                                key={user.memberId + i}
                                value={[
                                  user.memberId,
                                  user.primaryEmail,
                                  user.status,
                                  user.memberType.memberType,
                                  user.memberType.fee,
                                  user.memberType.duration,
                                ]}
                              >
                                {user.memberId}
                              </option>
                            ))}
                        </Form.Control>
                      </Form.Group>

                      <Form.Group as={Col} md='2'>
                        <Form.Label>Payment Type**</Form.Label>
                      </Form.Group>
                      <Form.Group as={Col} md='10' controlId='paymentType'>
                        <Form.Control
                          as='select'
                          onChange={paymentTypeChangeHandler}
                        >
                          <option>Select Payment Type</option>
                          {status === 'inactive' ? (
                            <>
                              <option value={[memberType, fee, duration]}>
                                {memberType} fee USD{fee} for {duration} year/s
                              </option>
                            </>
                          ) : (
                            <>
                              {types.map((paymentType, index) => (
                                <option
                                  key={index}
                                  value={[
                                    paymentType.paymentTypeName,
                                    paymentType.paymentTypeAmount,
                                    // paymentType.paymentTypePrimary,
                                  ]}
                                >
                                  {paymentType.paymentTypeName}
                                </option>
                              ))}
                            </>
                          )}
                        </Form.Control>
                      </Form.Group>

                      <>
                        <Form.Group as={Col} md='2'>
                          <label>Number of Duration**</label>
                        </Form.Group>
                        <Form.Group as={Col} md='10' controlId='yearsId'>
                          <Form.Control
                            as='select'
                            // type='number'
                            // min='1'
                            placeholder='Please enter number of duration terms of payment'
                            value={qty}
                            onChange={qtyChangeHandler}
                            // onChange={(e) => setQty(e.target.value)}
                          >
                            <option>
                              Select Number of Duration for Payment
                            </option>

                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                          </Form.Control>
                        </Form.Group>
                      </>

                      <Form.Group as={Col} md='2'>
                        <Form.Label>Reference**</Form.Label>
                      </Form.Group>

                      <Form.Group as={Col} md='10' controlId='reference'>
                        <Form.Control
                          type='text'
                          placeholder='Please Enter Reference..'
                          value={paymentResult.paymentStatus}
                          onChange={(e) => {
                            setPaymentResult({
                              ...paymentResult,
                              paymentStatus: e.target.value,
                            });
                          }}
                          required
                        ></Form.Control>
                      </Form.Group>
                    </Form.Row>

                    <Row className='justify-content-md-center mb-2'>
                      <Col className='mb-2'>
                        <p>**required</p>
                      </Col>
                    </Row>
                  </Form>
                </>
              </ListGroup>
            )}
            <Card>
              <ListGroup variant='flush'>
                <ListGroup.Item>
                  <h5 className='text-info'>Payment Summary</h5>
                </ListGroup.Item>

                <ListGroup.Item>
                  <Row>
                    <Col>Payment Type:</Col>
                    <Col>{paymentTypeName}</Col>
                  </Row>
                </ListGroup.Item>

                <ListGroup.Item>
                  <Row>
                    <Col>Member Id</Col>
                    <Col>{memberId}</Col>
                  </Row>
                </ListGroup.Item>

                <ListGroup.Item>
                  <Row>
                    <Col>Email</Col>
                    <Col>{contactEmail}</Col>
                  </Row>
                </ListGroup.Item>

                <ListGroup.Item>
                  <Row>
                    <Col>Payment Year</Col>
                    <Col>{qty}</Col>
                  </Row>
                </ListGroup.Item>

                <ListGroup.Item>
                  <Row>
                    <Col>Total Amount</Col>
                    <Col>${paymentTypeAmount * qty}</Col>
                  </Row>
                </ListGroup.Item>

                <ListGroup.Item>
                  <Row>
                    <Col>Payer ID</Col>
                    <Col>{paymentResult.payerId}</Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>Payment ID</Col>
                    <Col>{paymentResult.paymentId}</Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>Payment Reference</Col>
                    <Col>{paymentResult.paymentStatus}</Col>
                  </Row>
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Link
            className='btn btn-outline-danger btn-sm rounded'
            onClick={handleClose}
            to='#'
          >
            Cancel
          </Link>

          <Button
            className='btn btn-outline-info btn-sm rounded'
            onClick={() => {
              addPayment();
            }}
            disabled={memberId === '' || paymentResult.paymentStatus === ''}
            variant='transparent'
            // to='#'
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddPayment;
