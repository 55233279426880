import {
  MEMBER_TYPE_ALL_FAIL,
  MEMBER_TYPE_ALL_REQUEST,
  MEMBER_TYPE_ALL_SUCCESS,
  MEMBER_TYPE_BY_ID_FAIL,
  MEMBER_TYPE_BY_ID_REQUEST,
  MEMBER_TYPE_BY_ID_RESET,
  MEMBER_TYPE_BY_ID_SUCCESS,
  MEMBER_TYPE_DELETE_FAIL,
  MEMBER_TYPE_DELETE_REQUEST,
  MEMBER_TYPE_DELETE_SUCCESS,
  MEMBER_TYPE_NEW_FAIL,
  MEMBER_TYPE_NEW_REQUEST,
  MEMBER_TYPE_NEW_RESET,
  MEMBER_TYPE_NEW_SUCCESS,
  MEMBER_TYPE_UPDATE_BY_ID_FAIL,
  MEMBER_TYPE_UPDATE_BY_ID_REQUEST,
  MEMBER_TYPE_UPDATE_BY_ID_RESET,
  MEMBER_TYPE_UPDATE_BY_ID_SUCCESS,
} from '../constants/memberTypeConstant';

export const memberTypeNewReducer = (state = {}, action) => {
  switch (action.type) {
    case MEMBER_TYPE_NEW_REQUEST:
      return { loading: true };
    case MEMBER_TYPE_NEW_SUCCESS:
      return { loading: false, success: action.payload };
    case MEMBER_TYPE_NEW_FAIL:
      return { loading: false, error: action.payload };
    case MEMBER_TYPE_NEW_RESET:
      return {};
    default:
      return state;
  }
};

export const memberTypeAllReducer = (state = { mTypes: [] }, action) => {
  switch (action.type) {
    case MEMBER_TYPE_ALL_REQUEST:
      return { loading: true };
    case MEMBER_TYPE_ALL_SUCCESS:
      return { loading: false, mTypes: action.payload };
    case MEMBER_TYPE_ALL_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const memberTypeByIdReducer = (state = { mType: {} }, action) => {
  switch (action.type) {
    case MEMBER_TYPE_BY_ID_REQUEST:
      return { ...state, loading: true };
    case MEMBER_TYPE_BY_ID_SUCCESS:
      return { loading: false, success: true, mType: action.payload };
    case MEMBER_TYPE_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    case MEMBER_TYPE_BY_ID_RESET:
      return {};
    default:
      return state;
  }
};

export const memberTypeUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case MEMBER_TYPE_UPDATE_BY_ID_REQUEST:
      return { loading: true };
    case MEMBER_TYPE_UPDATE_BY_ID_SUCCESS:
      return { loading: false, success: true, mType: action.payload };
    case MEMBER_TYPE_UPDATE_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    case MEMBER_TYPE_UPDATE_BY_ID_RESET:
      return {};
    default:
      return state;
  }
};

export const memberTypeDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case MEMBER_TYPE_DELETE_REQUEST:
      return { loading: true };
    case MEMBER_TYPE_DELETE_SUCCESS:
      return { loading: false, success: true };
    case MEMBER_TYPE_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
