import React from 'react';
import _ from 'lodash';
import { Accordion, Card, ListGroup, Row } from 'react-bootstrap';
import ImageLightBox from './ImageLightBox';

const ImageByEventGroup = ({ events }) => {
  const year = (item) => new Date(item.eventDate[0].value).getFullYear();
  /////////////////////////////////Dont delete //////////
  // const groups = _(events)
  //   .groupBy(year)
  //   .map((eventsGroup, year) => {
  //     const grouped = eventsGroup.map((event) => event);
  //     return {
  //       year,
  //       grouped,
  //     };
  //   })
  //   .value();
  // console.log(groups);
  /////////////////////////////////Dont delete //////////

  const groupEdit = _.chain(events)
    .filter((filter_by) => filter_by.eventImageGalleries.length !== 0)
    .sortBy('CreatedAt')
    .groupBy(year)
    .map((eventsGroup, year) => {
      const grouped = eventsGroup.map((event) => event);
      return {
        year,
        grouped,
      };
    })
    .value();

  console.log(groupEdit);

  return (
    <Accordion defaultActiveKey={'0'}>
      {groupEdit.map((year, idx) => (
        <Card key={idx}>
          <Accordion.Toggle
            as={Card.Header}
            eventKey={year.year}
            className='text-center text-info h5'
            style={{ cursor: 'pointer' }}
          >
            Year Book: {year.year}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={year.year}>
            <>
              {year.grouped.map((event, index) => (
                <div key={index}>
                  {event.eventImageGalleries &&
                    event.eventImageGalleries.length !== 0 && (
                      <Card>
                        <Card.Header className='text-info'>
                          {event.eventName} , {year.year}
                        </Card.Header>
                        <Card.Body>
                          <ListGroup variant='flush'>
                            <ListGroup.Item className='mb-2'>
                              <Row className='justify-content-center'>
                                <ImageLightBox
                                  images={event.eventImageGalleries}
                                />
                              </Row>
                            </ListGroup.Item>
                          </ListGroup>
                        </Card.Body>
                      </Card>
                    )}
                </div>
              ))}
            </>
          </Accordion.Collapse>
        </Card>
      ))}
    </Accordion>
  );
};

export default ImageByEventGroup;
