import axios from 'axios';
import {
  JOB_NEW_FAIL,
  JOB_NEW_REQUEST,
  JOB_NEW_SUCCESS,
  JOB_ALL_FAIL,
  JOB_ALL_REQUEST,
  JOB_ALL_SUCCESS,
  JOB_BY_ID_REQUEST,
  JOB_BY_ID_SUCCESS,
  JOB_BY_ID_FAIL,
  JOB_UPDATE_BY_ID_REQUEST,
  JOB_UPDATE_BY_ID_SUCCESS,
  JOB_UPDATE_BY_ID_FAIL,
  JOB_DELETE_REQUEST,
  JOB_DELETE_SUCCESS,
  JOB_DELETE_FAIL,
} from '../constants/careerConstants';

export const newJob = (jobData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: JOB_NEW_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    console.log(jobData);
    const { data } = await axios.post('/api/job', jobData, config);

    dispatch({
      type: JOB_NEW_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: JOB_NEW_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const allJobs =
  (
    // checkChapter,
    // keyword = '',
    // pageNumber = '',
    // pageSize = '',
    // approveStatus = '',
    // rejectStatus = ''
    pageData
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: JOB_ALL_REQUEST,
      });

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      console.log('from action page: ' + pageData.keyword);

      const { data } = await axios.get(
        `/api/job/chapter/${pageData.checkChapter}?keyword=${
          pageData.keyword ? pageData.keyword : ''
        }&page=${pageData.pageNumber ? pageData.pageNumber : ''}&size=${
          pageData.pageSize ? pageData.pageSize : ''
        }&approveStatus=${
          pageData.approveStatus ? pageData.approveStatus : ''
        }&rejectStatus=${pageData.rejectStatus ? pageData.rejectStatus : ''}`,

        config
      );

      dispatch({
        type: JOB_ALL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: JOB_ALL_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getJobById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: JOB_BY_ID_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(
      `/api/job/${id}`,

      config
    );

    dispatch({
      type: JOB_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: JOB_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateJobById = (jobData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: JOB_UPDATE_BY_ID_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(`/api/job/${jobData.id}`, jobData, config);

    dispatch({
      type: JOB_UPDATE_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: JOB_UPDATE_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteJob = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: JOB_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.delete(`/api/job/${id}`, config);

    dispatch({ type: JOB_DELETE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: JOB_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
