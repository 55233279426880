import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Loader';
import Message from '../Message';
import { getChapterDonations } from '../../actions/chapterActions';
import RTable from '../Table/RTable';
import ColumnFilter from '../Table/ColumnFilter';
import { Card, Col, Row } from 'react-bootstrap';
import Moment from 'react-moment';
import AddDonation from './AddDonation';

const Donations = ({ donationTypes }) => {
  const dispatch = useDispatch();
  const [columnsAllDonations, setColumnsAllDonations] = useState([]);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const chapterDonationAll = useSelector((state) => state.chapterDonationAll);
  const {
    loading: chapterDonationAllLoading,
    error: chapterDonationAllError,
    allDonations,
  } = chapterDonationAll;

  const checkChapter = window.location.host;

  useEffect(() => {
    if (
      (userInfo && userInfo.userRole === 'admin') ||
      userInfo.userRole === 'systemAdmin'
    ) {
      dispatch(getChapterDonations(checkChapter));

      setColumnsAllDonations([
        {
          Header: 'No.',
          accessor: (row, i) => i + 1,
        },
        {
          Header: 'Donation Type',
          accessor: 'donationType',
          Filter: ColumnFilter,
        },

        {
          Header: 'Amount',
          accessor: 'amount',
          Filter: ColumnFilter,
        },
        {
          Header: 'Date',
          accessor: 'donationDate',
          Filter: ColumnFilter,
          Cell: ({ value }) => {
            return <Moment>{value}</Moment>;
          },
        },

        {
          Header: 'Member Id',
          accessor: 'memberId',
          Filter: ColumnFilter,
        },

        {
          Header: 'First Name',
          accessor: 'firstName',
          Filter: ColumnFilter,
        },
        {
          Header: 'Last Name',
          accessor: 'lastName',
          Filter: ColumnFilter,
        },
        {
          Header: 'Email',
          accessor: 'email',
          Filter: ColumnFilter,
        },

        {
          Header: 'Member?',
          accessor: 'isMember',
          Filter: ColumnFilter,
          Cell: ({ value }) => {
            if (value) {
              return 'Yes';
            } else {
              return 'No';
            }
          },
        },
        {
          Header: 'Chapter',
          accessor: 'subDomain',
          Filter: ColumnFilter,
        },
        {
          Header: 'Payment Status',
          accessor: 'paymentStatus',
          Filter: ColumnFilter,
        },
      ]);
    }
  }, [dispatch, userInfo, checkChapter]);

  return (
    <Card>
      <Card.Header as='h3' className='text-info text-center'>
        <Row>
          <Col className='mr-auto'>DONATIONS</Col>
          <Col className='ml-auto' md={2} xs={4}>
            <AddDonation types={donationTypes} />
          </Col>
        </Row>
      </Card.Header>
      {chapterDonationAllLoading ? (
        <Loader />
      ) : chapterDonationAllError ? (
        <Message variant='danger'>{chapterDonationAllError}</Message>
      ) : (
        <>
          {allDonations && allDonations.length !== 0 && (
            <RTable users={allDonations} COLUMNS={columnsAllDonations} />
          )}
        </>
      )}
    </Card>
  );
};

export default Donations;
