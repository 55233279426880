import React, { useState } from 'react';
import {
  Col,
  Form,
  ListGroup,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

const CreateRoom = ({ hotelId, createRoom }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [roomType, setRoomType] = useState('');
  const [roomDescription, setRoomDescription] = useState('');
  const [accomodation, setAccomodation] = useState(1);
  const [rent, setRent] = useState(0);

  return (
    <>
      <OverlayTrigger
        overlay={
          <Tooltip id='tooltip-disabled'>Add Room Type to Hotel</Tooltip>
        }
      >
        <span onClick={handleShow}>
          <i
            className='fas fa-plus action mr-2 text-info'
            style={{
              cursor: 'pointer',
            }}
          ></i>
        </span>
      </OverlayTrigger>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        size='lg'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className='text-center text-info'>
            New Room Type:
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup variant='flush'>
            <ListGroup.Item>
              <Form.Row>
                <Form.Group as={Col} md='2'>
                  <Form.Label>Room Type :</Form.Label>
                </Form.Group>
                <Col>
                  <Form.Group as={Col} controlId='roomType'>
                    <Form.Control
                      required
                      type='text'
                      placeholder='New Room Type Name..'
                      value={roomType}
                      onChange={(e) => setRoomType(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Form.Row>
            </ListGroup.Item>

            <ListGroup.Item>
              <Form.Row>
                <Form.Group as={Col} md='2'>
                  <Form.Label>Room Description :</Form.Label>
                </Form.Group>
                <Col>
                  <Form.Group as={Col} controlId='roomDescription'>
                    <Form.Control
                      required
                      type='text'
                      placeholder='Room details..'
                      value={roomDescription}
                      onChange={(e) => setRoomDescription(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Form.Row>
            </ListGroup.Item>

            <ListGroup.Item>
              <Form.Row>
                <Form.Group as={Col} md='2'>
                  <Form.Label>Accomodation :</Form.Label>
                </Form.Group>
                <Col>
                  <Row>
                    <Form.Group as={Col} controlId='accomodation'>
                      <Form.Control
                        required
                        type='number'
                        placeholder='Number of Persons'
                        value={accomodation}
                        onChange={(e) => setAccomodation(e.target.value)}
                        min='1'
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group as={Col} controlId='personNumber'>
                      <Form.Label>Person/s</Form.Label>
                    </Form.Group>
                  </Row>
                </Col>
              </Form.Row>
            </ListGroup.Item>

            <ListGroup.Item>
              <Form.Row>
                <Form.Group as={Col} md='2'>
                  <Form.Label>Rent :</Form.Label>
                </Form.Group>
                <Col>
                  <Row>
                    <Form.Group as={Col} controlId='rent'>
                      <Form.Control
                        required
                        type='number'
                        placeholder='Number of Persons'
                        value={rent}
                        onChange={(e) => setRent(e.target.value)}
                        min='1'
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group as={Col} controlId='roomRent'>
                      <Form.Label>USD</Form.Label>
                    </Form.Group>
                  </Row>
                </Col>
              </Form.Row>
            </ListGroup.Item>
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Link
            className='btn btn-outline-danger btn-sm rounded'
            to='#'
            onClick={handleClose}
          >
            Cancel
          </Link>

          <Link
            className='btn btn-outline-info btn-sm rounded'
            to='#'
            onClick={() => {
              createRoom(
                hotelId,
                roomType,
                roomDescription,
                accomodation,
                rent
              );
              handleClose();
            }}
          >
            Create
          </Link>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateRoom;
