export const BLOG_NEW_REQUEST = 'BLOG_NEW_REQUEST';
export const BLOG_NEW_SUCCESS = 'BLOG_NEW_SUCCESS';
export const BLOG_NEW_FAIL = 'BLOG_NEW_FAIL';
export const BLOG_NEW_RESET = 'BLOG_NEW_RESET';

export const BLOG_ALL_REQUEST = 'BLOG_ALL_REQUEST';
export const BLOG_ALL_SUCCESS = 'BLOG_ALL_SUCCESS';
export const BLOG_ALL_FAIL = 'BLOG_ALL_FAIL';

export const BLOG_BY_ID_REQUEST = 'BLOG_BY_ID_REQUEST';
export const BLOG_BY_ID_SUCCESS = 'BLOG_BY_ID_SUCCESS';
export const BLOG_BY_ID_FAIL = 'BLOG_BY_ID_FAIL';
export const BLOG_BY_ID_RESET = 'BLOG_BY_ID_RESET';

export const BLOG_UPDATE_BY_ID_REQUEST = 'BLOG_UPDATE_BY_ID_REQUEST';
export const BLOG_UPDATE_BY_ID_SUCCESS = 'BLOG_UPDATE_BY_ID_SUCCESS';
export const BLOG_UPDATE_BY_ID_FAIL = 'BLOG_UPDATE_BY_ID_FAIL';
export const BLOG_UPDATE_BY_ID_RESET = 'BLOG_UPDATE_BY_ID_RESET';

export const BLOG_DELETE_REQUEST = 'BLOG_DELETE_REQUEST';
export const BLOG_DELETE_SUCCESS = 'BLOG_DELETE_SUCCESS';
export const BLOG_DELETE_FAIL = 'BLOG_DELETE_FAIL';
