import React, { useEffect } from 'react';
import { Card, Col, ListGroup, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getAdvsByEvent } from '../../actions/advAction';
import { allEvents } from '../../actions/eventActions';
import { allImage, getHomeScreenImage } from '../../actions/imageActions';
import {
  ADVERTISEMENT_BY_ID_RESET,
  ADVERTISEMENT_NEW_RESET,
} from '../../constants/advertisementConstant';
import ImageLightBox from '../Image/ImageLightBox';
import Loader from '../Loader';
import Message from '../Message';

const EventAdvertisements = ({ history, advType }) => {
  const dispatch = useDispatch();
  const checkChapter = window.location.host;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const imageDelete = useSelector((state) => state.imageDelete);
  const { success: successDelete } = imageDelete;

  const advsByEvent = useSelector((state) => state.advsByEvent);
  const {
    loading: advsByEventLoading,
    error: advsByEventError,
    advs,
  } = advsByEvent;

  console.log(advs);

  useEffect(() => {
    if (
      userInfo &&
      (userInfo.userRole === 'systemAdmin' || userInfo.userRole === 'admin')
    ) {
      dispatch(allImage(checkChapter));
      dispatch(getHomeScreenImage(checkChapter));
      dispatch(getAdvsByEvent(checkChapter));
      dispatch(allEvents(checkChapter));
      dispatch({ type: ADVERTISEMENT_NEW_RESET });
      dispatch({ type: ADVERTISEMENT_BY_ID_RESET });
    } else {
      history.push('/login');
    }
  }, [dispatch, history, advType, userInfo, checkChapter, successDelete]);

  return (
    <>
      <Row>
        <Col
          md={{ span: 12, order: 12 }}
          lg={{ span: 12, order: 12 }}
          className='mb-2 p-0'
          id='all-chapter'
        >
          {/* Event Images */}
          <Card className='text-center mb-2' border='info'>
            <Card.Header as='h3' className='text-info'>
              Event Advertisements
            </Card.Header>

            <Card.Body>
              {advsByEventLoading ? (
                <Loader />
              ) : advsByEventError ? (
                <Message variant='danger'>{advsByEventError}</Message>
              ) : (
                advs && (
                  <ListGroup variant='flush'>
                    {advs.map((adv, index) => (
                      <ListGroup.Item key={index} className='mb-2'>
                        <Card.Title className='text-info'>
                          {adv.eventName}
                        </Card.Title>
                        <Row className='justify-content-center'>
                          {adv.advertisements &&
                          adv.advertisements.length !== 0 ? (
                            <>
                              <ImageLightBox images={adv.advertisements} />
                            </>
                          ) : (
                            <h5>No Advertisement</h5>
                          )}
                        </Row>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                )
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default EventAdvertisements;
