import axios from 'axios';
import {
  ADVERTISEMENT_NEW_FAIL,
  ADVERTISEMENT_NEW_REQUEST,
  ADVERTISEMENT_NEW_SUCCESS,
  ADVERTISEMENT_ALL_FAIL,
  ADVERTISEMENT_ALL_REQUEST,
  ADVERTISEMENT_ALL_SUCCESS,
  ADVERTISEMENT_BY_ID_REQUEST,
  ADVERTISEMENT_BY_ID_SUCCESS,
  ADVERTISEMENT_BY_ID_FAIL,
  ADVERTISEMENT_UPDATE_BY_ID_REQUEST,
  ADVERTISEMENT_UPDATE_BY_ID_SUCCESS,
  ADVERTISEMENT_UPDATE_BY_ID_FAIL,
  ADVERTISEMENT_DELETE_REQUEST,
  ADVERTISEMENT_DELETE_SUCCESS,
  ADVERTISEMENT_DELETE_FAIL,
  ADVERTISEMENT_BY_EVENT_REQUEST,
  ADVERTISEMENT_BY_EVENT_SUCCESS,
  ADVERTISEMENT_BY_EVENT_FAIL,
} from '../constants/advertisementConstant';

export const newAdv = (advDetails) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADVERTISEMENT_NEW_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post('/api/adv', advDetails, config);

    dispatch({
      type: ADVERTISEMENT_NEW_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADVERTISEMENT_NEW_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const allAdv =
  ({ checkChapter, advType = '', eventId = '', active = '' }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ADVERTISEMENT_ALL_REQUEST,
      });

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      // console.log('from action page: ' + checkChapter);
      const { data } = await axios.get(
        `/api/adv/chapter/${checkChapter}/?advType=${advType}&eventId=${eventId}&active=${active}`,

        config
      );

      dispatch({
        type: ADVERTISEMENT_ALL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADVERTISEMENT_ALL_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getAdvsByEvent = (checkChapter) => async (dispatch) => {
  try {
    dispatch({
      type: ADVERTISEMENT_BY_EVENT_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(
      `/api/adv/event/chapter/${checkChapter}`,
      config
    );

    dispatch({
      type: ADVERTISEMENT_BY_EVENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADVERTISEMENT_BY_EVENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getAdvById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ADVERTISEMENT_BY_ID_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(
      `/api/adv/${id}`,

      config
    );

    dispatch({
      type: ADVERTISEMENT_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADVERTISEMENT_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateAdvById = (advDetails) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADVERTISEMENT_UPDATE_BY_ID_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/adv/${advDetails.id}`,
      advDetails,
      config
    );

    dispatch({
      type: ADVERTISEMENT_UPDATE_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADVERTISEMENT_UPDATE_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteAdv = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADVERTISEMENT_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/adv/${id}`, config);

    dispatch({ type: ADVERTISEMENT_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: ADVERTISEMENT_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
