import axios from "axios";
import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  Card,
  Container,
  ListGroup,
  Button,
  Accordion,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import swal from "sweetalert";
import { PayPalButton } from "react-paypal-button-v2";
import { registerEvent } from "../../actions/eventActions";
import GroupRegistration from "../../components/Events/GroupRegistration";
import { saveHotelRoom, saveProgramGroup } from "../../actions/cartAction";
import { Link } from "react-router-dom";
import { CART_RESET } from "../../constants/cartConstants";
import RegisteredGroup from "../../components/Events/RegisteredGroup";
import RegisteredRoom from "../../components/Events/RegisteredRoom";
import NumberInput from "../../components/Events/NumberInput";

const EventRegistrationPayment = ({ match, history }) => {
  const [sdkReady, setSdkReady] = useState(false);
  const [successRegistration, setSuccessRegistration] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [hotelRent, setHotelRent] = useState(0);
  const [subEventFee, setSubEventFee] = useState(0);

  // central 2022 specific modification
  const [newHotelarray, setNewHotelarray] = useState();
  const [newHotelarray2, setNewHotelarray2] = useState();
  const [showMore, setShowMore] = useState(false);

  const dispatch = useDispatch();

  const eventRegister = useSelector((state) => state.eventRegister);
  const {
    loading: eventRegisterLoading,
    error: eventRegisterError,
    success: eventRegisterSuccess,
  } = eventRegister;

  const eventById = useSelector((state) => state.eventById);
  const { loading, error, event } = eventById;

  const eventContactAll = useSelector((state) => state.eventContactAll);
  const {
    loading: eventContactsLoading,
    error: eventContactsError,
    eventContacts,
  } = eventContactAll;

  const cart = useSelector((state) => state.cart);
  const { success, cartItems, programs, hotels, error: cartError } = cart;

  const checkChapter = window.location.host;

  useEffect(() => {
    if (
      event.cap <=
      cartItems.numberOfAdults * event.adultFee +
        cartItems.numberOfMinors * event.minorFee
    ) {
      setTotalAmount(event.cap);
    } else {
      setTotalAmount(
        cartItems.numberOfAdults * event.adultFee +
          cartItems.numberOfMinors * event.minorFee
      );
    }
    // central 2022 specific modification
    if (event && event.eventHotels && event.eventHotels.length !== 0) {
      const ary1 = event.eventHotels[0].eventHotelRooms.filter((element) => {
        return (
          element.roomType === "7th October 2022" ||
          element.roomType === "8th October 2022"
        );
      });
      setNewHotelarray(ary1);
      const ary2 = event.eventHotels[0].eventHotelRooms.filter((element) => {
        return (
          element.roomType !== "7th October 2022" &&
          element.roomType !== "8th October 2022"
        );
      });
      setNewHotelarray2(ary2);
    }

    if (hotels && hotels.length !== 0) {
      let sum = 0;
      for (let i = 0; i < hotels.length; i++) {
        sum += hotels[i].rent * hotels[i].qty;
      }
      setHotelRent(sum);
    }

    if (programs && programs.length !== 0) {
      let sum = 0;
      for (let i = 0; i < programs.length; i++) {
        sum += programs[i].fee;
      }
      setSubEventFee(sum);
    }

    if (eventRegisterSuccess) {
      swal("Success!", "Registration successfull", "success").then(() => {
        setSuccessRegistration(true);
      });
    } else if (eventRegisterError || cartError) {
      swal("Error!", eventRegisterError || cartError, "error");
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const addPaypalScript = async () => {
      const { data: clientId } = await axios.get(
        `/api/chapters/paypal/${checkChapter}`,
        config
      );
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}`;
      script.async = true;
      script.onload = () => {
        setSdkReady(true);
      };
      document.body.appendChild(script);
    };

    if (!window.paypal) {
      addPaypalScript();
    } else {
      setSdkReady(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    // id,
    history,
    checkChapter,
    eventRegisterSuccess,
    eventRegisterError,
    success,
    programs,
    hotels,
    cartError,
  ]);

  // groupId, programId, eventId, groupName, participantName, age, grade, fee, schoolName
  const registerGroup = (
    groupId,
    programId,
    eventId,
    programName,
    groupName,
    participantName,
    age,
    grade,
    fee,
    schoolName
  ) => {
    dispatch(
      saveProgramGroup({
        groupId,
        programId,
        eventId,
        programName,
        groupName,
        participantName,
        age,
        grade,
        fee,
        schoolName,
      })
    );
  };

  // hotelName, eventHotelRoomId, eventHotelId, eventId, roomType, roomDescription, accomodation, rent, qty
  const registerRoom = (
    hotelName,
    eventHotelRoomId,
    eventHotelId,
    eventId,
    roomType,
    roomDescription,
    accomodation,
    rent,
    qty
  ) => {
    dispatch(
      saveHotelRoom({
        hotelName,
        eventHotelRoomId,
        eventHotelId,
        eventId,
        roomType,
        roomDescription,
        accomodation,
        rent,
        qty,
      })
    );
  };

  const submitHandler = (e) => {
    e.preventDefault();

    let paymentResult = [];

    dispatch(
      registerEvent({
        eventId: cartItems.eventId,
        eventName: event.eventName,
        mInit: cartItems.mInit,
        firstName: cartItems.firstName,
        lastName: cartItems.lastName,
        isMember: cartItems.isMember,
        memberId: cartItems.memberId,
        email: cartItems.email,
        phone: cartItems.phone,
        numberOfAdults: cartItems.numberOfAdults,
        numberOfMinors: cartItems.numberOfMinors,
        paymentResult: paymentResult,
        programs: programs,
        hotels: hotels,
        totalAmount: totalAmount,
        subEventFee: subEventFee,
        hotelRent: hotelRent,
        contributionAmount: 0,
      })
    );
  };

  const successPaymentHandler = (paymentResult) => {
    dispatch(
      registerEvent({
        eventId: cartItems.eventId,
        eventName: event.eventName,
        mInit: cartItems.mInit,
        firstName: cartItems.firstName,
        lastName: cartItems.lastName,
        isMember: cartItems.isMember,
        memberId: cartItems.memberId,
        email: cartItems.email,
        phone: cartItems.phone,
        numberOfAdults: cartItems.numberOfAdults,
        numberOfMinors: cartItems.numberOfMinors,
        paymentResult: paymentResult,
        programs: programs,
        hotels: hotels,
        totalAmount: totalAmount,
        subEventFee: subEventFee,
        hotelRent: hotelRent,
        contributionAmount: cartItems.contributionAmount,
      })
    );
  };

  const showMoreDates = (e) => {
    e.preventDefault();

    setShowMore(!showMore);
  };

  return (
    <Container>
      <>
        {event && event.length !== 0 && (
          <Link
            className="btn btn-outline-info btn-sm rounded mb-2 mt-2"
            to={`/event/register/${event.eventId}`}
            onClick={(e) => dispatch({ type: CART_RESET })}
          >
            Go Back
          </Link>
        )}
        <Card border="info">
          {eventRegisterLoading ? (
            <Loader />
          ) : (
            <>
              {!successRegistration ? (
                <>
                  <Card.Header
                    className="text-center bg-info text-light"
                    as="h5"
                  >
                    Event Registration Payment
                  </Card.Header>
                  <Card.Body>
                    {cartItems && (
                      <Form onSubmit={submitHandler}>
                        <Row>
                          {loading ? (
                            <Loader />
                          ) : error ? (
                            <Message variant="danger">{error}</Message>
                          ) : event ? (
                            event && (
                              <>
                                <Col md={8}>
                                  <ListGroup variant="flush">
                                    {event.eventId !== "42141" ? (
                                      <ListGroup.Item>
                                        <Row>
                                          <Col
                                            md={3}
                                            className="text-info p-0"
                                            as="h6"
                                          >
                                            Member Id / Reference:
                                          </Col>
                                          <Col>{cartItems.memberId}</Col>
                                        </Row>
                                      </ListGroup.Item>
                                    ) : null}

                                    <ListGroup.Item>
                                      <Row>
                                        <Col
                                          md={3}
                                          className="text-info p-0"
                                          as="h5"
                                        >
                                          Name:
                                        </Col>
                                        <Col>
                                          {cartItems.mInit}{" "}
                                          {cartItems.firstName}{" "}
                                          {cartItems.lastName}
                                        </Col>
                                      </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                      <Row>
                                        <Col
                                          md={3}
                                          className="text-info p-0"
                                          as="h5"
                                        >
                                          Contact Details:
                                        </Col>
                                        <Col>
                                          <ListGroup variant="flush">
                                            <ListGroup.Item>
                                              <Row>
                                                <Col md={3}>Phone Number :</Col>
                                                <Col>{cartItems.phone}</Col>
                                              </Row>
                                            </ListGroup.Item>

                                            <ListGroup.Item>
                                              <Row>
                                                <Col md={3}>Email :</Col>
                                                <Col>{cartItems.email}</Col>
                                              </Row>
                                            </ListGroup.Item>
                                          </ListGroup>
                                        </Col>
                                      </Row>
                                    </ListGroup.Item>

                                    {event.eventType &&
                                    event.eventType !== "donation" &&
                                    event.eventType !== "fund-raising" ? (
                                      <>
                                        <ListGroup.Item>
                                          <Row>
                                            <Col
                                              md={3}
                                              className="text-info p-0"
                                              as="h5"
                                            >
                                              Number Of Attendees:
                                            </Col>
                                            <Col>
                                              <ListGroup variant="flush">
                                                {event.eventId === "42141" ? (
                                                  <>
                                                    <ListGroup.Item>
                                                      <Row>
                                                        <Col>
                                                          {cartItems.numberOfAdults >
                                                          0 ? (
                                                            <>
                                                              Full 3-Day Event:
                                                              per person
                                                              including Grand
                                                              dinner
                                                            </>
                                                          ) : cartItems.numberOfMinors >
                                                            0 ? (
                                                            <>
                                                              Just Grand Dinner
                                                              with Cultural Show
                                                            </>
                                                          ) : (
                                                            <>
                                                              Full 3-Day Event:
                                                              per person
                                                              including Grand
                                                              dinner: $150
                                                            </>
                                                          )}
                                                        </Col>
                                                      </Row>
                                                    </ListGroup.Item>

                                                    <ListGroup.Item>
                                                      <Row>
                                                        <Col>
                                                          Number of persons:
                                                        </Col>
                                                        <Col>
                                                          {cartItems.numberOfAdults >
                                                          0
                                                            ? cartItems.numberOfAdults
                                                            : cartItems.numberOfMinors >
                                                              0
                                                            ? cartItems.numberOfMinors
                                                            : cartItems.numberOfAdults}
                                                        </Col>
                                                      </Row>
                                                    </ListGroup.Item>
                                                  </>
                                                ) : (
                                                  <>
                                                    <ListGroup.Item>
                                                      <Row>
                                                        <Col md={3}>
                                                          Adults :
                                                        </Col>
                                                        <Col>
                                                          {
                                                            cartItems.numberOfAdults
                                                          }
                                                        </Col>
                                                      </Row>
                                                    </ListGroup.Item>

                                                    <ListGroup.Item>
                                                      <Row>
                                                        <Col md={3}>
                                                          Minors :
                                                        </Col>
                                                        <Col>
                                                          {
                                                            cartItems.numberOfMinors
                                                          }
                                                        </Col>
                                                      </Row>
                                                    </ListGroup.Item>
                                                  </>
                                                )}
                                              </ListGroup>
                                            </Col>
                                          </Row>
                                          <hr
                                            className="border-info"
                                            // style={{ b: '50px' }}
                                          />

                                          {programs && programs.length !== 0 ? (
                                            <RegisteredGroup
                                              programs={programs}
                                            />
                                          ) : null}
                                        </ListGroup.Item>

                                        {/* Programs */}

                                        <Accordion
                                          defaultActiveKey="0"
                                          className="mb-2"
                                        >
                                          {event.eventPrograms &&
                                            event.eventPrograms.length !== 0 &&
                                            event.eventPrograms.map(
                                              (program, index) => (
                                                <Card key={index}>
                                                  <Accordion.Toggle
                                                    as={Card.Header}
                                                    eventKey={
                                                      program.eventProgramId
                                                    }
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    <Row>
                                                      {/* <Col md={3}>
                                                          Program Name:
                                                        </Col> */}
                                                      <Col className="mr-auto">
                                                        {program.programName}
                                                      </Col>

                                                      <Col
                                                        className="ml-auto"
                                                        md={3}
                                                        xs={6}
                                                      >
                                                        Participate ?
                                                      </Col>
                                                    </Row>
                                                  </Accordion.Toggle>
                                                  <Accordion.Collapse
                                                    eventKey={
                                                      program.eventProgramId
                                                    }
                                                  >
                                                    <Card.Body>
                                                      {program.eventProgramGroups &&
                                                      program.eventProgramGroups
                                                        .length !== 0
                                                        ? program.eventProgramGroups.map(
                                                            (group, index) => (
                                                              <Card.Body
                                                                key={index}
                                                                className="mb-2"
                                                              >
                                                                <ListGroup variant="flush">
                                                                  <ListGroup.Item>
                                                                    <Row>
                                                                      <Col
                                                                        md={3}
                                                                      >
                                                                        Group
                                                                        Name:
                                                                      </Col>
                                                                      <Col className="mr-auto">
                                                                        {
                                                                          group.groupName
                                                                        }
                                                                      </Col>

                                                                      <Col
                                                                        className="ml-auto text-center"
                                                                        md={2}
                                                                        xs={2}
                                                                      >
                                                                        <GroupRegistration
                                                                          group={
                                                                            group
                                                                          }
                                                                          programName={
                                                                            program.programName
                                                                          }
                                                                          registerGroup={
                                                                            registerGroup
                                                                          }
                                                                        />
                                                                      </Col>
                                                                    </Row>
                                                                  </ListGroup.Item>

                                                                  <ListGroup.Item>
                                                                    <Row>
                                                                      <Col
                                                                        md={3}
                                                                      >
                                                                        Grade :
                                                                      </Col>
                                                                      <Col>
                                                                        {
                                                                          group.gradeFrom
                                                                        }{" "}
                                                                        to{" "}
                                                                        {
                                                                          group.gradeTo
                                                                        }
                                                                      </Col>
                                                                    </Row>
                                                                  </ListGroup.Item>

                                                                  <ListGroup.Item>
                                                                    <Row>
                                                                      <Col
                                                                        md={3}
                                                                      >
                                                                        Date:
                                                                      </Col>
                                                                      <Col>
                                                                        <ListGroup variant="flush">
                                                                          <ListGroup.Item className="p-0">
                                                                            <Row>
                                                                              <Col
                                                                                md={
                                                                                  4
                                                                                }
                                                                              >
                                                                                Start
                                                                                Date
                                                                                :
                                                                              </Col>
                                                                              {group.ProgramDateFrom ? (
                                                                                <>
                                                                                  {new Date(
                                                                                    group.ProgramDateFrom
                                                                                  ).toLocaleString()}
                                                                                </>
                                                                              ) : null}
                                                                            </Row>
                                                                          </ListGroup.Item>

                                                                          <ListGroup.Item className="p-0">
                                                                            <Row>
                                                                              <Col
                                                                                md={
                                                                                  4
                                                                                }
                                                                              >
                                                                                End
                                                                                Date
                                                                                :
                                                                              </Col>
                                                                              {group.ProgramDateTo ? (
                                                                                <>
                                                                                  {new Date(
                                                                                    group.ProgramDateTo
                                                                                  ).toLocaleString()}
                                                                                </>
                                                                              ) : null}
                                                                            </Row>
                                                                          </ListGroup.Item>
                                                                        </ListGroup>
                                                                      </Col>
                                                                    </Row>
                                                                  </ListGroup.Item>

                                                                  <ListGroup.Item>
                                                                    <Row>
                                                                      <Col
                                                                        md={3}
                                                                      >
                                                                        Fee :
                                                                      </Col>
                                                                      <Col>
                                                                        {
                                                                          group.fee
                                                                        }
                                                                      </Col>
                                                                    </Row>
                                                                  </ListGroup.Item>
                                                                </ListGroup>
                                                                <hr />
                                                              </Card.Body>
                                                            )
                                                          )
                                                        : null}
                                                    </Card.Body>
                                                  </Accordion.Collapse>
                                                </Card>
                                              )
                                            )}
                                        </Accordion>

                                        {/* Hotel */}

                                        <Accordion
                                          defaultActiveKey={
                                            event.eventHotels &&
                                            event.eventHotels.length !== 0
                                              ? event.eventHotels[0]
                                                  .eventHotelId
                                              : "0"
                                          }
                                          className="mb-2"
                                        >
                                          {event.eventHotels &&
                                            event.eventHotels.length !== 0 &&
                                            event.eventHotels.map(
                                              (hotel, index) => (
                                                <Card key={index}>
                                                  <Accordion.Toggle
                                                    as={Card.Header}
                                                    eventKey={
                                                      hotel.eventHotelId
                                                    }
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    className="btn-primary-yellow btn-xl text-uppercase"
                                                  >
                                                    <Row>
                                                      <Col className="mr-auto text-info">
                                                        {hotel.hotelName}
                                                      </Col>

                                                      <Col
                                                        className="ml-auto text-info"
                                                        md={3}
                                                        xs={6}
                                                      >
                                                        Book hotel ?
                                                      </Col>
                                                    </Row>
                                                  </Accordion.Toggle>
                                                  <Accordion.Collapse
                                                    eventKey={
                                                      hotel.eventHotelId
                                                    }
                                                  >
                                                    <>
                                                      {/* Extra dates */}
                                                      <div className="text-right m-5">
                                                        {!showMore ? (
                                                          <Button
                                                            className="btn btn-info btn-sm rounded"
                                                            variant="transparent"
                                                            onClick={
                                                              showMoreDates
                                                            }
                                                          >
                                                            More Dates
                                                          </Button>
                                                        ) : (
                                                          <Button
                                                            className="btn btn-info btn-sm rounded"
                                                            variant="transparent"
                                                            onClick={
                                                              showMoreDates
                                                            }
                                                          >
                                                            Less Dates
                                                          </Button>
                                                        )}
                                                      </div>

                                                      <Card.Body>
                                                        {newHotelarray &&
                                                        newHotelarray.length !==
                                                          0
                                                          ? newHotelarray.map(
                                                              (room, index) => (
                                                                <Card.Body
                                                                  key={index}
                                                                  className="mb-2"
                                                                >
                                                                  <ListGroup variant="flush">
                                                                    <Row>
                                                                      <Col
                                                                        md={3}
                                                                        className="text-info p-0"
                                                                        as="h6"
                                                                      >
                                                                        Date:
                                                                      </Col>
                                                                      <Col className="mr-auto">
                                                                        {
                                                                          room.roomType
                                                                        }
                                                                      </Col>
                                                                    </Row>

                                                                    <Row>
                                                                      <Col
                                                                        md={3}
                                                                        className="text-info p-0"
                                                                        as="h6"
                                                                      >
                                                                        Description
                                                                        :
                                                                      </Col>
                                                                      <Col>
                                                                        {
                                                                          room.roomDescription
                                                                        }
                                                                      </Col>
                                                                    </Row>

                                                                    <Row>
                                                                      <Col
                                                                        md={3}
                                                                        className="text-info p-0"
                                                                        as="h6"
                                                                      >
                                                                        Accomodation:
                                                                      </Col>
                                                                      <Col>
                                                                        {
                                                                          room.accomodation
                                                                        }{" "}
                                                                        Person(s)
                                                                      </Col>
                                                                    </Row>

                                                                    <Row>
                                                                      <Col
                                                                        md={3}
                                                                        className="text-info p-0"
                                                                        as="h6"
                                                                      >
                                                                        Rent:
                                                                      </Col>
                                                                      <Col>
                                                                        {" "}
                                                                        $
                                                                        {
                                                                          room.rent
                                                                        }
                                                                      </Col>
                                                                    </Row>

                                                                    <Row>
                                                                      <Col
                                                                        md={3}
                                                                        className="text-info p-0"
                                                                        as="h6"
                                                                      >
                                                                        Number
                                                                        of room
                                                                        reserved:
                                                                      </Col>
                                                                      <Col>
                                                                        <NumberInput
                                                                          room={
                                                                            room
                                                                          }
                                                                          hotelName={
                                                                            hotel.hotelName
                                                                          }
                                                                          registerRoom={
                                                                            registerRoom
                                                                          }
                                                                        />
                                                                      </Col>
                                                                    </Row>
                                                                  </ListGroup>
                                                                  <hr />
                                                                </Card.Body>
                                                              )
                                                            )
                                                          : null}
                                                      </Card.Body>

                                                      {showMore ? (
                                                        <Card.Body>
                                                          {newHotelarray2 &&
                                                          newHotelarray2.length !==
                                                            0
                                                            ? newHotelarray2.map(
                                                                (
                                                                  room,
                                                                  index
                                                                ) => (
                                                                  <Card.Body
                                                                    key={index}
                                                                    className="mb-2"
                                                                  >
                                                                    <ListGroup variant="flush">
                                                                      <Row>
                                                                        <Col
                                                                          md={3}
                                                                          className="text-info p-0"
                                                                          as="h6"
                                                                        >
                                                                          Date:
                                                                        </Col>
                                                                        <Col className="mr-auto">
                                                                          {
                                                                            room.roomType
                                                                          }
                                                                        </Col>
                                                                      </Row>

                                                                      <Row>
                                                                        <Col
                                                                          md={3}
                                                                          className="text-info p-0"
                                                                          as="h6"
                                                                        >
                                                                          Description
                                                                          :
                                                                        </Col>
                                                                        <Col>
                                                                          {
                                                                            room.roomDescription
                                                                          }
                                                                        </Col>
                                                                      </Row>

                                                                      <Row>
                                                                        <Col
                                                                          md={3}
                                                                          className="text-info p-0"
                                                                          as="h6"
                                                                        >
                                                                          Accomodation:
                                                                        </Col>
                                                                        <Col>
                                                                          {
                                                                            room.accomodation
                                                                          }{" "}
                                                                          Person(s)
                                                                        </Col>
                                                                      </Row>

                                                                      <Row>
                                                                        <Col
                                                                          md={3}
                                                                          className="text-info p-0"
                                                                          as="h6"
                                                                        >
                                                                          Rent:
                                                                        </Col>
                                                                        <Col>
                                                                          {" "}
                                                                          $
                                                                          {
                                                                            room.rent
                                                                          }
                                                                        </Col>
                                                                      </Row>

                                                                      <Row>
                                                                        <Col
                                                                          md={3}
                                                                          className="text-info p-0"
                                                                          as="h6"
                                                                        >
                                                                          Number
                                                                          of
                                                                          room
                                                                          reserved:
                                                                        </Col>
                                                                        <Col>
                                                                          <NumberInput
                                                                            room={
                                                                              room
                                                                            }
                                                                            hotelName={
                                                                              hotel.hotelName
                                                                            }
                                                                            registerRoom={
                                                                              registerRoom
                                                                            }
                                                                          />
                                                                        </Col>
                                                                      </Row>
                                                                    </ListGroup>
                                                                    <hr />
                                                                  </Card.Body>
                                                                )
                                                              )
                                                            : null}
                                                        </Card.Body>
                                                      ) : null}
                                                    </>
                                                  </Accordion.Collapse>
                                                </Card>
                                              )
                                            )}
                                        </Accordion>
                                      </>
                                    ) : null}
                                  </ListGroup>
                                </Col>
                                <Col md={4}>
                                  <Card>
                                    {event.eventType &&
                                    event.eventType !== "donation" &&
                                    event.eventType !== "fund-raising" ? (
                                      <>
                                        <ListGroup variant="flush">
                                          <ListGroup.Item>
                                            <h5 className="text-info">
                                              Summary of payment
                                            </h5>
                                          </ListGroup.Item>

                                          <ListGroup.Item>
                                            <Row>
                                              <Col>Event Name:</Col>
                                              <Col>{event.eventName}</Col>
                                            </Row>
                                          </ListGroup.Item>

                                          {event.eventId === "42141" ? (
                                            <>
                                              <ListGroup.Item>
                                                <Row>
                                                  <Col>No. Of Attendees:</Col>
                                                  <Col>
                                                    {cartItems.numberOfAdults >
                                                    0
                                                      ? cartItems.numberOfAdults
                                                      : cartItems.numberOfMinors >
                                                        0
                                                      ? cartItems.numberOfMinors
                                                      : cartItems.numberOfAdults}
                                                  </Col>
                                                </Row>
                                              </ListGroup.Item>
                                            </>
                                          ) : (
                                            <>
                                              <ListGroup.Item>
                                                <Row>
                                                  <Col>No. Of Adults:</Col>
                                                  <Col>
                                                    {cartItems.numberOfAdults}
                                                  </Col>
                                                </Row>
                                              </ListGroup.Item>

                                              <ListGroup.Item>
                                                <Row>
                                                  <Col>No. Of Minors:</Col>
                                                  <Col>
                                                    {cartItems.numberOfMinors}
                                                  </Col>
                                                </Row>
                                              </ListGroup.Item>
                                            </>
                                          )}

                                          <ListGroup.Item>
                                            <Row>
                                              <Col>Registration Fee :</Col>

                                              <Col>$ {totalAmount}</Col>
                                            </Row>
                                          </ListGroup.Item>

                                          {programs &&
                                          programs.length !== 0 &&
                                          subEventFee !== 0 ? (
                                            <ListGroup.Item>
                                              <Row>
                                                <Col>Sub-event fee :</Col>

                                                <Col>$ {subEventFee}</Col>
                                              </Row>
                                            </ListGroup.Item>
                                          ) : null}

                                          {hotels && hotels.length !== 0 ? (
                                            <ListGroup.Item>
                                              <Row>
                                                <Col>Hotel Rent :</Col>

                                                <Col>$ {hotelRent}</Col>
                                              </Row>
                                            </ListGroup.Item>
                                          ) : null}

                                          <ListGroup.Item>
                                            <Row>
                                              <Col>Extra Contribution :</Col>

                                              <Col>
                                                $ {cartItems.contributionAmount}
                                              </Col>
                                            </Row>
                                          </ListGroup.Item>

                                          <ListGroup.Item>
                                            <Row>
                                              <Col>Total Payment</Col>
                                              <Col>
                                                ${" "}
                                                {totalAmount +
                                                  cartItems.contributionAmount *
                                                    1 +
                                                  hotelRent +
                                                  subEventFee}
                                              </Col>
                                            </Row>
                                          </ListGroup.Item>

                                          {event.adultFee === 0 &&
                                          event.minorFee === 0 &&
                                          cartItems.numberOfAdults !== 0 &&
                                          cartItems.contributionAmount * 1 ===
                                            0 &&
                                          hotelRent === 0 &&
                                          subEventFee === 0 ? (
                                            <Button
                                              type="submit"
                                              variant="info"
                                              block
                                            >
                                              Procced
                                            </Button>
                                          ) : (
                                            <>
                                              <ListGroup.Item>
                                                <Row>
                                                  <Col>
                                                    Please proceed to payment to
                                                    complete the registration
                                                  </Col>
                                                </Row>
                                              </ListGroup.Item>

                                              <ListGroup.Item>
                                                {!sdkReady ? (
                                                  <Loader />
                                                ) : (
                                                  <PayPalButton
                                                    amount={
                                                      totalAmount +
                                                      cartItems.contributionAmount *
                                                        1 +
                                                      hotelRent +
                                                      subEventFee
                                                    }
                                                    onSuccess={
                                                      successPaymentHandler
                                                    }
                                                  />
                                                )}
                                              </ListGroup.Item>
                                            </>
                                          )}
                                        </ListGroup>
                                      </>
                                    ) : (
                                      <>
                                        {/* event type = donation or fund-raising > successPaymentHandler */}
                                        <ListGroup variant="flush">
                                          <ListGroup.Item>
                                            <h5 className="text-info">
                                              Summary
                                            </h5>
                                          </ListGroup.Item>

                                          <ListGroup.Item>
                                            <Row>
                                              <Col>Event Name:</Col>
                                              <Col>{event.eventName}</Col>
                                            </Row>
                                          </ListGroup.Item>

                                          <ListGroup.Item>
                                            <Row>
                                              <Col>Total Payment</Col>
                                              <Col>
                                                ${cartItems.contributionAmount}
                                              </Col>
                                            </Row>
                                          </ListGroup.Item>

                                          <ListGroup.Item>
                                            <Row>
                                              <Col>
                                                Please proceed to payment to
                                                complete the donation
                                              </Col>
                                            </Row>
                                          </ListGroup.Item>

                                          <ListGroup.Item>
                                            {!sdkReady ? (
                                              <Loader />
                                            ) : (
                                              <PayPalButton
                                                amount={
                                                  cartItems.contributionAmount
                                                }
                                                onSuccess={
                                                  successPaymentHandler
                                                }
                                              />
                                            )}
                                          </ListGroup.Item>
                                        </ListGroup>
                                      </>
                                    )}
                                  </Card>
                                </Col>
                              </>
                            )
                          ) : null}
                        </Row>
                      </Form>
                    )}
                  </Card.Body>
                </>
              ) : (
                <>
                  <Card.Header className="text-center text-info" as="h5">
                    Registration Complete
                  </Card.Header>
                  <Card.Body>
                    {loading ? (
                      <Loader />
                    ) : error ? (
                      <Message variant="danger">{error}</Message>
                    ) : event ? (
                      event && (
                        <>
                          <Row>
                            <Col md={8} className="m-0 p-1">
                              <Card>
                                <ListGroup variant="flush">
                                  <ListGroup.Item>
                                    <Row>
                                      <Col md={3}>Event Name:</Col>

                                      <Col>{event.eventName}</Col>
                                    </Row>
                                  </ListGroup.Item>

                                  <ListGroup.Item>
                                    <Row>
                                      <Col md={3}>Description:</Col>

                                      <Col>
                                        {parse(`${event.eventDescription}`)}
                                      </Col>
                                    </Row>
                                  </ListGroup.Item>

                                  <ListGroup.Item>
                                    <Row>
                                      <Col md={3}>Date:</Col>
                                      <Col>
                                        <ListGroup variant="flush">
                                          <ListGroup.Item className="p-0">
                                            <Row>
                                              <>
                                                <Col md={4}>Start Date :</Col>
                                                {event.eventDate &&
                                                event.eventDate.length !== 0 &&
                                                event.eventDate[0].value ? (
                                                  <Col>
                                                    {new Date(
                                                      event.eventDate[0].value
                                                    ).toLocaleString()}
                                                  </Col>
                                                ) : null}
                                              </>
                                            </Row>
                                          </ListGroup.Item>

                                          <ListGroup.Item className="p-0">
                                            <Row>
                                              <>
                                                <Col md={4}>End Date :</Col>
                                                {event.eventDate &&
                                                event.eventDate.length !== 0 &&
                                                event.eventDate[1].value ? (
                                                  <Col>
                                                    {new Date(
                                                      event.eventDate[1].value
                                                    ).toLocaleString()}
                                                  </Col>
                                                ) : null}
                                              </>
                                            </Row>
                                          </ListGroup.Item>
                                        </ListGroup>
                                      </Col>
                                    </Row>
                                  </ListGroup.Item>

                                  <ListGroup.Item>
                                    <Row>
                                      <Col md={3}>Event Location:</Col>

                                      <Col>{event.eventAddress}</Col>
                                    </Row>
                                  </ListGroup.Item>

                                  {/* {event.eventType &&
                                  event.eventType !== 'donation' &&
                                  event.eventType !== 'fund-raising' ? (
                                    <>
                                      <ListGroup.Item>
                                        <Row>
                                          <Col md={3}>Adult Fee:</Col>

                                          <Col>$ {event.adultFee}</Col>
                                        </Row>
                                      </ListGroup.Item>

                                      <ListGroup.Item>
                                        <Row>
                                          <Col md={3}>Minor Fee:</Col>

                                          <Col>$ {event.minorFee}</Col>
                                        </Row>
                                      </ListGroup.Item>
                                    </>
                                  ) : null} */}
                                </ListGroup>
                              </Card>
                            </Col>

                            <Col md={4} className="m-0 p-1">
                              <Card>
                                <Card.Header className="text-info text-center">
                                  Contacts:
                                </Card.Header>

                                {eventContactsLoading ? (
                                  <Loader />
                                ) : eventContactsError ? (
                                  <Message variant="danger">
                                    {eventContactsError}
                                  </Message>
                                ) : (
                                  <>
                                    <ListGroup variant="flush">
                                      {eventContacts &&
                                      eventContacts.length !== 0
                                        ? eventContacts.map(
                                            (eventContact, index) => (
                                              <ListGroup.Item key={index}>
                                                <Row>
                                                  <Col md={4}>Position :</Col>
                                                  <Col>
                                                    {eventContact.positionName}
                                                  </Col>
                                                </Row>

                                                <Row>
                                                  <Col md={4}>Name :</Col>
                                                  <Col>
                                                    {eventContact.contactName}
                                                  </Col>
                                                </Row>

                                                <Row>
                                                  <Col md={4}>Email :</Col>
                                                  <Col>
                                                    {eventContact.contactEmail}
                                                  </Col>
                                                </Row>

                                                <Row>
                                                  <Col md={4}>Phone :</Col>
                                                  <Col>
                                                    <a
                                                      href={`tel: ${eventContact.contactPhone}`}
                                                    >
                                                      {
                                                        eventContact.contactPhone
                                                      }
                                                    </a>
                                                  </Col>
                                                </Row>
                                              </ListGroup.Item>
                                            )
                                          )
                                        : null}{" "}
                                    </ListGroup>
                                  </>
                                )}
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )
                    ) : null}
                  </Card.Body>

                  <Card.Header>Registration Summary</Card.Header>
                  <Card.Body>
                    {cartItems && (
                      <Form>
                        <Row>
                          <Col md={8}>
                            <ListGroup variant="flush">
                              <ListGroup.Item>
                                <Row>
                                  <Col md={3}>Member Id / Reference :</Col>
                                  <Col>{cartItems.memberId}</Col>
                                </Row>
                              </ListGroup.Item>

                              <ListGroup.Item>
                                <Row>
                                  <Col md={3}>Name:</Col>
                                  <Col>
                                    {cartItems.mInit} {cartItems.firstName}{" "}
                                    {cartItems.lastName}
                                  </Col>
                                </Row>
                              </ListGroup.Item>

                              <ListGroup.Item>
                                <Row>
                                  <Col md={3}>Contact Details:</Col>
                                  <Col>
                                    <ListGroup variant="flush">
                                      <ListGroup.Item>
                                        <Row>
                                          <Col md={4}>Phone Number :</Col>
                                          <Col>{cartItems.phone}</Col>
                                        </Row>
                                      </ListGroup.Item>

                                      <ListGroup.Item>
                                        <Row>
                                          <Col md={4}>Email :</Col>
                                          <Col>{cartItems.email}</Col>
                                        </Row>
                                      </ListGroup.Item>
                                    </ListGroup>
                                  </Col>
                                </Row>
                              </ListGroup.Item>

                              {event.eventType &&
                              event.eventType !== "donation" &&
                              event.eventType !== "fund-raising" ? (
                                <>
                                  <ListGroup.Item>
                                    <Row>
                                      <Col md={3}>Number Of Attendees:</Col>
                                      <Col>
                                        <ListGroup variant="flush">
                                          {event.eventId === "42141" ? (
                                            <>
                                              <ListGroup.Item>
                                                <Row>
                                                  <Col>
                                                    {cartItems.numberOfAdults >
                                                    0 ? (
                                                      <>
                                                        Full 3-Day Event: per
                                                        person including Grand
                                                        dinner
                                                      </>
                                                    ) : cartItems.numberOfMinors >
                                                      0 ? (
                                                      <>
                                                        Just Grand Dinner with
                                                        Cultural Show
                                                      </>
                                                    ) : (
                                                      <>
                                                        Full 3-Day Event: per
                                                        person including Grand
                                                        dinner: $150
                                                      </>
                                                    )}
                                                  </Col>
                                                </Row>
                                              </ListGroup.Item>

                                              <ListGroup.Item>
                                                <Row>
                                                  <Col>Number of persons:</Col>
                                                  <Col>
                                                    {cartItems.numberOfAdults >
                                                    0
                                                      ? cartItems.numberOfAdults
                                                      : cartItems.numberOfMinors >
                                                        0
                                                      ? cartItems.numberOfMinors
                                                      : cartItems.numberOfAdults}
                                                  </Col>
                                                </Row>
                                              </ListGroup.Item>
                                            </>
                                          ) : (
                                            <>
                                              <ListGroup.Item>
                                                <Row>
                                                  <Col md={3}>Adults :</Col>
                                                  <Col>
                                                    {cartItems.numberOfAdults}
                                                  </Col>
                                                </Row>
                                              </ListGroup.Item>

                                              <ListGroup.Item>
                                                <Row>
                                                  <Col md={3}>Minors :</Col>
                                                  <Col>
                                                    {cartItems.numberOfMinors}
                                                  </Col>
                                                </Row>
                                              </ListGroup.Item>
                                            </>
                                          )}
                                        </ListGroup>
                                      </Col>
                                    </Row>
                                  </ListGroup.Item>
                                </>
                              ) : null}
                            </ListGroup>
                          </Col>
                          <Col md={4}>
                            <Card>
                              <ListGroup variant="flush">
                                <ListGroup.Item>
                                  <h5 className="text-info">Payment Summary</h5>
                                </ListGroup.Item>

                                {eventRegisterSuccess && (
                                  <>
                                    <ListGroup.Item>
                                      <Row>
                                        <Col>Registration Id:</Col>
                                        <Col>
                                          {eventRegisterSuccess.registrationId}
                                        </Col>
                                      </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                      <Row>
                                        <Col>Amount Paid:</Col>
                                        <Col>{eventRegisterSuccess.amount}</Col>
                                      </Row>
                                    </ListGroup.Item>
                                  </>
                                )}
                              </ListGroup>
                            </Card>
                          </Col>
                        </Row>
                      </Form>
                    )}
                    <hr
                      className="border-info"
                      // style={{ b: '50px' }}
                    />

                    {programs && programs.length !== 0 ? (
                      <RegisteredGroup programs={programs} />
                    ) : null}

                    {hotels && hotels.length !== 0 ? (
                      <RegisteredRoom hotels={hotels} />
                    ) : null}
                  </Card.Body>
                </>
              )}
            </>
          )}

          {/* <Card.Footer className='text-muted'>2 days ago</Card.Footer> */}
        </Card>
      </>
    </Container>
  );
};

export default EventRegistrationPayment;
