import React from "react";

import { Button, Card } from "react-bootstrap";

const JobNew = ({ history }) => {
  const newJobPostHandler = (e) => {
    e.preventDefault();

    history.push(`/job/new`);
  };

  return (
    <Card className="mb-2">
      <Card.Header className="text-center" as="h2">
        <Button
          className="btn btn-outline-info btn-sm btn-block rounded font-weight-bold"
          variant="transparent"
          onClick={newJobPostHandler}
        >
          Post New Job
        </Button>
      </Card.Header>
    </Card>
  );
};

export default JobNew;
