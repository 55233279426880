export const JOB_NEW_REQUEST = 'JOB_NEW_REQUEST';
export const JOB_NEW_SUCCESS = 'JOB_NEW_SUCCESS';
export const JOB_NEW_FAIL = 'JOB_NEW_FAIL';
export const JOB_NEW_RESET = 'JOB_NEW_RESET';

export const JOB_ALL_REQUEST = 'JOB_ALL_REQUEST';
export const JOB_ALL_SUCCESS = 'JOB_ALL_SUCCESS';
export const JOB_ALL_FAIL = 'JOB_ALL_FAIL';

export const JOB_BY_ID_REQUEST = 'JOB_BY_ID_REQUEST';
export const JOB_BY_ID_SUCCESS = 'JOB_BY_ID_SUCCESS';
export const JOB_BY_ID_FAIL = 'JOB_BY_ID_FAIL';
export const JOB_BY_ID_RESET = 'JOB_BY_ID_RESET';

export const JOB_UPDATE_BY_ID_REQUEST = 'JOB_UPDATE_BY_ID_REQUEST';
export const JOB_UPDATE_BY_ID_SUCCESS = 'JOB_UPDATE_BY_ID_SUCCESS';
export const JOB_UPDATE_BY_ID_FAIL = 'JOB_UPDATE_BY_ID_FAIL';
export const JOB_UPDATE_BY_ID_RESET = 'JOB_UPDATE_BY_ID_RESET';

export const JOB_DELETE_REQUEST = 'JOB_DELETE_REQUEST';
export const JOB_DELETE_SUCCESS = 'JOB_DELETE_SUCCESS';
export const JOB_DELETE_FAIL = 'JOB_DELETE_FAIL';
export const JOB_DELETE_RESET = 'JOB_DELETE_RESET';
