import axios from 'axios';
import {
  SPONSOR_NEW_FAIL,
  SPONSOR_NEW_REQUEST,
  SPONSOR_NEW_SUCCESS,
  SPONSOR_ALL_FAIL,
  SPONSOR_ALL_REQUEST,
  SPONSOR_ALL_SUCCESS,
  SPONSOR_BY_ID_REQUEST,
  SPONSOR_BY_ID_SUCCESS,
  SPONSOR_BY_ID_FAIL,
  SPONSOR_UPDATE_BY_ID_REQUEST,
  SPONSOR_UPDATE_BY_ID_SUCCESS,
  SPONSOR_UPDATE_BY_ID_FAIL,
  SPONSOR_DELETE_REQUEST,
  SPONSOR_DELETE_SUCCESS,
  SPONSOR_DELETE_FAIL,
} from '../constants/sponsorConstant';

export const newSponsor = (sponsorInfo) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SPONSOR_NEW_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post('/api/sponsor', sponsorInfo, config);

    dispatch({
      type: SPONSOR_NEW_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SPONSOR_NEW_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const allSponsors = (checkChapter) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SPONSOR_ALL_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `/api/sponsor/chapter/${checkChapter}`,

      config
    );

    dispatch({
      type: SPONSOR_ALL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SPONSOR_ALL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getSponsorById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SPONSOR_BY_ID_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(
      `/api/sponsor/${id}`,

      config
    );

    dispatch({
      type: SPONSOR_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SPONSOR_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateSponsorById =
  (sponsorInfo) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SPONSOR_UPDATE_BY_ID_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/sponsor/${sponsorInfo.id}`,
        sponsorInfo,
        config
      );

      dispatch({
        type: SPONSOR_UPDATE_BY_ID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SPONSOR_UPDATE_BY_ID_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteSponsor = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SPONSOR_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/sponsor/${id}`, config);

    dispatch({ type: SPONSOR_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: SPONSOR_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
