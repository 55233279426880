import React from 'react';
import { Card, Col, ListGroup, Row } from 'react-bootstrap';

const RegisteredGroup = ({ programs }) => {
  return (
    <>
      {programs.map((program) => (
        <>
          <Card.Header>
            {program.programName} {program.groupName}
          </Card.Header>
          <Row>
            <Col>
              <ListGroup variant='flush'>
                <ListGroup.Item>
                  <Row>
                    <Col md={3}>Name :</Col>
                    <Col>{program.participantName}</Col>
                  </Row>
                </ListGroup.Item>

                {/* <ListGroup.Item>
                  <Row>
                    <Col md={3}>Age :</Col>
                    <Col>{program.age}</Col>
                  </Row>
                </ListGroup.Item> */}

                <ListGroup.Item>
                  <Row>
                    <Col md={3}>Grade :</Col>
                    <Col>{program.grade}</Col>
                  </Row>
                </ListGroup.Item>

                <ListGroup.Item>
                  <Row>
                    <Col md={3}>School :</Col>
                    <Col>{program.schoolName}</Col>
                  </Row>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
          <hr className='border-info' />
        </>
      ))}
    </>
  );
};

export default RegisteredGroup;
