import React, { useEffect, useState } from 'react';
import { Table, Button, Row, Col, Card, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import Sidebar from '../../components/Sidebar/Sidebar';
import swal from 'sweetalert';
import {
  allMTypes,
  deleteMType,
  getMTypeById,
  newMType,
  updateMTypeById,
} from '../../actions/memberTypeAction';
import {
  MEMBER_TYPE_BY_ID_RESET,
  MEMBER_TYPE_NEW_RESET,
  MEMBER_TYPE_UPDATE_BY_ID_RESET,
} from '../../constants/memberTypeConstant';

const MemberTypeScreen = ({ history }) => {
  const dispatch = useDispatch();

  const checkChapter = window.location.host;

  const [addMemberType, setAddMemberType] = useState(false);
  const [editMemberType, setEditMemberType] = useState(false);

  const [memberType, setMemberType] = useState('');
  const [fee, setFee] = useState('');
  const [description, setDescription] = useState('');
  const [duration, setDuration] = useState(1);

  const memberTypeAll = useSelector((state) => state.memberTypeAll);
  const { loading, error, mTypes } = memberTypeAll;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const memberTypeDelete = useSelector((state) => state.memberTypeDelete);
  const { success: successMTypeDelete } = memberTypeDelete;

  const memberTypeNew = useSelector((state) => state.memberTypeNew);
  const {
    loading: newMTypeLoading,
    error: newMTypeError,
    success,
  } = memberTypeNew;

  const memberTypeById = useSelector((state) => state.memberTypeById);
  const { mType } = memberTypeById;

  const memberTypeUpdate = useSelector((state) => state.memberTypeUpdate);
  const { mType: mTypeUpdated } = memberTypeUpdate;

  useEffect(() => {
    if (userInfo) {
      dispatch(allMTypes(checkChapter));
      dispatch({ type: MEMBER_TYPE_BY_ID_RESET });
      dispatch({ type: MEMBER_TYPE_NEW_RESET });
    } else {
      history.push('/login');
    }
    if (success) {
      setAddMemberType((addMemberType) => !addMemberType);
      setMemberType('');
      setFee('');
      setDescription('');
      setDuration(1);
    }
    if (newMTypeError) {
      swal('Error!', newMTypeError, 'error').then(() => {
        dispatch({ type: MEMBER_TYPE_NEW_RESET });
      });
    }
    if (mTypeUpdated) {
      swal('Success!', mTypeUpdated, 'success').then(() => {
        setAddMemberType(false);
        setEditMemberType(false);
        setMemberType('');
        setFee('');
        setDescription('');
        setDuration(1);
        dispatch({ type: MEMBER_TYPE_UPDATE_BY_ID_RESET });
      });
    }
  }, [
    dispatch,
    history,
    checkChapter,
    userInfo,
    successMTypeDelete,
    newMTypeError,
    success,
    mTypeUpdated,
  ]);

  useEffect(() => {
    if (mType) {
      setAddMemberType(true);
      setMemberType(mType.memberType);
      setFee(mType.fee);
      setDescription(mType.description);
      setDuration(mType.duration);
    }
  }, [mType]);

  const addNewMemberType = (e) => {
    e.preventDefault();

    setAddMemberType(!addMemberType);
    setEditMemberType(false);
    setMemberType('');
    setFee('');
    setDescription('');
    setDuration(1);
  };

  const editMemberTypeHandler = (id) => {
    dispatch(getMTypeById(id));
    setEditMemberType(true);
  };

  const deleteMemberTypeHandler = (id) => {
    if (window.confirm('Are You Sure?')) {
      dispatch(deleteMType(id));
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (editMemberType) {
      dispatch(
        updateMTypeById({
          id: mType.memberTypeId,
          memberType,
          fee,
          description,
          duration,
          checkChapter,
        })
      );
    } else {
      dispatch(
        newMType({ memberType, fee, description, duration, checkChapter })
      );
    }
  };

  console.log(duration);

  return (
    <>
      <Row className='content'>
        {/* Sidebar */}
        <Col
          md={{ span: 3, order: 1 }}
          lg={{ span: 3, order: 1 }}
          id='sidebar-wrapper'
          className='m-0 p-1'
        >
          <Sidebar />
        </Col>
        {/* Sidebar End */}
        <Col
          md={{ span: 9, order: 12 }}
          lg={{ span: 9, order: 12 }}
          id='page-content-wrapper'
          className='m-0 p-0'
        >
          <>
            <Row>
              <Col
                md={{ span: 6, order: 1 }}
                lg={{ span: 6, order: 1 }}
                // style={{ padding: 0 }}
                className='mb-2 p-1'
              >
                <>
                  {/* Card Start */}
                  <Card border='info'>
                    <Card.Header className='text-center' as='h2'>
                      <Button
                        variant='transperent'
                        className='btn btn-outline-info btn-sm btn-block rounded'
                        onClick={addNewMemberType}
                        // to=''
                      >
                        {addMemberType || editMemberType ? (
                          <>Cancel</>
                        ) : (
                          <>Add New Member Type</>
                        )}
                      </Button>
                    </Card.Header>
                    <Card.Body>
                      {addMemberType
                        ? (newMTypeError && (
                            <Message variant='danger'>{newMTypeError}</Message>
                          )) ||
                          (newMTypeLoading && <Loader />) ||
                          (success ? (
                            <Message variant='success'>{success}</Message>
                          ) : (
                            <Form onSubmit={submitHandler}>
                              <Form.Group controlId='mType'>
                                <Form.Label>Member type</Form.Label>
                                <Form.Control
                                  type='text'
                                  placeholder='Please Enter Member Type Name..'
                                  value={memberType}
                                  onChange={(e) =>
                                    setMemberType(e.target.value)
                                  }
                                ></Form.Control>
                              </Form.Group>

                              <Form.Group controlId='fee'>
                                <Form.Label>Fee</Form.Label>
                                <Form.Control
                                  type='number'
                                  placeholder='Please Enter an amount for this Member type fee'
                                  value={fee}
                                  onChange={(e) => setFee(e.target.value)}
                                  min='0'
                                ></Form.Control>
                              </Form.Group>

                              <Form.Group controlId='description'>
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                  type='text'
                                  placeholder='Please Enter Description..'
                                  value={description}
                                  onChange={(e) =>
                                    setDescription(e.target.value)
                                  }
                                ></Form.Control>
                              </Form.Group>

                              <Form.Group controlId='duration'>
                                <Form.Label>Duration Term</Form.Label>
                                <Form.Control
                                  type='number'
                                  placeholder='Please Enter Number of year/s'
                                  value={duration}
                                  onChange={(e) => setDuration(e.target.value)}
                                  min='0'
                                ></Form.Control>
                                <span>
                                  Duration term is the number of years for a
                                  member for a single subscription of that
                                  member type. For example, if a regular
                                  'Member" has a 1 year of active validity upon
                                  one subscription, the duration terms value
                                  will be 1. If a member type has a 5 years of
                                  active validity, the duration term for that
                                  specific member type will me 5. For a{' '}
                                  <strong>life time</strong> member type the
                                  duration term should be 99{' '}
                                </span>
                              </Form.Group>

                              <Button type='submit' variant='info' block>
                                {editMemberType ? (
                                  <>Update</>
                                ) : (
                                  <>
                                    <i className='fas fa-plus' /> Add
                                  </>
                                )}
                              </Button>
                            </Form>
                          ))
                        : null}
                    </Card.Body>
                  </Card>
                  {/* Card End */}
                </>
              </Col>
              {/* 1st card section end~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}

              <Col
                md={{ span: 6, order: 12 }}
                lg={{ span: 6, order: 12 }}
                className='mb-2 p-1'
                id='all-chapter'
              >
                <Card className='text-center' border='info'>
                  <Card.Header as='h5' className='text-info'>
                    All Member Types
                  </Card.Header>

                  <Card.Body>
                    {loading ? (
                      <Loader />
                    ) : error ? (
                      <Message variant='danger'>{error}</Message>
                    ) : (
                      <>
                        {
                          <>
                            <Table
                              striped
                              bordered
                              hover
                              responsive
                              className='table-sm'
                            >
                              <thead>
                                <tr>
                                  {/* <th>ID</th> */}
                                  {/* <th>IMAGE</th> */}
                                  <th>NAME</th>
                                  <th>AMOUNT</th>
                                  <th>TERM</th>
                                  <th>DESCRIPTION</th>

                                  {userInfo &&
                                    (userInfo.userRole === 'systemAdmin' ||
                                      userInfo.userRole === 'admin') && (
                                      <th>ACTION</th>
                                    )}
                                </tr>
                              </thead>

                              <tbody>
                                {mTypes.map((memberType) => (
                                  <tr key={memberType.paymentTypeId}>
                                    {/* <td>{memberType.paymentTypeId}</td> */}

                                    <td>
                                      {memberType.duration === true ? (
                                        <span className='badge badge-info badge-pill'>
                                          Primary
                                        </span>
                                      ) : null}
                                      {memberType.memberType}
                                    </td>

                                    <td>{memberType.fee}</td>
                                    <td>{memberType.duration} year/s</td>
                                    <td>{memberType.description}</td>

                                    {userInfo &&
                                      (userInfo.userRole === 'systemAdmin' ||
                                        userInfo.userRole === 'admin') && (
                                        <td>
                                          {/* <LinkContainer
                                    to={`/paymentType/${paymentType.paymentTypeId}/edit`}
                                  >
                                    <Button variant='light' className='btn-sm'>
                                      <i className='fas fa-edit'></i>
                                    </Button>
                                  </LinkContainer> */}
                                          <span
                                            onClick={() =>
                                              editMemberTypeHandler(
                                                memberType.memberTypeId
                                              )
                                            }
                                          >
                                            <i
                                              className='far fa-edit action'
                                              style={{
                                                color: '#4285F4',
                                                cursor: 'pointer',
                                              }}
                                            ></i>
                                          </span>

                                          <span
                                            onClick={() =>
                                              deleteMemberTypeHandler(
                                                memberType.memberTypeId
                                              )
                                            }
                                          >
                                            <i
                                              className='fas fa-trash action ml-2'
                                              style={{
                                                color: 'red',
                                                cursor: 'pointer',
                                              }}
                                            ></i>
                                          </span>
                                        </td>
                                      )}
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </>
                        }
                      </>
                    )}
                  </Card.Body>
                </Card>
              </Col>
              {/* 5th card section : All Chapter List End~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}

              {/* </CardColumns> */}
            </Row>
          </>
        </Col>
      </Row>
    </>
  );
};

export default MemberTypeScreen;
