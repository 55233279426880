import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import NewsletterSubscription from "./Newsletter/NewsletterSubscription";

const Footer = () => {
  const chapterBySubDomain = useSelector((state) => state.chapterBySubDomain);
  const { chapterByDomain } = chapterBySubDomain;
  return (
    <footer className="bg-dark">
      {chapterByDomain && chapterByDomain.jobPortal ? (
        <NewsletterSubscription chapterName={chapterByDomain.chapterName} />
      ) : null}
      <Container>
        <Row>
          <Col className="text-center py-3 m-5">
            Copyright &copy; {new Date().getFullYear()} : AABEA. All Rights
            Reserved. Designed and powered by{" "}
            <a href="https://www.goctq.com/">CTQ</a> in collaboration with{" "}
            <a href="https://www.canopus-lab.com/">Canopus-Lab</a>.
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
