export const EVENT_NEW_REQUEST = "EVENT_NEW_REQUEST";
export const EVENT_NEW_SUCCESS = "EVENT_NEW_SUCCESS";
export const EVENT_NEW_FAIL = "EVENT_NEW_FAIL";
export const EVENT_NEW_RESET = "EVENT_NEW_RESET";

export const EVENT_ALL_REQUEST = "EVENT_ALL_REQUEST";
export const EVENT_ALL_SUCCESS = "EVENT_ALL_SUCCESS";
export const EVENT_ALL_FAIL = "EVENT_ALL_FAIL";

export const EVENT_ALL_PAST_REQUEST = "EVENT_ALL_PAST_REQUEST";
export const EVENT_ALL_PAST_SUCCESS = "EVENT_ALL_PAST_SUCCESS";
export const EVENT_ALL_PAST_FAIL = "EVENT_ALL_PAST_FAIL";

export const EVENT_ALL_UPCOMING_REQUEST = "EVENT_ALL_UPCOMING_REQUEST";
export const EVENT_ALL_UPCOMING_SUCCESS = "EVENT_ALL_UPCOMING_SUCCESS";
export const EVENT_ALL_UPCOMING_FAIL = "EVENT_ALL_UPCOMING_FAIL";

export const EVENT_BY_ID_REQUEST = "EVENT_BY_ID_REQUEST";
export const EVENT_BY_ID_SUCCESS = "EVENT_BY_ID_SUCCESS";
export const EVENT_BY_ID_FAIL = "EVENT_BY_ID_FAIL";
export const EVENT_BY_ID_RESET = "EVENT_BY_ID_RESET";

export const EVENT_UPDATE_BY_ID_REQUEST = "EVENT_UPDATE_BY_ID_REQUEST";
export const EVENT_UPDATE_BY_ID_SUCCESS = "EVENT_UPDATE_BY_ID_SUCCESS";
export const EVENT_UPDATE_BY_ID_FAIL = "EVENT_UPDATE_BY_ID_FAIL";
export const EVENT_UPDATE_BY_ID_RESET = "EVENT_UPDATE_BY_ID_RESET";

export const EVENT_DELETE_REQUEST = "EVENT_DELETE_REQUEST";
export const EVENT_DELETE_SUCCESS = "EVENT_DELETE_SUCCESS";
export const EVENT_DELETE_FAIL = "EVENT_DELETE_FAIL";

////////////////////// FLYER DELETE ///////////////////
export const FLYER_DELETE_REQUEST = "FLYER_DELETE_REQUEST";
export const FLYER_DELETE_SUCCESS = "FLYER_DELETE_SUCCESS";
export const FLYER_DELETE_FAIL = "FLYER_DELETE_FAIL";

//////////////////EVENT CONTACT/////////////////////
export const EVENT_CONTACT_NEW_REQUEST = "EVENT_CONTACT_NEW_REQUEST";
export const EVENT_CONTACT_NEW_SUCCESS = "EVENT_CONTACT_NEW_SUCCESS";
export const EVENT_CONTACT_NEW_FAIL = "EVENT_CONTACT_NEW_FAIL";
export const EVENT_CONTACT_NEW_RESET = "EVENT_CONTACT_NEW_RESET";

export const EVENT_CONTACT_ALL_REQUEST = "EVENT_CONTACT_ALL_REQUEST";
export const EVENT_CONTACT_ALL_SUCCESS = "EVENT_CONTACT_ALL_SUCCESS";
export const EVENT_CONTACT_ALL_FAIL = "EVENT_CONTACT_ALL_FAIL";

export const EVENT_CONTACT_BY_ID_REQUEST = "EVENT_CONTACT_BY_ID_REQUEST";
export const EVENT_CONTACT_BY_ID_SUCCESS = "EVENT_CONTACT_BY_ID_SUCCESS";
export const EVENT_CONTACT_BY_ID_FAIL = "EVENT_CONTACT_BY_ID_FAIL";
export const EVENT_CONTACT_BY_ID_RESET = "EVENT_CONTACT_BY_ID_RESET";

export const EVENT_CONTACT_UPDATE_BY_ID_REQUEST =
  "EVENT_CONTACT_UPDATE_BY_ID_REQUEST";
export const EVENT_CONTACT_UPDATE_BY_ID_SUCCESS =
  "EVENT_CONTACT_UPDATE_BY_ID_SUCCESS";
export const EVENT_CONTACT_UPDATE_BY_ID_FAIL =
  "EVENT_CONTACT_UPDATE_BY_ID_FAIL";
export const EVENT_CONTACT_UPDATE_BY_ID_RESET =
  "EVENT_CONTACT_UPDATE_BY_ID_RESET";

export const EVENT_CONTACT_DELETE_REQUEST = "EVENT_CONTACT_DELETE_REQUEST";
export const EVENT_CONTACT_DELETE_SUCCESS = "EVENT_CONTACT_DELETE_SUCCESS";
export const EVENT_CONTACT_DELETE_FAIL = "EVENT_CONTACT_DELETE_FAIL";

// ///////////////EVENT PUBLISH///////////////////
export const EVENT_PUBLISH_REQUEST = "EVENT_PUBLISH_REQUEST";
export const EVENT_PUBLISH_SUCCESS = "EVENT_PUBLISH_SUCCESS";
export const EVENT_PUBLISH_FAIL = "EVENT_PUBLISH_FAIL";
export const EVENT_PUBLISH_RESET = "EVENT_PUBLISH_RESET";

// ///////////////EVENT UNPUBLISH///////////////////
export const EVENT_UNPUBLISH_REQUEST = "EVENT_UNPUBLISH_REQUEST";
export const EVENT_UNPUBLISH_SUCCESS = "EVENT_UNPUBLISH_SUCCESS";
export const EVENT_UNPUBLISH_FAIL = "EVENT_UNPUBLISH_FAIL";
export const EVENT_UNPUBLISH_RESET = "EVENT_UNPUBLISH_RESET";

///////////////////EVENT REGISTRATION/////////////////////////////
export const EVENT_REGISTER_REQUEST = "EVENT_REGISTER_REQUEST";
export const EVENT_REGISTER_SUCCESS = "EVENT_REGISTER_SUCCESS";
export const EVENT_REGISTER_FAIL = "EVENT_REGISTER_FAIL";
export const EVENT_REGISTER_RESET = "EVENT_REGISTER_RESET";

//////////////////EVENT PROGRAM/////////////////////
export const EVENT_PROGRAM_NEW_REQUEST = "EVENT_PROGRAM_NEW_REQUEST";
export const EVENT_PROGRAM_NEW_SUCCESS = "EVENT_PROGRAM_NEW_SUCCESS";
export const EVENT_PROGRAM_NEW_FAIL = "EVENT_PROGRAM_NEW_FAIL";
export const EVENT_PROGRAM_NEW_RESET = "EVENT_PROGRAM_NEW_RESET";

export const EVENT_PROGRAM_ALL_REQUEST = "EVENT_PROGRAM_ALL_REQUEST";
export const EVENT_PROGRAM_ALL_SUCCESS = "EVENT_PROGRAM_ALL_SUCCESS";
export const EVENT_PROGRAM_ALL_FAIL = "EVENT_PROGRAM_ALL_FAIL";

export const EVENT_PROGRAM_BY_ID_REQUEST = "EVENT_PROGRAM_BY_ID_REQUEST";
export const EVENT_PROGRAM_BY_ID_SUCCESS = "EVENT_PROGRAM_BY_ID_SUCCESS";
export const EVENT_PROGRAM_BY_ID_FAIL = "EVENT_PROGRAM_BY_ID_FAIL";
export const EVENT_PROGRAM_BY_ID_RESET = "EVENT_PROGRAM_BY_ID_RESET";

export const EVENT_PROGRAM_UPDATE_BY_ID_REQUEST =
  "EVENT_PROGRAM_UPDATE_BY_ID_REQUEST";
export const EVENT_PROGRAM_UPDATE_BY_ID_SUCCESS =
  "EVENT_PROGRAM_UPDATE_BY_ID_SUCCESS";
export const EVENT_PROGRAM_UPDATE_BY_ID_FAIL =
  "EVENT_PROGRAM_UPDATE_BY_ID_FAIL";
export const EVENT_PROGRAM_UPDATE_BY_ID_RESET =
  "EVENT_PROGRAM_UPDATE_BY_ID_RESET";

export const EVENT_PROGRAM_DELETE_REQUEST = "EVENT_PROGRAM_DELETE_REQUEST";
export const EVENT_PROGRAM_DELETE_SUCCESS = "EVENT_PROGRAM_DELETE_SUCCESS";
export const EVENT_PROGRAM_DELETE_FAIL = "EVENT_PROGRAM_DELETE_FAIL";

//////////////////EVENT PROGRAM GROUP/////////////////////
export const EVENT_GROUP_NEW_REQUEST = "EVENT_GROUP_NEW_REQUEST";
export const EVENT_GROUP_NEW_SUCCESS = "EVENT_GROUP_NEW_SUCCESS";
export const EVENT_GROUP_NEW_FAIL = "EVENT_GROUP_NEW_FAIL";
export const EVENT_GROUP_NEW_RESET = "EVENT_GROUP_NEW_RESET";

export const EVENT_GROUP_ALL_REQUEST = "EVENT_GROUP_ALL_REQUEST";
export const EVENT_GROUP_ALL_SUCCESS = "EVENT_GROUP_ALL_SUCCESS";
export const EVENT_GROUP_ALL_FAIL = "EVENT_GROUP_ALL_FAIL";

export const EVENT_GROUP_BY_ID_REQUEST = "EVENT_GROUP_BY_ID_REQUEST";
export const EVENT_GROUP_BY_ID_SUCCESS = "EVENT_GROUP_BY_ID_SUCCESS";
export const EVENT_GROUP_BY_ID_FAIL = "EVENT_GROUP_BY_ID_FAIL";
export const EVENT_GROUP_BY_ID_RESET = "EVENT_GROUP_BY_ID_RESET";

export const EVENT_GROUP_UPDATE_BY_ID_REQUEST =
  "EVENT_GROUP_UPDATE_BY_ID_REQUEST";
export const EVENT_GROUP_UPDATE_BY_ID_SUCCESS =
  "EVENT_GROUP_UPDATE_BY_ID_SUCCESS";
export const EVENT_GROUP_UPDATE_BY_ID_FAIL = "EVENT_GROUP_UPDATE_BY_ID_FAIL";
export const EVENT_GROUP_UPDATE_BY_ID_RESET = "EVENT_GROUP_UPDATE_BY_ID_RESET";

export const EVENT_GROUP_DELETE_REQUEST = "EVENT_GROUP_DELETE_REQUEST";
export const EVENT_GROUP_DELETE_SUCCESS = "EVENT_GROUP_DELETE_SUCCESS";
export const EVENT_GROUP_DELETE_FAIL = "EVENT_GROUP_DELETE_FAIL";

//////////////////EVENT HOTEL/////////////////////
export const EVENT_HOTEL_NEW_REQUEST = "EVENT_HOTEL_NEW_REQUEST";
export const EVENT_HOTEL_NEW_SUCCESS = "EVENT_HOTEL_NEW_SUCCESS";
export const EVENT_HOTEL_NEW_FAIL = "EVENT_HOTEL_NEW_FAIL";
export const EVENT_HOTEL_NEW_RESET = "EVENT_HOTEL_NEW_RESET";

export const EVENT_HOTEL_ALL_REQUEST = "EVENT_HOTEL_ALL_REQUEST";
export const EVENT_HOTEL_ALL_SUCCESS = "EVENT_HOTEL_ALL_SUCCESS";
export const EVENT_HOTEL_ALL_FAIL = "EVENT_HOTEL_ALL_FAIL";

export const EVENT_HOTEL_BY_ID_REQUEST = "EVENT_HOTEL_BY_ID_REQUEST";
export const EVENT_HOTEL_BY_ID_SUCCESS = "EVENT_HOTEL_BY_ID_SUCCESS";
export const EVENT_HOTEL_BY_ID_FAIL = "EVENT_HOTEL_BY_ID_FAIL";
export const EVENT_HOTEL_BY_ID_RESET = "EVENT_HOTEL_BY_ID_RESET";

export const EVENT_HOTEL_UPDATE_BY_ID_REQUEST =
  "EVENT_HOTEL_UPDATE_BY_ID_REQUEST";
export const EVENT_HOTEL_UPDATE_BY_ID_SUCCESS =
  "EVENT_HOTEL_UPDATE_BY_ID_SUCCESS";
export const EVENT_HOTEL_UPDATE_BY_ID_FAIL = "EVENT_HOTEL_UPDATE_BY_ID_FAIL";
export const EVENT_HOTEL_UPDATE_BY_ID_RESET = "EVENT_HOTEL_UPDATE_BY_ID_RESET";

export const EVENT_HOTEL_DELETE_REQUEST = "EVENT_HOTEL_DELETE_REQUEST";
export const EVENT_HOTEL_DELETE_SUCCESS = "EVENT_HOTEL_DELETE_SUCCESS";
export const EVENT_HOTEL_DELETE_FAIL = "EVENT_HOTEL_DELETE_FAIL";

//////////////////EVENT HOTEL ROOM/////////////////////
export const EVENT_ROOM_NEW_REQUEST = "EVENT_ROOM_NEW_REQUEST";
export const EVENT_ROOM_NEW_SUCCESS = "EVENT_ROOM_NEW_SUCCESS";
export const EVENT_ROOM_NEW_FAIL = "EVENT_ROOM_NEW_FAIL";
export const EVENT_ROOM_NEW_RESET = "EVENT_ROOM_NEW_RESET";

export const EVENT_ROOM_ALL_REQUEST = "EVENT_ROOM_ALL_REQUEST";
export const EVENT_ROOM_ALL_SUCCESS = "EVENT_ROOM_ALL_SUCCESS";
export const EVENT_ROOM_ALL_FAIL = "EVENT_ROOM_ALL_FAIL";

export const EVENT_ROOM_BY_ID_REQUEST = "EVENT_ROOM_BY_ID_REQUEST";
export const EVENT_ROOM_BY_ID_SUCCESS = "EVENT_ROOM_BY_ID_SUCCESS";
export const EVENT_ROOM_BY_ID_FAIL = "EVENT_ROOM_BY_ID_FAIL";
export const EVENT_ROOM_BY_ID_RESET = "EVENT_ROOM_BY_ID_RESET";

export const EVENT_ROOM_UPDATE_BY_ID_REQUEST =
  "EVENT_ROOM_UPDATE_BY_ID_REQUEST";
export const EVENT_ROOM_UPDATE_BY_ID_SUCCESS =
  "EVENT_ROOM_UPDATE_BY_ID_SUCCESS";
export const EVENT_ROOM_UPDATE_BY_ID_FAIL = "EVENT_ROOM_UPDATE_BY_ID_FAIL";
export const EVENT_ROOM_UPDATE_BY_ID_RESET = "EVENT_ROOM_UPDATE_BY_ID_RESET";

export const EVENT_ROOM_DELETE_REQUEST = "EVENT_ROOM_DELETE_REQUEST";
export const EVENT_ROOM_DELETE_SUCCESS = "EVENT_ROOM_DELETE_SUCCESS";
export const EVENT_ROOM_DELETE_FAIL = "EVENT_ROOM_DELETE_FAIL";
