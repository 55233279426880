import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { allAdv } from '../../../actions/advAction';
import Loader from '../../Loader';
import Message from '../../Message';
import VideoPlayer from './VideoPlayer';

const VideoPlayerBoxHome = () => {
  const dispatch = useDispatch();

  const advAll = useSelector((state) => state.advAll);
  const { loading, error, advs, advType } = advAll;

  // console.log(image);
  const checkChapter = window.location.host;

  useEffect(() => {
    dispatch(allAdv({ checkChapter, advType: 'home-video-box', active: true }));
  }, [dispatch, checkChapter]);
  // console.log(advs);
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        advs &&
        advs.length !== 0 &&
        advType === 'home-video-box' && (
          <>
            <VideoPlayer adv={advs[0].image} play={true} />
          </>
        )
      )}
    </>
  );
};

export default VideoPlayerBoxHome;
