import React from 'react';
import { Card, Col, ListGroup, Row } from 'react-bootstrap';

const RegisteredRoom = ({ hotels }) => {
  return (
    <>
      {hotels.map((room, index) => (
        <>
          <Card.Header key={index} className='bg-info text-light'>
            Hotel Name: {room.hotelName}, Date: {room.roomType}
          </Card.Header>
          <Row>
            <Col>
              <ListGroup variant='flush'>
                <ListGroup.Item>
                  <Row>
                    <Col md={3}>Description :</Col>
                    <Col>{room.roomDescription}</Col>
                  </Row>
                </ListGroup.Item>

                <ListGroup.Item>
                  <Row>
                    <Col md={3}>Accomodation :</Col>
                    <Col>{room.accomodation}</Col>
                  </Row>
                </ListGroup.Item>

                <ListGroup.Item>
                  <Row>
                    <Col md={3}>Rent :</Col>
                    <Col>{room.rent}</Col>
                  </Row>
                </ListGroup.Item>

                <ListGroup.Item>
                  <Row>
                    <Col md={3}>Number of room/s :</Col>
                    <Col>{room.qty}</Col>
                  </Row>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
          <hr className='border-info' />
        </>
      ))}
    </>
  );
};

export default RegisteredRoom;
