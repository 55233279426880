import React, { useState } from 'react';
import {
  Col,
  Form,
  ListGroup,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

const EditSession = ({ sessionId, tenure, active, updateSession }) => {
  const [show, setShow] = useState(false);
  const [tenureFrom, setTenureFrom] = useState('');
  const [tenureTo, setTenureTo] = useState('');
  const [current, setCurrent] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setTenureFrom(tenure.split('-')[0]);
    setTenureTo(tenure.split('-')[1]);
    setCurrent(active);
    setShow(true);
  };

  return (
    <>
      <OverlayTrigger
        overlay={
          <Tooltip id='tooltip-disabled'>Edit Committee Session</Tooltip>
        }
      >
        <span onClick={handleShow}>
          <i
            className='far fa-edit action'
            style={{
              color: '#4285F4',
              cursor: 'pointer',
            }}
            // style={{ cursor: 'pointer' }}
          ></i>
        </span>
      </OverlayTrigger>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        size='lg'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className='text-center text-info'>
            Edit Session
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={{ span: 10 }}>
              <ListGroup variant='flush'>
                <ListGroup.Item>
                  <Form.Row>
                    <Form.Group as={Col} md='2'>
                      <Form.Label>Tenure :</Form.Label>
                    </Form.Group>
                    <Col>
                      <Row>
                        <Form.Group as={Col} controlId='tenureFrom'>
                          <Form.Label>From: </Form.Label>
                          <Form.Control
                            required
                            type='number'
                            value={tenureFrom}
                            onChange={(e) => setTenureFrom(e.target.value)}
                          ></Form.Control>
                        </Form.Group>

                        <Form.Group as={Col} controlId='tenureTo'>
                          <Form.Label>To: </Form.Label>
                          <Form.Control
                            required
                            type='number'
                            value={tenureTo}
                            onChange={(e) => setTenureTo(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                      </Row>
                    </Col>
                  </Form.Row>
                </ListGroup.Item>

                <ListGroup.Item>
                  <Form.Row>
                    <Form.Group as={Col} md='6'>
                      <Form.Label>Set as active Committee Session ?</Form.Label>
                    </Form.Group>
                    <Col>
                      <Form.Control
                        required
                        as='select'
                        type='text'
                        value={current}
                        onChange={(e) => setCurrent(e.target.value)}
                      >
                        <option value='false'>No</option>
                        <option value='true'>Yes</option>
                      </Form.Control>
                    </Col>
                  </Form.Row>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Link
            className='btn btn-outline-danger btn-sm rounded'
            onClick={handleClose}
            to='#'
          >
            Cancel
          </Link>

          <Link
            className='btn btn-outline-info btn-sm rounded'
            onClick={() => {
              const updatedTenure = tenureFrom + '-' + tenureTo;
              updateSession(sessionId, updatedTenure, current);
              handleClose();
            }}
            to='#'
          >
            Update
          </Link>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditSession;
