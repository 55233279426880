export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGIN_RESET = 'USER_LOGIN_RESET';
export const USER_LOGOUT = 'USER_LOGOUT';

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';
export const USER_REGISTER_RESET = 'USER_REGISTER_RESET';

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST';
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL';
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET';

export const USER_DETAILS_BY_ID_REQUEST = 'USER_DETAILS_BY_ID_REQUEST';
export const USER_DETAILS_BY_ID_SUCCESS = 'USER_DETAILS_BY_ID_SUCCESS';
export const USER_DETAILS_BY_ID_FAIL = 'USER_DETAILS_BY_ID_FAIL';
export const USER_DETAILS_BY_ID_RESET = 'USER_DETAILS_BY_ID_RESET';

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST';
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS';
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL';
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET';

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_FAIL = 'USER_LIST_FAIL';
export const USER_LIST_RESET = 'USER_LIST_RESET';

export const USER_ALL_LIST_REQUEST = 'USER_ALL_LIST_REQUEST';
export const USER_ALL_LIST_SUCCESS = 'USER_ALL_LIST_SUCCESS';
export const USER_ALL_LIST_FAIL = 'USER_ALL_LIST_FAIL';
export const USER_ALL_LIST_RESET = 'USER_ALL_LIST_RESET';

export const ADMIN_LIST = 'ADMIN_LIST';

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL';
export const USER_DELETE_RESET = 'USER_DELETE_RESET';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL';
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET';

export const USER_PAYMENT_DETAILS_REQUEST = 'USER_PAYMENT_DETAILS_REQUEST';
export const USER_PAYMENT_DETAILS_SUCCESS = 'USER_PAYMENT_DETAILS_SUCCESS';
export const USER_PAYMENT_DETAILS_FAIL = 'USER_PAYMENT_DETAILS_FAIL';
export const USER_PAYMENT_DETAILS_RESET = 'USER_PAYMENT_DETAILS_RESET';

export const USER_PAY_REQUEST = 'USER_PAY_REQUEST';
export const USER_PAY_SUCCESS = 'USER_PAY_SUCCESS';
export const USER_PAY_FAIL = 'USER_PAY_FAIL';
export const USER_PAY_RESET = 'USER_PAY_RESET';

export const USER_EMAIL_VERIFY_REQUEST = 'USER_EMAIL_VERIFY_REQUEST';
export const USER_EMAIL_VERIFY_SUCCESS = 'USER_EMAIL_VERIFY_SUCCESS';
export const USER_EMAIL_VERIFY_FAIL = 'USER_EMAIL_VERIFY_FAIL';
export const USER_EMAIL_VERIFY_RESET = 'USER_EMAIL_VERIFY_RESET';

export const USER_VERIFY_EMAIL_RESEND_REQUEST =
  'USER_VERIFY_EMAIL_RESEND_REQUEST';
export const USER_VERIFY_EMAIL_RESEND_SUCCESS =
  'USER_VERIFY_EMAIL_RESEND_SUCCESS';
export const USER_VERIFY_EMAIL_RESEND_FAIL = 'USER_VERIFY_EMAIL_RESEND_FAIL';
export const USER_VERIFY_EMAIL_RESEND_RESET = 'USER_VERIFY_EMAIL_RESEND_RESET';

export const USER_DONATE_REQUEST = 'USER_DONATE_REQUEST';
export const USER_DONATE_SUCCESS = 'USER_DONATE_SUCCESS';
export const USER_DONATE_FAIL = 'USER_DONATE_FAIL';
export const USER_DONATE_RESET = 'USER_DONATE_RESET';

export const USER_DONATION_DETAILS_REQUEST = 'USER_DONATION_DETAILS_REQUEST';
export const USER_DONATION_DETAILS_SUCCESS = 'USER_DONATION_DETAILS_SUCCESS';
export const USER_DONATION_DETAILS_FAIL = 'USER_DONATION_DETAILS_FAIL';
export const USER_DONATION_DETAILS_RESET = 'USER_DONATION_DETAILS_RESET';

export const USER_PENDING_LIST_REQUEST = 'USER_PENDING_LIST_REQUEST';
export const USER_PENDING_LIST_SUCCESS = 'USER_PENDING_LIST_SUCCESS';
export const USER_PENDING_LIST_FAIL = 'USER_PENDING_LIST_FAIL';
export const USER_PENDING_LIST_RESET = 'USER_PENDING_LIST_RESET';

export const USER_PENDING_DETAILS_REQUEST = 'USER_PENDING_DETAILS_REQUEST';
export const USER_PENDING_DETAILS_SUCCESS = 'USER_PENDING_DETAILS_SUCCESS';
export const USER_PENDING_DETAILS_FAIL = 'USER_PENDING_DETAILS_FAIL';
export const USER_PENDING_DETAILS_RESET = 'USER_PENDING_DETAILS_RESET';

export const USER_PENDING_DELETE_REQUEST = 'USER_PENDING_DELETE_REQUEST';
export const USER_PENDING_DELETE_SUCCESS = 'USER_PENDING_DELETE_SUCCESS';
export const USER_PENDING_DELETE_FAIL = 'USER_PENDING_DELETE_FAIL';
export const USER_PENDING_DELETE_RESET = 'USER_PENDING_DELETE_RESET';

export const USER_APPROVE_REQUEST = 'USER_APPROVE_REQUEST';
export const USER_APPROVE_SUCCESS = 'USER_APPROVE_SUCCESS';
export const USER_APPROVE_FAIL = 'USER_APPROVE_FAIL';
export const USER_APPROVE_RESET = 'USER_APPROVE_RESET';

export const USER_CREATE_ADMIN_REQUEST = 'USER_CREATE_ADMIN_REQUEST';
export const USER_CREATE_ADMIN_SUCCESS = 'USER_CREATE_ADMIN_SUCCESS';
export const USER_CREATE_ADMIN_FAIL = 'USER_CREATE_ADMIN_FAIL';
export const USER_CREATE_ADMIN_RESET = 'USER_CREATE_ADMIN_RESET';

export const USER_DELETE_ADMIN_REQUEST = 'USER_DELETE_ADMIN_REQUEST';
export const USER_DELETE_ADMIN_SUCCESS = 'USER_DELETE_ADMIN_SUCCESS';
export const USER_DELETE_ADMIN_FAIL = 'USER_DELETE_ADMIN_FAIL';
export const USER_DELETE_ADMIN_RESET = 'USER_DELETE_ADMIN_RESET';

export const USER_PASSWORD_RESET_REQUEST = 'USER_PASSWORD_RESET_REQUEST';
export const USER_PASSWORD_RESET_SUCCESS = 'USER_PASSWORD_RESET_SUCCESS';
export const USER_PASSWORD_RESET_FAIL = 'USER_PASSWORD_RESET_FAIL';
export const USER_PASSWORD_RESET_RESET = 'USER_PASSWORD_RESET_RESET';

export const USER_PASSWORD_UPDATE_REQUEST = 'USER_PASSWORD_UPDATE_REQUEST';
export const USER_PASSWORD_UPDATE_SUCCESS = 'USER_PASSWORD_UPDATE_SUCCESS';
export const USER_PASSWORD_UPDATE_FAIL = 'USER_PASSWORD_UPDATE_FAIL';
export const USER_PASSWORD_UPDATE_RESET = 'USER_PASSWORD_UPDATE_RESET';

export const USER_ADD_REQUEST = 'USER_ADD_REQUEST';
export const USER_ADD_SUCCESS = 'USER_ADD_SUCCESS';
export const USER_ADD_FAIL = 'USER_ADD_FAIL';
export const USER_ADD_RESET = 'USER_ADD_RESET';
