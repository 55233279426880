export const CHAPTER_REGISTER_REQUEST = 'CHAPTER_REGISTER_REQUEST';
export const CHAPTER_REGISTER_SUCCESS = 'CHAPTER_REGISTER_SUCCESS';
export const CHAPTER_REGISTER_FAIL = 'CHAPTER_REGISTER_FAIL';
export const CHAPTER_REGISTER_RESET = 'CHAPTER_REGISTER_RESET';

export const CHAPTER_LIST_REQUEST = 'CHAPTER_LIST_REQUEST';
export const CHAPTER_LIST_SUCCESS = 'CHAPTER_LIST_SUCCESS';
export const CHAPTER_LIST_FAIL = 'CHAPTER_LIST_FAIL';
export const CHAPTER_LIST_RESET = 'CHAPTER_LIST_RESET';

export const CHAPTER_DETAILS_REQUEST = 'CHAPTER_DETAILS_REQUEST';
export const CHAPTER_DETAILS_SUCCESS = 'CHAPTER_DETAILS_SUCCESS';
export const CHAPTER_DETAILS_FAIL = 'CHAPTER_DETAILS_FAIL';
export const CHAPTER_DETAILS_RESET = 'CHAPTER_DETAILS_RESET';

export const CHAPTER_UPDATE_REQUEST = 'CHAPTER_UPDATE_REQUEST';
export const CHAPTER_UPDATE_SUCCESS = 'CHAPTER_UPDATE_SUCCESS';
export const CHAPTER_UPDATE_FAIL = 'CHAPTER_UPDATE_FAIL';
export const CHAPTER_UPDATE_RESET = 'CHAPTER_UPDATE_RESET';

export const CHAPTER_DELETE_REQUEST = 'CHAPTER_DELETE_REQUEST';
export const CHAPTER_DELETE_SUCCESS = 'CHAPTER_DELETE_SUCCESS';
export const CHAPTER_DELETE_FAIL = 'CHAPTER_DELETE_FAIL';

////////////////////////Chapter Settings////////////////////////////////////////////

export const CHAPTER_SETTINGS_NEW_REQUEST = 'CHAPTER_SETTINGS_NEW_REQUEST';
export const CHAPTER_SETTINGS_NEW_SUCCESS = 'CHAPTER_SETTINGS_NEW_SUCCESS';
export const CHAPTER_SETTINGS_NEW_FAIL = 'CHAPTER_SETTINGS_NEW_FAIL';
export const CHAPTER_SETTINGS_NEW_RESET = 'CHAPTER_SETTINGS_NEW_RESET';

export const CHAPTER_SETTINGS_REQUEST = 'CHAPTER_SETTINGS_REQUEST';
export const CHAPTER_SETTINGS_SUCCESS = 'CHAPTER_SETTINGS_SUCCESS';
export const CHAPTER_SETTINGS_FAIL = 'CHAPTER_SETTINGS_FAIL';
export const CHAPTER_SETTINGS_RESET = 'CHAPTER_SETTINGS_RESET';

export const CHAPTER_SETTINGS_UPDATE_REQUEST =
  'CHAPTER_SETTINGS_UPDATE_REQUEST';
export const CHAPTER_SETTINGS_UPDATE_SUCCESS =
  'CHAPTER_SETTINGS_UPDATE_SUCCESS';
export const CHAPTER_SETTINGS_UPDATE_FAIL = 'CHAPTER_SETTINGS_UPDATE_FAIL';
export const CHAPTER_SETTINGS_UPDATE_RESET = 'CHAPTER_SETTINGS_UPDATE_RESET';

///////////////////////////////Chapter By Subdomain///////////////////////////
export const CHAPTER_BY_SUBDOMAIN_REQUEST = 'CHAPTER_BY_SUBDOMAIN_REQUEST';
export const CHAPTER_BY_SUBDOMAIN_SUCCESS = 'CHAPTER_BY_SUBDOMAIN_SUCCESS';
export const CHAPTER_BY_SUBDOMAIN_FAIL = 'CHAPTER_BY_SUBDOMAIN_FAIL';
export const CHAPTER_BY_SUBDOMAIN_RESET = 'CHAPTER_BY_SUBDOMAIN_RESET';

///////////////////////////////Chapter Payments///////////////////////////
export const CHAPTER_PAYMENTS_REQUEST = 'CHAPTER_PAYMENTS_REQUEST';
export const CHAPTER_PAYMENTS_SUCCESS = 'CHAPTER_PAYMENTS_SUCCESS';
export const CHAPTER_PAYMENTS_FAIL = 'CHAPTER_PAYMENTS_FAIL';
export const CHAPTER_PAYMENTS_RESET = 'CHAPTER_PAYMENTS_RESET';

///////////////////////////////Chapter Donations///////////////////////////
export const CHAPTER_DONATIONS_REQUEST = 'CHAPTER_DONATIONS_REQUEST';
export const CHAPTER_DONATIONS_SUCCESS = 'CHAPTER_DONATIONS_SUCCESS';
export const CHAPTER_DONATIONS_FAIL = 'CHAPTER_DONATIONS_FAIL';
export const CHAPTER_DONATIONS_RESET = 'CHAPTER_DONATIONS_RESET';
