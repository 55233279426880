import React, { useEffect } from 'react';

const EmbedVideo = () => {
  useEffect(() => {
    let script = document.createElement('script');

    script.src = document.querySelectorAll('oembed[url]').forEach((element) => {
      // Create the <a href="..." class="embedly-card"></a> element that Embedly uses
      // to discover the media.
      const anchor = document.createElement('a');

      anchor.setAttribute('href', element.getAttribute('url'));
      anchor.className = 'embedly-card';

      element.appendChild(anchor);
    });
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div></div>;
};

export default EmbedVideo;
