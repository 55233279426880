import React from 'react';
import { Route } from 'react-router-dom';

import { Col, Row } from 'react-bootstrap';

import SearchBox from '../../components/SearchBox/SearchBox';
import Blogs from '../../components/Blogs/Blogs';
import BlogNew from '../../components/Blogs/BlogNew';

const BlogScreen = () => {
  return (
    <>
      <Row className='content'>
        <Col md={{ span: 8, order: 1 }} className='m-0 p-1'>
          <Route render={({ match }) => <Blogs match={match} />} />
        </Col>

        <Col md={{ span: 4, order: 12 }} className='m-0 p-1'>
          <Route render={({ history }) => <BlogNew history={history} />} />

          <>
            {/* <!-- Blog Search Well --> */}
            <Route render={({ history }) => <SearchBox history={history} />} />
          </>
        </Col>
      </Row>
    </>
  );
};

export default BlogScreen;
