import {
  BOD_FAIL,
  BOD_REQUEST,
  BOD_SUCCESS,
  COMMITTEE_MEMBER_ALL_FAIL,
  COMMITTEE_MEMBER_ALL_REQUEST,
  COMMITTEE_MEMBER_ALL_SUCCESS,
  COMMITTEE_MEMBER_BY_ID_FAIL,
  COMMITTEE_MEMBER_BY_ID_REQUEST,
  COMMITTEE_MEMBER_BY_ID_RESET,
  COMMITTEE_MEMBER_BY_ID_SUCCESS,
  COMMITTEE_MEMBER_DELETE_FAIL,
  COMMITTEE_MEMBER_DELETE_REQUEST,
  COMMITTEE_MEMBER_DELETE_RESET,
  COMMITTEE_MEMBER_DELETE_SUCCESS,
  COMMITTEE_MEMBER_NEW_FAIL,
  COMMITTEE_MEMBER_NEW_REQUEST,
  COMMITTEE_MEMBER_NEW_RESET,
  COMMITTEE_MEMBER_NEW_SUCCESS,
  COMMITTEE_MEMBER_UPDATE_BY_ID_FAIL,
  COMMITTEE_MEMBER_UPDATE_BY_ID_REQUEST,
  COMMITTEE_MEMBER_UPDATE_BY_ID_RESET,
  COMMITTEE_MEMBER_UPDATE_BY_ID_SUCCESS,
  COMMITTEE_SESSION_BY_ID_FAIL,
  COMMITTEE_SESSION_BY_ID_REQUEST,
  COMMITTEE_SESSION_BY_ID_RESET,
  COMMITTEE_SESSION_BY_ID_SUCCESS,
  COMMITTEE_SESSION_DELETE_FAIL,
  COMMITTEE_SESSION_DELETE_REQUEST,
  COMMITTEE_SESSION_DELETE_RESET,
  COMMITTEE_SESSION_DELETE_SUCCESS,
  COMMITTEE_SESSION_NEW_FAIL,
  COMMITTEE_SESSION_NEW_REQUEST,
  COMMITTEE_SESSION_NEW_RESET,
  COMMITTEE_SESSION_NEW_SUCCESS,
  COMMITTEE_SESSION_UPDATE_BY_ID_FAIL,
  COMMITTEE_SESSION_UPDATE_BY_ID_REQUEST,
  COMMITTEE_SESSION_UPDATE_BY_ID_RESET,
  COMMITTEE_SESSION_UPDATE_BY_ID_SUCCESS,
} from '../constants/committeeConstants';

export const cMemberNewReducer = (state = {}, action) => {
  switch (action.type) {
    case COMMITTEE_MEMBER_NEW_REQUEST:
      return { loading: true };
    case COMMITTEE_MEMBER_NEW_SUCCESS:
      return { loading: false, success: action.payload };
    case COMMITTEE_MEMBER_NEW_FAIL:
      return { loading: false, error: action.payload };
    case COMMITTEE_MEMBER_NEW_RESET:
      return {};
    default:
      return state;
  }
};

export const cMemberAllReducer = (state = { cMembers: [] }, action) => {
  switch (action.type) {
    case COMMITTEE_MEMBER_ALL_REQUEST:
      return { loading: true };
    case COMMITTEE_MEMBER_ALL_SUCCESS:
      return { loading: false, cMembers: action.payload };
    case COMMITTEE_MEMBER_ALL_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const cMemberByIdReducer = (state = { cMember: {} }, action) => {
  switch (action.type) {
    case COMMITTEE_MEMBER_BY_ID_REQUEST:
      return { ...state, loading: true };
    case COMMITTEE_MEMBER_BY_ID_SUCCESS:
      return { loading: false, cMember: action.payload };
    case COMMITTEE_MEMBER_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    case COMMITTEE_MEMBER_BY_ID_RESET:
      return {};
    default:
      return state;
  }
};

export const cMemberUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case COMMITTEE_MEMBER_UPDATE_BY_ID_REQUEST:
      return { loading: true };
    case COMMITTEE_MEMBER_UPDATE_BY_ID_SUCCESS:
      return { loading: false, success: action.payload };
    case COMMITTEE_MEMBER_UPDATE_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    case COMMITTEE_MEMBER_UPDATE_BY_ID_RESET:
      return {};
    default:
      return state;
  }
};

export const cMemberDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case COMMITTEE_MEMBER_DELETE_REQUEST:
      return { loading: true };
    case COMMITTEE_MEMBER_DELETE_SUCCESS:
      return { loading: false, success: action.payload };
    case COMMITTEE_MEMBER_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case COMMITTEE_MEMBER_DELETE_RESET:
      return {};

    default:
      return state;
  }
};

// ////////////////////// SESSION ////////////////////////////////

export const sessionNewReducer = (state = {}, action) => {
  switch (action.type) {
    case COMMITTEE_SESSION_NEW_REQUEST:
      return { loading: true };
    case COMMITTEE_SESSION_NEW_SUCCESS:
      return { loading: false, success: action.payload };
    case COMMITTEE_SESSION_NEW_FAIL:
      return { loading: false, error: action.payload };
    case COMMITTEE_SESSION_NEW_RESET:
      return {};
    default:
      return state;
  }
};

export const sessionByIdReducer = (state = { session: {} }, action) => {
  switch (action.type) {
    case COMMITTEE_SESSION_BY_ID_REQUEST:
      return { ...state, loading: true };
    case COMMITTEE_SESSION_BY_ID_SUCCESS:
      return { loading: false, session: action.payload };
    case COMMITTEE_SESSION_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    case COMMITTEE_SESSION_BY_ID_RESET:
      return {};
    default:
      return state;
  }
};

export const sessionUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case COMMITTEE_SESSION_UPDATE_BY_ID_REQUEST:
      return { loading: true };
    case COMMITTEE_SESSION_UPDATE_BY_ID_SUCCESS:
      return { loading: false, success: action.payload };
    case COMMITTEE_SESSION_UPDATE_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    case COMMITTEE_SESSION_UPDATE_BY_ID_RESET:
      return {};
    default:
      return state;
  }
};

export const sessionDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case COMMITTEE_SESSION_DELETE_REQUEST:
      return { loading: true };
    case COMMITTEE_SESSION_DELETE_SUCCESS:
      return { loading: false, success: action.payload };
    case COMMITTEE_SESSION_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case COMMITTEE_SESSION_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

///////////////////////// BOD //////////////////////////////////////
export const bodReducer = (state = { bods: [] }, action) => {
  switch (action.type) {
    case BOD_REQUEST:
      return { loading: true };
    case BOD_SUCCESS:
      return { loading: false, bods: action.payload };
    case BOD_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
