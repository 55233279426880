import React, { useEffect } from "react";
import { Col, Row, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import parse from "html-react-parser";
import { allCMembers } from "../../actions/committeeActions";

const HomeExecutiveCom = () => {
  const dispatch = useDispatch();

  const cMemberAll = useSelector((state) => state.cMemberAll);
  const {
    loading: cMemeberLoading,
    error: cMemberError,
    cMembers,
  } = cMemberAll;

  const checkChapter = window.location.host;
  // const subDomain = window.location.host.split('.')[0];

  useEffect(() => {
    dispatch(allCMembers(checkChapter)); // done
  }, [dispatch, checkChapter]);
  return (
    <>
      {cMemeberLoading ? (
        <Loader />
      ) : cMemberError ? (
        <Message variant="danger">{cMemberError}</Message>
      ) : (
        cMembers.length !== 0 &&
        cMembers.map((cMember, index) => (
          <div key={index}>
            {cMember.active ? (
              <>
                <Card.Header className="text-info text-center" as="h4">
                  Executive Committee
                </Card.Header>
                {cMember.committees.map((committee, index) => (
                  <div key={index}>
                    {committee.position === "president" && (
                      <Card className="mb-2">
                        {committee.member.profilePicture && (
                          <Card.Img
                            variant="top"
                            src={committee.member.profilePicture}
                            style={{ width: "100%" }}
                          />
                        )}

                        <Card.Header className="text-info">
                          {committee.position.toUpperCase()}
                          <Card.Title className="text-info">
                            {committee.member.mInit}{" "}
                            {committee.member.firstName}{" "}
                            {committee.member.lastName}
                          </Card.Title>
                        </Card.Header>

                        <Card.Body className="text-justify">
                          {parse(committee.bio.substring(0, 100))}...
                          <Link to={`/committee/${committee.cId}`}>
                            Read more
                          </Link>
                        </Card.Body>
                      </Card>
                    )}
                  </div>
                ))}

                {cMember.committees.map((committee, index) => (
                  <div key={index}>
                    {committee.position === "president-elect" && (
                      <Row xs={{ order: 1 }}>
                        <Col className="m-0 p-0">
                          <Card className="mb-2">
                            {committee.member.profilePicture && (
                              <Card.Img
                                variant="top"
                                src={committee.member.profilePicture}
                                style={{ width: "100%" }}
                              />
                            )}

                            <Card.Header className="text-info">
                              {committee.position.toUpperCase()}
                              <Card.Title className="text-info">
                                {committee.member.mInit}{" "}
                                {committee.member.firstName}{" "}
                                {committee.member.lastName}
                              </Card.Title>
                            </Card.Header>

                            <Card.Body className="text-justify">
                              {parse(committee.bio.substring(0, 100))}...
                              <Link to={`/committee/${committee.cId}`}>
                                Read more
                              </Link>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    )}
                  </div>
                ))}

                {cMember.committees.map((committee, index) => (
                  <div key={index}>
                    {committee.position === "secretary" && (
                      <Card className="mb-2" order="2">
                        {committee.member.profilePicture && (
                          <Card.Img
                            variant="top"
                            src={committee.member.profilePicture}
                            style={{ width: "100%" }}
                          />
                        )}

                        <Card.Header className="text-info">
                          {committee.position.toUpperCase()}
                          <Card.Title className="text-info">
                            {committee.member.mInit}{" "}
                            {committee.member.firstName}{" "}
                            {committee.member.lastName}
                          </Card.Title>
                        </Card.Header>

                        <Card.Body className="text-justify">
                          {parse(committee.bio.substring(0, 100))}...
                          <Link to={`/committee/${committee.cId}`}>
                            Read more
                          </Link>
                        </Card.Body>
                      </Card>
                    )}
                  </div>
                ))}

                {cMember.committees.map((committee, index) => (
                  <div key={index}>
                    {committee.position === "treasurer" && (
                      <Card className="mb-2">
                        {committee.member.profilePicture && (
                          <Card.Img
                            variant="top"
                            src={committee.member.profilePicture}
                            style={{ width: "100%" }}
                          />
                        )}

                        <Card.Header className="text-info">
                          {committee.position.toUpperCase()}
                          <Card.Title className="text-info">
                            {committee.member.mInit}{" "}
                            {committee.member.firstName}{" "}
                            {committee.member.lastName}
                          </Card.Title>
                        </Card.Header>

                        <Card.Body className="text-justify">
                          {parse(committee.bio.substring(0, 100))}...
                          <Link to={`/committee/${committee.cId}`}>
                            Read more
                          </Link>
                        </Card.Body>
                      </Card>
                    )}
                  </div>
                ))}

                {cMember.committees.map((committee, index) => (
                  <div key={index}>
                    {committee.position === "executive-member" && (
                      <Card className="mb-2">
                        {committee.member.profilePicture && (
                          <Card.Img
                            variant="top"
                            src={committee.member.profilePicture}
                            style={{ width: "100%" }}
                          />
                        )}

                        <Card.Header className="text-info">
                          {committee.position.toUpperCase()}
                          <Card.Title className="text-info">
                            {committee.member.mInit}{" "}
                            {committee.member.firstName}{" "}
                            {committee.member.lastName}
                          </Card.Title>
                        </Card.Header>

                        <Card.Body className="text-justify">
                          {parse(committee.bio.substring(0, 100))}...
                          <Link to={`/committee/${committee.cId}`}>
                            Read more
                          </Link>
                        </Card.Body>
                      </Card>
                    )}
                  </div>
                ))}
              </>
            ) : null}
          </div>
        ))
      )}
    </>
  );
};

export default HomeExecutiveCom;
