// Payment Types Start
export const DONATION_TYPE_REGISTER_REQUEST = 'DONATION_TYPE_REGISTER_REQUEST';
export const DONATION_TYPE_REGISTER_SUCCESS = 'DONATION_TYPE_REGISTER_SUCCESS';
export const DONATION_TYPE_REGISTER_FAIL = 'DONATION_TYPE_REGISTER_FAIL';
export const DONATION_TYPE_REGISTER_RESET = 'DONATION_TYPE_REGISTER_RESET';

export const DONATION_TYPE_LIST_REQUEST = 'DONATION_TYPE_LIST_REQUEST';
export const DONATION_TYPE_LIST_SUCCESS = 'DONATION_TYPE_LIST_SUCCESS';
export const DONATION_TYPE_LIST_FAIL = 'DONATION_TYPE_LIST_FAIL';
export const DONATION_TYPE_LIST_RESET = 'DONATION_TYPE_LIST_RESET';

export const DONATION_TYPE_DELETE_REQUEST = 'DONATION_TYPE_DELETE_REQUEST';
export const DONATION_TYPE_DELETE_SUCCESS = 'DONATION_TYPE_DELETE_SUCCESS';
export const DONATION_TYPE_DELETE_FAIL = 'DONATION_TYPE_DELETE_FAIL';
// Payment Types End
