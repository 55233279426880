import React, { useEffect, useState } from "react";

import {
  Form,
  Row,
  Col,
  Card,
  Container,
  ListGroup,
  Button,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../../Message";
import Loader from "../../../Loader";
import { getEventById, registerEvent } from "../../../../actions/eventActions";

import generalPackages from "./2022GeneralPackages.json";
import extraPackage1 from "./2022ExtraPackage1.json";
import boatPackages from "./2022BoatPackages.json";
import hotelPackages from "./Hotels.json";

import PersonNumberInput from "../PersonNumberInput";
import swal from "sweetalert";
import { EVENT_REGISTER_RESET } from "../../../../constants/eventConstants";
import axios from "axios";
import { PayPalButton } from "react-paypal-button-v2";
import { Link } from "react-router-dom";

const getFormattedPrice = (price) => `$${price.toFixed(2)}`;

const CentralEventModReg = ({ match, history }) => {
  const { id } = match.params;

  const [isMember] = useState(false);
  const [programs] = useState([]);
  const [memberId] = useState("");

  const [mInit, setMInit] = useState("Mr");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [numberOfAdults, setNumberOfAdults] = useState(1);
  const [numberOfMinors, setNumberOfMinors] = useState(0);

  const [selectedGeneralPackage, setSelectedGeneralPackage] = useState({
    packageId: "0",
    color: "",
    name: "",
    description: "",
    price: "",
    person: "",
    room: "",
    nights: "",
  });
  const [selectedBoatPackage, setSelectedBoatPackage] = useState({
    packageId: "0",
    color: "",
    name: "",
    description: "",
    price: "",
    person: "",
    room: "",
    nights: "",
  });

  const [selectedExtraPackage1, setSelectedExtraPackage1] = useState({
    packageId: "0",
    color: "",
    name: "",
    description: "",
    price: "",
    person: "",
    room: "",
    nights: "",
  });

  const { packageId, color } = selectedGeneralPackage;

  const [generalCheckedState, setGeneralCheckedState] = useState(
    new Array(generalPackages.length).fill(false)
  );
  const [boatCheckedState, setBoatCheckedState] = useState(
    new Array(boatPackages.length).fill(false)
  );
  const [extra1CheckedState, setExtra1CheckedState] = useState(
    new Array(extraPackage1.length).fill(false)
  );

  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPackageSelected, setTotalPackageSelected] = useState(0);
  const [finalSelection, setFinalSelection] = useState([]);

  const [numberOfGuest] = useState(1);

  const [readyToPay, setReadyToPay] = useState(false);

  // central 2022 specific hotel modificatio
  const [hotels, setHotels] = useState(hotelPackages);
  const [totalPerson, setTotalPerson] = useState(0);
  const [extraHotelCost, setExtraHotelCost] = useState(0);
  const [sdkReady, setSdkReady] = useState(true);

  const dispatch = useDispatch();

  const eventById = useSelector((state) => state.eventById);
  const { loading, error, event } = eventById;

  const eventRegister = useSelector((state) => state.eventRegister);
  const {
    loading: eventRegisterLoading,
    error: eventRegisterError,
    success: eventRegisterSuccess,
  } = eventRegister;

  useEffect(() => {
    if (id === "42141") {
      dispatch(getEventById(id));
    } else {
      history.push("/");
    }
  }, [dispatch, history, id]);

  useEffect(() => {
    hotels.reduce((sum, item, index) => {
      return Number(sum) + Number(item.qty);
    }, 0);

    const totalExtraCost = hotels.reduce((sum, item, index) => {
      return Number(sum) + Number(item.qty) * Number(item.rent);
    }, 0);

    setExtraHotelCost(totalExtraCost);
  }, [hotels]);

  useEffect(() => {
    if (packageId === "6") {
      setNumberOfAdults(numberOfGuest);
      setNumberOfMinors(0);
    }

    if (packageId === "7") {
      setNumberOfAdults(0);
      setNumberOfMinors(numberOfGuest);
    }

    if (
      packageId !== "6" &&
      packageId !== "7" &&
      packageId !== "8" &&
      packageId !== "11" &&
      packageId !== "12" &&
      packageId !== "13"
    ) {
      setNumberOfAdults(totalPerson);
    }
  }, [
    history,
    numberOfGuest,
    packageId,
    selectedGeneralPackage.nights,
    selectedGeneralPackage.room,
    totalPerson,
  ]);

  const submitHandler = (e) => {
    e.preventDefault();

    setReadyToPay(true);
  };

  const handleGeneralPackageChange = (position) => {
    setReadyToPay(false);

    const updatedCheckedState = generalCheckedState.map((item, index) =>
      index === position ? !item : item === true ? !item : item
    );

    setGeneralCheckedState(updatedCheckedState);

    const idx = updatedCheckedState.findIndex(
      (currentState) => currentState === true
    );

    if (idx !== -1) {
      setSelectedGeneralPackage(() => ({
        packageId: generalPackages[idx].packageId,
        color: generalPackages[idx].color,
        name: generalPackages[idx].name,
        description: generalPackages[idx].description,
        price: generalPackages[idx].price,
        person: generalPackages[idx].person,
        room: generalPackages[idx].room,
        nights: generalPackages[idx].nights,
      }));
    } else {
      setSelectedGeneralPackage(() => ({
        packageId: "0",
        color: "",
        name: "",
        description: "",
        price: "",
        person: "",
        room: "",
        nights: "",
      }));
    }
  };

  const handleBoatPackageChange = (position) => {
    setReadyToPay(false);

    const updatedCheckedState = boatCheckedState.map((item, index) =>
      index === position ? !item : item === true ? !item : item
    );

    setBoatCheckedState(updatedCheckedState);

    const idx = updatedCheckedState.findIndex(
      (currentState) => currentState === true
    );

    if (idx !== -1) {
      setSelectedBoatPackage(() => ({
        packageId: boatPackages[idx].packageId,
        color: boatPackages[idx].color,
        name: boatPackages[idx].name,
        description: boatPackages[idx].description,
        price: boatPackages[idx].price,
        person: boatPackages[idx].person,
        room: boatPackages[idx].room,
        nights: generalPackages[idx].nights,
      }));
    } else {
      setSelectedBoatPackage(() => ({
        packageId: "0",
        color: "",
        name: "",
        description: "",
        price: "",
        person: "",
        room: "",
        nights: "",
      }));
    }
  };
  const handleExtra1PackageChange = (position) => {
    setReadyToPay(false);

    const updatedCheckedState = extra1CheckedState.map((item, index) =>
      index === position ? !item : item === true ? !item : item
    );

    setExtra1CheckedState(updatedCheckedState);

    const idx = updatedCheckedState.findIndex(
      (currentState) => currentState === true
    );

    if (idx !== -1) {
      setSelectedExtraPackage1(() => ({
        packageId: extraPackage1[idx].packageId,
        color: extraPackage1[idx].color,
        name: extraPackage1[idx].name,
        description: extraPackage1[idx].description,
        price: extraPackage1[idx].price,
        person: extraPackage1[idx].person,
        room: extraPackage1[idx].room,
        nights: extraPackage1[idx].nights,
      }));
    } else {
      setSelectedExtraPackage1(() => ({
        packageId: "0",
        color: "",
        name: "",
        description: "",
        price: "",
        person: "",
        room: "",
        nights: "",
      }));
    }
  };

  useEffect(() => {
    let final = [];
    if (selectedGeneralPackage.packageId !== "0") {
      final.push(selectedGeneralPackage);
    }

    if (selectedBoatPackage.packageId !== "0") {
      final.push(selectedBoatPackage);
    }
    if (selectedExtraPackage1.packageId !== "0") {
      final.push(selectedExtraPackage1);
    }

    setFinalSelection(final);

    if (final.length !== 0) {
      let packageName = "";
      const totalSelected = final.map((item, index) => {
        if (packageName !== "") {
          return (packageName = ` + ${item.name}`);
        } else {
          return (packageName = `${item.name}`);
        }
      });

      setTotalPackageSelected(totalSelected);
    } else {
      setTotalPackageSelected(["none"]);
    }

    const totalPrice = final.reduce((sum, item, index) => {
      return Number(sum) + Number(item.price);
    }, 0);

    setTotalPrice(totalPrice);

    const totalPerson = final.reduce((sum, item, index) => {
      return Number(sum) + Number(item.person);
    }, 0);

    setTotalPerson(totalPerson);

    if (selectedGeneralPackage.packageId === "0") {
      let temtHotel = [];
      hotels.forEach((item, index) => {
        item.qty = 0;
        temtHotel.push(item);
        return temtHotel;
      });
      setHotels(temtHotel);
    }
  }, [
    selectedGeneralPackage,
    selectedBoatPackage,
    selectedExtraPackage1,
    hotels,
  ]);

  const generalPackageAttendees = (qty) => {
    const originalPrice = generalPackages.find(
      (item) => item.packageId === selectedGeneralPackage.packageId
    );
    setSelectedGeneralPackage((item) => ({
      ...item,
      person: qty,
      price: originalPrice.price * qty,
    }));
  };

  const boatPackageAttendees = (qty) => {
    const originalPrice = boatPackages.find(
      (item) => item.packageId === selectedBoatPackage.packageId
    );
    setSelectedBoatPackage((item) => ({
      ...item,
      person: qty,
      price: originalPrice.price * qty,
    }));
  };
  const extra1PackageAttendees = (qty) => {
    const originalPrice = extraPackage1.find(
      (item) => item.packageId === selectedExtraPackage1.packageId
    );
    setSelectedExtraPackage1((item) => ({
      ...item,
      person: qty,
      price: originalPrice.price * qty,
    }));
  };

  const checkChapter = window.location.host;

  useEffect(() => {
    if (eventRegisterSuccess) {
      swal("Success!", "Registration successfull", "success").then(() => {
        dispatch({ type: EVENT_REGISTER_RESET });
        history.push("/");
      });
    } else if (eventRegisterError) {
      swal("Error!", eventRegisterError, "error");
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const addPaypalScript = async () => {
      const { data: clientId } = await axios.get(
        `/api/chapters/paypal/${checkChapter}`,
        config
      );
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}`;
      script.async = true;
      script.onload = () => {
        setSdkReady(true);
      };
      document.body.appendChild(script);
    };

    if (!window.paypal) {
      addPaypalScript();
    } else {
      setSdkReady(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    history,
    checkChapter,
    eventRegisterSuccess,
    eventRegisterError,
  ]);

  const successPaymentHandler = (paymentResult) => {
    dispatch(
      registerEvent({
        eventId: id,
        eventName: event.eventName,
        mInit,
        firstName,
        lastName,
        isMember,
        memberId,
        email,
        phone,
        numberOfAdults,
        numberOfMinors,
        paymentResult,
        programs,
        hotels: hotels,
        totalAmount: totalPrice,
        subEventFee: 0,
        hotelRent: extraHotelCost,
        contributionAmount: 0,
        selectedPackages: finalSelection,
      })
    );
  };

  return (
    <Container>
      <>
        <Card border="info" className="mt-2">
          <Card.Header className="text-center text-info" as="h5">
            Event Registration
          </Card.Header>
          <Card.Body>
            {eventRegisterLoading ? (
              <Loader />
            ) : (
              <Form onSubmit={submitHandler}>
                <Row>
                  {loading ? (
                    <Loader />
                  ) : error ? (
                    <Message variant="danger">{error}</Message>
                  ) : event ? (
                    event && (
                      <>
                        <Col md={8}>
                          <ListGroup variant="flush">
                            <ListGroup.Item>
                              <Row>
                                <Col md={3} className="text-info p-0" as="h5">
                                  Name{" "}
                                </Col>
                                <Col>
                                  <>
                                    <Row>
                                      <Col md={3}>Title: </Col>
                                      <Col>
                                        <Form.Group controlId="mInit">
                                          <Form.Control
                                            required
                                            as="select"
                                            type="text"
                                            value={mInit}
                                            onChange={(e) =>
                                              setMInit(e.target.value)
                                            }
                                          >
                                            <option value="Mr">Mr</option>
                                            <option value="Mrs">Mrs</option>
                                            <option value="Miss">Ms</option>
                                          </Form.Control>
                                        </Form.Group>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col md={3}>First Name: </Col>
                                      <Col>
                                        <Form.Group controlId="firstName">
                                          <Form.Control
                                            type="firstName"
                                            placeholder="Please Enter Your First Name.."
                                            value={firstName}
                                            onChange={(e) =>
                                              setFirstName(e.target.value)
                                            }
                                          ></Form.Control>
                                        </Form.Group>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col md={3}>Last Name: </Col>
                                      <Col>
                                        <Form.Group controlId="lastName">
                                          <Form.Control
                                            type="lastName"
                                            placeholder="Please Enter Your Last Name.."
                                            value={lastName}
                                            onChange={(e) =>
                                              setLastName(e.target.value)
                                            }
                                          ></Form.Control>
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </>
                                </Col>
                              </Row>
                            </ListGroup.Item>

                            {/* Contact Details */}
                            <ListGroup.Item>
                              <Row>
                                <Col md={3} className="text-info p-0" as="h5">
                                  Contact Details:{" "}
                                </Col>
                                <Col>
                                  <>
                                    <Row>
                                      <Col md={3}>Phone Number :</Col>
                                      <Col>
                                        <Form.Group controlId="phone">
                                          <Form.Control
                                            type="phone"
                                            placeholder="Enter Your Phone Number.."
                                            value={phone}
                                            onChange={(e) =>
                                              setPhone(e.target.value)
                                            }
                                          ></Form.Control>
                                        </Form.Group>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col md={3}>Email :</Col>
                                      <Col>
                                        <Form.Group controlId="email">
                                          <Form.Control
                                            required
                                            type="email"
                                            placeholder="Enter Email.."
                                            value={email}
                                            onChange={(e) =>
                                              setEmail(e.target.value)
                                            }
                                          ></Form.Control>
                                          <Form.Control.Feedback type="invalid">
                                            **Required
                                          </Form.Control.Feedback>
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </>
                                </Col>
                              </Row>
                            </ListGroup.Item>

                            {/* Friday Night */}
                            <ListGroup.Item>
                              <Row>
                                <Col md={3} className="text-info p-0" as="h5">
                                  Friday Night:
                                </Col>
                                <Col>
                                  <Form.Group
                                    controlId="extra1PackageType"
                                    className="m-0 p-0"
                                  >
                                    {extraPackage1 &&
                                      extraPackage1.map((packageType, i) => (
                                        <Form.Check
                                          key={i}
                                          className="m-3"
                                          type="checkbox"
                                          id={`extra1Package-${packageType.packageId}`}
                                        >
                                          <Form.Check.Input
                                            type="checkbox"
                                            name={`extra1Package-${packageType.name}`}
                                            value={packageType.name}
                                            checked={extra1CheckedState[i]}
                                            onChange={() =>
                                              handleExtra1PackageChange(i)
                                            }
                                          />
                                          <Form.Check.Label>
                                            <strong
                                              style={{
                                                color: `${packageType.color}`,
                                              }}
                                            >
                                              {packageType.description}
                                            </strong>
                                            {packageType.packageId ===
                                            "0" ? null : (
                                              <>: ${packageType.price}/person</>
                                            )}
                                          </Form.Check.Label>
                                        </Form.Check>
                                      ))}
                                  </Form.Group>
                                </Col>
                              </Row>

                              {selectedExtraPackage1.packageId === "11" && (
                                <Row>
                                  <Col md={3} className="text-info p-0" as="h5">
                                    Number Of Attendees:
                                  </Col>
                                  <Col md={{ offset: 1 }}>
                                    <PersonNumberInput
                                      person={selectedExtraPackage1.person}
                                      setNumberInput={extra1PackageAttendees}
                                    />
                                  </Col>
                                </Row>
                              )}
                            </ListGroup.Item>

                            <ListGroup.Item>
                              <Row>
                                <Col md={3} className="text-info p-0" as="h5">
                                  Saturday Night:
                                </Col>
                                <Col>
                                  <Form.Group controlId="generalPackageType">
                                    {generalPackages &&
                                      generalPackages.map((packageType, i) => (
                                        <Form.Check
                                          key={i}
                                          className="m-3"
                                          type="checkbox"
                                          id={`generalPackage-${packageType.packageId}`}
                                        >
                                          <Form.Check.Input
                                            type="checkbox"
                                            name={packageType.name}
                                            value={packageType.name}
                                            checked={generalCheckedState[i]}
                                            onChange={() =>
                                              handleGeneralPackageChange(i)
                                            }
                                          />
                                          <Form.Check.Label>
                                            <strong
                                              style={{
                                                color: `${packageType.color}`,
                                              }}
                                            >
                                              {packageType.description}
                                            </strong>
                                            {packageType.packageId ===
                                            "0" ? null : (
                                              <>: ${packageType.price}/person</>
                                            )}
                                          </Form.Check.Label>
                                        </Form.Check>
                                      ))}
                                  </Form.Group>
                                </Col>
                              </Row>

                              {(selectedGeneralPackage.packageId === "6" ||
                                selectedGeneralPackage.packageId === "7") && (
                                <Row>
                                  <Col md={3} className="text-info p-0" as="h5">
                                    Number Of Attendees:
                                  </Col>
                                  <Col md={{ offset: 1 }}>
                                    <PersonNumberInput
                                      person={selectedGeneralPackage.person}
                                      setNumberInput={generalPackageAttendees}
                                    />
                                  </Col>
                                </Row>
                              )}
                            </ListGroup.Item>

                            {/* Boat */}
                            <ListGroup.Item>
                              <Row>
                                <Col md={3} className="text-info p-0" as="h5">
                                  Sunday Cruise:
                                </Col>
                                <Col>
                                  <Form.Group controlId="boatPackageType">
                                    {boatPackages &&
                                      boatPackages.map((packageType, i) => (
                                        <Form.Check
                                          key={i}
                                          className="m-3"
                                          type="checkbox"
                                          id={`boatPackage-${packageType.packageId}`}
                                        >
                                          <Form.Check.Input
                                            type="checkbox"
                                            name={`boatPackage-${packageType.name}`}
                                            value={packageType.name}
                                            checked={boatCheckedState[i]}
                                            onChange={() =>
                                              handleBoatPackageChange(i)
                                            }
                                          />
                                          <Form.Check.Label>
                                            <strong
                                              style={{
                                                color: `${packageType.color}`,
                                              }}
                                            >
                                              {packageType.description}
                                            </strong>
                                            {packageType.packageId ===
                                            "0" ? null : (
                                              <>: ${packageType.price}/person</>
                                            )}
                                          </Form.Check.Label>
                                        </Form.Check>
                                      ))}
                                  </Form.Group>
                                </Col>
                              </Row>

                              {selectedBoatPackage.packageId === "8" && (
                                <Row>
                                  <Col md={3} className="text-info p-0" as="h5">
                                    Number Of Attendees:
                                  </Col>
                                  <Col md={{ offset: 1 }}>
                                    <PersonNumberInput
                                      person={selectedBoatPackage.person}
                                      setNumberInput={boatPackageAttendees}
                                    />
                                  </Col>
                                </Row>
                              )}
                            </ListGroup.Item>

                            <ListGroup.Item>
                              <Row>
                                <Col className="text-info p-0">
                                  <p>
                                    Exclusive 3-Day package available for
                                    Students, ONLY at $100/person.{" "}
                                    <Link to="/event/register/42141">
                                      <a>Click here</a>
                                    </Link>{" "}
                                    to get your student package NOW.
                                  </p>
                                  <p>
                                    For details on other registration options
                                    for the Grand Event{" "}
                                    <Link to="/event/42141">
                                      <a>click here</a>
                                    </Link>
                                    .
                                  </p>
                                </Col>
                              </Row>
                            </ListGroup.Item>
                          </ListGroup>
                        </Col>
                        {/* Summary */}
                        <Col md={4}>
                          <Card>
                            <ListGroup variant="flush">
                              <ListGroup.Item>
                                <h5 className="text-info">Summary</h5>
                              </ListGroup.Item>

                              <ListGroup.Item>
                                <Row>
                                  <Col md={3} className="p-0 m-0">
                                    Event Name:
                                  </Col>
                                  <Col>{event.eventName}</Col>
                                </Row>
                              </ListGroup.Item>

                              <ListGroup.Item>
                                <Row>
                                  <Col>Selected Package/s:</Col>
                                  <Col style={{ color: `${color}` }}>
                                    <strong>{totalPackageSelected}</strong>
                                  </Col>
                                </Row>
                              </ListGroup.Item>

                              <ListGroup.Item>
                                <Row>
                                  <Col>Total No. Of Attendees:</Col>
                                  <Col>
                                    {Number(
                                      Number(totalPerson) -
                                        Number(selectedBoatPackage.person)
                                    )}
                                  </Col>
                                </Row>
                              </ListGroup.Item>

                              <ListGroup.Item>
                                <Row>
                                  <Col>Boat Cruise Attendees :</Col>

                                  <Col>{selectedBoatPackage.person}</Col>
                                </Row>
                              </ListGroup.Item>

                              <ListGroup.Item>
                                <Row>
                                  <Col>Total :</Col>

                                  <Col>
                                    {getFormattedPrice(
                                      totalPrice + extraHotelCost
                                    )}
                                  </Col>
                                </Row>
                              </ListGroup.Item>

                              <ListGroup.Item>
                                <Row>
                                  <Col>
                                    Please proceed to payment to complete the
                                    registration
                                  </Col>
                                </Row>
                              </ListGroup.Item>

                              <ListGroup.Item>
                                {!sdkReady ? (
                                  <Loader />
                                ) : readyToPay ? (
                                  <PayPalButton
                                    amount={
                                      Number(totalPrice) +
                                      Number(extraHotelCost)
                                    }
                                    onSuccess={successPaymentHandler}
                                  />
                                ) : (
                                  // <Button onClick={successPaymentHandler}>
                                  //   test register
                                  // </Button>
                                  // <Button onClick={successPaymentHandler}>
                                  //   test pay
                                  // </Button>
                                  // <Button
                                  //   onClick={successPaymentHandler}
                                  //   variant='info'
                                  //   block
                                  // >
                                  //   Pay check
                                  // </Button>
                                  <Button
                                    type="submit"
                                    variant="info"
                                    disabled={
                                      finalSelection.length === 0 ? true : false
                                    }
                                    block
                                  >
                                    Pay
                                  </Button>
                                )}
                              </ListGroup.Item>
                            </ListGroup>
                          </Card>
                        </Col>
                      </>
                    )
                  ) : null}
                </Row>
              </Form>
            )}
          </Card.Body>
        </Card>
      </>
    </Container>
  );
};

export default CentralEventModReg;
