import {
  EVENT_ALL_FAIL,
  EVENT_ALL_PAST_FAIL,
  EVENT_ALL_PAST_REQUEST,
  EVENT_ALL_PAST_SUCCESS,
  EVENT_ALL_REQUEST,
  EVENT_ALL_SUCCESS,
  EVENT_ALL_UPCOMING_FAIL,
  EVENT_ALL_UPCOMING_REQUEST,
  EVENT_ALL_UPCOMING_SUCCESS,
  EVENT_BY_ID_FAIL,
  EVENT_BY_ID_REQUEST,
  EVENT_BY_ID_RESET,
  EVENT_BY_ID_SUCCESS,
  EVENT_CONTACT_ALL_FAIL,
  EVENT_CONTACT_ALL_REQUEST,
  EVENT_CONTACT_ALL_SUCCESS,
  EVENT_CONTACT_BY_ID_FAIL,
  EVENT_CONTACT_BY_ID_REQUEST,
  EVENT_CONTACT_BY_ID_RESET,
  EVENT_CONTACT_BY_ID_SUCCESS,
  EVENT_CONTACT_DELETE_FAIL,
  EVENT_CONTACT_DELETE_REQUEST,
  EVENT_CONTACT_DELETE_SUCCESS,
  EVENT_CONTACT_NEW_FAIL,
  EVENT_CONTACT_NEW_REQUEST,
  EVENT_CONTACT_NEW_RESET,
  EVENT_CONTACT_NEW_SUCCESS,
  EVENT_CONTACT_UPDATE_BY_ID_FAIL,
  EVENT_CONTACT_UPDATE_BY_ID_REQUEST,
  EVENT_CONTACT_UPDATE_BY_ID_RESET,
  EVENT_CONTACT_UPDATE_BY_ID_SUCCESS,
  EVENT_DELETE_FAIL,
  EVENT_DELETE_REQUEST,
  EVENT_DELETE_SUCCESS,
  EVENT_GROUP_ALL_FAIL,
  EVENT_GROUP_ALL_REQUEST,
  EVENT_GROUP_ALL_SUCCESS,
  EVENT_GROUP_BY_ID_FAIL,
  EVENT_GROUP_BY_ID_REQUEST,
  EVENT_GROUP_BY_ID_RESET,
  EVENT_GROUP_BY_ID_SUCCESS,
  EVENT_GROUP_DELETE_FAIL,
  EVENT_GROUP_DELETE_REQUEST,
  EVENT_GROUP_DELETE_SUCCESS,
  EVENT_GROUP_NEW_FAIL,
  EVENT_GROUP_NEW_REQUEST,
  EVENT_GROUP_NEW_RESET,
  EVENT_GROUP_NEW_SUCCESS,
  EVENT_GROUP_UPDATE_BY_ID_FAIL,
  EVENT_GROUP_UPDATE_BY_ID_REQUEST,
  EVENT_GROUP_UPDATE_BY_ID_RESET,
  EVENT_GROUP_UPDATE_BY_ID_SUCCESS,
  EVENT_HOTEL_ALL_FAIL,
  EVENT_HOTEL_ALL_REQUEST,
  EVENT_HOTEL_ALL_SUCCESS,
  EVENT_HOTEL_BY_ID_FAIL,
  EVENT_HOTEL_BY_ID_REQUEST,
  EVENT_HOTEL_BY_ID_RESET,
  EVENT_HOTEL_BY_ID_SUCCESS,
  EVENT_HOTEL_DELETE_FAIL,
  EVENT_HOTEL_DELETE_REQUEST,
  EVENT_HOTEL_DELETE_SUCCESS,
  EVENT_HOTEL_NEW_FAIL,
  EVENT_HOTEL_NEW_REQUEST,
  EVENT_HOTEL_NEW_RESET,
  EVENT_HOTEL_NEW_SUCCESS,
  EVENT_HOTEL_UPDATE_BY_ID_FAIL,
  EVENT_HOTEL_UPDATE_BY_ID_REQUEST,
  EVENT_HOTEL_UPDATE_BY_ID_RESET,
  EVENT_HOTEL_UPDATE_BY_ID_SUCCESS,
  EVENT_NEW_FAIL,
  EVENT_NEW_REQUEST,
  EVENT_NEW_RESET,
  EVENT_NEW_SUCCESS,
  EVENT_PROGRAM_ALL_FAIL,
  EVENT_PROGRAM_ALL_REQUEST,
  EVENT_PROGRAM_ALL_SUCCESS,
  EVENT_PROGRAM_BY_ID_FAIL,
  EVENT_PROGRAM_BY_ID_REQUEST,
  EVENT_PROGRAM_BY_ID_RESET,
  EVENT_PROGRAM_BY_ID_SUCCESS,
  EVENT_PROGRAM_DELETE_FAIL,
  EVENT_PROGRAM_DELETE_REQUEST,
  EVENT_PROGRAM_DELETE_SUCCESS,
  EVENT_PROGRAM_NEW_FAIL,
  EVENT_PROGRAM_NEW_REQUEST,
  EVENT_PROGRAM_NEW_RESET,
  EVENT_PROGRAM_NEW_SUCCESS,
  EVENT_PROGRAM_UPDATE_BY_ID_FAIL,
  EVENT_PROGRAM_UPDATE_BY_ID_REQUEST,
  EVENT_PROGRAM_UPDATE_BY_ID_RESET,
  EVENT_PROGRAM_UPDATE_BY_ID_SUCCESS,
  EVENT_PUBLISH_FAIL,
  EVENT_PUBLISH_REQUEST,
  EVENT_PUBLISH_RESET,
  EVENT_PUBLISH_SUCCESS,
  EVENT_REGISTER_FAIL,
  EVENT_REGISTER_REQUEST,
  EVENT_REGISTER_RESET,
  EVENT_REGISTER_SUCCESS,
  EVENT_ROOM_ALL_FAIL,
  EVENT_ROOM_ALL_REQUEST,
  EVENT_ROOM_ALL_SUCCESS,
  EVENT_ROOM_BY_ID_FAIL,
  EVENT_ROOM_BY_ID_REQUEST,
  EVENT_ROOM_BY_ID_RESET,
  EVENT_ROOM_BY_ID_SUCCESS,
  EVENT_ROOM_DELETE_FAIL,
  EVENT_ROOM_DELETE_REQUEST,
  EVENT_ROOM_DELETE_SUCCESS,
  EVENT_ROOM_NEW_FAIL,
  EVENT_ROOM_NEW_REQUEST,
  EVENT_ROOM_NEW_RESET,
  EVENT_ROOM_NEW_SUCCESS,
  EVENT_ROOM_UPDATE_BY_ID_FAIL,
  EVENT_ROOM_UPDATE_BY_ID_REQUEST,
  EVENT_ROOM_UPDATE_BY_ID_RESET,
  EVENT_ROOM_UPDATE_BY_ID_SUCCESS,
  EVENT_UNPUBLISH_FAIL,
  EVENT_UNPUBLISH_REQUEST,
  EVENT_UNPUBLISH_RESET,
  EVENT_UNPUBLISH_SUCCESS,
  EVENT_UPDATE_BY_ID_FAIL,
  EVENT_UPDATE_BY_ID_REQUEST,
  EVENT_UPDATE_BY_ID_RESET,
  EVENT_UPDATE_BY_ID_SUCCESS,
  FLYER_DELETE_FAIL,
  FLYER_DELETE_REQUEST,
  FLYER_DELETE_SUCCESS,
} from "../constants/eventConstants";

export const eventNewReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENT_NEW_REQUEST:
      return { loading: true };
    case EVENT_NEW_SUCCESS:
      return { loading: false, success: true, newCreatedEvent: action.payload };
    case EVENT_NEW_FAIL:
      return { loading: false, error: action.payload };
    case EVENT_NEW_RESET:
      return {};
    default:
      return state;
  }
};

export const eventAllReducer = (state = { events: [] }, action) => {
  switch (action.type) {
    case EVENT_ALL_REQUEST:
      return { loading: true };
    case EVENT_ALL_SUCCESS:
      return {
        loading: false,
        totalItems: action.payload.totalItems,
        events: action.payload.items,
        totalPages: action.payload.totalPages,
        currentPage: action.payload.currentPage,
      };
    case EVENT_ALL_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const eventAllPastReducer = (state = { pastEvents: [] }, action) => {
  switch (action.type) {
    case EVENT_ALL_PAST_REQUEST:
      return { loading: true };
    case EVENT_ALL_PAST_SUCCESS:
      return {
        loading: false,
        totalPastItems: action.payload.totalItems,
        pastEvents: action.payload.items,
        totalPastPages: action.payload.totalPages,
        currentPastPage: action.payload.currentPage,
      };
    case EVENT_ALL_PAST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const eventAllUpcomingReducer = (
  state = { upcomingEvents: [] },
  action
) => {
  switch (action.type) {
    case EVENT_ALL_UPCOMING_REQUEST:
      return { loading: true };
    case EVENT_ALL_UPCOMING_SUCCESS:
      return {
        loading: false,
        totalUpcomingItems: action.payload.totalItems,
        upcomingEvents: action.payload.items,
        totalUpcomingPages: action.payload.totalPages,
        currentUpcomingPage: action.payload.currentPage,
      };
    case EVENT_ALL_UPCOMING_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const eventByIdReducer = (state = { event: {} }, action) => {
  switch (action.type) {
    case EVENT_BY_ID_REQUEST:
      return { ...state, loading: true };
    case EVENT_BY_ID_SUCCESS:
      return { loading: false, success: true, event: action.payload };
    case EVENT_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    case EVENT_BY_ID_RESET:
      return {};
    default:
      return state;
  }
};

export const eventUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENT_UPDATE_BY_ID_REQUEST:
      return { loading: true };
    case EVENT_UPDATE_BY_ID_SUCCESS:
      return { loading: false, success: true, event: action.payload };
    case EVENT_UPDATE_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    case EVENT_UPDATE_BY_ID_RESET:
      return {};
    default:
      return state;
  }
};

export const eventDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENT_DELETE_REQUEST:
      return { loading: true };
    case EVENT_DELETE_SUCCESS:
      return { loading: false, success: true };
    case EVENT_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

////////// FLYER DELETE ///////////////////////
export const eventDeleteFlyer = (state = {}, action) => {
  switch (action.type) {
    case FLYER_DELETE_REQUEST:
      return { loading: true };
    case FLYER_DELETE_SUCCESS:
      return { loading: false, success: true };
    case FLYER_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

///////////Event Contact/////////////////////////

export const eventContactNewReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENT_CONTACT_NEW_REQUEST:
      return { loading: true };
    case EVENT_CONTACT_NEW_SUCCESS:
      return { loading: false, success: action.payload };
    case EVENT_CONTACT_NEW_FAIL:
      return { loading: false, error: action.payload };
    case EVENT_CONTACT_NEW_RESET:
      return {};
    default:
      return state;
  }
};

export const eventContactAllReducer = (
  state = { eventContacts: [] },
  action
) => {
  switch (action.type) {
    case EVENT_CONTACT_ALL_REQUEST:
      return { loading: true };
    case EVENT_CONTACT_ALL_SUCCESS:
      return { loading: false, eventContacts: action.payload };
    case EVENT_CONTACT_ALL_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const eventContactByIdReducer = (
  state = { eventContact: {} },
  action
) => {
  switch (action.type) {
    case EVENT_CONTACT_BY_ID_REQUEST:
      return { ...state, loading: true };
    case EVENT_CONTACT_BY_ID_SUCCESS:
      return { loading: false, success: true, eventContact: action.payload };
    case EVENT_CONTACT_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    case EVENT_CONTACT_BY_ID_RESET:
      return {};
    default:
      return state;
  }
};

export const eventContactUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENT_CONTACT_UPDATE_BY_ID_REQUEST:
      return { loading: true };
    case EVENT_CONTACT_UPDATE_BY_ID_SUCCESS:
      return { loading: false, success: action.payload };
    case EVENT_CONTACT_UPDATE_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    case EVENT_CONTACT_UPDATE_BY_ID_RESET:
      return {};
    default:
      return state;
  }
};

export const eventContactDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENT_CONTACT_DELETE_REQUEST:
      return { loading: true };
    case EVENT_CONTACT_DELETE_SUCCESS:
      return { loading: false, success: true };
    case EVENT_CONTACT_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

///////////Event Program/////////////////////////

export const eventProgramNewReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENT_PROGRAM_NEW_REQUEST:
      return { loading: true };
    case EVENT_PROGRAM_NEW_SUCCESS:
      return { loading: false, success: action.payload };
    case EVENT_PROGRAM_NEW_FAIL:
      return { loading: false, error: action.payload };
    case EVENT_PROGRAM_NEW_RESET:
      return {};
    default:
      return state;
  }
};

export const eventProgramAllReducer = (
  state = { eventPrograms: [] },
  action
) => {
  switch (action.type) {
    case EVENT_PROGRAM_ALL_REQUEST:
      return { loading: true };
    case EVENT_PROGRAM_ALL_SUCCESS:
      return { loading: false, eventPrograms: action.payload };
    case EVENT_PROGRAM_ALL_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const eventProgramByIdReducer = (
  state = { eventProgram: {} },
  action
) => {
  switch (action.type) {
    case EVENT_PROGRAM_BY_ID_REQUEST:
      return { ...state, loading: true };
    case EVENT_PROGRAM_BY_ID_SUCCESS:
      return { loading: false, success: true, eventProgram: action.payload };
    case EVENT_PROGRAM_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    case EVENT_PROGRAM_BY_ID_RESET:
      return {};
    default:
      return state;
  }
};

export const eventProgramUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENT_PROGRAM_UPDATE_BY_ID_REQUEST:
      return { loading: true };
    case EVENT_PROGRAM_UPDATE_BY_ID_SUCCESS:
      return { loading: false, success: action.payload };
    case EVENT_PROGRAM_UPDATE_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    case EVENT_PROGRAM_UPDATE_BY_ID_RESET:
      return {};
    default:
      return state;
  }
};

export const eventProgramDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENT_PROGRAM_DELETE_REQUEST:
      return { loading: true };
    case EVENT_PROGRAM_DELETE_SUCCESS:
      return { loading: false, success: true };
    case EVENT_PROGRAM_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

///////////Event Program Group/////////////////////////

export const eventGroupNewReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENT_GROUP_NEW_REQUEST:
      return { loading: true };
    case EVENT_GROUP_NEW_SUCCESS:
      return { loading: false, success: action.payload };
    case EVENT_GROUP_NEW_FAIL:
      return { loading: false, error: action.payload };
    case EVENT_GROUP_NEW_RESET:
      return {};
    default:
      return state;
  }
};

export const eventGroupAllReducer = (state = { eventGroups: [] }, action) => {
  switch (action.type) {
    case EVENT_GROUP_ALL_REQUEST:
      return { loading: true };
    case EVENT_GROUP_ALL_SUCCESS:
      return { loading: false, eventGroups: action.payload };
    case EVENT_GROUP_ALL_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const eventGroupByIdReducer = (state = { eventGroup: {} }, action) => {
  switch (action.type) {
    case EVENT_GROUP_BY_ID_REQUEST:
      return { ...state, loading: true };
    case EVENT_GROUP_BY_ID_SUCCESS:
      return { loading: false, success: true, eventGroup: action.payload };
    case EVENT_GROUP_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    case EVENT_GROUP_BY_ID_RESET:
      return {};
    default:
      return state;
  }
};

export const eventGroupUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENT_GROUP_UPDATE_BY_ID_REQUEST:
      return { loading: true };
    case EVENT_GROUP_UPDATE_BY_ID_SUCCESS:
      return { loading: false, success: action.payload };
    case EVENT_GROUP_UPDATE_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    case EVENT_GROUP_UPDATE_BY_ID_RESET:
      return {};
    default:
      return state;
  }
};

export const eventGroupDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENT_GROUP_DELETE_REQUEST:
      return { loading: true };
    case EVENT_GROUP_DELETE_SUCCESS:
      return { loading: false, success: true };
    case EVENT_GROUP_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

///////////Event Hotel/////////////////////////

export const eventHotelNewReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENT_HOTEL_NEW_REQUEST:
      return { loading: true };
    case EVENT_HOTEL_NEW_SUCCESS:
      return { loading: false, success: action.payload };
    case EVENT_HOTEL_NEW_FAIL:
      return { loading: false, error: action.payload };
    case EVENT_HOTEL_NEW_RESET:
      return {};
    default:
      return state;
  }
};

export const eventHotelAllReducer = (state = { eventHotels: [] }, action) => {
  switch (action.type) {
    case EVENT_HOTEL_ALL_REQUEST:
      return { loading: true };
    case EVENT_HOTEL_ALL_SUCCESS:
      return { loading: false, eventHotels: action.payload };
    case EVENT_HOTEL_ALL_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const eventHotelByIdReducer = (state = { eventHotel: {} }, action) => {
  switch (action.type) {
    case EVENT_HOTEL_BY_ID_REQUEST:
      return { ...state, loading: true };
    case EVENT_HOTEL_BY_ID_SUCCESS:
      return { loading: false, success: true, eventHotel: action.payload };
    case EVENT_HOTEL_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    case EVENT_HOTEL_BY_ID_RESET:
      return {};
    default:
      return state;
  }
};

export const eventHotelUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENT_HOTEL_UPDATE_BY_ID_REQUEST:
      return { loading: true };
    case EVENT_HOTEL_UPDATE_BY_ID_SUCCESS:
      return { loading: false, success: action.payload };
    case EVENT_HOTEL_UPDATE_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    case EVENT_HOTEL_UPDATE_BY_ID_RESET:
      return {};
    default:
      return state;
  }
};

export const eventHotelDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENT_HOTEL_DELETE_REQUEST:
      return { loading: true };
    case EVENT_HOTEL_DELETE_SUCCESS:
      return { loading: false, success: true };
    case EVENT_HOTEL_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

///////////Event Hotel Room/////////////////////////

export const eventRoomNewReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENT_ROOM_NEW_REQUEST:
      return { loading: true };
    case EVENT_ROOM_NEW_SUCCESS:
      return { loading: false, success: action.payload };
    case EVENT_ROOM_NEW_FAIL:
      return { loading: false, error: action.payload };
    case EVENT_ROOM_NEW_RESET:
      return {};
    default:
      return state;
  }
};

export const eventRoomAllReducer = (state = { eventRooms: [] }, action) => {
  switch (action.type) {
    case EVENT_ROOM_ALL_REQUEST:
      return { loading: true };
    case EVENT_ROOM_ALL_SUCCESS:
      return { loading: false, eventRooms: action.payload };
    case EVENT_ROOM_ALL_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const eventRoomByIdReducer = (state = { eventRoom: {} }, action) => {
  switch (action.type) {
    case EVENT_ROOM_BY_ID_REQUEST:
      return { ...state, loading: true };
    case EVENT_ROOM_BY_ID_SUCCESS:
      return { loading: false, success: true, eventRoom: action.payload };
    case EVENT_ROOM_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    case EVENT_ROOM_BY_ID_RESET:
      return {};
    default:
      return state;
  }
};

export const eventRoomUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENT_ROOM_UPDATE_BY_ID_REQUEST:
      return { loading: true };
    case EVENT_ROOM_UPDATE_BY_ID_SUCCESS:
      return { loading: false, success: action.payload };
    case EVENT_ROOM_UPDATE_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    case EVENT_ROOM_UPDATE_BY_ID_RESET:
      return {};
    default:
      return state;
  }
};

export const eventRoomDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENT_ROOM_DELETE_REQUEST:
      return { loading: true };
    case EVENT_ROOM_DELETE_SUCCESS:
      return { loading: false, success: true };
    case EVENT_ROOM_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

/////////////////////Event Publish//////////////////////////////////////

export const eventPublishReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENT_PUBLISH_REQUEST:
      return { loading: true };
    case EVENT_PUBLISH_SUCCESS:
      return { loading: false, success: action.payload };
    case EVENT_PUBLISH_FAIL:
      return { loading: false, error: action.payload };
    case EVENT_PUBLISH_RESET:
      return {};
    default:
      return state;
  }
};

export const eventUnpublishReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENT_UNPUBLISH_REQUEST:
      return { loading: true };
    case EVENT_UNPUBLISH_SUCCESS:
      return { loading: false, success: action.payload };
    case EVENT_UNPUBLISH_FAIL:
      return { loading: false, error: action.payload };
    case EVENT_UNPUBLISH_RESET:
      return {};
    default:
      return state;
  }
};

///////////////////Event Register///////////////////////////////////////////

export const eventRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENT_REGISTER_REQUEST:
      return { loading: true };
    case EVENT_REGISTER_SUCCESS:
      return { loading: false, success: action.payload };
    case EVENT_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    case EVENT_REGISTER_RESET:
      return {};
    default:
      return state;
  }
};
