export const MEMBER_TYPE_NEW_REQUEST = 'MEMBER_TYPE_NEW_REQUEST';
export const MEMBER_TYPE_NEW_SUCCESS = 'MEMBER_TYPE_NEW_SUCCESS';
export const MEMBER_TYPE_NEW_FAIL = 'MEMBER_TYPE_NEW_FAIL';
export const MEMBER_TYPE_NEW_RESET = 'MEMBER_TYPE_NEW_RESET';

export const MEMBER_TYPE_ALL_REQUEST = 'MEMBER_TYPE_ALL_REQUEST';
export const MEMBER_TYPE_ALL_SUCCESS = 'MEMBER_TYPE_ALL_SUCCESS';
export const MEMBER_TYPE_ALL_FAIL = 'MEMBER_TYPE_ALL_FAIL';

export const MEMBER_TYPE_BY_ID_REQUEST = 'MEMBER_TYPE_BY_ID_REQUEST';
export const MEMBER_TYPE_BY_ID_SUCCESS = 'MEMBER_TYPE_BY_ID_SUCCESS';
export const MEMBER_TYPE_BY_ID_FAIL = 'MEMBER_TYPE_BY_ID_FAIL';
export const MEMBER_TYPE_BY_ID_RESET = 'MEMBER_TYPE_BY_ID_RESET';

export const MEMBER_TYPE_UPDATE_BY_ID_REQUEST =
  'MEMBER_TYPE_UPDATE_BY_ID_REQUEST';
export const MEMBER_TYPE_UPDATE_BY_ID_SUCCESS =
  'MEMBER_TYPE_UPDATE_BY_ID_SUCCESS';
export const MEMBER_TYPE_UPDATE_BY_ID_FAIL = 'MEMBER_TYPE_UPDATE_BY_ID_FAIL';
export const MEMBER_TYPE_UPDATE_BY_ID_RESET = 'MEMBER_TYPE_UPDATE_BY_ID_RESET';

export const MEMBER_TYPE_DELETE_REQUEST = 'MEMBER_TYPE_DELETE_REQUEST';
export const MEMBER_TYPE_DELETE_SUCCESS = 'MEMBER_TYPE_DELETE_SUCCESS';
export const MEMBER_TYPE_DELETE_FAIL = 'MEMBER_TYPE_DELETE_FAIL';
