import React, { useMemo } from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
import {
  useTable,
  useExpanded,
  useGroupBy,
  useSortBy,
  useGlobalFilter,
  useFilters,
  usePagination,
  useRowSelect,
} from 'react-table';
import Moment from 'react-moment';
import { Checkbox } from './CheckBox';
import GlobalFilter from './GlobalFilter';

import { useExportData } from 'react-table-plugins';
import Papa from 'papaparse';
import XLSX from 'xlsx';
import JsPDF from 'jspdf';
import 'jspdf-autotable';

const RTableTest = ({ users }) => {
  const data = useMemo(() => users, [users]);
  // const columns = useMemo(() => COLUMNS, [COLUMNS]);
  function name(leafValues) {
    return leafValues[0];
  }

  const columns = useMemo(
    () => [
      {
        Header: 'No.',
        accessor: (row, i) => i + 1,
      },
      {
        Header: 'Registration Id',
        accessor: 'registrationId',
      },
      {
        Header: 'Registration Time',
        accessor: 'createdAt',
        aggregate: name,
        Aggregated: ({ value }) => {
          return <Moment>{value}</Moment>;
        },
      },
      {
        Header: 'First Name',
        accessor: 'firstName',
        aggregate: name,
        Aggregated: ({ value }) => `${value} `,
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
        aggregate: name,
        Aggregated: ({ value }) => `${value} `,
      },
      {
        Header: 'Member Id',
        accessor: 'memberId',
        aggregate: name,
        Aggregated: ({ value }) => `${value} `,
      },
      {
        Header: 'Email',
        accessor: 'email',
        aggregate: name,
        Aggregated: ({ value }) => `${value} `,
      },
      {
        Header: 'Phone',
        accessor: 'phone',
        aggregate: name,
        Aggregated: ({ value }) => `${value} `,
      },
      {
        Header: 'Adult/s',
        accessor: 'numberOfAdults',
        aggregate: name,
        Aggregated: ({ value }) => `${value} `,
      },
      {
        Header: 'Minor/s',
        accessor: 'numberOfMinors',
        aggregate: name,
        Aggregated: ({ value }) => `${value} `,
      },
      {
        Header: 'Payment',
        accessor: 'payment',
        aggregate: name,
        Aggregated: ({ value }) => `${value} `,
        // Filter: ColumnFilter,
      },
      {
        Header: 'Note',
        accessor: 'note',
        aggregate: name,
        Aggregated: ({ value }) => `${value} `,
      },
      {
        Header: 'sub-Event',
        columns: [
          {
            Header: 'Program Name',
            accessor: 'groupRegistration.programName',
          },
          {
            Header: 'Group',
            accessor: 'groupRegistration.groupName',
          },
          {
            Header: 'Participant Name',
            accessor: 'groupRegistration.participantName',
          },
          {
            Header: 'Grade',
            accessor: 'groupRegistration.grade',
          },
          {
            Header: 'School',
            accessor: 'groupRegistration.school',
          },
        ],
        // Filter: ColumnFilter,
      },

      {
        Header: 'Hotel',
        columns: [
          {
            Header: 'Hotel Name',
            accessor: 'hotelRegistration.hotelName',
          },
          {
            Header: 'Room Type',
            accessor: 'hotelRegistration.roomType',
          },
          {
            Header: 'Qty',
            accessor: 'hotelRegistration.qty',
          },
          {
            Header: 'Rent',
            accessor: 'hotelRegistration.rent',
          },
        ],
        // Filter: ColumnFilter,
      },

      // {
      //   Header: 'Count In Stock',
      //   accessor: 'countInStock',
      //   aggregate: 'sum',
      //   Aggregated: ({ value }) => `${value} (sum)`,
      // },
      // {
      //   Header: 'Brand',
      //   accessor: 'brand',
      // },
      // {
      //   Header: 'Rating',
      //   accessor: 'rating',
      //   aggregate: 'average',
      //   Aggregated: ({ value }) => `${Math.round(value * 100) / 100} (avg)`,
      // },
      // {
      //   Header: 'Description',
      //   accessor: 'description',
      // },
    ],
    []
  );

  // Export function
  function getExportFileBlob({ columns, data, fileType, fileName }) {
    if (fileType === 'csv') {
      // CSV example
      const headerNames = columns
        .filter((c) => c.Header !== 'Action')
        .map((col) => col.exportValue);
      const csvString = Papa.unparse({ fields: headerNames, data });
      return new Blob([csvString], { type: 'text/csv' });
    } else if (fileType === 'xlsx') {
      // XLSX example

      const header = columns
        .filter((c) => c.Header !== 'Action')
        .map((c) => c.exportValue);
      const compatibleData = data.map((row) => {
        const obj = {};
        header.forEach((col, index) => {
          obj[col] = row[index];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, 'React Table Data');
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    //PDF example
    if (fileType === 'pdf') {
      const headerNames = columns
        .filter((c) => c.Header !== 'Action')
        .map((column) => column.exportValue);
      const doc = new JsPDF();
      doc.autoTable({
        head: [headerNames],
        body: data,
        styles: {
          minCellHeight: 9,
          halign: 'left',
          valign: 'center',
          fontSize: 11,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }

    // Other formats goes here
    return false;
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    exportData,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      data: data,
      columns: columns,
      getExportFileBlob,
    },
    useFilters,
    useGlobalFilter,
    useGroupBy,
    useSortBy,
    useExpanded,
    useExportData,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <Checkbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
              <Checkbox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
        ];
      });
    }
  );
  // console.log({
  //   selectedFlatRows: selectedFlatRows.map((row) => row.original),
  // });

  /////////////  Bulk delete option //////////////////////////////
  // const BulkDelete = (selectedFlatRows, parent_action) => {
  //   let selected_id = selectedFlatRows.map((data) => {
  //     return data.values._id;
  //   });
  //   swal({
  //     title: 'Are you sure?',
  //     text: 'Once deleted, you will not be able to recover this data!',
  //     icon: 'warning',
  //     buttons: true,
  //     dangerMode: true,
  //   }).then((willDelete) => {
  //     if (willDelete) {
  //       // dispatch(parent_action.bulk_delete(selected_id))
  //       swal('Poof! Your POS Machine data has been deleted!', {
  //         icon: 'success',
  //       });
  //     }
  //   });
  // };

  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <>
      <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      <div className='form-group input-group'>
        <button
          className='btn btnexport mr-1'
          onClick={() => {
            exportData('csv', true);
          }}
        >
          <i className='fa fa-file-csv'></i> Export as CSV
        </button>{' '}
        <button
          className='btn btnexport mr-1'
          onClick={() => {
            exportData('xlsx', true);
          }}
        >
          <i className='fa fa-file-excel'></i> Export as xlsx
        </button>{' '}
        <button
          className='btn btnexport mr-1'
          onClick={() => {
            exportData('pdf', true);
          }}
        >
          <i className='fa fa-file-pdf'></i>
          Export as PDF
        </button>
        {/* {Object.keys(selectedRowIds).length != 0 ? (
          <button
            className='btn btn-danger'
            onClick={() => {
              BulkDelete(selectedFlatRows, parent_action);
            }}
          >
            <i className='fa fa-trash'></i>
            Delete {Object.keys(selectedRowIds).length} row
          </button>
        ) : (
          ''
        )} */}
      </div>
      <Table
        {...getTableProps()}
        striped
        bordered
        hover
        responsive
        className='table-sm'
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.canGroupBy &&
                  (column.id === 'registrationId' ||
                    column.id === 'groupRegistration.programName' ||
                    column.id === 'hotelRegistration.hotelName') ? (
                    // If the column can be grouped, let's add a toggle
                    <span {...column.getGroupByToggleProps()}>
                      {column.isGrouped ? '🛑 ' : '👊 '}
                    </span>
                  ) : null}
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? '  ▼'
                        : '  ▲'
                      : ''}
                  </span>
                  {/* <div>{column.canFilter ? column.render('Filter') : null}</div> */}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        background: cell.isGrouped
                          ? '#0aff0082'
                          : cell.isAggregated
                          ? '#ffa50078'
                          : cell.isPlaceholder
                          ? '#ff000042'
                          : 'white',
                      }}
                    >
                      {cell.isGrouped ? (
                        // If it's a grouped cell, add an expander and row count
                        <>
                          <span {...row.getToggleRowExpandedProps()}>
                            {row.isExpanded ? '👇' : '👉'}
                          </span>{' '}
                          {cell.render('Cell')} ({row.subRows.length})
                        </>
                      ) : cell.isAggregated ? (
                        // If the cell is aggregated, use the Aggregated
                        // renderer for cell
                        cell.row.groupByID ===
                        ('groupRegistration.programName' ||
                          'hotelRegistration.hotelName') ? null : (
                          cell.render('Aggregated')
                        )
                      ) : // cell.row.groupByID
                      cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                        // Otherwise, just render the regular cell
                        cell.render('Cell')
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        {/* <pre>
          <code>
            {JSON.stringify({
              selectedFlatRows: selectedFlatRows.map((row) => row.original),
            })}
          </code>
        </pre> */}
      </Table>
      <Row className='justify-content-md-center'>
        <Col>
          <Button
            variant=''
            className='btn-sm'
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {'<<'}
          </Button>
          <Button
            variant=''
            className='btn-sm'
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {' '}
            Previous
            {/* <i className='fas fa-trash'></i> */}
          </Button>
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
          <span>
            | Go to page:{' '}
            <input
              type='number'
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0;
                gotoPage(pageNumber);
              }}
              style={{ width: '50px' }}
              min={1}
              max={pageOptions.length}
            />
          </span>
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {[2, 5, 10, 25, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>

          <Button
            variant=''
            className='btn-sm'
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {' '}
            Next
            {/* <i className='fas fa-trash'></i> */}
          </Button>
          <Button
            variant=''
            className='btn-sm'
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {'>>'}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default RTableTest;
