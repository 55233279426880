import React, { useEffect } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import parse from 'html-react-parser';
import { getActivityById } from '../../actions/activityAction';
import ActivityImageCarousel from '../../components/ImageCarousel/ActivityImageCarousel';
import EmbedVideo from '../../components/Video/EmbedVideo';
import ActivityImageLightBox from '../../components/Image/ActivityImageLightBox';
import swal from 'sweetalert';
import { IMAGE_DELETE_RESET } from '../../constants/imageConstants';

const ActivityByIdScreen = ({ history, match }) => {
  const id = match.params.id;
  const dispatch = useDispatch();

  const activityById = useSelector((state) => state.activityById);
  const { loading, error, activity } = activityById;

  const imageDelete = useSelector((state) => state.imageDelete);
  const { error: imageDeleteError, success } = imageDelete;

  useEffect(() => {
    dispatch(getActivityById(id));

    if (success) {
      swal('Success!', 'The image has been deleted', 'success').then(() => {
        dispatch({ type: IMAGE_DELETE_RESET });
      });
    }
    if (imageDeleteError) {
      swal('Error!', imageDeleteError, 'error').then(() => {
        dispatch({ type: IMAGE_DELETE_RESET });
      });
    }
  }, [dispatch, history, id, success, imageDeleteError]);

  return (
    <Container>
      <Link className='btn btn-light my-3 btn-sm btn-outline-info' to='/'>
        Go Back
      </Link>

      <Card>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          activity && (
            <>
              <Row>
                <Col>
                  {activity.activityPhoto &&
                    activity.activityPhoto.length !== 0 && (
                      <ActivityImageCarousel images={activity.activityPhoto} />
                    )}
                  {activity.body && <EmbedVideo />}
                </Col>
              </Row>
              <Card.Header className='text-info' as='h2'>
                {activity.title}
                <Card.Title className='text-muted'>
                  Date: {`${activity.date}`.substring(0, 10)}
                </Card.Title>
              </Card.Header>
              <Card.Body>{parse(`<div>${activity.body}</div>`)}</Card.Body>
              {activity.activityPhoto && activity.activityPhoto.length !== 0 && (
                <Card.Footer className='text-center mb-2'>
                  <ActivityImageLightBox images={activity.activityPhoto} />
                </Card.Footer>
              )}
            </>
          )
        )}
      </Card>
    </Container>
  );
};

export default ActivityByIdScreen;
