export const ACTIVITY_NEW_REQUEST = 'ACTIVITY_NEW_REQUEST';
export const ACTIVITY_NEW_SUCCESS = 'ACTIVITY_NEW_SUCCESS';
export const ACTIVITY_NEW_FAIL = 'ACTIVITY_NEW_FAIL';
export const ACTIVITY_NEW_RESET = 'ACTIVITY_NEW_RESET';

export const ACTIVITY_ALL_REQUEST = 'ACTIVITY_ALL_REQUEST';
export const ACTIVITY_ALL_SUCCESS = 'ACTIVITY_ALL_SUCCESS';
export const ACTIVITY_ALL_FAIL = 'ACTIVITY_ALL_FAIL';

export const ACTIVITY_BY_ID_REQUEST = 'ACTIVITY_BY_ID_REQUEST';
export const ACTIVITY_BY_ID_SUCCESS = 'ACTIVITY_BY_ID_SUCCESS';
export const ACTIVITY_BY_ID_FAIL = 'ACTIVITY_BY_ID_FAIL';
export const ACTIVITY_BY_ID_RESET = 'ACTIVITY_BY_ID_RESET';

export const ACTIVITY_UPDATE_BY_ID_REQUEST = 'ACTIVITY_UPDATE_BY_ID_REQUEST';
export const ACTIVITY_UPDATE_BY_ID_SUCCESS = 'ACTIVITY_UPDATE_BY_ID_SUCCESS';
export const ACTIVITY_UPDATE_BY_ID_FAIL = 'ACTIVITY_UPDATE_BY_ID_FAIL';
export const ACTIVITY_UPDATE_BY_ID_RESET = 'ACTIVITY_UPDATE_BY_ID_RESET';

export const ACTIVITY_DELETE_REQUEST = 'ACTIVITY_DELETE_REQUEST';
export const ACTIVITY_DELETE_SUCCESS = 'ACTIVITY_DELETE_SUCCESS';
export const ACTIVITY_DELETE_FAIL = 'ACTIVITY_DELETE_FAIL';
