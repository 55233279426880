export const EMAIL_NEW_REQUEST = 'EMAIL_NEW_REQUEST';
export const EMAIL_NEW_SUCCESS = 'EMAIL_NEW_SUCCESS';
export const EMAIL_NEW_FAIL = 'EMAIL_NEW_FAIL';
export const EMAIL_NEW_RESET = 'EMAIL_NEW_RESET';

export const EMAIL_ALL_REQUEST = 'EMAIL_ALL_REQUEST';
export const EMAIL_ALL_SUCCESS = 'EMAIL_ALL_SUCCESS';
export const EMAIL_ALL_FAIL = 'EMAIL_ALL_FAIL';

export const EMAIL_BY_ID_REQUEST = 'EMAIL_BY_ID_REQUEST';
export const EMAIL_BY_ID_SUCCESS = 'EMAIL_BY_ID_SUCCESS';
export const EMAIL_BY_ID_FAIL = 'EMAIL_BY_ID_FAIL';
export const EMAIL_BY_ID_RESET = 'EMAIL_BY_ID_RESET';
