import React, { useState } from 'react';
import { Col, Form, ListGroup, Modal, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const CreateSession = ({ createSession }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [tenureFrom, setTenureFrom] = useState('');
  const [tenureTo, setTenureTo] = useState('');
  const [active, setActive] = useState(false);

  return (
    <>
      <Link
        className='btn btn-outline-info btn-sm btn-block  rounded'
        onClick={handleShow}
        to='#'
      >
        Create New Committee
      </Link>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        size='lg'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className='text-center text-info'>
            New Session for:
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup variant='flush'>
            <ListGroup.Item>
              <Form.Row>
                <Form.Group as={Col} md='2'>
                  <Form.Label>Tenure :</Form.Label>
                </Form.Group>
                <Col>
                  <Row>
                    <Form.Group as={Col} controlId='tenureFrom'>
                      <Form.Label>From: </Form.Label>
                      <Form.Control
                        required
                        type='number'
                        value={tenureFrom}
                        onChange={(e) => setTenureFrom(e.target.value)}
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group as={Col} controlId='tenureTo'>
                      <Form.Label>To: </Form.Label>
                      <Form.Control
                        required
                        type='number'
                        value={tenureTo}
                        onChange={(e) => setTenureTo(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Row>
                </Col>
              </Form.Row>
            </ListGroup.Item>

            <ListGroup.Item>
              <Form.Row>
                <Form.Group as={Col} md='6'>
                  <Form.Label>Set as active Committee Session ?</Form.Label>
                </Form.Group>
                <Col>
                  <Form.Control
                    required
                    as='select'
                    type='text'
                    value={active}
                    onChange={(e) => setActive(e.target.value)}
                  >
                    <option value='false'>No</option>
                    <option value='true'>Yes</option>
                  </Form.Control>
                </Col>
              </Form.Row>
            </ListGroup.Item>
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Link
            className='btn btn-outline-danger btn-sm rounded'
            onClick={handleClose}
            to='#'
          >
            Cancel
          </Link>

          <Link
            className='btn btn-outline-info btn-sm rounded'
            onClick={() => {
              const tenure = tenureFrom + '-' + tenureTo;
              // console.log(tenure);
              // console.log(typeof tenure);
              createSession(tenure, active);
              handleClose();
            }}
            to='#'
          >
            Create
          </Link>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateSession;
