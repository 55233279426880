import React, { useEffect } from 'react';

import { Card } from 'react-bootstrap';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const BlogNew = ({ history }) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // const checkChapter = window.location.host.split('.')[0];

  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
    }
  }, [history, userInfo]);

  const newBlogHandler = (e) => {
    e.preventDefault();

    // console.log('Edit clicked');
    history.push(`/blog/new`);

    // dispatch(approveUser(pendingId));
    // history.push('/systemAdmin');
  };

  return (
    <>
      <Card className='mb-2'>
        <Card.Header className='text-center' as='h2'>
          <Link
            className='btn btn-outline-info btn-sm btn-block rounded'
            // onClick={() => setAddAnnouncement(!addAnnouncement)}
            onClick={newBlogHandler}
          >
            Create new Blog
          </Link>
        </Card.Header>
      </Card>
    </>
  );
};

export default BlogNew;
