import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Route } from 'react-router-dom';
import Courtesy from '../../components/Career/Courtesy';
import JobNew from '../../components/Career/JobNew';
import Jobs from '../../components/Career/Jobs';
import JobSearchBox from '../../components/Career/JobSearchBox';

const jobScreen = () => {
  return (
    <>
      <Courtesy />
      <Row className='content'>
        <Col md={{ span: 8, order: 1 }} className='m-0 p-1'>
          <Route render={({ match }) => <Jobs match={match} />} />
        </Col>

        <Col md={{ span: 4, order: 12 }} className='m-0 p-1'>
          <Route render={({ history }) => <JobNew history={history} />} />

          <>
            {/* <!-- Blog Search Well --> */}
            <Route
              render={({ history }) => <JobSearchBox history={history} />}
            />
          </>
        </Col>
      </Row>
    </>
  );
};

export default jobScreen;
