import axios from "axios";
import {
  EVENT_ALL_FAIL,
  EVENT_ALL_PAST_FAIL,
  EVENT_ALL_PAST_REQUEST,
  EVENT_ALL_PAST_SUCCESS,
  EVENT_ALL_REQUEST,
  EVENT_ALL_SUCCESS,
  EVENT_ALL_UPCOMING_FAIL,
  EVENT_ALL_UPCOMING_REQUEST,
  EVENT_ALL_UPCOMING_SUCCESS,
  EVENT_BY_ID_FAIL,
  EVENT_BY_ID_REQUEST,
  EVENT_BY_ID_SUCCESS,
  EVENT_CONTACT_ALL_FAIL,
  EVENT_CONTACT_ALL_REQUEST,
  EVENT_CONTACT_ALL_SUCCESS,
  EVENT_CONTACT_BY_ID_FAIL,
  EVENT_CONTACT_BY_ID_REQUEST,
  EVENT_CONTACT_BY_ID_SUCCESS,
  EVENT_CONTACT_DELETE_FAIL,
  EVENT_CONTACT_DELETE_REQUEST,
  EVENT_CONTACT_DELETE_SUCCESS,
  EVENT_CONTACT_NEW_FAIL,
  EVENT_CONTACT_NEW_REQUEST,
  EVENT_CONTACT_NEW_SUCCESS,
  EVENT_CONTACT_UPDATE_BY_ID_FAIL,
  EVENT_CONTACT_UPDATE_BY_ID_REQUEST,
  EVENT_CONTACT_UPDATE_BY_ID_SUCCESS,
  EVENT_DELETE_FAIL,
  EVENT_DELETE_REQUEST,
  EVENT_DELETE_SUCCESS,
  EVENT_GROUP_ALL_FAIL,
  EVENT_GROUP_ALL_REQUEST,
  EVENT_GROUP_ALL_SUCCESS,
  EVENT_GROUP_BY_ID_FAIL,
  EVENT_GROUP_BY_ID_REQUEST,
  EVENT_GROUP_BY_ID_SUCCESS,
  EVENT_GROUP_DELETE_FAIL,
  EVENT_GROUP_DELETE_REQUEST,
  EVENT_GROUP_DELETE_SUCCESS,
  EVENT_GROUP_NEW_FAIL,
  EVENT_GROUP_NEW_REQUEST,
  EVENT_GROUP_NEW_SUCCESS,
  EVENT_GROUP_UPDATE_BY_ID_FAIL,
  EVENT_GROUP_UPDATE_BY_ID_REQUEST,
  EVENT_GROUP_UPDATE_BY_ID_SUCCESS,
  EVENT_HOTEL_ALL_FAIL,
  EVENT_HOTEL_ALL_REQUEST,
  EVENT_HOTEL_ALL_SUCCESS,
  EVENT_HOTEL_BY_ID_FAIL,
  EVENT_HOTEL_BY_ID_REQUEST,
  EVENT_HOTEL_BY_ID_SUCCESS,
  EVENT_HOTEL_DELETE_FAIL,
  EVENT_HOTEL_DELETE_REQUEST,
  EVENT_HOTEL_DELETE_SUCCESS,
  EVENT_HOTEL_NEW_FAIL,
  EVENT_HOTEL_NEW_REQUEST,
  EVENT_HOTEL_NEW_SUCCESS,
  EVENT_HOTEL_UPDATE_BY_ID_FAIL,
  EVENT_HOTEL_UPDATE_BY_ID_REQUEST,
  EVENT_HOTEL_UPDATE_BY_ID_SUCCESS,
  EVENT_NEW_FAIL,
  EVENT_NEW_REQUEST,
  EVENT_NEW_SUCCESS,
  EVENT_PROGRAM_ALL_FAIL,
  EVENT_PROGRAM_ALL_REQUEST,
  EVENT_PROGRAM_ALL_SUCCESS,
  EVENT_PROGRAM_BY_ID_FAIL,
  EVENT_PROGRAM_BY_ID_REQUEST,
  EVENT_PROGRAM_BY_ID_SUCCESS,
  EVENT_PROGRAM_DELETE_FAIL,
  EVENT_PROGRAM_DELETE_REQUEST,
  EVENT_PROGRAM_DELETE_SUCCESS,
  EVENT_PROGRAM_NEW_FAIL,
  EVENT_PROGRAM_NEW_REQUEST,
  EVENT_PROGRAM_NEW_SUCCESS,
  EVENT_PROGRAM_UPDATE_BY_ID_FAIL,
  EVENT_PROGRAM_UPDATE_BY_ID_REQUEST,
  EVENT_PROGRAM_UPDATE_BY_ID_SUCCESS,
  EVENT_PUBLISH_FAIL,
  EVENT_PUBLISH_REQUEST,
  EVENT_PUBLISH_SUCCESS,
  EVENT_REGISTER_FAIL,
  EVENT_REGISTER_REQUEST,
  EVENT_REGISTER_SUCCESS,
  EVENT_ROOM_ALL_FAIL,
  EVENT_ROOM_ALL_REQUEST,
  EVENT_ROOM_ALL_SUCCESS,
  EVENT_ROOM_BY_ID_FAIL,
  EVENT_ROOM_BY_ID_REQUEST,
  EVENT_ROOM_BY_ID_SUCCESS,
  EVENT_ROOM_DELETE_FAIL,
  EVENT_ROOM_DELETE_REQUEST,
  EVENT_ROOM_DELETE_SUCCESS,
  EVENT_ROOM_NEW_FAIL,
  EVENT_ROOM_NEW_REQUEST,
  EVENT_ROOM_NEW_SUCCESS,
  EVENT_ROOM_UPDATE_BY_ID_FAIL,
  EVENT_ROOM_UPDATE_BY_ID_REQUEST,
  EVENT_ROOM_UPDATE_BY_ID_SUCCESS,
  EVENT_UNPUBLISH_FAIL,
  EVENT_UNPUBLISH_REQUEST,
  EVENT_UNPUBLISH_SUCCESS,
  EVENT_UPDATE_BY_ID_FAIL,
  EVENT_UPDATE_BY_ID_REQUEST,
  EVENT_UPDATE_BY_ID_SUCCESS,
  FLYER_DELETE_FAIL,
  FLYER_DELETE_REQUEST,
  FLYER_DELETE_SUCCESS,
} from "../constants/eventConstants";

export const newEvent =
  (
    eventType,
    eventName,
    eventDescription,
    eventDate,
    eventAddress,
    adultFee,
    minorFee,
    cap,
    checkChapter
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: EVENT_NEW_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        "/api/events/new",
        {
          eventType,
          eventName,
          eventDescription,
          eventDate,
          eventAddress,
          adultFee,
          minorFee,
          cap,
          checkChapter,
        },
        config
      );

      dispatch({
        type: EVENT_NEW_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EVENT_NEW_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const allEvents =
  (checkChapter, keyword = "", pageNumber = "") =>
  async (dispatch) => {
    try {
      dispatch({
        type: EVENT_ALL_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(
        `/api/events/chapter/${checkChapter}?keyword=${keyword}&page=${pageNumber}`,
        config
      );

      dispatch({
        type: EVENT_ALL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EVENT_ALL_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const allPastEvents =
  (checkChapter, keyword = "", pageNumber = "") =>
  async (dispatch) => {
    try {
      dispatch({
        type: EVENT_ALL_PAST_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(
        `/api/events/pastchapter/${checkChapter}?keyword=${keyword}&page=${pageNumber}`,
        config
      );

      dispatch({
        type: EVENT_ALL_PAST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EVENT_ALL_PAST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const allUpcomingEvents =
  (checkChapter, keyword = "", pageNumber = "") =>
  async (dispatch) => {
    try {
      dispatch({
        type: EVENT_ALL_UPCOMING_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(
        `/api/events/futurechapter/${checkChapter}?keyword=${keyword}&page=${pageNumber}`,
        config
      );

      dispatch({
        type: EVENT_ALL_UPCOMING_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EVENT_ALL_UPCOMING_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getEventById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EVENT_BY_ID_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(
      `/api/events/${id}`,

      config
    );

    dispatch({
      type: EVENT_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EVENT_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateEventById =
  (
    id,
    eventName,
    eventDescription,
    eventDate,
    eventAddress,
    adultFee,
    minorFee,
    cap,
    flyer
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: EVENT_UPDATE_BY_ID_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/events/${id}`,
        {
          eventName,
          eventDescription,
          eventDate,
          eventAddress,
          adultFee,
          minorFee,
          cap,
          flyer,
        },
        config
      );

      dispatch({
        type: EVENT_UPDATE_BY_ID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EVENT_UPDATE_BY_ID_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteEvent = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EVENT_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/events/${id}`, config);

    dispatch({ type: EVENT_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: EVENT_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

////////////////// flyer delete ///////////////////////////
export const deleteFlyer = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FLYER_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/events/flyer/${id}`, config);

    dispatch({ type: FLYER_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: FLYER_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

////////////////////Event Contact///////////////////////////////////////
export const newEventContact =
  (id, memberId, contactEmail, contactPhone) => async (dispatch, getState) => {
    try {
      dispatch({
        type: EVENT_CONTACT_NEW_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `/api/events/newContact/${id}`,
        {
          memberId,
          contactEmail,
          contactPhone,
        },
        config
      );

      dispatch({
        type: EVENT_CONTACT_NEW_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EVENT_CONTACT_NEW_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const eventAllContact = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EVENT_CONTACT_ALL_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(`/api/events/contacts/${id}`, config);

    dispatch({
      type: EVENT_CONTACT_ALL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EVENT_CONTACT_ALL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getEventContactById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EVENT_CONTACT_BY_ID_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(`/api/events/contactby/${id}`, {}, config);

    dispatch({
      type: EVENT_CONTACT_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EVENT_CONTACT_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateEventContactById =
  (eventContactId, memberId, contactEmail, contactPhone) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: EVENT_CONTACT_UPDATE_BY_ID_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/events/contactby/${eventContactId}`,
        {
          memberId,
          contactEmail,
          contactPhone,
        },
        config
      );

      dispatch({
        type: EVENT_CONTACT_UPDATE_BY_ID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EVENT_CONTACT_UPDATE_BY_ID_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteEventContact = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EVENT_CONTACT_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/events/contactby/${id}`, config);

    dispatch({ type: EVENT_CONTACT_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: EVENT_CONTACT_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

///////////////////////Event publish/////////////////////////////////////////////////

export const publishEvent = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EVENT_PUBLISH_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(`/api/events/publish/${id}`, {}, config);

    dispatch({
      type: EVENT_PUBLISH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EVENT_PUBLISH_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const unpublishEvent = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EVENT_UNPUBLISH_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(`/api/events/unpublish/${id}`, {}, config);

    dispatch({
      type: EVENT_UNPUBLISH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EVENT_UNPUBLISH_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

/////////////////////////Event Registration///////////////////////////////////////////////

export const registerEvent = (registerData) => async (dispatch) => {
  try {
    dispatch({
      type: EVENT_REGISTER_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    console.log({ registerData });

    const { data } = await axios.post(
      "/api/events/register",
      registerData,
      config
    );

    dispatch({
      type: EVENT_REGISTER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EVENT_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

////////////////////Event Program///////////////////////////////////////
export const newEventProgram =
  ({ id, programName }) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: EVENT_PROGRAM_NEW_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `/api/events/newProgram/${id}`,
        {
          programName,
        },
        config
      );

      dispatch({
        type: EVENT_PROGRAM_NEW_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EVENT_PROGRAM_NEW_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const eventAllProgram = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EVENT_PROGRAM_ALL_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(`/api/events/programs/${id}`, config);

    dispatch({
      type: EVENT_PROGRAM_ALL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EVENT_PROGRAM_ALL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getEventProgramById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EVENT_PROGRAM_BY_ID_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(`/api/events/programby/${id}`, {}, config);

    dispatch({
      type: EVENT_PROGRAM_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EVENT_PROGRAM_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateEventProgramById =
  (eventProgramId, programName) => async (dispatch, getState) => {
    try {
      dispatch({
        type: EVENT_PROGRAM_UPDATE_BY_ID_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/events/programby/${eventProgramId}`,
        { programName },
        config
      );

      dispatch({
        type: EVENT_PROGRAM_UPDATE_BY_ID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EVENT_PROGRAM_UPDATE_BY_ID_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteEventProgram = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EVENT_PROGRAM_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/events/programby/${id}`, config);

    dispatch({ type: EVENT_PROGRAM_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: EVENT_PROGRAM_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

////////////////////Event Program Group///////////////////////////////////////
export const newEventGroup =
  (
    id,
    eventId,
    groupName,
    ageFrom,
    ageTo,
    gradeFrom,
    gradeTo,
    ProgramDateFrom,
    ProgramDateTo,
    fee
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: EVENT_GROUP_NEW_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `/api/events/newGroup/${id}`,
        {
          eventId,
          groupName,
          ageFrom,
          ageTo,
          gradeFrom,
          gradeTo,
          ProgramDateFrom,
          ProgramDateTo,
          fee,
        },
        config
      );

      dispatch({
        type: EVENT_GROUP_NEW_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EVENT_GROUP_NEW_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const eventAllGroup = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EVENT_GROUP_ALL_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(`/api/events/groups/${id}`, config);

    dispatch({
      type: EVENT_GROUP_ALL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EVENT_GROUP_ALL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getEventGroupById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EVENT_GROUP_BY_ID_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(`/api/events/groupby/${id}`, {}, config);

    dispatch({
      type: EVENT_GROUP_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EVENT_GROUP_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateEventGroupById =
  (
    // eventGroupId,
    // groupName,
    // ageFrom,
    // ageTo,
    // gradeFrom,
    // gradeTo,
    // ProgramDateFrom,
    // ProgramDateTo
    updateGroup
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: EVENT_GROUP_UPDATE_BY_ID_REQUEST,
      });
      const id = updateGroup.eventGroupId;

      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      console.log(id);

      const { data } = await axios.put(
        `/api/events/groupby/${updateGroup.eventGroupId}`,

        // groupName,
        // ageFrom,
        // ageTo,
        // gradeFrom,
        // gradeTo,
        // ProgramDateFrom,
        // ProgramDateTo,
        updateGroup,

        config
      );

      dispatch({
        type: EVENT_GROUP_UPDATE_BY_ID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EVENT_GROUP_UPDATE_BY_ID_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteEventGroup = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EVENT_GROUP_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/events/groupby/${id}`, config);

    dispatch({ type: EVENT_GROUP_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: EVENT_GROUP_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

////////////////////Event Hotel///////////////////////////////////////
export const newEventHotel =
  (id, hotelName, hotelDescription, hotelAddress, hotelSiteLink) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: EVENT_HOTEL_NEW_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `/api/events/newHotel/${id}`,
        {
          hotelName,
          hotelDescription,
          hotelAddress,
          hotelSiteLink,
        },
        config
      );

      dispatch({
        type: EVENT_HOTEL_NEW_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EVENT_HOTEL_NEW_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const eventAllHotel = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EVENT_HOTEL_ALL_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(`/api/events/hotels/${id}`, config);

    dispatch({
      type: EVENT_HOTEL_ALL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EVENT_HOTEL_ALL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getEventHotelById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EVENT_HOTEL_BY_ID_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(`/api/events/hotelby/${id}`, {}, config);

    dispatch({
      type: EVENT_HOTEL_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EVENT_HOTEL_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateEventHotelById =
  (eventHotelId, hotelName, hotelDescription, hotelAddress, hotelSiteLink) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: EVENT_HOTEL_UPDATE_BY_ID_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/events/hotelby/${eventHotelId}`,
        {
          hotelName,
          hotelDescription,
          hotelAddress,
          hotelSiteLink,
        },
        config
      );

      dispatch({
        type: EVENT_HOTEL_UPDATE_BY_ID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EVENT_HOTEL_UPDATE_BY_ID_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteEventHotel = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EVENT_HOTEL_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/events/hotelby/${id}`, config);

    dispatch({ type: EVENT_HOTEL_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: EVENT_HOTEL_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

////////////////////Event Hotel Room///////////////////////////////////////
export const newEventHotelRoom =
  (
    id,
    eventId,
    roomType,
    roomDescription,
    accomodation,
    rent
    // extraBed,
    // extraRent
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: EVENT_ROOM_NEW_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `/api/events/newRoom/${id}`,
        {
          eventId,
          roomType,
          roomDescription,
          accomodation,
          rent,
          // extraBed,
          // extraRent,
        },
        config
      );

      dispatch({
        type: EVENT_ROOM_NEW_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EVENT_ROOM_NEW_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const eventAllHotelRoom = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EVENT_ROOM_ALL_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(`/api/events/rooms/${id}`, config);

    dispatch({
      type: EVENT_ROOM_ALL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EVENT_ROOM_ALL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getEventHotelRoomById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EVENT_ROOM_BY_ID_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(`/api/events/roomby/${id}`, {}, config);

    dispatch({
      type: EVENT_ROOM_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EVENT_ROOM_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateEventHotelRoomById =
  (
    eventHotelRoomId,
    roomType,
    roomDescription,
    accomodation,
    rent
    // extraBed,
    // extraRent
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: EVENT_ROOM_UPDATE_BY_ID_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/events/roomby/${eventHotelRoomId}`,
        {
          roomType,
          roomDescription,
          accomodation,
          rent,
          // extraBed,
          // extraRent,
        },
        config
      );

      dispatch({
        type: EVENT_ROOM_UPDATE_BY_ID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EVENT_ROOM_UPDATE_BY_ID_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteEventHotelRoom = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EVENT_ROOM_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/events/roomby/${id}`, config);

    dispatch({ type: EVENT_ROOM_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: EVENT_ROOM_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
