import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Route } from 'react-router-dom';
import Courtesy from '../../components/Career/Courtesy';
import JobAllSearchBox from '../../components/Career/JobAllSearchBox';
import JobNew from '../../components/Career/JobNew';
import JobsAll from '../../components/Career/JobsAll';

const JobAllScreen = () => {
  const checkChapter = window.location.host;

  return (
    <>
      <Courtesy />
      <Row className='content'>
        <Col md={{ span: 8, order: 1 }} className='m-0 p-1'>
          <Route render={({ match }) => <JobsAll match={match} />} />
        </Col>

        <Col md={{ span: 4, order: 12 }} className='m-0 p-1'>
          {checkChapter.replace('www.', '').split('.')[0] !== 'aabea' ? (
            <Route render={({ history }) => <JobNew history={history} />} />
          ) : null}

          <>
            {/* <!-- Blog Search Well --> */}
            <Route
              render={({ history }) => <JobAllSearchBox history={history} />}
            />
          </>
        </Col>
      </Row>
    </>
  );
};

export default JobAllScreen;
