import React, { useState } from 'react';
import { Col, Form, ListGroup, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const CreateProgram = ({ createProgram }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [programName, setProgramName] = useState('');

  return (
    <>
      <Link
        className='btn btn-outline-info btn-sm btn-block  rounded'
        to='#'
        onClick={handleShow}
      >
        Add Sub-event
      </Link>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        size='lg'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className='text-center text-info'>
            Add Sub-event
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup variant='flush'>
            <ListGroup.Item>
              <Form.Row>
                <Form.Group as={Col} md='3'>
                  <Form.Label>Program Name :</Form.Label>
                </Form.Group>
                <Col>
                  <Form.Group as={Col} controlId='programName'>
                    <Form.Control
                      required
                      type='text'
                      placeholder='New Program Name..'
                      value={programName}
                      onChange={(e) => setProgramName(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Form.Row>
            </ListGroup.Item>
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Link
            className='btn btn-outline-danger btn-sm rounded'
            to='#'
            onClick={handleClose}
          >
            Cancel
          </Link>

          <Link
            className='btn btn-outline-info btn-sm rounded'
            to='#'
            onClick={() => {
              createProgram(programName);
              handleClose();
            }}
          >
            Create
          </Link>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateProgram;
