import React from 'react';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';

// const PNBody1 = styled.div`
//   ul {
//     display: -ms-flexbox;
//     display: flex;
//     padding-left: 0;
//     list-style: none;
//     border: 1px solid red;
//   }
//   ul a:hover {
//     text-decoration: none;
//   }
//   li:not(:first-child) a {
//     margin-left: -1px;
//   }
//   li.active a {
//     z-index: 3;
//     color: #fff;
//     background-color: #eb6864;
//     border-color: #eb6864;
//   }
//   li.disabled a {
//     color: #777;
//     pointer-events: none;
//     background-color: #fff;
//     border-color: #dee2e6;
//   }
//   li:first-child a {
//     border-top-left-radius: 0.25rem;
//     border-bottom-left-radius: 0.25rem;
//   }
//   li:last-child a {
//     border-top-right-radius: 0.25rem;
//     border-bottom-right-radius: 0.25rem;
//   }
//   a {
//     position: relative;
//     display: block;
//     color: #eb6864;
//     text-decoration: none;
//     background-color: #fff;
//     border: 1px solid #dee2e6;
//     transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
//       border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
//   }
//   @media (prefers-reduced-motion: reduce) {
//     a {
//       transition: none;
//     }
//   }
//   a:hover {
//     z-index: 2;
//     color: #fff;
//     background-color: #eb6864;
//     border-color: #eb6864;
//   }
//   a:focus {
//     z-index: 3;
//     color: #033c73;
//     background-color: #eee;
//     outline: 0;
//     box-shadow: 0 0 0 0.25rem rgba(235, 104, 100, 0.25);
//   }
//   a {
//     padding: 0.375rem 0.75rem;
//   }
// `;

const PNBody2 = styled.div`
  ul {
    width: fit-content !important;
    margin: 0;
  }
  /* .page-item.active .page-link {
    z-index: 3;
    color: #fff;
    /* background-color: #dfdf; */
  /* background-color: #033c73; */
  /* background-color: rgba(47, 164, 231, 0.5); */
  /* border-color: unset; */
  /* } */
`;

const Paginate3 = ({
  totalPages,
  currentPage,
  keyword = '',
  changePageNumber,
}) => {
  return (
    <PNBody2>
      <ReactPaginate
        pageCount={totalPages}
        pageRangeDisplayed={1}
        marginPagesDisplayed={1}
        initialPage={currentPage}
        previousLabel='‹'
        nextLabel='›'
        containerClassName='pagination'
        activeClassName='active'
        activeLinkClassName='active'
        disabledClassName=''
        pageClassName='page-item'
        previousClassName='page-item'
        breakClassName='page-item'
        nextClassName='page-item'
        pageLinkClassName='page-link'
        previousLinkClassName='page-link'
        breakLinkClassName='page-link'
        nextLinkClassName='page-link'
        onPageChange={(e) => {
          console.log('onPageChange');
          console.log(e.selected);
          // if (keyword) {
          //     // history.push(`/search/${keyword}/page/${e.selected + 1}`)
          // } else {
          changePageNumber(e.selected);
          // }
        }}
        onPageActive={(e) => {
          console.log('onPageActive');
          console.log(e.selected);
        }}
      />
    </PNBody2>
  );
};

export default Paginate3;
