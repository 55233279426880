import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';

import {
  Button,
  Card,
  Col,
  Image,
  ListGroup,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getAdvById } from '../../../actions/advAction';
import Loader from '../../Loader';
import Message from '../../Message';

const AdvertisementById = ({ image, height }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [id, setId] = useState('');
  const [sponsorId, setSponsorId] = useState('');

  const advById = useSelector((state) => state.advById);
  const { loading, error, adv } = advById;

  // console.log(image);
  useEffect(() => {
    console.log(id);
    dispatch(getAdvById(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sponsorId]);
  // console.log(sponsorId);
  // console.log(adv);

  return (
    <>
      <OverlayTrigger
        overlay={<Tooltip id='tooltip-disabled'>{image.type}</Tooltip>}
      >
        <span
          onClick={() => {
            // dispatch(getAdvById(image.imageId));
            setId(image.imageId);
            setSponsorId(image.sponsorId);
            handleShow();
          }}
        >
          <Image
            src={image.image}
            alt={image.type}
            fluid
            style={{
              cursor: 'pointer',
              width: '100%',
              height: `${height}`,
              padding: '0.2px',
            }}
            // className='navbarEventImage'
          />
        </span>
      </OverlayTrigger>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        size='xl'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className='text-center text-info'>
            Sponsor Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Image
              src={image.image}
              alt={image.type}
              fluid
              style={{
                // cursor: 'pointer',
                width: '100%',
                height: '80vh',
                padding: '0.2px',
              }}
              // className='navbarEventImage'
            />
            {loading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>{error}</Message>
            ) : (
              adv &&
              adv.sponsorId && (
                <Card>
                  <Card.Header className='text-info' as='h2'>
                    {adv.sponsor.sponsorName.toUpperCase()}
                  </Card.Header>
                  <Card.Body>
                    <ListGroup variant='flush'>
                      <ListGroup.Item>
                        <Row>
                          <Col md={3}>Details:</Col>
                          <Col>
                            {parse(`<div>${adv.sponsor.description}</div>`)}
                          </Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col md={3}>Email:</Col>
                          <Col>{adv.sponsor.email}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col md={3}>Phone:</Col>
                          <Col>{adv.sponsor.phone}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col md={3}>Address:</Col>
                          <Col>{adv.sponsor.address}</Col>
                        </Row>
                      </ListGroup.Item>
                    </ListGroup>
                  </Card.Body>
                </Card>
              )
            )}
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='transparent'
            className='btn btn-outline-danger btn-sm rounded'
            to='#'
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdvertisementById;
