import React from "react";
import { Image } from "react-bootstrap";
import Slider from "react-slick";

const ActivityImageCarousel = ({ images }) => {
  var settings = {
    dots: false,
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <Slider {...settings}>
        {images &&
          images.map((image, index) => (
            <div key={index}>
              {image.split(".").pop() === "pdf" ? (
                <iframe
                  // id='viewer'
                  title="#"
                  style={{ width: "100%", height: "800px" }}
                  src={image}
                ></iframe>
              ) : (
                <Image
                  src={image}
                  alt={image}
                  fluid
                  style={{ width: "100%", height: "50%", padding: "0.2px" }}
                />
              )}
            </div>
          ))}
      </Slider>
    </div>
  );
};
export default ActivityImageCarousel;
