export const VISSION_NEW_REQUEST = 'VISSION_NEW_REQUEST';
export const VISSION_NEW_SUCCESS = 'VISSION_NEW_SUCCESS';
export const VISSION_NEW_FAIL = 'VISSION_NEW_FAIL';
export const VISSION_NEW_RESET = 'VISSION_NEW_RESET';

export const VISSION_ALL_REQUEST = 'VISSION_ALL_REQUEST';
export const VISSION_ALL_SUCCESS = 'VISSION_ALL_SUCCESS';
export const VISSION_ALL_FAIL = 'VISSION_ALL_FAIL';

export const VISSION_BY_ID_REQUEST = 'VISSION_BY_ID_REQUEST';
export const VISSION_BY_ID_SUCCESS = 'VISSION_BY_ID_SUCCESS';
export const VISSION_BY_ID_FAIL = 'VISSION_BY_ID_FAIL';
export const VISSION_BY_ID_RESET = 'VISSION_BY_ID_RESET';

export const VISSION_UPDATE_BY_ID_REQUEST = 'VISSION_UPDATE_BY_ID_REQUEST';
export const VISSION_UPDATE_BY_ID_SUCCESS = 'VISSION_UPDATE_BY_ID_SUCCESS';
export const VISSION_UPDATE_BY_ID_FAIL = 'VISSION_UPDATE_BY_ID_FAIL';
export const VISSION_UPDATE_BY_ID_RESET = 'VISSION_UPDATE_BY_ID_RESET';

export const VISSION_DELETE_REQUEST = 'VISSION_DELETE_REQUEST';
export const VISSION_DELETE_SUCCESS = 'VISSION_DELETE_SUCCESS';
export const VISSION_DELETE_FAIL = 'VISSION_DELETE_FAIL';
