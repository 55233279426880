import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../../components/Sidebar/Sidebar';

import * as S from './ImagesScreen.Styles';
import HomeMainBanner from '../../components/Advertisement/HomeMainBanner';
import Advertisement from '../../components/Advertisement/Advertisement';
import ManageSponsor from '../../components/Advertisement/ManageSponsor';
import EventAdvertisements from '../../components/Advertisement/EventAdvertisements';
import HomeVideoBox from '../../components/Advertisement/HomeVideoBox';
import HomeImageBox from '../../components/Advertisement/HomeImageBox';

const AdvertisementScreen = ({ history, match }) => {
  const dispatch = useDispatch();
  const checkChapter = window.location.host;

  const chapterBySubDomain = useSelector((state) => state.chapterBySubDomain);
  const { chapterByDomain } = chapterBySubDomain;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (chapterByDomain && chapterByDomain.advertisement) {
      if (
        !userInfo ||
        (userInfo &&
          !(
            userInfo.userRole === 'systemAdmin' || userInfo.userRole === 'admin'
          ))
      ) {
        history.push('/login');
      }
    } else {
      history.push('/');
    }
  }, [dispatch, history, chapterByDomain, userInfo, checkChapter]);

  const advtitle = match.params.advtitle;
  // console.log(advtitle);
  // console.log(match.params.advgroup);
  // const query = history.location.search
  //   ? history.location.search.split('=')
  //   : null;

  return (
    <>
      <Row className='content'>
        {/* Sidebar */}
        <Col
          md={{ span: 3, order: 1 }}
          lg={{ span: 3, order: 1 }}
          id='sidebar-wrapper'
          className='m-0 p-1'
        >
          <Sidebar customMatch={match} eventKey='1' />
        </Col>
        {/* Sidebar End */}
        <Col
          md={{ span: 9, order: 12 }}
          lg={{ span: 9, order: 12 }}
          id='page-content-wrapper'
          className='m-0 p-1'
        >
          <S.CardDeck>
            {/* <CardColumns> */}
            {chapterByDomain && chapterByDomain.advertisement ? (
              <>
                {match.params.advtitle === 'manage-sponsor' ? (
                  <ManageSponsor
                    history={history}
                    advType={match.params.advtitle}
                  />
                ) : match.params.advtitle === 'event-sponsor' ? (
                  <EventAdvertisements
                    history={history}
                    advType={match.params.advtitle}
                  />
                ) : match.params.advtitle === 'home-main-banner' ? (
                  <HomeMainBanner
                    history={history}
                    advType={match.params.advtitle}
                  />
                ) : match.params.advtitle === 'home-video-box' ? (
                  <HomeVideoBox
                    history={history}
                    advType={match.params.advtitle}
                  />
                ) : match.params.advtitle === 'home-image-box' ? (
                  <HomeImageBox
                    history={history}
                    advType={match.params.advtitle}
                  />
                ) : (
                  <Advertisement />
                )}
              </>
            ) : null}
          </S.CardDeck>
        </Col>
      </Row>
    </>
  );
};

export default AdvertisementScreen;
