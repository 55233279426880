import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Card, Image } from 'react-bootstrap';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import { getImageByEvent, getImageById } from '../../actions/imageActions';
import ImageByEventGroup from '../../components/Image/ImageByEventGroup';

const ImageByIdScreen = ({ match }) => {
  const id = match.params.id;
  const dispatch = useDispatch();

  const imageById = useSelector((state) => state.imageById);
  const { loading, error, image } = imageById;

  const imageByEvent = useSelector((state) => state.imageByEvent);
  const {
    loading: imageByEventLoading,
    error: imageByEventError,
    images: imageByEventImages,
  } = imageByEvent;

  const imageDelete = useSelector((state) => state.imageDelete);
  const { success: successDelete } = imageDelete;

  const checkChapter = window.location.host;

  useEffect(() => {
    dispatch(getImageById(id));
    dispatch(getImageByEvent(checkChapter));
  }, [dispatch, id, checkChapter, successDelete]);

  return (
    <>
      <Card className='text-center mb-2' border='info'>
        <Card.Body>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <>
              {image && (
                <>
                  <Card.Header as='h3' className='text-info'>
                    {image.imageDescription}
                  </Card.Header>
                  <Image
                    src={image.image} // api/uploads/pdf-file
                    alt={image.image}
                    fluid
                    rounded
                    style={{ height: '80vh', width: '80%' }}
                  />
                </>
              )}
            </>
          )}
        </Card.Body>
      </Card>

      {/* Event Images */}
      <Card className='text-center mb-2' border='info'>
        <Card.Header as='h3' className='text-info'>
          Event Images
        </Card.Header>

        <>
          {imageByEventLoading ? (
            <Loader />
          ) : imageByEventError ? (
            <Message variant='danger'>{imageByEventError}</Message>
          ) : imageByEventImages && imageByEventImages.length !== 0 ? (
            <>
              <ImageByEventGroup events={imageByEventImages} />
            </>
          ) : null}
        </>
      </Card>
    </>
  );
};

export default ImageByIdScreen;
