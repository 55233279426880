import axios from 'axios';
import {
  MEMBER_TYPE_ALL_FAIL,
  MEMBER_TYPE_ALL_REQUEST,
  MEMBER_TYPE_ALL_SUCCESS,
  MEMBER_TYPE_BY_ID_FAIL,
  MEMBER_TYPE_BY_ID_REQUEST,
  MEMBER_TYPE_BY_ID_SUCCESS,
  MEMBER_TYPE_DELETE_FAIL,
  MEMBER_TYPE_DELETE_REQUEST,
  MEMBER_TYPE_DELETE_SUCCESS,
  MEMBER_TYPE_NEW_FAIL,
  MEMBER_TYPE_NEW_REQUEST,
  MEMBER_TYPE_NEW_SUCCESS,
  MEMBER_TYPE_UPDATE_BY_ID_FAIL,
  MEMBER_TYPE_UPDATE_BY_ID_REQUEST,
  MEMBER_TYPE_UPDATE_BY_ID_SUCCESS,
} from '../constants/memberTypeConstant';

export const newMType = (mTypeData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MEMBER_TYPE_NEW_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    console.log(mTypeData);

    const { data } = await axios.post('/api/mtype', mTypeData, config);

    dispatch({
      type: MEMBER_TYPE_NEW_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MEMBER_TYPE_NEW_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const allMTypes = (checkChapter) => async (dispatch) => {
  try {
    dispatch({
      type: MEMBER_TYPE_ALL_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    // console.log('from action page: ' + checkChapter);
    const { data } = await axios.get(
      `/api/mtype/chapter/${checkChapter}`,
      config
    );

    dispatch({
      type: MEMBER_TYPE_ALL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MEMBER_TYPE_ALL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getMTypeById = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MEMBER_TYPE_BY_ID_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/mtype/${id}`, config);

    dispatch({
      type: MEMBER_TYPE_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MEMBER_TYPE_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateMTypeById = (mTypedata) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MEMBER_TYPE_UPDATE_BY_ID_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/mtype/${mTypedata.id}`,
      mTypedata,
      config
    );

    dispatch({
      type: MEMBER_TYPE_UPDATE_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MEMBER_TYPE_UPDATE_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteMType = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MEMBER_TYPE_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/mtype/${id}`, config);

    dispatch({ type: MEMBER_TYPE_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: MEMBER_TYPE_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
