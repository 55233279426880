import {
  SPONSOR_ALL_FAIL,
  SPONSOR_ALL_REQUEST,
  SPONSOR_ALL_SUCCESS,
  SPONSOR_BY_ID_FAIL,
  SPONSOR_BY_ID_REQUEST,
  SPONSOR_BY_ID_RESET,
  SPONSOR_BY_ID_SUCCESS,
  SPONSOR_DELETE_FAIL,
  SPONSOR_DELETE_REQUEST,
  SPONSOR_DELETE_SUCCESS,
  SPONSOR_NEW_FAIL,
  SPONSOR_NEW_REQUEST,
  SPONSOR_NEW_RESET,
  SPONSOR_NEW_SUCCESS,
  SPONSOR_UPDATE_BY_ID_FAIL,
  SPONSOR_UPDATE_BY_ID_REQUEST,
  SPONSOR_UPDATE_BY_ID_RESET,
  SPONSOR_UPDATE_BY_ID_SUCCESS,
} from '../constants/sponsorConstant';

export const sponsorNewReducer = (state = {}, action) => {
  switch (action.type) {
    case SPONSOR_NEW_REQUEST:
      return { loading: true };
    case SPONSOR_NEW_SUCCESS:
      return { loading: false, success: action.payload };
    case SPONSOR_NEW_FAIL:
      return { loading: false, error: action.payload };
    case SPONSOR_NEW_RESET:
      return {};
    default:
      return state;
  }
};

export const sponsorAllReducer = (state = { sponsors: [] }, action) => {
  switch (action.type) {
    case SPONSOR_ALL_REQUEST:
      return { loading: true };
    case SPONSOR_ALL_SUCCESS:
      return {
        loading: false,
        sponsors: action.payload,
      };
    case SPONSOR_ALL_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const sponsorByIdReducer = (state = { sponsor: {} }, action) => {
  switch (action.type) {
    case SPONSOR_BY_ID_REQUEST:
      return { ...state, loading: true };
    case SPONSOR_BY_ID_SUCCESS:
      return { loading: false, success: true, sponsor: action.payload };
    case SPONSOR_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    case SPONSOR_BY_ID_RESET:
      return {};
    default:
      return state;
  }
};

export const sponsorUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case SPONSOR_UPDATE_BY_ID_REQUEST:
      return { loading: true };
    case SPONSOR_UPDATE_BY_ID_SUCCESS:
      return { loading: false, success: true, sponsor: action.payload };
    case SPONSOR_UPDATE_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    case SPONSOR_UPDATE_BY_ID_RESET:
      return {};
    default:
      return state;
  }
};

export const sponsorDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case SPONSOR_DELETE_REQUEST:
      return { loading: true };
    case SPONSOR_DELETE_SUCCESS:
      return { loading: false, success: true };
    case SPONSOR_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
