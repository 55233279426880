export const CATEGORY_NEW_REQUEST = 'CATEGORY_NEW_REQUEST';
export const CATEGORY_NEW_SUCCESS = 'CATEGORY_NEW_SUCCESS';
export const CATEGORY_NEW_FAIL = 'CATEGORY_NEW_FAIL';
export const CATEGORY_NEW_RESET = 'CATEGORY_NEW_RESET';

export const CATEGORY_ALL_REQUEST = 'CATEGORY_ALL_REQUEST';
export const CATEGORY_ALL_SUCCESS = 'CATEGORY_ALL_SUCCESS';
export const CATEGORY_ALL_FAIL = 'CATEGORY_ALL_FAIL';

export const CATEGORY_BY_ID_REQUEST = 'CATEGORY_BY_ID_REQUEST';
export const CATEGORY_BY_ID_SUCCESS = 'CATEGORY_BY_ID_SUCCESS';
export const CATEGORY_BY_ID_FAIL = 'CATEGORY_BY_ID_FAIL';
export const CATEGORY_BY_ID_RESET = 'CATEGORY_BY_ID_RESET';

export const CATEGORY_UPDATE_BY_ID_REQUEST = 'CATEGORY_UPDATE_BY_ID_REQUEST';
export const CATEGORY_UPDATE_BY_ID_SUCCESS = 'CATEGORY_UPDATE_BY_ID_SUCCESS';
export const CATEGORY_UPDATE_BY_ID_FAIL = 'CATEGORY_UPDATE_BY_ID_FAIL';
export const CATEGORY_UPDATE_BY_ID_RESET = 'CATEGORY_UPDATE_BY_ID_RESET';

export const CATEGORY_DELETE_REQUEST = 'CATEGORY_DELETE_REQUEST';
export const CATEGORY_DELETE_SUCCESS = 'CATEGORY_DELETE_SUCCESS';
export const CATEGORY_DELETE_FAIL = 'CATEGORY_DELETE_FAIL';
