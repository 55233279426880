/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";

const PersonNumberInput = ({ person, setNumberInput, maxUserInput }) => {
  const [qty, setQty] = useState(Number(person) || 1);

  useEffect(() => {
    if (!!maxUserInput && qty > maxUserInput) {
      setNumberInput(maxUserInput);
      setQty(maxUserInput);
    } else {
      setNumberInput(qty);
    }
  }, [qty, person, maxUserInput]);

  const increment = (e) => {
    e.preventDefault();

    setQty(qty + 1);
  };

  const decrement = (e) => {
    e.preventDefault();

    setQty(qty - 1);
  };

  return (
    <Row className="m-0 p-0">
      <Col className="m-0 p-0">
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <Button
              className="btn btn-info btn-sm rounded mr-2"
              variant="transparent"
              onClick={decrement}
              disabled={qty === 1}
            >
              <i
                className="fas fa-minus action"
                style={{
                  cursor: "pointer",
                }}
              ></i>
            </Button>
          </InputGroup.Prepend>
          <Form.Control
            required
            type="number"
            value={qty}
            onChange={(e) => setQty(Number(e.target.value))}
            min="1"
            className="text-center font-weight-bold"
          ></Form.Control>
          <InputGroup.Append>
            <Button
              className="btn btn-info btn-sm rounded ml-2"
              variant="transparent"
              onClick={increment}
              disabled={!!maxUserInput && qty === maxUserInput}
            >
              <i
                className="fas fa-plus action"
                style={{
                  cursor: "pointer",
                }}
              ></i>
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </Col>
    </Row>
  );
};

export default PersonNumberInput;
