import React from 'react';
import { Pagination } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const JobPaginate = ({ totalPages, currentPage, keyword = '' }) => {
  return (
    totalPages > 1 && (
      <Pagination>
        {[...Array(totalPages).keys()].map((x) => (
          <LinkContainer
            key={x}
            to={
              keyword ? `/jobs/search/${keyword}/page/${x}` : `/jobs/page/${x}`
            }
          >
            <Pagination.Item active={x === currentPage}>
              {x + 1}
            </Pagination.Item>
          </LinkContainer>
        ))}
      </Pagination>
    )
  );
};

export default JobPaginate;
