import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  Form,
  Row,
  Col,
  Card,
  Container,
  ListGroup,
  Button,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { PayPalButton } from "react-paypal-button-v2";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import { getEventById } from "../../actions/eventActions";
import { addToCart } from "../../actions/cartAction";
import { EVENT_REGISTER_RESET } from "../../constants/eventConstants";
import { getUserProfile, listAllUsers } from "../../actions/userActions";
import { registerEvent } from "../../actions/eventActions";

import swal from "sweetalert";

const GeneralEventRegistrationScreen = ({ match, history }) => {
  const { id } = match.params;

  const [mInit, setMInit] = useState("Mr");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isMember, setIsMember] = useState(false);
  const [memberId, setMemberId] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [numberOfAdults, setNumberOfAdults] = useState(1);
  const [numberOfMinors, setNumberOfMinors] = useState(0);
  const [contributionAmount, setContributionAmount] = useState(0);
  const [loggedInUser, setLoggedInUser] = useState(false);

  const [sdkReady, setSdkReady] = useState(false);
  const [eventProgramGroupId, setEventProgramGroupId] = useState("");
  const [nominationFee, setNominationFee] = useState(0);
  const [selectedPosition, setSelectedPosition] = useState("");
  const [readyToPay, setReadyToPay] = useState(false);

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  const userAllList = useSelector((state) => state.userAllList);
  const { users: allUserList } = userAllList;

  const eventById = useSelector((state) => state.eventById);
  const { loading, error, event } = eventById;

  const cart = useSelector((state) => state.cart);
  const { error: cartError } = cart;

  const eventRegister = useSelector((state) => state.eventRegister);
  const {
    loading: eventRegisterLoading,
    error: eventRegisterError,
    success: eventRegisterSuccess,
  } = eventRegister;

  useEffect(() => {
    dispatch(getEventById(id));
    dispatch({ type: EVENT_REGISTER_RESET });

    if (userInfo) {
      if (!user.memberId) {
        dispatch(getUserProfile());
      } else {
        setMInit(user.mInit);
        setFirstName(user.firstName);
        setLastName(user.lastName);
        setIsMember(true);
        setMemberId(user.memberId);
        setEmail(user.primaryEmail);
        setPhone(user.primaryPhone);
        setLoggedInUser(true);
      }
    }
    if (!userInfo && isMember) {
      // history.push(`/login?redirect=/event/register/${id}`);
      dispatch(listAllUsers());
    }
  }, [dispatch, userInfo, user, id, history, cartError, isMember]);

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(
      addToCart({
        eventId: id,
        eventName: event.eventName,
        mInit,
        firstName,
        lastName,
        isMember,
        memberId,
        email,
        phone,
        numberOfAdults,
        numberOfMinors,
        contributionAmount,
      })
    );

    history.push(`/event/registration/payment`);
    // }
  };

  const selectedNominationPosition = (e) => {
    e.preventDefault();

    setEventProgramGroupId(e.target.value.split(",")[0]);
    setSelectedPosition(e.target.value.split(",")[1]);
    setNominationFee(e.target.value.split(",")[2]);
  };

  const memberIdHandler = (e) => {
    e.preventDefault();

    setMemberId(e.target.value.split(",")[0]);
    setMInit(e.target.value.split(",")[1]);
    setFirstName(e.target.value.split(",")[2]);
    setLastName(e.target.value.split(",")[3]);
    setEmail(e.target.value.split(",")[4]);
    setPhone(e.target.value.split(",")[5]);
  };

  const checkChapter = window.location.host;

  useEffect(() => {
    if (eventRegisterSuccess) {
      swal("Success!", "Registration successfull", "success").then(() => {
        dispatch({ type: EVENT_REGISTER_RESET });
        history.push("/");
      });
    } else if (eventRegisterError || cartError) {
      swal("Error!", eventRegisterError || cartError, "error");
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const addPaypalScript = async () => {
      const { data: clientId } = await axios.get(
        `/api/chapters/paypal/${checkChapter}`,
        config
      );
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}`;
      script.async = true;
      script.onload = () => {
        setSdkReady(true);
      };
      document.body.appendChild(script);
    };

    if (!window.paypal) {
      addPaypalScript();
    } else {
      setSdkReady(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    history,
    checkChapter,
    eventRegisterSuccess,
    eventRegisterError,
  ]);

  const successPaymentHandler = (paymentResult) => {
    dispatch(
      registerEvent({
        eventId: id,
        eventName: event.eventName,
        mInit,
        firstName,
        lastName,
        isMember,
        memberId,
        email,
        phone,
        totalAmount: nominationFee * 1 + contributionAmount * 1,
        paymentResult,
        eventProgramGroupId,
        selectedPosition,
        nominationFee,
        contributionAmount,
      })
    );
  };

  return (
    <Container>
      <>
        <Card border="info" className="mt-2">
          <Card.Header className="text-center text-info" as="h5">
            Event Registration
          </Card.Header>
          {eventRegisterLoading ? (
            <Loader />
          ) : (
            <Card.Body>
              <Form onSubmit={submitHandler}>
                <Row>
                  {loading ? (
                    <Loader />
                  ) : error ? (
                    <Message variant="danger">{error}</Message>
                  ) : event ? (
                    event && (
                      <>
                        <Col md={8}>
                          <ListGroup variant="flush">
                            {event.eventId !== "42141" ? (
                              <ListGroup.Item>
                                <Row>
                                  <Col md={3} className="text-info p-0" as="h5">
                                    Is a Member ?{" "}
                                  </Col>
                                  <Col>
                                    <>
                                      <Form.Group>
                                        <Form.Control
                                          required
                                          as="select"
                                          // type='text'
                                          value={isMember}
                                          // onChange={memberYesNoHandler}
                                          onChange={(e) =>
                                            setIsMember(e.target.value)
                                          }
                                          readOnly={loggedInUser}
                                          disabled={loggedInUser}
                                        >
                                          <option value="false">No</option>
                                          <option value="true">Yes</option>
                                        </Form.Control>
                                      </Form.Group>

                                      {isMember && isMember === "true" ? (
                                        <Form.Group controlId="memberId">
                                          <Form.Control
                                            required
                                            // type='number'
                                            // placeholder='Please Enter the member Id..'
                                            // value={memberId}
                                            // onChange={(e) =>
                                            //   setMemberId(e.target.value)
                                            // }
                                            as="select"
                                            onChange={memberIdHandler}
                                            readOnly={loggedInUser}
                                          >
                                            <option>Select member Id</option>
                                            {allUserList &&
                                              allUserList.length !== 0 &&
                                              allUserList.map((user) => (
                                                <option
                                                  key={user.memberId}
                                                  value={[
                                                    user.memberId,
                                                    user.mInit,
                                                    user.firstName,
                                                    user.lastName,
                                                    user.primaryEmail,
                                                    user.primaryPhone,
                                                  ]}
                                                >
                                                  {user.memberId}
                                                </option>
                                              ))}
                                          </Form.Control>
                                        </Form.Group>
                                      ) : (
                                        <Form.Group controlId="memberIdGuset">
                                          <Form.Control
                                            type="number"
                                            placeholder="Please enter a referrel Member Id..(optional)"
                                            value={memberId}
                                            onChange={(e) =>
                                              setMemberId(e.target.value)
                                            }
                                            readOnly={loggedInUser}
                                          ></Form.Control>
                                        </Form.Group>
                                      )}
                                    </>
                                  </Col>
                                </Row>
                              </ListGroup.Item>
                            ) : null}

                            {/* Name */}
                            <ListGroup.Item>
                              <Row>
                                <Col md={3} className="text-info p-0" as="h5">
                                  Name{" "}
                                </Col>
                                <Col>
                                  <>
                                    <Row>
                                      <Col md={3}>Title: </Col>
                                      <Col>
                                        <Form.Group controlId="mInit">
                                          <Form.Control
                                            required
                                            as="select"
                                            type="text"
                                            value={mInit}
                                            onChange={(e) =>
                                              setMInit(e.target.value)
                                            }
                                            disabled={
                                              loggedInUser ||
                                              isMember === "true"
                                            }
                                            readOnly={
                                              loggedInUser ||
                                              isMember === "true"
                                            }
                                          >
                                            <option value="Mr">Mr</option>
                                            <option value="Mrs">Mrs</option>
                                            <option value="Miss">Ms</option>
                                          </Form.Control>
                                        </Form.Group>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col md={3}>First Name: </Col>
                                      <Col>
                                        <Form.Group controlId="firstName">
                                          <Form.Control
                                            type="text"
                                            placeholder="Please Enter Your First Name.."
                                            value={firstName}
                                            onChange={(e) =>
                                              setFirstName(e.target.value)
                                            }
                                            disabled={
                                              loggedInUser ||
                                              isMember === "true"
                                            }
                                            readOnly={
                                              loggedInUser ||
                                              isMember === "true"
                                            }
                                          ></Form.Control>
                                        </Form.Group>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col md={3}>Last Name: </Col>
                                      <Col>
                                        <Form.Group controlId="lastName">
                                          <Form.Control
                                            type="lastName"
                                            placeholder="Please Enter Your Last Name.."
                                            value={lastName}
                                            onChange={(e) =>
                                              setLastName(e.target.value)
                                            }
                                            disabled={
                                              loggedInUser ||
                                              isMember === "true"
                                            }
                                            readOnly={
                                              loggedInUser ||
                                              isMember === "true"
                                            }
                                          ></Form.Control>
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </>
                                </Col>
                              </Row>
                            </ListGroup.Item>

                            {/* Contact Details */}
                            <ListGroup.Item>
                              <Row>
                                <Col md={3} className="text-info p-0" as="h5">
                                  Contact Details:{" "}
                                </Col>
                                <Col>
                                  <>
                                    <Row>
                                      <Col md={3}>Phone Number :</Col>
                                      <Col>
                                        <Form.Group controlId="phone">
                                          <Form.Control
                                            type="phone"
                                            placeholder="Enter Your Phone Number.."
                                            value={phone}
                                            onChange={(e) =>
                                              setPhone(e.target.value)
                                            }
                                            disabled={
                                              loggedInUser ||
                                              isMember === "true"
                                            }
                                            readOnly={
                                              loggedInUser ||
                                              isMember === "true"
                                            }
                                          ></Form.Control>
                                        </Form.Group>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col md={3}>Email :</Col>
                                      <Col>
                                        <Form.Group controlId="email">
                                          <Form.Control
                                            required
                                            type="email"
                                            placeholder="Enter Email.."
                                            value={email}
                                            onChange={(e) =>
                                              setEmail(e.target.value)
                                            }
                                            disabled={
                                              loggedInUser ||
                                              isMember === "true"
                                            }
                                            readOnly={
                                              loggedInUser ||
                                              isMember === "true"
                                            }
                                          ></Form.Control>
                                          <Form.Control.Feedback type="invalid">
                                            **Required
                                          </Form.Control.Feedback>
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </>
                                </Col>
                              </Row>
                            </ListGroup.Item>

                            {event.eventType === "election" && (
                              <>
                                <ListGroup.Item>
                                  <Row>
                                    <Col
                                      md={3}
                                      className="text-info p-0"
                                      as="h5"
                                    >
                                      Please a Select Nomination Type
                                    </Col>
                                    <Col>
                                      <>
                                        <Form.Group controlId="programGroupId">
                                          <Form.Control
                                            required
                                            as="select"
                                            onChange={
                                              selectedNominationPosition
                                            }
                                          >
                                            <option>Select A Position</option>
                                            {event.eventPrograms &&
                                              event.eventPrograms.length !==
                                                0 &&
                                              event.eventPrograms[0].eventProgramGroups?.map(
                                                (position) => (
                                                  <option
                                                    key={position.eventGroupId}
                                                    value={[
                                                      position.eventGroupId,
                                                      position.groupName,
                                                      position.fee,
                                                    ]}
                                                  >
                                                    {position.groupName}
                                                  </option>
                                                )
                                              )}
                                          </Form.Control>
                                        </Form.Group>
                                      </>
                                    </Col>
                                  </Row>
                                </ListGroup.Item>
                              </>
                            )}

                            {event.eventType &&
                            event.eventType !== "donation" &&
                            event.eventType !== "election" &&
                            event.eventType !== "fund-raising" ? (
                              <>
                                {/* Number of Attendees */}
                                <ListGroup.Item>
                                  <Row>
                                    <Col
                                      md={3}
                                      className="text-info p-0"
                                      as="h5"
                                    >
                                      Number Of Attendees:
                                    </Col>
                                    <Col>
                                      <>
                                        {/* Adults */}
                                        <Row>
                                          <Col md={3}>Adults :</Col>
                                          <Col>
                                            <Form.Group controlId="numberOfAdults">
                                              <Form.Control
                                                required
                                                type="number"
                                                min="0"
                                                placeholder="Enter number of adults"
                                                value={numberOfAdults}
                                                onChange={(e) =>
                                                  setNumberOfAdults(
                                                    e.target.value
                                                  )
                                                }
                                              ></Form.Control>
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                        {/* Minors */}
                                        <Row>
                                          <Col md={3}>Minors :</Col>
                                          <Col>
                                            <Form.Group controlId="minors">
                                              <Form.Control
                                                required
                                                type="number"
                                                min="0"
                                                placeholder="Enter number of minors.."
                                                value={numberOfMinors}
                                                onChange={(e) =>
                                                  setNumberOfMinors(
                                                    e.target.value
                                                  )
                                                }
                                              ></Form.Control>
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                      </>
                                    </Col>
                                  </Row>
                                </ListGroup.Item>

                                {/* Extra Contribution (optional): */}
                                <ListGroup.Item>
                                  <Row>
                                    <Col
                                      md={3}
                                      className="text-info p-0"
                                      as="h5"
                                    >
                                      Extra Contribution (optional):
                                    </Col>
                                    <Col>
                                      <>
                                        <Row>
                                          <Col md={3}>USD :</Col>
                                          <Col>
                                            <Form.Group controlId="contributionAmount">
                                              <Form.Control
                                                type="number"
                                                min="0"
                                                placeholder="Contibute any amount"
                                                value={contributionAmount}
                                                onChange={(e) =>
                                                  setContributionAmount(
                                                    e.target.value
                                                  )
                                                }
                                              ></Form.Control>
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                      </>
                                    </Col>
                                  </Row>
                                </ListGroup.Item>
                              </>
                            ) : (
                              <>
                                <ListGroup.Item>
                                  <Row>
                                    <Col md={3}>Donate? :</Col>
                                    <Col>
                                      <Form.Group controlId="donateAmount">
                                        <Form.Control
                                          required
                                          type="number"
                                          min="1"
                                          // placeholder='Enter number of adults'
                                          value={contributionAmount}
                                          onChange={(e) =>
                                            setContributionAmount(
                                              e.target.value
                                            )
                                          }
                                        ></Form.Control>
                                      </Form.Group>
                                    </Col>
                                    <Form.Group as={Col} controlId="amount">
                                      <Form.Label>USD</Form.Label>
                                    </Form.Group>
                                  </Row>
                                </ListGroup.Item>
                              </>
                            )}

                            {/* <Button type='submit' variant='primary' block>
                      Update
                    </Button> */}
                          </ListGroup>
                        </Col>
                        {/* Summary */}
                        <Col md={4}>
                          <Card>
                            <ListGroup variant="flush">
                              <ListGroup.Item>
                                <h5 className="text-info">Summary</h5>
                              </ListGroup.Item>

                              <ListGroup.Item>
                                <Row>
                                  <Col md={3} className="p-0 m-0">
                                    Event Name:
                                  </Col>
                                  <Col>{event.eventName}</Col>
                                </Row>
                              </ListGroup.Item>

                              <>
                                {event.eventType &&
                                event.eventType !== "donation" &&
                                event.eventType !== "election" &&
                                event.eventType !== "fund-raising" ? (
                                  <>
                                    <ListGroup.Item>
                                      <Row>
                                        <Col>No. Of Adults:</Col>
                                        <Col>{numberOfAdults}</Col>
                                      </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                      <Row>
                                        <Col>No. Of Minors:</Col>
                                        <Col>{numberOfMinors}</Col>
                                      </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                      <Row>
                                        <Col>Registration Fee :</Col>
                                        {event.cap <=
                                        numberOfAdults * event.adultFee +
                                          numberOfMinors * event.minorFee ? (
                                          <Col>${event.cap}</Col>
                                        ) : (
                                          <Col>
                                            $
                                            {numberOfAdults * event.adultFee +
                                              numberOfMinors * event.minorFee}
                                          </Col>
                                        )}
                                      </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                      <Row>
                                        <Col>Extra Contribution :</Col>

                                        <Col>
                                          $
                                          {/* {numberOfAdults * event.adultFee +
                                        numberOfMinors * event.minorFee} */}
                                          {contributionAmount * 1}
                                        </Col>
                                      </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                      <Row>
                                        <Col>Total :</Col>

                                        {event.cap <=
                                        numberOfAdults * event.adultFee +
                                          numberOfMinors * event.minorFee ? (
                                          <Col>
                                            $
                                            {`${
                                              event.cap + contributionAmount * 1
                                            }`}
                                          </Col>
                                        ) : (
                                          <Col>
                                            $
                                            {`${
                                              numberOfAdults * event.adultFee +
                                              numberOfMinors * event.minorFee +
                                              contributionAmount * 1
                                            }`}
                                          </Col>
                                        )}
                                      </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                      <Row>
                                        <Col>
                                          Please proceed to next page to
                                          complete the registration
                                        </Col>
                                      </Row>
                                    </ListGroup.Item>
                                  </>
                                ) : (
                                  <>
                                    {event.eventType === "election" && (
                                      <>
                                        <ListGroup.Item>
                                          <Row>
                                            <Col md={3} className="p-0 m-0">
                                              Position Name:
                                            </Col>
                                            <Col>
                                              <strong>
                                                {selectedPosition}
                                              </strong>
                                            </Col>
                                          </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                          <Row>
                                            <Col md={3} className="p-0 m-0">
                                              Registration Fee:
                                            </Col>
                                            <Col>
                                              <strong>{nominationFee}</strong>
                                            </Col>
                                          </Row>
                                        </ListGroup.Item>
                                      </>
                                    )}
                                    <ListGroup.Item>
                                      <Row>
                                        <Col md={3} className="p-0 m-0">
                                          Donation:
                                        </Col>
                                        <Col>${contributionAmount}</Col>
                                      </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                      <Row>
                                        <Col md={3} className="p-0 m-0">
                                          Total :
                                        </Col>

                                        <Col>
                                          $
                                          {`${
                                            nominationFee * 1 +
                                            contributionAmount * 1
                                          }`}
                                        </Col>
                                      </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                      <Row>
                                        <Col className="p-0 m-0">
                                          Please proceed to complete the
                                          registration
                                        </Col>
                                      </Row>
                                    </ListGroup.Item>
                                  </>
                                )}
                                <ListGroup.Item>
                                  {event.eventType === "election" ? (
                                    !sdkReady ? (
                                      <Loader />
                                    ) : readyToPay ? (
                                      <PayPalButton
                                        amount={
                                          nominationFee * 1 +
                                          contributionAmount * 1
                                        }
                                        onSuccess={successPaymentHandler}
                                      />
                                    ) : (
                                      <Button
                                        variant="info"
                                        block
                                        onClick={() => setReadyToPay(true)}
                                        disabled={
                                          !firstName ||
                                          !lastName ||
                                          !phone ||
                                          !email ||
                                          !selectedPosition
                                        }
                                      >
                                        Procced
                                      </Button>
                                    )
                                  ) : (
                                    <Button
                                      type="submit"
                                      variant="info"
                                      block
                                      disabled={!email ? true : false}
                                    >
                                      Next
                                    </Button>
                                  )}
                                </ListGroup.Item>
                              </>
                            </ListGroup>
                          </Card>
                        </Col>
                      </>
                    )
                  ) : null}
                </Row>
              </Form>
            </Card.Body>
          )}
          {/* <Card.Footer className='text-muted'>2 days ago</Card.Footer> */}
        </Card>
      </>
    </Container>
  );
};

export default GeneralEventRegistrationScreen;

// ///// Register attributes
// {

//   "firstName": "jobayer", done
//   "mInit": "Mr.", done
//   "lastName": "Ahmad", done
//   "address1": "30 long Island Steet, NY,USA", Done
//   "address2": "31 long Island Steet, NY,USA", //////////////// not in from register
//   "city": "Newyork", Done
//   "state": "NY",
//   "zipcode": "123456",
//   "email": "jobayer@example.com",
//   "password": "123456",
//   "alternateEmail": "jobayer_alter@example.com",
//   "primaryPhone": "1234567890",
//   "alternatePhone": "1234567890",
//   "degree": "B.Sc",
//   "degreeYear": "2000",
//   "major": "EEE",
//   "collegeName": "AIUB",
//   "status": "active",
//   "balance": "10000.05"

// state,
// zipcode,
//
//
// primaryPhone,
//  degree,
//   degreeYear,
//   major,
//   collegeName

// }
