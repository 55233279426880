import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import { Container } from "react-bootstrap";
import Footer from "./components/Footer";
import Header from "./components/Header/Header";
import HomeScreen from "./screens/HomeScreen/HomeScreen";
import LoginScreen from "./screens/LoginScreen/LoginScreen";
import RegisterScreen from "./screens/RegistrationScreen/RegisterScreen";
import DashboardScreen from "./screens/DashboardScreen/DashboardScreen";
import UserListScreen from "./screens/UserListScreen";
import UserEditScreen from "./screens/UserEditScreen";

import PaymentScreen from "./screens/PaymentScreen/PaymentScreen";
import DonateScreen from "./screens/DonateScreen/DonateScreen";
import TrainingScreen from "./screens/TrainingScreen/TrainingScreen";
import CommittieesScreen from "./screens/CommitteesScreen/CommitteesScreen";
import EmailActivationScreen from "./screens/EmailActivationScreen/EmailActivationScreen";
import SystemAdminScreen from "./screens/SystemAdminScreen/SystemAdminScreen";
import UserPendingApproveScreen from "./screens/UserPendingApproveScreen/UserPendingApproveScreen";
import ChapterScreen from "./screens/ChapterScreen/ChapterScreen";
import PaymentTypeScreen from "./screens/PaymentTypeScreen/PaymentTypeScreen";
import AnnouncementScreen from "./screens/AnnouncementScreen/AnnouncementScreen";
import MissionScreen from "./screens/MissionScreen/MissionScreen";
import VissionScreen from "./screens/VissionScreen/VissionScreen";
import HistoryScreen from "./screens/HistoryScreen/HistoryScreen";
import PasswordResetScreen from "./screens/PasswordResetScreen/PasswordResetScreen";
import PasswordUpdateScreen from "./screens/PasswordUpdateScreen/PasswordUpdateScreen";
import ProfileScreen from "./screens/ProfileScreen/ProfileScreen";
import EditProfileScreen from "./screens/ProfileScreen/EditProfileScreen";
import ImagesScreen from "./screens/ImagesScreen/ImagesScreen";
import MembersScreen from "./screens/MembersScreen/MembersScreen";
import Announcement from "./screens/AnnouncementScreen/Announcement";
import Mission from "./screens/MissionScreen/Mission";
import Vission from "./screens/VissionScreen/Vission";
import History from "./screens/HistoryScreen/History";
import CommitteeMemberScreen from "./screens/CommitteesScreen/CommitteeMemberScreens";
import About from "./screens/AboutScreen/About";
import EventScreen from "./screens/EventScreen/EventScreen";
import SettingScreen from "./screens/SettingScreen/SettingScreen";
import EventNewScreen from "./screens/EventScreen/EventNewScreen";
import EventByIdScreen from "./screens/EventScreen/EventByIdScreen";
import EventRegisterScreen from "./screens/EventScreen/EventRegistrationScreen";
import EmailScreen from "./screens/EmailScreen/EmailScreen";
import BlogScreen from "./screens/BlogScreen/BlogScreen";
import CreateBlogScreen from "./screens/BlogScreen/CreateBlogScreen";
import BlogScreenById from "./screens/BlogScreen/BlogScreenById";
import DonationTypeScreen from "./screens/DonationTypeScreen/DonationTypeScreen";
// import ImageByEventScreen from './screens/ImageByEventScreen/ImageByEventScreen';
import HelpContactScreen from "./screens/HelpContactScreen/HelpContactScreen";
import QuickLinkScreen from "./screens/QuickLinkScreen/QuickLinkScreen";
import ImageByIdScreen from "./screens/ImagesScreen/ImageByIdScreen";
import EventRegistrationPayment from "./screens/EventScreen/EventRegistrationPayment";
import UploadScreen from "./screens/UploadScreen/UploadScreen";
import Document from "./screens/UploadScreen/Document";
import EmailViewScreen from "./screens/EmailScreen/EmailViewScreen";
import EventNewScreenNew from "./screens/EventScreen/EventNewScreenNew";
import EventRegPayNew from "./screens/EventScreen/EventRegPayNew";
import ActivityScreen from "./screens/ActivityScreen/ActivityScreen";
import ActivityByIdScreen from "./screens/ActivityScreen/ActivityByIdScreen";
import BodScreen from "./screens/CommitteesScreen/BodScreen";

import AuthVerify from "./common/auth-verify";
import AdvertisementScreen from "./screens/AdvertisementScreen/AdvertisementScreen";
import MemberTypeScreen from "./screens/MemberTypeScreen/MemberTypeScreen";
import JobScreen from "./screens/CareerScreen/JobScreen";
import CreateJobScreen from "./screens/CareerScreen/CreateJobScreen";
import JobByIdScreen from "./screens/CareerScreen/JobByIdScreen";
import NewsLetterUnSubScreen from "./screens/CareerScreen/NewsLetterUnSubScreen";
import CareerScreen from "./screens/CareerScreen/CareerScreen";
import JobPostEditScreen from "./screens/CareerScreen/JobPostEditScreen";
import JobAllScreen from "./screens/CareerScreen/JobAllScreen";
import CentralEventModReg from "./components/CentralEvent/BiAnnualEvent2022/ModRegister/CentralEventModReg";

const App = () => {
  return (
    <>
      <Router>
        <Header />
        <main className="py-3">
          <Container>
            <Route path="/about" component={About} />
            <Route path="/login" component={LoginScreen} />
            <Route path="/password/recover" component={PasswordResetScreen} />
            <Route
              path="/password/reset/:id/:token"
              component={PasswordUpdateScreen}
            />
            <Route path="/register" component={RegisterScreen} />
            <Route path="/activate/:hash" component={EmailActivationScreen} />
            <Route path="/dashboardScreen" component={UserListScreen} />
            <Route path="/dashboard" component={DashboardScreen} />
            <Route path="/blog/new" component={CreateBlogScreen} exact />
            <Route path="/blogs/:id" component={BlogScreenById} />
            <Route
              exact
              path="/blog/search/:keyword/page/:pageNumber"
              component={BlogScreen}
            />
            <Route exact path="/blog/search/:keyword" component={BlogScreen} />
            <Route exact path="/blog/page/:pageNumber" component={BlogScreen} />
            <Route exact path="/blog" component={BlogScreen} />

            <Route path="/jobs/search/:keyword" component={JobScreen} />
            <Route path="/jobs/page/:pageNumber" component={JobScreen} />
            <Route exact path="/jobs" component={JobScreen} />
            <Route path="/jobsall/search/:keyword" component={JobAllScreen} />
            <Route path="/jobsall/page/:pageNumber" component={JobAllScreen} />
            <Route exact path="/jobsall" component={JobAllScreen} />
            <Route path="/job/new" component={CreateJobScreen} />
            <Route exact path="/jobs/:id" component={JobByIdScreen} />
            <Route
              path="/newsletter/optout"
              component={NewsLetterUnSubScreen}
            />
            <Route path="/career/edit/:id" component={JobPostEditScreen} />
            <Route exact path="/career/:pagetitle?" component={CareerScreen} />

            <Route path="/activity" component={ActivityScreen} exact />
            <Route path="/activity/:id" component={ActivityByIdScreen} />

            <Route path="/systemAdmin" component={SystemAdminScreen} />
            <Route path="/chapter" component={ChapterScreen} />
            <Route
              path="/advertisement/:advtitle?"
              component={AdvertisementScreen}
            />

            <Route path="/mission" component={MissionScreen} />
            <Route path="/chapters/mission/:id" component={Mission} />
            <Route path="/announcement" component={AnnouncementScreen} />
            <Route path="/help" component={HelpContactScreen} />
            <Route path="/links" component={QuickLinkScreen} />
            <Route path="/announcements/:id" component={Announcement} />
            <Route path="/vission" component={VissionScreen} />
            <Route path="/chapters/vission/:id" component={Vission} />
            <Route path="/history" component={HistoryScreen} />
            <Route path="/chapters/history/:id" component={History} />
            <Route path="/memberType" component={MemberTypeScreen} />
            <Route path="/paymentType" component={PaymentTypeScreen} />
            <Route path="/donationType" component={DonationTypeScreen} />
            <Route path="/images" component={ImagesScreen} />
            {/* <Route path='/image/event' component={ImageByEventScreen} /> */}
            <Route path="/image/:id" component={ImageByIdScreen} />
            <Route path="/payment" component={PaymentScreen} />
            <Route path="/donate" component={DonateScreen} />
            <Route path="/training" component={TrainingScreen} />
            <Route path="/bod" component={BodScreen} />
            <Route path="/committiees" component={CommittieesScreen} />
            <Route path="/committee/:id" component={CommitteeMemberScreen} />
            <Route path="/documents" component={UploadScreen} />
            <Route path="/doc/:id" component={Document} />
            <Route path="/members" component={MembersScreen} />
            <Route path="/emails" component={EmailScreen} />
            <Route path="/email/:id" component={EmailViewScreen} />
            <Route path="/events" component={EventScreen} />
            <Route path="/event/register/:id" component={EventRegisterScreen} />
            <Route path="/event/regmod/:id" component={CentralEventModReg} />
            <Route path="/eventsnew" component={EventNewScreen} />
            <Route
              path="/event/registration/payment"
              component={EventRegistrationPayment}
            />
            <Route path="/event/payment" component={EventRegPayNew} />
            <Route path="/eventnew" component={EventNewScreenNew} />
            <Route path="/settings" component={SettingScreen} />
            <Route
              path="/users/:id/pending"
              component={UserPendingApproveScreen}
            />
            <Route path="/profile" component={ProfileScreen} />
            <Route path="/users/profile/edit" component={EditProfileScreen} />
            <Route path="/admin/users/:id/edit" component={UserEditScreen} />
            <Route path="/" component={HomeScreen} exact />
          </Container>
          <Route path="/event/:id" component={EventByIdScreen} exact />
        </main>
        <Footer />
        <AuthVerify />
      </Router>
    </>
  );
};

export default App;
