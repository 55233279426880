import axios from 'axios';
import {
  NEWSLETTER_SUB_FAIL,
  NEWSLETTER_SUB_REQUEST,
  NEWSLETTER_SUB_SUCCESS,
  NEWSLETTER_UNSUB_FAIL,
  NEWSLETTER_UNSUB_REQUEST,
  NEWSLETTER_UNSUB_SUCCESS,
} from '../constants/newsletterConstants';

export const newSubscription = (newSubData) => async (dispatch) => {
  try {
    dispatch({
      type: NEWSLETTER_SUB_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    console.log(newSubData);
    const { data } = await axios.post('/api/newsletter', newSubData, config);

    dispatch({
      type: NEWSLETTER_SUB_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEWSLETTER_SUB_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const unSubscription = (unSubData) => async (dispatch) => {
  try {
    dispatch({
      type: NEWSLETTER_UNSUB_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.put(`/api/newsletter`, unSubData, config);

    dispatch({
      type: NEWSLETTER_UNSUB_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEWSLETTER_UNSUB_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
