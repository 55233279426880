import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import ReCAPTCHA from 'react-google-recaptcha';

import { Form, Button } from 'react-bootstrap';
import { unSubscription } from '../../actions/newsletterActions';
import { NEWSLETTER_UNSUB_RESET } from '../../constants/newsletterConstants';

const NewsletterUnSubscription = ({ history, chapterName }) => {
  const checkChapter = window.location.host;

  const reRef = useRef();

  const dispatch = useDispatch();

  const [email, setEmail] = useState('');

  const newsletterUnSub = useSelector((state) => state.newsletterUnSub);
  const { error, success } = newsletterUnSub;

  useEffect(() => {
    if (success) {
      swal('Success!', success, 'success').then(() => {
        setEmail('');
        dispatch({ type: NEWSLETTER_UNSUB_RESET });
        history.push('/');
      });
    }
    if (error) {
      swal('Errpr!', error, 'error').then(() => {
        // setEmail('');
        dispatch({ type: NEWSLETTER_UNSUB_RESET });
      });
    }
  }, [history, dispatch, success, error]);

  const submitHandler = async (e) => {
    e.preventDefault();

    const captcha = await reRef.current.executeAsync();
    dispatch(unSubscription({ captcha, email, checkChapter }));
    await reRef.current.reset();
  };

  return (
    <>
      <section
        id='subscription'
        className='bg-main  text-center d-flex align-content-between'
      >
        <div className='p-4 m-auto'>
          <h1 className='text-info'>
            To Un-Subscribe from AABEA {chapterName}'s job alert service please
            submit your email
          </h1>
        </div>
        <div className='p-4 m-auto'>
          <Form onSubmit={submitHandler} inline>
            <Form.Control
              required
              type='email'
              placeholder='Enter your email'
              className='mr-sm-2'
              value={email}
              onChange={(e) => setEmail(e.target.value.toLowerCase())}
            />
            <ReCAPTCHA
              sitekey='6LennzEfAAAAACe1LSYvKB9Tf51-I-d_OAP49JyP'
              ref={reRef}
              id='widgetId2'
              size='invisible'
            />
            <Button type='submit' className='m-3' variant='outline-info'>
              Submit
            </Button>
          </Form>
        </div>
      </section>
    </>
  );
};

export default NewsletterUnSubscription;
