import React, { useEffect } from 'react';
import parse from 'html-react-parser';

import { Col, Row } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Loader from '../Loader';
import Message from '../Message';
import { BLOG_BY_ID_RESET } from '../../constants/blogConstants';
import { allJobs } from '../../actions/careerActions';
import JobPaginate from './JobPagination';

const Jobs = ({ match }) => {
  const keyword = match.params.keyword;
  const pageNumber = match.params.pageNumber || 0;
  const dispatch = useDispatch();

  const jobAll = useSelector((state) => state.jobAll);
  const { loading, error, jobs, totalPages, currentPage } = jobAll;

  const checkChapter = window.location.host;

  useEffect(() => {
    dispatch(
      allJobs({
        checkChapter,
        keyword,
        pageNumber,
        approveStatus: true,
        rejectStatus: false,
      })
    );
    dispatch({ type: BLOG_BY_ID_RESET });
  }, [dispatch, checkChapter, keyword, pageNumber]);

  return (
    <>
      <div>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : jobs && jobs.length !== 0 ? (
          <>
            {jobs.map((job, i) => (
              <>
                <Row key={i + job.jobId}>
                  {/* <Col md={6}>
                  <Card.Img variant='top' src={blog.photoId} alt='pic' />
                </Col> */}

                  <Col>
                    <h3>{job.title}</h3>

                    {/* Company Name */}
                    <p className='lead'>
                      Company: <span>{job.companyName}</span>
                    </p>

                    <hr />

                    {/* Date/Time */}
                    <p>
                      <span className='glyphicon glyphicon-time'></span> Posted
                      on {job.createdAt.substring(0, 10)}
                    </p>

                    <hr />

                    {/* Post Content */}
                    <p className='lead'>
                      {parse(job.body.substring(0, 100))}...
                      <Link to={`/jobs/${job.jobId}`}>read more</Link>
                    </p>
                  </Col>
                </Row>
                <hr />
                <hr />
              </>
            ))}
            <JobPaginate
              totalPages={totalPages}
              currentPage={currentPage}
              keyword={keyword ? keyword : ''}
            />
          </>
        ) : (
          <p>No Job Post Available!</p>
        )}
      </div>
    </>
  );
};

export default Jobs;
