import React from 'react';
import { useDispatch } from 'react-redux';

import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import { logout } from '../actions/userActions';

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

const AuthVerify = (props) => {
  const dispatch = useDispatch();
  props.history.listen(() => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    if (userInfo) {
      const decodedJwt = parseJwt(userInfo.token);
      // console.log(decodedJwt);
      if (decodedJwt.exp * 1000 < Date.now()) {
        console.log('times up');
        swal(
          'Error!',
          'Upps! your current session expired. Please relogin to continue.',
          'error'
        ).then((value) => {
          dispatch(logout());
        });
      }
    }
  });

  return <div></div>;
};

export default withRouter(AuthVerify);
