import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Card, Col, Container, ListGroup, Row } from 'react-bootstrap';
import parse from 'html-react-parser';

import { getJobById, updateJobById } from '../../actions/careerActions';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import swal from 'sweetalert';
import { JOB_UPDATE_BY_ID_RESET } from '../../constants/careerConstants';
import JobCreateEdit from '../../components/Career/JobCreateEdit';

const JobPostEditScreen = ({ match, history }) => {
  const id = match.params.id;
  const checkChapter = window.location.host;

  const [editPost, setEditPost] = useState(false);

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const jobById = useSelector((state) => state.jobById);
  const { loading, error, job } = jobById;

  const jobUpdate = useSelector((state) => state.jobUpdate);
  const {
    loading: jobUpdateLoading,
    error: jobUpdateError,
    success,
  } = jobUpdate;

  useEffect(() => {
    if (
      userInfo &&
      (userInfo.userRole === 'systemAdmin' || userInfo.userRole === 'admin')
    ) {
      dispatch(getJobById(id));
    } else {
      history.push('/');
    }
    if (success) {
      swal('Success!', success, 'success').then(() => {
        dispatch({ type: JOB_UPDATE_BY_ID_RESET });
        history.push('/career');
      });
    }
    if (jobUpdateError) {
      swal('Error!', jobUpdateError, 'error').then(() => {
        dispatch({ type: JOB_UPDATE_BY_ID_RESET });
      });
    }
  }, [dispatch, history, id, userInfo, success, jobUpdateError]);

  const postApproveHandler = (e) => {
    e.preventDefault();

    dispatch(
      updateJobById({
        checkChapter,
        id,
        approveStatus: true,
        rejectStatus: false,
      })
    );
  };

  const postRejectHandler = (e) => {
    e.preventDefault();

    dispatch(
      updateJobById({
        checkChapter,
        id,
        approveStatus: false,
        rejectStatus: true,
      })
    );
  };

  const postEditHandler = (e) => {
    e.preventDefault();

    setEditPost(!editPost);

    // dispatch(
    //   updateJobById({
    //     checkChapter,
    //     id,
    //     approveStatus: false,
    //     rejectStatus: true,
    //   })
    // );
  };

  return (
    <Container>
      <Link className='btn my-3 btn-sm btn-outline-info' to='/career'>
        Go Back
      </Link>
      <Row className='content'>
        <Col className='m-0 p-1'>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            job && (
              <Card className='text-justify'>
                {!editPost ? (
                  <>
                    <Card.Header className='text-info' as='h2'>
                      <div>
                        {job.title}
                        <Card.Title className='text-muted'>
                          {' '}
                          Published Date: {'  '}
                          {`${job.createdAt}`.substring(0, 10)}
                        </Card.Title>
                        <Card.Title className='text-muted'>
                          Post by{' '}
                          <span>
                            {job.member.firstName} {job.member.lastName}
                          </span>
                        </Card.Title>
                      </div>
                      <Button
                        className='btn btn-outline-warning btn-sm rounded'
                        variant='transparent'
                        onClick={postEditHandler}
                        // disabled={job.rejected}
                      >
                        Edit
                      </Button>
                    </Card.Header>

                    {/* <hr /> */}

                    <ListGroup variant='flush'>
                      <ListGroup.Item>
                        <strong>Organization:</strong> {job.companyName}
                      </ListGroup.Item>
                      {job.web ? (
                        <>
                          <ListGroup.Item>
                            <strong>Website:</strong> {job.web}
                          </ListGroup.Item>
                        </>
                      ) : null}

                      {job.email ? (
                        <>
                          <ListGroup.Item>
                            <strong>Contact Email:</strong> {job.email}
                          </ListGroup.Item>
                        </>
                      ) : null}

                      {job.phone ? (
                        <>
                          <ListGroup.Item>
                            <strong>Contact phone:</strong> {job.phone}
                          </ListGroup.Item>
                        </>
                      ) : null}

                      {job.city ? (
                        <>
                          <ListGroup.Item>
                            <strong>Job location city:</strong> {job.city}
                          </ListGroup.Item>
                        </>
                      ) : null}

                      {job.state ? (
                        <>
                          <ListGroup.Item>
                            <strong>Job location state:</strong> {job.state}
                          </ListGroup.Item>
                        </>
                      ) : null}
                    </ListGroup>

                    {/* Post Content */}
                    <Card.Body className='lead'>
                      {parse(`<div>${job.body}</div>`)}
                    </Card.Body>

                    {job.attachments && job.attachments.length !== 0 ? (
                      <Card.Footer>
                        <span>
                          <strong>Attachments: </strong>
                        </span>
                        {job.attachments.map((Attachment, idx) => {
                          return (
                            // <span key={idx} className='badge'>
                            //   {Attachment}
                            // </span>
                            <a
                              key={idx}
                              href={Attachment}
                              className='badge'
                              target='_blank'
                              rel='noreferrer'
                            >
                              {Attachment.split('/image-')[1]}
                            </a>
                          );
                        })}
                      </Card.Footer>
                    ) : null}

                    <Card.Footer className='text-muted'>
                      {jobUpdateLoading ? (
                        <Loader />
                      ) : (
                        <Row>
                          <Col>
                            <Button
                              className='btn btn-outline-info btn-sm btn-block rounded'
                              variant='transparent'
                              onClick={postApproveHandler}
                              disabled={job.approved}
                            >
                              APPROVE
                            </Button>
                          </Col>
                          <Col>
                            <Button
                              className='btn btn-outline-danger btn-sm btn-block rounded'
                              variant='transparent'
                              onClick={postRejectHandler}
                              disabled={job.rejected}
                            >
                              REJECT
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </Card.Footer>
                  </>
                ) : (
                  <>
                    <JobCreateEdit history={history} post={job} />
                    {/* <Button
                      className='btn btn-outline-danger btn-sm rounded'
                      variant='transparent'
                      onClick={postEditHandler}
                      // disabled={job.rejected}
                    >
                      Cancel
                    </Button> */}
                  </>
                )}
              </Card>
            )
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default JobPostEditScreen;
