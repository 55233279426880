import { format } from 'date-fns';
import React, { useEffect, useRef } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import { allJobs, deleteJob } from '../../actions/careerActions';
import { JOB_DELETE_RESET } from '../../constants/careerConstants';

import Loader from '../Loader';
import Message from '../Message';
import ColumnFilter from '../Table/ColumnFilter';
import RTable from '../Table/RTable';

const AllJobPosts = ({ history, pageType }) => {
  const dispatch = useDispatch();

  const jobsRef = useRef();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const jobAll = useSelector((state) => state.jobAll);
  const { loading, error, jobs } = jobAll;

  jobsRef.current = jobs;

  const jobDelete = useSelector((state) => state.jobDelete);
  const { error: errorDelete, success } = jobDelete;

  const checkChapter = window.location.host;

  useEffect(() => {
    if (
      userInfo &&
      (userInfo.userRole === 'systemAdmin' || userInfo.userRole === 'admin')
    ) {
      if (success) {
        swal('Success!', success, 'success').then((value) => {
          dispatch({ type: JOB_DELETE_RESET });
          // window.reload();
        });
      }
      if (errorDelete) {
        swal('Error!', errorDelete, 'error').then((value) => {
          dispatch({ type: JOB_DELETE_RESET });
        });
      }
      const pageSize = 1000;
      if (pageType === 'approved') {
        dispatch(
          allJobs({
            checkChapter,
            pageSize,
            approveStatus: 'true',
            rejectStatus: 'false',
          })
        );
      } else if (pageType === 'pending') {
        dispatch(
          allJobs({
            checkChapter,
            pageSize,
            approveStatus: 'false',
            rejectStatus: 'false',
          })
        );
      } else if (pageType === 'rejected') {
        dispatch(
          allJobs({
            checkChapter,
            pageSize,
            approveStatus: 'false',
            rejectStatus: 'true',
          })
        );
      }
    } else {
      history.push('/login');
    }
  }, [
    dispatch,
    history,
    pageType,
    userInfo,
    checkChapter,
    success,
    errorDelete,
  ]);

  const view = (rowIndex) => {
    const id = jobsRef.current[rowIndex].jobId;
    history.push(`/career/edit/${id}`);
  };

  const deleteHandler = (rowIndex) => {
    const id = jobsRef.current[rowIndex].jobId;

    if (window.confirm('Are You Sure?')) {
      dispatch(deleteJob(id));
    }
  };

  const columnsAdmin = [
    {
      Header: 'No.',
      accessor: (row, i) => i + 1,
    },
    {
      Header: 'Title',
      accessor: 'title',
      Filter: ColumnFilter,
    },
    // {
    //   Header: 'Status',
    //   accessor: 'approved',
    //   Filter: ColumnFilter,
    //   Cell: ({ value }) => {
    //     return value ? (
    //       <i className='fas fa-check' style={{ color: 'green' }}></i>
    //     ) : (
    //       <i className='fas fa-times' style={{ color: 'red' }}></i>
    //     );
    //   },
    // },
    // {
    //   Header: 'Title',
    //   accessor: 'title',
    //   Filter: ColumnFilter,
    // },

    {
      Header: 'Date',
      accessor: 'createdAt',
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        return format(new Date(value), 'dd/mm/yyyy');
      },
    },

    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: (props) => {
        const rowIdx = props.row.id;
        return (
          <div>
            <span onClick={() => view(rowIdx)}>
              <i
                className='far fa-edit action mr-2'
                style={{ color: '#4285F4', cursor: 'pointer' }}
              ></i>
            </span>

            <span onClick={() => deleteHandler(rowIdx)}>
              <i
                className='fas fa-trash action'
                style={{ color: 'red', cursor: 'pointer' }}
              ></i>
            </span>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Row>
        <Col
          md={{ span: 12, order: 12 }}
          lg={{ span: 12, order: 12 }}
          className='mb-2 p-0'
          id='all-chapter'
        >
          <Card border='info'>
            <Card.Header as='h5' className='text-center text-info'>
              Job post: {pageType} List
            </Card.Header>

            <>
              {loading ? (
                <Loader />
              ) : error ? (
                <Message variant='danger'>{error}</Message>
              ) : (
                <>
                  <RTable users={jobs} COLUMNS={columnsAdmin} />
                </>
              )}
            </>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AllJobPosts;
