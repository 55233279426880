import React from 'react';
import Slider from 'react-slick';
import AdvertisementById from './AdvertisementById';

const AdvertisementSlider = ({ images }) => {
  var settings = {
    dots: false,
    infinite: true,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const height = '150px';
  return (
    <div>
      <Slider {...settings}>
        {images &&
          images.map((image, index) => (
            <div key={index}>
              {/* <Link to={`/image/${image.imageId}`}> */}

              <AdvertisementById
                image={image}
                height={height}
                // eventFrom={event.eventDate[0].value}
                // eventTo={event.eventDate[1].value}
                // createGroup={createGroup}
              />
              {/* <Carousel.Caption className='carousel-caption'>
              <h2>{image.imageName}</h2>
            </Carousel.Caption> */}
              {/* </Link> */}
            </div>
          ))}
      </Slider>
    </div>
  );
};
export default AdvertisementSlider;
