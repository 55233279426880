import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { allAdv } from '../../../actions/advAction';
import Loader from '../../Loader';
import Message from '../../Message';
import AdvertisementSliderHome from './AdvertisementSliderHome';

const HomeBanner = ({ playVideo }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  // const [playVideo, setPlayVideo] = useState(false);

  const handleClose = () => {
    setShow(false);
    playVideo(true);
  };
  const handleShow = () => setShow(true);

  const advAll = useSelector((state) => state.advAll);
  const { loading, error, advs, advType } = advAll;

  // console.log(image);
  const checkChapter = window.location.host;

  useEffect(() => {
    dispatch(
      allAdv({ checkChapter, advType: 'home-main-banner', active: true })
    );
  }, [dispatch, checkChapter]);
  // console.log(advs);

  useEffect(() => {
    if (advs && advs.length !== 0 && advs[0].type === 'home-main-banner') {
      setTimeout(function () {
        handleShow();
        document.body.style.overflow = 'unset';
      }, 2000);
    }

    if (advs && advs.length === 0 && advType === 'home-main-banner') {
      setTimeout(function () {
        playVideo(true);
      }, 2000);
    }
  }, [advs, advType]);

  // console.log(loading);
  // console.log(!error);
  // console.log(advs);
  // console.log(advType);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        size='md'
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <>
            {loading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>{error}</Message>
            ) : (
              show &&
              advs &&
              advs.length !== 0 &&
              advs[0].type === 'home-main-banner' && (
                <AdvertisementSliderHome images={advs} />
              )
            )}
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HomeBanner;
