import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Form,
  ListGroup,
  Modal,
  OverlayTrigger,
  Tooltip,
  Card,
  Button,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { donateUserGuest, listUsers } from '../../actions/userActions';
import { USER_DONATE_RESET } from '../../constants/userConstants';
import Loader from '../Loader';
import Message from '../Message';

const AddDonation = ({ types }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const userList = useSelector((state) => state.userList);
  const { users } = userList;

  const [guest, setGuest] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [mInit, setMInit] = useState('Mr');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [donationTypeName, setDonationTypeName] = useState('');
  const [donateAmount, setDonateAmount] = useState(5);

  // const [setMemberId] = useState('');

  const [paymentResult, setPaymentResult] = useState({
    amount: 5,
    payerId: '',
    paymentId: '',
    paymentStatus: '',
    paymentTime: new Date(),
  });

  const userDonate = useSelector((state) => state.userDonate);
  const {
    loading: loadingDonate,
    error: errorDonate,
    donateResulte,
    success,
  } = userDonate;

  const checkChapter = window.location.host;

  useEffect(() => {
    dispatch(listUsers(checkChapter));
    if (success) {
      swal('Success!', 'success', 'success').then((value) => {
        console.log('successsssssssssss');
        dispatch({ type: USER_DONATE_RESET });
        // setDonateAmount(0);
        // setGuest(false);
        // setFirstName('');
        // setMInit('');
        // setLastName('');
        // setEmail('');
        // setDonationTypeName('');
        handleClose();
      });
    }
    if (errorDonate) {
      swal('Error!', errorDonate, 'error').then((value) => {
        dispatch({ type: USER_DONATE_RESET });
      });
    }
  }, [dispatch, checkChapter, success, errorDonate]);

  const addDonation = () => {
    // e.preventDefault();

    dispatch(
      donateUserGuest(
        checkChapter,
        guest,
        email,
        firstName,
        mInit,
        lastName,
        donationTypeName,
        paymentResult
      )
    );
    // handleClose();
  };

  const memberIdHandler = (e) => {
    e.preventDefault();

    // setMemberId(e.target.value.split(',')[0]);
    setEmail(e.target.value.split(',')[0]);
    setFirstName(e.target.value.split(',')[1]);
    setLastName(e.target.value.split(',')[2]);
    setMInit(e.target.value.split(',')[3]);

    setPaymentResult({
      ...paymentResult,
      payerId: e.target.value.split(',')[1],
      paymentId: e.target.value.split(',')[1],
    });
  };

  const donationTypeChangeHandler = (e) => {
    e.preventDefault();

    setDonationTypeName(e.target.value);
  };

  return (
    <>
      <OverlayTrigger
        overlay={<Tooltip id='tooltip-disabled'>Add Donation</Tooltip>}
      >
        <span onClick={handleShow}>
          <i
            className='fas fa-plus action mr-2 text-info'
            style={{
              cursor: 'pointer',
            }}
          ></i>
        </span>
      </OverlayTrigger>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        size='lg'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className='text-center text-info'>
            Add Donation:
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            {errorDonate && <Message variant='danger'>{errorDonate}</Message>}
            {loadingDonate && <Loader />}
            {donateResulte ? (
              <Message variant='success'>{donateResulte}</Message>
            ) : (
              <Card border='info'>
                <Card.Header as='h4' className='text-center text-info'>
                  Donation
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col md={8}>
                      <Form>
                        <Form.Row>
                          <Form.Group as={Col} md='2'>
                            <Form.Label>Member ?</Form.Label>
                          </Form.Group>

                          <Form.Group as={Col} md='4' controlId='guest'>
                            <Form.Control
                              required
                              as='select'
                              type='text'
                              value={guest}
                              onChange={(e) => setGuest(e.target.value)}
                            >
                              <option value='false'>No</option>
                              <option value='true'>Yes</option>
                            </Form.Control>
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col} md='2'>
                            <Form.Label>Member Id</Form.Label>
                          </Form.Group>

                          <Form.Group as={Col} md='4' controlId='memberId'>
                            <Form.Control
                              as='select'
                              onChange={memberIdHandler}
                            >
                              <option>Select member Id</option>
                              {users &&
                                users.length !== 0 &&
                                users.map((user) => (
                                  <option
                                    key={user.memberId}
                                    value={[
                                      // user.memberId,
                                      user.primaryEmail,
                                      user.firstName,
                                      user.lastName,
                                      user.mInit,
                                    ]}
                                  >
                                    {user.memberId}
                                  </option>
                                ))}
                            </Form.Control>
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col} md='2'>
                            <Form.Label>Name</Form.Label>
                          </Form.Group>

                          <Form.Group as={Col} md='10'>
                            <Form.Group controlId='mInit'>
                              <Form.Control
                                required
                                as='select'
                                type='text'
                                value={mInit}
                                onChange={(e) => setMInit(e.target.value)}
                              >
                                <option value='Mr'>Mr</option>
                                <option value='Mrs'>Mrs</option>
                                <option value='Miss'>Ms</option>
                              </Form.Control>
                            </Form.Group>

                            <Form.Group controlId='firstName'>
                              <Form.Control
                                required
                                type='text'
                                placeholder='First Name'
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                              ></Form.Control>
                            </Form.Group>

                            <Form.Group controlId='lastName'>
                              <Form.Control
                                required
                                placeholder='Last Name'
                                type='text'
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                              ></Form.Control>
                            </Form.Group>
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col} md='2'>
                            <Form.Label>Email Address</Form.Label>
                          </Form.Group>
                          <Form.Group as={Col} md='10' controlId='email'>
                            <Form.Control
                              required
                              type='email'
                              placeholder='Enter Email..'
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                                setPaymentResult({
                                  ...paymentResult,
                                  payerId: e.target.value,
                                  paymentId: e.target.value,
                                });
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col} md='2'>
                            <Form.Label>Donation Cause</Form.Label>
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            md='10'
                            controlId='donationTypeName'
                          >
                            <Form.Control
                              as='select'
                              onChange={donationTypeChangeHandler}
                            >
                              <option>Select cause</option>
                              {types &&
                                types.map((donationType, index) => (
                                  <option
                                    key={index}
                                    value={donationType.donationTypeName}
                                  >
                                    {donationType.donationTypeName}
                                  </option>
                                ))}
                            </Form.Control>
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col} md='2'>
                            <Form.Label>Donate Amount</Form.Label>
                          </Form.Group>
                          <Form.Group as={Col} md='10' controlId='donateAmount'>
                            <Form.Control
                              required
                              type='number'
                              min='5'
                              placeholder='Please Enter Donation Amount'
                              value={donateAmount}
                              onChange={(e) => {
                                setDonateAmount(e.target.value);
                                setPaymentResult({
                                  ...paymentResult,
                                  amount: e.target.value,
                                });
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col} md='2'>
                            <Form.Label>Reference</Form.Label>
                          </Form.Group>
                          <Form.Group as={Col} md='10' controlId='reference'>
                            <Form.Control
                              type='text'
                              placeholder='Please Enter Reference..'
                              value={paymentResult.paymentStatus}
                              onChange={(e) => {
                                setPaymentResult({
                                  ...paymentResult,
                                  paymentStatus: e.target.value,
                                });
                              }}
                              required
                            ></Form.Control>
                          </Form.Group>
                        </Form.Row>
                      </Form>
                    </Col>
                    <Col md={4}>
                      <ListGroup variant='flush'>
                        <ListGroup.Item>
                          <Row>
                            <Col>Donation cause</Col>
                            <Col>{donationTypeName}</Col>
                          </Row>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <Row>
                            <Col>Donation Amount</Col>
                            <Col>${donateAmount}</Col>
                          </Row>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <Row>
                            <Col>Payment Result Amount</Col>
                            <Col>${paymentResult.amount}</Col>
                          </Row>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <Row>
                            <Col>Donation payerId</Col>
                            <Col>${paymentResult.payerId}</Col>
                          </Row>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <Row>
                            <Col>Donation paymentid</Col>
                            <Col>${paymentResult.paymentId}</Col>
                          </Row>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <Row>
                            <Col>Donation reference</Col>
                            <Col>${paymentResult.paymentStatus}</Col>
                          </Row>
                        </ListGroup.Item>
                      </ListGroup>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            )}
          </>
        </Modal.Body>
        <Modal.Footer>
          <Link
            className='btn btn-outline-danger btn-sm rounded'
            onClick={handleClose}
            to='#'
          >
            Cancel
          </Link>

          <Button
            className='btn btn-outline-info btn-sm rounded'
            onClick={() => {
              addDonation();
            }}
            // disabled={
            //   email === '' ||
            //   (status === 'inactive' && paymentTypePrimary !== 'true')
            // }
            variant='transparent'
            to='#'
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddDonation;
