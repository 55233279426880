import React, { useEffect, useState } from "react";
import { Card, ListGroup, Pagination } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import { allUpcomingEvents } from "../../actions/eventActions";
import Paginate3 from "./Paginate3";

const HomeUpcomingEvents = () => {
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(0);

  const eventUpcomingAll = useSelector((state) => state.eventUpcomingAll);
  const {
    loading: eventsLoading,
    error: eventsError,
    upcomingEvents,
    totalUpcomingPages,
    currentUpcomingPage,
  } = eventUpcomingAll;

  const checkChapter = window.location.host;
  // const subDomain = window.location.host.split('.')[0];

  useEffect(() => {
    dispatch(allUpcomingEvents(checkChapter, "", pageNumber)); // done
  }, [dispatch, checkChapter, pageNumber]);

  const changePageNumber = (changePageNumber) => {
    setPageNumber(changePageNumber);
  };

  return (
    <Card className="mb-2">
      <Card.Header className="text-info text-center" as="h4">
        Upcoming Events
      </Card.Header>
      <>
        {eventsLoading ? (
          <Loader />
        ) : eventsError ? (
          <Message variant="danger">{eventsError}</Message>
        ) : (
          <ListGroup variant="flush">
            {upcomingEvents.map((event, index) => (
              <ListGroup.Item key={index}>
                <Link to={`/event/${event.eventId}`}>
                  <span className="text-info d-flex justify-content-between align-items-center">
                    {event.eventName}

                    {event.eventStatus ? (
                      <>
                        <span className="badge badge-info badge-pill blink_me">
                          {event.eventId === "42042"
                            ? "Registration"
                            : "Upcoming"}
                        </span>
                      </>
                    ) : null}
                  </span>
                </Link>
                {event.eventId === "42042" && (
                  <>
                    <hr></hr>

                    <a
                      href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1710535931074&key=GRP&app=resvlink"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="text-info d-flex justify-content-between align-items-center">
                        For Hotel reservation Please CLICK HERE
                        <span className="badge badge-info badge-pill blink_me">
                          Hotel Booking
                        </span>
                      </span>
                    </a>
                  </>
                )}
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}

        <div className="align-middle">
          {totalUpcomingPages > 1 && (
            <Pagination size="sm" className="justify-content-center my-1">
              <Paginate3
                totalPages={totalUpcomingPages}
                currentPage={currentUpcomingPage}
                changePageNumber={changePageNumber}
              />
            </Pagination>
          )}
        </div>
      </>
    </Card>
  );
};

export default HomeUpcomingEvents;
