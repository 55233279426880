import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Form,
  ListGroup,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import {
  eventAllContact,
  getEventById,
  getEventContactById,
  newEventContact,
  publishEvent,
  unpublishEvent,
  updateEventContactById,
  deleteEventContact,
  updateEventById,
  newEventProgram,
  updateEventProgramById,
  deleteEventProgram,
  newEventGroup,
  deleteEventGroup,
  newEventHotel,
  deleteEventHotel,
  newEventHotelRoom,
  deleteEventHotelRoom,
  deleteFlyer,
  updateEventGroupById,
} from "../../actions/eventActions";
import {
  EVENT_CONTACT_BY_ID_RESET,
  EVENT_CONTACT_NEW_RESET,
  EVENT_CONTACT_UPDATE_BY_ID_RESET,
  EVENT_GROUP_NEW_RESET,
  EVENT_GROUP_UPDATE_BY_ID_RESET,
  EVENT_HOTEL_NEW_RESET,
  EVENT_PROGRAM_NEW_RESET,
  EVENT_PUBLISH_RESET,
  EVENT_ROOM_NEW_RESET,
  EVENT_UNPUBLISH_RESET,
} from "../../constants/eventConstants";
import swal from "sweetalert";
import parse from "html-react-parser";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import RTable from "../../components/Table/RTable";
import ColumnFilter from "../../components/Table/ColumnFilter";
import { listAllUsers, listUsers } from "../../actions/userActions";
import { CART_RESET } from "../../constants/cartConstants";
import CreateGroup from "../../components/Events/CreateGroup";
import CreateHotel from "../../components/Events/CreateHotel";
import CreateProgram from "../../components/Events/CreateProgram";
import EditProgram from "../../components/Events/EditProgram";
import CreateRoom from "../../components/Events/CreateRoom";
import EventImageSlider from "../../components/Events/EventImageSlider";
import ModTableData from "../../components/Table/ModTableData";
import EditGroup from "../../components/Events/EditGroup";
import ImageLightBox from "../../components/Image/ImageLightBox";
import AdvertisementSlider from "../../components/Advertisement/Display/AdvertisementSlider";
import CentralEvent2022 from "../../components/CentralEvent/BiAnnualEvent2022/CentralEvent2022";

const EventByIdScreen = ({ history, match }) => {
  const id = match.params.id;
  const dispatch = useDispatch();

  const [addEventContact, setAddEventContact] = useState(false);
  const [editEventContact, setEditEventContact] = useState(false);
  const [eventContactId, setEventContactId] = useState("");
  const [memberId, setMemberId] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");

  const [editEvent, setEditEvent] = useState(false);
  const [eventName, setEventName] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [eventStartDate, setEventStartDate] = useState("");
  const [eventEndDate, setEventEndDate] = useState("");
  const [eventStartTime, setEventStartTime] = useState("");
  const [eventEndTime, setEventEndTime] = useState("");
  const [eventAddress, setEventAddress] = useState("");
  const [adultFee, setAdultFee] = useState(0);
  const [minorFee, setMinorFee] = useState(0);
  const [cap, setCap] = useState(0);

  const [eventFlyer, setEventFlyer] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState([]);

  const [columnsAdmin, setColumnsAdmin] = useState([]);

  const chapterBySubDomain = useSelector((state) => state.chapterBySubDomain);
  const { chapterByDomain } = chapterBySubDomain;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userList = useSelector((state) => state.userList);
  const { users } = userList;

  const userAllList = useSelector((state) => state.userAllList);
  const { users: allUserList } = userAllList;

  const eventById = useSelector((state) => state.eventById);
  const { loading, error, event } = eventById;

  const eventUpdate = useSelector((state) => state.eventUpdate);
  const { success: eventUpdateSuccess } = eventUpdate;

  const flyerDelete = useSelector((state) => state.flyerDelete);
  const { success: flyerDeleteSuccess } = flyerDelete;

  const eventProgramNew = useSelector((state) => state.eventProgramNew);
  const { error: eventProgramNewError, success: eventProgramNewSuccess } =
    eventProgramNew;

  const eventProgramUpdate = useSelector((state) => state.eventProgramUpdate);
  const { success: eventProgramUpdateSuccess } = eventProgramUpdate;

  const eventProgramDelete = useSelector((state) => state.eventProgramDelete);
  const { success: eventProgramDeleteSuccess } = eventProgramDelete;

  const eventGroupNew = useSelector((state) => state.eventGroupNew);
  const { error: eventGroupNewError, success: eventGroupNewSuccess } =
    eventGroupNew;

  const eventGroupUpdate = useSelector((state) => state.eventGroupUpdate);
  const { error: eventGroupUpdateError, success: eventGroupUpdateSuccess } =
    eventGroupUpdate;

  const eventGroupDelete = useSelector((state) => state.eventGroupDelete);
  const { success: eventGroupDeleteSuccess } = eventGroupDelete;

  const eventHotelNew = useSelector((state) => state.eventHotelNew);
  const { error: eventHotelNewError, success: eventHotelNewSuccess } =
    eventHotelNew;

  const eventHotelDelete = useSelector((state) => state.eventHotelDelete);
  const { success: eventHotelDeleteSuccess } = eventHotelDelete;

  const eventRoomNew = useSelector((state) => state.eventRoomNew);
  const { error: eventRoomNewError, success: eventRoomNewSuccess } =
    eventRoomNew;

  const eventContactNew = useSelector((state) => state.eventContactNew);
  const {
    loading: eventContactNewLoading,
    error: eventContactNewError,
    success,
  } = eventContactNew;

  const eventContactAll = useSelector((state) => state.eventContactAll);
  const {
    loading: eventContactsLoading,
    error: eventContactsError,
    eventContacts,
  } = eventContactAll;

  const eventContactById = useSelector((state) => state.eventContactById);
  const { success: eventContactByIdSuccess, eventContact } = eventContactById;

  const eventContactUpdate = useSelector((state) => state.eventContactUpdate);
  const { error: eventContactUpdateError, success: eventContactUpdateSuccess } =
    eventContactUpdate;

  const eventContactDelete = useSelector((state) => state.eventContactDelete);
  const { success: successDelete } = eventContactDelete;

  const eventPublish = useSelector((state) => state.eventPublish);
  const { error: eventPublishError, success: eventPublishSuccess } =
    eventPublish;

  const eventUnpublish = useSelector((state) => state.eventUnpublish);
  const { error: eventUnpublishError, success: eventUnpublishSuccess } =
    eventUnpublish;

  const imageDelete = useSelector((state) => state.imageDelete);
  const { success: successImageDelete } = imageDelete;

  const checkChapter = window.location.host;

  useEffect(() => {
    dispatch({ type: EVENT_PROGRAM_NEW_RESET });
    dispatch(getEventById(id));
    dispatch(eventAllContact(id));

    if (userInfo) {
      dispatch({ type: EVENT_CONTACT_NEW_RESET });
      dispatch({ type: EVENT_CONTACT_UPDATE_BY_ID_RESET });

      if (
        userInfo.userRole === "admin" ||
        userInfo.userRole === "systemAdmin"
      ) {
        if (checkChapter.replace("www.", "").split(".")[0] === "aabea") {
          dispatch(listAllUsers());
        } else {
          dispatch(listUsers(checkChapter));
        }

        setColumnsAdmin([
          {
            Header: "No.",
            accessor: (row, i) => i + 1,
          },
          {
            Header: "Registration Id",
            accessor: "registrationId",
            Filter: ColumnFilter,
          },

          {
            Header: "First Name",
            accessor: "firstName",
            Filter: ColumnFilter,
          },
          {
            Header: "Last Name",
            accessor: "lastName",
            Filter: ColumnFilter,
          },
          {
            Header: "Email",
            accessor: "email",
            Filter: ColumnFilter,
          },
          {
            Header: "Phone",
            accessor: "phone",
            Filter: ColumnFilter,
          },
          {
            Header: "Member Id",
            accessor: "memberId",
            Filter: ColumnFilter,
          },
          {
            Header: "Adult/s",
            accessor: "numberOfAdults",
            Filter: ColumnFilter,
          },
          {
            Header: "Minor/s",
            accessor: "numberOfMinors",
            Filter: ColumnFilter,
          },
          {
            Header: "Payment",
            accessor: "eventPayment.amount",
            Filter: ColumnFilter,
          },
        ]);
      }
    }

    if (
      eventProgramNewSuccess ||
      eventGroupNewSuccess ||
      eventHotelNewSuccess ||
      eventGroupUpdateSuccess ||
      eventRoomNewSuccess ||
      success ||
      eventContactUpdateSuccess
    ) {
      swal(
        "Success!",
        eventProgramNewSuccess ||
          eventGroupNewSuccess ||
          eventHotelNewSuccess ||
          eventGroupUpdateSuccess ||
          eventRoomNewSuccess ||
          success ||
          eventContactUpdateSuccess,
        "success"
      ).then(() => {
        setAddEventContact(false);
        setEditEventContact(false);

        setEventContactId("");
        setMemberId("");
        setContactEmail("");
        setContactPhone("");
        dispatch({ type: EVENT_CONTACT_NEW_RESET });
        dispatch({ type: EVENT_PROGRAM_NEW_RESET });
        dispatch({ type: EVENT_GROUP_NEW_RESET });
        dispatch({ type: EVENT_GROUP_UPDATE_BY_ID_RESET });
        dispatch({ type: EVENT_HOTEL_NEW_RESET });
        dispatch({ type: EVENT_ROOM_NEW_RESET });

        dispatch({ type: EVENT_CONTACT_BY_ID_RESET });
      });
    } else if (
      eventProgramNewError ||
      eventGroupNewError ||
      eventGroupUpdateError ||
      eventHotelNewError ||
      eventRoomNewError ||
      eventContactNewError ||
      eventContactUpdateError
    ) {
      swal(
        "Error!",
        eventProgramNewError ||
          eventGroupNewError ||
          eventGroupUpdateError ||
          eventHotelNewError ||
          eventRoomNewError ||
          eventContactNewError ||
          eventContactUpdateError,
        "error"
      );
    }

    if (eventPublishSuccess || eventUnpublishSuccess) {
      swal(
        "Success!",
        eventPublishSuccess || eventUnpublishSuccess,
        "success"
      ).then(() => {
        history.push("/events");
        dispatch({ type: EVENT_PUBLISH_RESET });
        dispatch({ type: EVENT_UNPUBLISH_RESET });
      });
    } else if (eventPublishError || eventUnpublishError) {
      swal("Error!", eventPublishError || eventUnpublishError, "error");
    }

    if (eventContactByIdSuccess) {
      setAddEventContact(true);
      setEditEventContact(true);
      setEventContactId(eventContact.eventContactId);
      setMemberId(eventContact.memberId);
      setContactEmail(eventContact.contactEmail);
      setContactPhone(eventContact.contactPhone);
    }
    if (eventUpdateSuccess) {
      setEventFlyer([]);
      setMessage([]);
    }
  }, [
    dispatch,
    history,
    userInfo,
    checkChapter,
    id,
    success,
    eventUpdateSuccess,
    flyerDeleteSuccess,
    eventProgramNewSuccess,
    eventProgramNewError,
    eventProgramUpdateSuccess,
    eventProgramDeleteSuccess,
    eventGroupNewSuccess,
    eventGroupUpdateSuccess,
    eventGroupNewError,
    eventGroupUpdateError,
    eventGroupDeleteSuccess,
    eventHotelNewSuccess,
    eventHotelNewError,
    eventHotelDeleteSuccess,
    eventRoomNewSuccess,
    eventRoomNewError,
    eventContactNewError,
    eventContactUpdateError,
    eventContactByIdSuccess,
    eventContact,
    eventContactUpdateSuccess,
    successDelete,
    eventPublishSuccess,
    eventUnpublishSuccess,
    eventPublishError,
    eventUnpublishError,
    successImageDelete,
  ]);

  const nominationTableColumns = [
    {
      Header: "No.",
      accessor: (row, i) => i + 1,
    },
    {
      Header: "Registration Id",
      accessor: "registrationId",
      Filter: ColumnFilter,
    },

    {
      Header: "First Name",
      accessor: "firstName",
      Filter: ColumnFilter,
    },
    {
      Header: "Last Name",
      accessor: "lastName",
      Filter: ColumnFilter,
    },
    {
      Header: "Email",
      accessor: "email",
      Filter: ColumnFilter,
    },
    {
      Header: "Phone",
      accessor: "phone",
      Filter: ColumnFilter,
    },
    {
      Header: "Member Id",
      accessor: "memberId",
      Filter: ColumnFilter,
    },
    {
      Header: "Position",
      accessor: "eventGroupRegistrations[0].groupName",
      Filter: ColumnFilter,
    },
    {
      Header: "Fee",
      accessor: "eventGroupRegistrations[0].fee",
      Filter: ColumnFilter,
    },
    {
      Header: "Donation",
      accessor: "contributionAmount",
      Filter: ColumnFilter,
    },
    {
      Header: "Total Payment",
      accessor: "eventPayment.amount",
      Filter: ColumnFilter,
    },
  ];

  const addNewEventContact = (e) => {
    e.preventDefault();

    setAddEventContact(!addEventContact);
    setMemberId("");
    setContactEmail("");
    setContactPhone("");
    setEditEventContact(false);
    dispatch({ type: EVENT_CONTACT_BY_ID_RESET });
    dispatch({ type: EVENT_CONTACT_NEW_RESET });
  };

  const memberIdHandler = (e) => {
    e.preventDefault();

    setMemberId(e.target.value.split(",")[0]);
    setContactEmail(e.target.value.split(",")[1]);
    setContactPhone(e.target.value.split(",")[2]);
  };

  // Single Image
  // const uploadFileHandler = async (e) => {
  //   const file = e.target.files[0];
  //   const formData = new FormData();
  //   formData.append('image', file);
  //   setUploading(true);

  //   try {
  //     const config = {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       },
  //     };

  //     const { data } = await axios.post('/api/upload', formData, config);
  //     setEventFlyer(data);
  //     setUploading(false);
  //   } catch (error) {
  //     console.error(error);
  //     setUploading(false);
  //   }
  // };

  // Multiple Image
  const uploadFileHandler = async (e) => {
    const file = e.target.files;

    for (let i = 0; i < file.length; i++) {
      const formData = new FormData();
      formData.append(`image`, file[i]);
      setUploading(true);

      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        const { data } = await axios.post("/api/upload", formData, config);
        eventFlyer.push(data);
        setUploading(false);

        setMessage((prevMessage) => [
          ...prevMessage,
          "Uploaded the file successfully: " + file[i].name,
        ]);
      } catch (error) {
        // console.error(error);
        setUploading(false);
        setMessage((prevMessage) => [
          ...prevMessage,
          "Could not upload the file: " + file[i].name,
        ]);
      }
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (editEventContact) {
      dispatch(
        updateEventContactById(
          eventContactId,
          memberId,
          contactEmail,
          contactPhone
        )
      );
    } else {
      dispatch(newEventContact(id, memberId, contactEmail, contactPhone));
    }
  };

  const editEventContactHandler = (id) => {
    dispatch({ type: EVENT_CONTACT_UPDATE_BY_ID_RESET });
    dispatch(getEventContactById(id));
  };

  const deleteEventContactHandler = (id) => {
    if (window.confirm("Are You Sure?")) {
      dispatch(deleteEventContact(id));
    }
  };

  const editEventHandler = (e) => {
    e.preventDefault();

    setEditEvent(!editEvent);

    setEventName(event.eventName);
    setEventDescription(event.eventDescription);
    setEventAddress(event.eventAddress);
    setAdultFee(event.adultFee);
    setMinorFee(event.minorFee);
    setCap(event.cap);
    // setEventStartDate(new Date(event.eventDate[0].value));
    setEventStartDate(
      new Date(event.eventDate[0].value).toISOString().split("T")[0]
    );

    setEventStartTime(
      new Date(event.eventDate[0].value)
        .toISOString()
        .split("T")[1]
        .split(".000")[0]
    );
    // setEventEndDate(new Date(event.eventDate[1].value));
    setEventEndDate(
      new Date(event.eventDate[1].value).toISOString().split("T")[0]
    );

    setEventEndTime(
      new Date(event.eventDate[1].value)
        .toISOString()
        .split("T")[1]
        .split(".000")[0]
    );

    setEventAddress(event.eventAddress);
    setAdultFee(event.adultFee);
    setMinorFee(event.minorFee);
    setCap(event.cap);
  };

  const updateEventHandler = (e) => {
    e.preventDefault();

    setEditEvent(!editEvent);
    const eventDate = [
      { value: new Date(eventStartDate + "T" + eventStartTime) },
      { value: new Date(eventEndDate + "T" + eventEndTime) },
    ];

    dispatch(
      updateEventById(
        id,
        eventName,
        eventDescription,
        eventDate,
        eventAddress,
        adultFee,
        minorFee,
        cap,
        eventFlyer
      )
    );
  };

  const createProgram = (programName) => {
    dispatch(newEventProgram({ id, programName }));
  };

  const updateProgram = (eventProgramId, programName) => {
    dispatch(updateEventProgramById(eventProgramId, programName));
  };

  const deleteProgramHandler = (eventProgramId) => {
    if (window.confirm("Are You Sure?")) {
      dispatch(deleteEventProgram(eventProgramId));
    }
  };

  const createGroup = (
    programId,
    groupName,
    ageFrom,
    ageTo,
    gradeFrom,
    gradeTo,
    programStart,
    programEnd,
    fee
  ) => {
    dispatch(
      newEventGroup(
        programId,
        id,
        groupName,
        ageFrom,
        ageTo,
        gradeFrom,
        gradeTo,
        programStart,
        programEnd,
        fee
      )
    );
  };

  const updateGroup = (updateGroup) => {
    dispatch(updateEventGroupById(updateGroup));
  };

  const deleteGroupHandler = (eventGroupId) => {
    if (window.confirm("Are You Sure?")) {
      dispatch(deleteEventGroup(eventGroupId));
    }
  };

  const createHotel = (
    hotelName,
    hotelDescription,
    hotelAddress,
    hotelSiteLink
  ) => {
    dispatch(
      newEventHotel(
        id,
        hotelName,
        hotelDescription,
        hotelAddress,
        hotelSiteLink
      )
    );
  };

  const deleteHotelHandler = (eventHotelId) => {
    if (window.confirm("Are You Sure?")) {
      dispatch(deleteEventHotel(eventHotelId));
    }
  };

  const createRoom = (
    hotelId,
    roomType,
    roomDescription,
    accomodation,
    rent
  ) => {
    dispatch(
      newEventHotelRoom(
        hotelId,
        id,
        roomType,
        roomDescription,
        accomodation,
        rent
      )
    );
  };

  const deleteRoomHandler = (eventHotelRoomId) => {
    if (window.confirm("Are You Sure?")) {
      dispatch(deleteEventHotelRoom(eventHotelRoomId));
    }
  };
  const deleteFlyerHandler = (flyerId) => {
    if (window.confirm("Are You Sure?")) {
      dispatch(deleteFlyer(flyerId));
    }
  };

  const publishEventHandler = (e) => {
    e.preventDefault();

    dispatch(publishEvent(id));
  };

  const unpublishEventHandler = (e) => {
    e.preventDefault();

    dispatch(unpublishEvent(id));
  };

  const EventRegisterHandler = (e) => {
    e.preventDefault();

    dispatch({ type: CART_RESET });
    history.push(`/event/register/${event.eventId}`);
  };

  useEffect(() => {
    if (id === "42042" && event && event.eventId === "42042") {
      if (
        !userInfo ||
        (userInfo.userRole !== "systemAdmin" && userInfo.userRole !== "admin")
      ) {
        history.push(`/event/register/${event.eventId}`);
      }
    }
  }, [event, userInfo]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : event ? (
        (event && event.eventId !== "42141" && event.eventId !== "42042") ||
        (event &&
          (event.eventId === "42141" || event.eventId === "42042") &&
          userInfo &&
          (userInfo.userRole === "systemAdmin" ||
            userInfo.userRole === "admin")) ? (
          <Container>
            {userInfo &&
            (userInfo.userRole === "systemAdmin" ||
              userInfo.userRole === "admin") ? (
              <Link className="btn btn-info my-3 btn-sm" to="/events">
                Go Back
              </Link>
            ) : (
              <Link className="btn btn-info my-3 btn-sm" to="/">
                Go Back
              </Link>
            )}
            <Card className="mb-2">
              {/* Event Image Galleries for non admin users */}
              {chapterByDomain &&
              chapterByDomain.advertisement &&
              event.advertisements &&
              event.advertisements.length !== 0 ? (
                <AdvertisementSlider images={event.advertisements} />
              ) : event.eventImageGalleries &&
                event.eventImageGalleries.length !== 0 ? (
                <EventImageSlider images={event.eventImageGalleries} />
              ) : null}
              <Row>
                <Col md={7} className="m-0 p-1">
                  <Card className="mb-2" border="info">
                    <ListGroup variant="flush">
                      <ListGroup.Item>
                        <Row>
                          <Col md={3} className="p-0">
                            Event Name:
                          </Col>
                          {userInfo &&
                          (userInfo.userRole === "systemAdmin" ||
                            userInfo.userRole === "admin") &&
                          editEvent ? (
                            <Form.Group
                              as={Col}
                              controlId="eventName"
                              className="p-0"
                            >
                              <Form.Control
                                required
                                type="text"
                                placeholder="Event Name"
                                value={eventName}
                                onChange={(e) => setEventName(e.target.value)}
                              ></Form.Control>
                            </Form.Group>
                          ) : (
                            <Col className="p-0">{event.eventName}</Col>
                          )}
                        </Row>
                      </ListGroup.Item>

                      <ListGroup.Item>
                        <Row>
                          <Col md={3} className="p-0">
                            Description:
                          </Col>
                          {userInfo &&
                          (userInfo.userRole === "systemAdmin" ||
                            userInfo.userRole === "admin") &&
                          editEvent ? (
                            <Form.Group
                              as={Col}
                              controlId="eventDescription"
                              className="p-0"
                            >
                              <CKEditor
                                editor={ClassicEditor}
                                data={eventDescription}
                                onChange={(e, editor) => {
                                  const data = editor.getData();
                                  setEventDescription(data);
                                }}
                              />
                            </Form.Group>
                          ) : (
                            <Col className="p-0">
                              {parse(`${event.eventDescription}`)}
                            </Col>
                          )}
                        </Row>
                      </ListGroup.Item>

                      <ListGroup.Item>
                        {/* <Col md={3}>Date:</Col> */}

                        <ListGroup variant="flush">
                          <ListGroup.Item className="p-0">
                            <Row>
                              {userInfo &&
                              (userInfo.userRole === "systemAdmin" ||
                                userInfo.userRole === "admin") &&
                              editEvent ? (
                                <Form.Group
                                  as={Col}
                                  // md='5'
                                  controlId="eventStartDate"
                                  className="p-0"
                                >
                                  <Form.Label>Start Date:</Form.Label>
                                  <Form.Control
                                    required
                                    type="date"
                                    placeholder="Enter Your Start Date"
                                    value={eventStartDate}
                                    onChange={(e) =>
                                      setEventStartDate(e.target.value)
                                    }
                                    min={new Date().toISOString().split("T")[0]}
                                  ></Form.Control>

                                  <Form.Label>From: </Form.Label>
                                  <Form.Control
                                    required
                                    type="time"
                                    value={eventStartTime}
                                    onChange={(e) =>
                                      setEventStartTime(e.target.value)
                                    }
                                  ></Form.Control>
                                </Form.Group>
                              ) : (
                                <>
                                  <Col md={3} className="p-0">
                                    Start Date :
                                  </Col>
                                  {event.eventDate &&
                                  event.eventDate.length !== 0 &&
                                  event.eventDate[0].value ? (
                                    // <Col>{event.eventDate[0].value}</Col>
                                    <>
                                      {new Date(
                                        event.eventDate[0].value
                                      ).toLocaleString()}
                                    </>
                                  ) : null}
                                </>
                              )}
                            </Row>
                          </ListGroup.Item>

                          <ListGroup.Item className="p-0">
                            <Row>
                              {userInfo &&
                              (userInfo.userRole === "systemAdmin" ||
                                userInfo.userRole === "admin") &&
                              editEvent ? (
                                <Form.Group
                                  as={Col}
                                  // md='5'
                                  controlId="eventEndDate"
                                  className="p-0"
                                >
                                  <Form.Label>End Date:</Form.Label>
                                  <Form.Control
                                    required
                                    type="date"
                                    placeholder="Enter The End Date"
                                    value={eventEndDate}
                                    onChange={(e) =>
                                      setEventEndDate(e.target.value)
                                    }
                                    min={eventStartDate}
                                  ></Form.Control>

                                  <Form.Label>To: </Form.Label>
                                  <Form.Control
                                    required
                                    type="time"
                                    value={eventEndTime}
                                    onChange={(e) =>
                                      setEventEndTime(e.target.value)
                                    }
                                  ></Form.Control>
                                </Form.Group>
                              ) : (
                                <>
                                  <Col md={3} className="p-0">
                                    End Date :
                                  </Col>
                                  {event.eventDate &&
                                  event.eventDate.length !== 0 &&
                                  event.eventDate[1].value ? (
                                    // <Col>{event.eventDate[1].value}</Col>
                                    // <Moment>{event.eventDate[1].value}</Moment>
                                    <>
                                      {new Date(
                                        event.eventDate[1].value
                                      ).toLocaleString()}
                                    </>
                                  ) : null}
                                </>
                              )}
                            </Row>
                          </ListGroup.Item>
                        </ListGroup>
                      </ListGroup.Item>

                      <ListGroup.Item>
                        <Row>
                          <Col md={3} className="p-0">
                            Event Location:
                          </Col>
                          {userInfo &&
                          (userInfo.userRole === "systemAdmin" ||
                            userInfo.userRole === "admin") &&
                          editEvent ? (
                            <Form.Group
                              as={Col}
                              controlId="eventAddress"
                              className="p-0"
                            >
                              <Form.Control
                                required
                                type="text"
                                placeholder="Enter Location Address"
                                value={eventAddress}
                                onChange={(e) =>
                                  setEventAddress(e.target.value)
                                }
                              ></Form.Control>
                            </Form.Group>
                          ) : (
                            <Col className="p-0">{event.eventAddress}</Col>
                          )}
                        </Row>
                      </ListGroup.Item>

                      {event.eventType &&
                      (event.eventType === "picnic" ||
                        event.eventType === "convention") ? (
                        <>
                          <ListGroup.Item>
                            <Row>
                              <Col md={3} className="p-0">
                                Adult Fee:
                              </Col>
                              {userInfo &&
                              (userInfo.userRole === "systemAdmin" ||
                                userInfo.userRole === "admin") &&
                              editEvent ? (
                                <Form.Group
                                  as={Col}
                                  controlId="adultFee"
                                  className="p-0"
                                >
                                  <Form.Control
                                    required
                                    type="number"
                                    min="0"
                                    placeholder="Set Audult Fee"
                                    value={adultFee}
                                    onChange={(e) =>
                                      setAdultFee(e.target.value)
                                    }
                                  ></Form.Control>
                                </Form.Group>
                              ) : (
                                <Col className="p-0">$ {event.adultFee}</Col>
                              )}
                            </Row>
                          </ListGroup.Item>

                          <ListGroup.Item>
                            <Row>
                              <Col md={3} className="p-0">
                                Minor Fee:
                              </Col>
                              {userInfo &&
                              (userInfo.userRole === "systemAdmin" ||
                                userInfo.userRole === "admin") &&
                              editEvent ? (
                                <Form.Group
                                  as={Col}
                                  controlId="minorFee"
                                  className="p-0"
                                >
                                  <Form.Control
                                    required
                                    type="number"
                                    min="0"
                                    placeholder="Set Minor Fee"
                                    value={minorFee}
                                    onChange={(e) =>
                                      setMinorFee(e.target.value)
                                    }
                                  ></Form.Control>
                                </Form.Group>
                              ) : (
                                <Col className="p-0">$ {event.minorFee}</Col>
                              )}
                            </Row>
                          </ListGroup.Item>

                          {userInfo &&
                            (userInfo.userRole === "systemAdmin" ||
                              userInfo.userRole === "admin") && (
                              <>
                                <ListGroup.Item>
                                  <Row>
                                    <Col md={3} className="p-0">
                                      Cap:
                                    </Col>
                                    {editEvent ? (
                                      <Form.Group
                                        as={Col}
                                        controlId="cap"
                                        className="p-0"
                                      >
                                        <Form.Control
                                          required
                                          type="number"
                                          min="0"
                                          placeholder="Set cap"
                                          value={cap}
                                          onChange={(e) =>
                                            setCap(e.target.value)
                                          }
                                        ></Form.Control>
                                      </Form.Group>
                                    ) : (
                                      <Col className="p-0">$ {event.cap}</Col>
                                    )}
                                  </Row>
                                </ListGroup.Item>
                              </>
                            )}
                        </>
                      ) : null}
                    </ListGroup>
                  </Card>

                  {event.eventType && event.eventType === "election" ? (
                    <>
                      {event.eventPrograms &&
                        event.eventPrograms.length !== 0 &&
                        event.eventPrograms.map((program) => (
                          <Card key={program.eventProgramId} border="info">
                            <Accordion
                              defaultActiveKey={program.eventProgramId}
                            >
                              <Accordion.Toggle
                                as={Card.Header}
                                eventKey={program.eventProgramId}
                                style={{ cursor: "pointer" }}
                              >
                                <Row>
                                  {/* <Col md={3} className='p-0'>
                                    Positions:
                                  </Col> */}
                                  <Col className="mr-auto p-0">
                                    {program.programName}
                                  </Col>
                                  {userInfo &&
                                    (userInfo.userRole === "systemAdmin" ||
                                      userInfo.userRole === "admin") && (
                                      <Col
                                        className="ml-auto p-0"
                                        md={2}
                                        xs={4}
                                      >
                                        {/* Create Group */}
                                        <CreateGroup
                                          eventType={event.eventType}
                                          programId={program.eventProgramId}
                                          eventFrom={event.eventDate[0].value}
                                          eventTo={event.eventDate[1].value}
                                          createGroup={createGroup}
                                        />
                                      </Col>
                                    )}
                                </Row>
                              </Accordion.Toggle>
                              <Accordion.Collapse
                                eventKey={program.eventProgramId}
                              >
                                <Card.Body className="p-0">
                                  {program.eventProgramGroups &&
                                  program.eventProgramGroups.length !== 0
                                    ? program.eventProgramGroups.map(
                                        (group) => (
                                          <Card.Body
                                            key={group.eventGroupId}
                                            className="mb-2 py-0"
                                          >
                                            <ListGroup variant="flush">
                                              <ListGroup.Item>
                                                <Row>
                                                  <Col md={3}>
                                                    {event?.eventType ===
                                                    "election"
                                                      ? "Position"
                                                      : "Group"}{" "}
                                                    Name:
                                                  </Col>
                                                  <Col className="mr-auto">
                                                    {group.groupName}
                                                  </Col>

                                                  {userInfo &&
                                                    (userInfo.userRole ===
                                                      "systemAdmin" ||
                                                      userInfo.userRole ===
                                                        "admin") && (
                                                      <Col
                                                        className="ml-auto text-center"
                                                        md={2}
                                                        xs={2}
                                                      >
                                                        {/* Program Group Edit */}
                                                        <EditGroup
                                                          eventType={
                                                            event.eventType
                                                          }
                                                          group={group}
                                                          updateGroup={
                                                            updateGroup
                                                          }
                                                        />
                                                        {/* Group Delete */}
                                                        <OverlayTrigger
                                                          overlay={
                                                            <Tooltip id="tooltip-disabled">
                                                              Delete Group!
                                                            </Tooltip>
                                                          }
                                                        >
                                                          <span
                                                            onClick={() =>
                                                              deleteGroupHandler(
                                                                group.eventGroupId
                                                              )
                                                            }
                                                          >
                                                            <i
                                                              className="fas fa-trash action ml-2"
                                                              style={{
                                                                color: "red",
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                            ></i>
                                                          </span>
                                                        </OverlayTrigger>
                                                      </Col>
                                                    )}
                                                </Row>
                                              </ListGroup.Item>

                                              {/* Has been hidden , dont delete */}
                                              {/* <ListGroup.Item>
                                            <Row>
                                              <Col md={3}>Age :</Col>
                                              <Col>
                                                {group.ageFrom} Years to{' '}
                                                {group.ageTo} Years
                                              </Col>
                                            </Row>
                                          </ListGroup.Item> */}

                                              {event?.eventType !==
                                                "election" && (
                                                <>
                                                  <ListGroup.Item>
                                                    <Row>
                                                      <Col md={3}>Grade :</Col>
                                                      <Col>
                                                        {group.gradeFrom} to{" "}
                                                        {group.gradeTo}
                                                      </Col>
                                                    </Row>
                                                  </ListGroup.Item>

                                                  <ListGroup.Item>
                                                    <Row>
                                                      <Col md={3}>Date:</Col>
                                                      <Col>
                                                        <ListGroup variant="flush">
                                                          <ListGroup.Item className="p-0">
                                                            <Row>
                                                              <Col md={4}>
                                                                Start Date :
                                                              </Col>
                                                              {group.ProgramDateFrom ? (
                                                                // <Moment>
                                                                //   {
                                                                //     group.ProgramDateFrom
                                                                //   }
                                                                // </Moment>
                                                                <>
                                                                  {new Date(
                                                                    group.ProgramDateFrom
                                                                  ).toLocaleString()}
                                                                </>
                                                              ) : null}
                                                            </Row>
                                                          </ListGroup.Item>

                                                          <ListGroup.Item className="p-0">
                                                            <Row>
                                                              <Col md={4}>
                                                                End Date :
                                                              </Col>
                                                              {group.ProgramDateTo ? (
                                                                // <Col>{event.eventDate[1].value}</Col>
                                                                // <Moment>
                                                                //   {
                                                                //     group.ProgramDateTo
                                                                //   }
                                                                // </Moment>
                                                                <>
                                                                  {new Date(
                                                                    group.ProgramDateTo
                                                                  ).toLocaleString()}
                                                                </>
                                                              ) : null}
                                                            </Row>
                                                          </ListGroup.Item>
                                                        </ListGroup>
                                                      </Col>
                                                    </Row>
                                                  </ListGroup.Item>
                                                </>
                                              )}

                                              <ListGroup.Item>
                                                <Row>
                                                  <Col md={3}>
                                                    {event?.eventType ===
                                                    "election"
                                                      ? "Registration"
                                                      : "Participation"}{" "}
                                                    Fee :
                                                  </Col>
                                                  <Col>{group.fee}</Col>
                                                </Row>
                                              </ListGroup.Item>
                                            </ListGroup>
                                            <hr />
                                          </Card.Body>
                                        )
                                      )
                                    : null}
                                </Card.Body>
                              </Accordion.Collapse>
                            </Accordion>
                          </Card>
                        ))}
                    </>
                  ) : (
                    <>
                      {/* Programs */}
                      <Accordion defaultActiveKey="0" className="mb-2">
                        {event.eventPrograms &&
                          event.eventPrograms.length !== 0 &&
                          event.eventPrograms.map((program) => (
                            <Card key={program.eventProgramId} border="info">
                              <Accordion.Toggle
                                as={Card.Header}
                                eventKey={program.eventProgramId}
                                style={{ cursor: "pointer" }}
                              >
                                <Row>
                                  <Col md={3} className="p-0">
                                    Program Name:
                                  </Col>
                                  <Col className="mr-auto p-0">
                                    {program.programName}
                                  </Col>
                                  {userInfo &&
                                    (userInfo.userRole === "systemAdmin" ||
                                      userInfo.userRole === "admin") && (
                                      <Col
                                        className="ml-auto p-0"
                                        md={2}
                                        xs={4}
                                      >
                                        {/* Create Group */}
                                        <CreateGroup
                                          programId={program.eventProgramId}
                                          eventFrom={event.eventDate[0].value}
                                          eventTo={event.eventDate[1].value}
                                          createGroup={createGroup}
                                        />

                                        {/* Program Edit */}
                                        <EditProgram
                                          programId={program.eventProgramId}
                                          programName={program.programName}
                                          updateProgram={updateProgram}
                                        />

                                        {/* Program Delete */}
                                        <OverlayTrigger
                                          overlay={
                                            <Tooltip id="tooltip-disabled">
                                              Delete Program!
                                            </Tooltip>
                                          }
                                        >
                                          <span
                                            onClick={() =>
                                              deleteProgramHandler(
                                                program.eventProgramId
                                              )
                                            }
                                          >
                                            <i
                                              className="fas fa-trash action ml-2"
                                              style={{
                                                color: "red",
                                                cursor: "pointer",
                                              }}
                                            ></i>
                                          </span>
                                        </OverlayTrigger>
                                      </Col>
                                    )}
                                </Row>
                              </Accordion.Toggle>
                              <Accordion.Collapse
                                eventKey={program.eventProgramId}
                              >
                                <Card.Body className="p-0">
                                  {program.eventProgramGroups &&
                                  program.eventProgramGroups.length !== 0
                                    ? program.eventProgramGroups.map(
                                        (group) => (
                                          <Card.Body
                                            key={group.eventGroupId}
                                            className="mb-2 py-0"
                                          >
                                            <ListGroup variant="flush">
                                              <ListGroup.Item>
                                                <Row>
                                                  <Col md={3}>Group Name:</Col>
                                                  <Col className="mr-auto">
                                                    {group.groupName}
                                                  </Col>

                                                  {userInfo &&
                                                    (userInfo.userRole ===
                                                      "systemAdmin" ||
                                                      userInfo.userRole ===
                                                        "admin") && (
                                                      <Col
                                                        className="ml-auto text-center"
                                                        md={2}
                                                        xs={2}
                                                      >
                                                        {/* Program Group Edit */}
                                                        <EditGroup
                                                          group={group}
                                                          updateGroup={
                                                            updateGroup
                                                          }
                                                        />
                                                        {/* Group Delete */}
                                                        <OverlayTrigger
                                                          overlay={
                                                            <Tooltip id="tooltip-disabled">
                                                              Delete Group!
                                                            </Tooltip>
                                                          }
                                                        >
                                                          <span
                                                            onClick={() =>
                                                              deleteGroupHandler(
                                                                group.eventGroupId
                                                              )
                                                            }
                                                          >
                                                            <i
                                                              className="fas fa-trash action ml-2"
                                                              style={{
                                                                color: "red",
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                            ></i>
                                                          </span>
                                                        </OverlayTrigger>
                                                      </Col>
                                                    )}
                                                </Row>
                                              </ListGroup.Item>

                                              {/* Has been hidden , dont delete */}
                                              {/* <ListGroup.Item>
                                                <Row>
                                                  <Col md={3}>Age :</Col>
                                                  <Col>
                                                    {group.ageFrom} Years to{' '}
                                                    {group.ageTo} Years
                                                  </Col>
                                                </Row>
                                              </ListGroup.Item> */}

                                              <ListGroup.Item>
                                                <Row>
                                                  <Col md={3}>Grade :</Col>
                                                  <Col>
                                                    {group.gradeFrom} to{" "}
                                                    {group.gradeTo}
                                                  </Col>
                                                </Row>
                                              </ListGroup.Item>

                                              <ListGroup.Item>
                                                <Row>
                                                  <Col md={3}>Date:</Col>
                                                  <Col>
                                                    <ListGroup variant="flush">
                                                      <ListGroup.Item className="p-0">
                                                        <Row>
                                                          <Col md={4}>
                                                            Start Date :
                                                          </Col>
                                                          {group.ProgramDateFrom ? (
                                                            // <Moment>
                                                            //   {
                                                            //     group.ProgramDateFrom
                                                            //   }
                                                            // </Moment>
                                                            <>
                                                              {new Date(
                                                                group.ProgramDateFrom
                                                              ).toLocaleString()}
                                                            </>
                                                          ) : null}
                                                        </Row>
                                                      </ListGroup.Item>

                                                      <ListGroup.Item className="p-0">
                                                        <Row>
                                                          <Col md={4}>
                                                            End Date :
                                                          </Col>
                                                          {group.ProgramDateTo ? (
                                                            // <Col>{event.eventDate[1].value}</Col>
                                                            // <Moment>
                                                            //   {
                                                            //     group.ProgramDateTo
                                                            //   }
                                                            // </Moment>
                                                            <>
                                                              {new Date(
                                                                group.ProgramDateTo
                                                              ).toLocaleString()}
                                                            </>
                                                          ) : null}
                                                        </Row>
                                                      </ListGroup.Item>
                                                    </ListGroup>
                                                  </Col>
                                                </Row>
                                              </ListGroup.Item>

                                              <ListGroup.Item>
                                                <Row>
                                                  <Col md={3}>Fee :</Col>
                                                  <Col>{group.fee}</Col>
                                                </Row>
                                              </ListGroup.Item>
                                            </ListGroup>
                                            <hr />
                                          </Card.Body>
                                        )
                                      )
                                    : null}
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                          ))}
                      </Accordion>

                      {/* Hotels */}
                      <Accordion defaultActiveKey="0" className="mb-2">
                        {event.eventHotels &&
                          event.eventHotels.length !== 0 &&
                          event.eventHotels.map((hotel) => (
                            <Card key={hotel.eventHotelId} border="info">
                              <Accordion.Toggle
                                as={Card.Header}
                                eventKey={hotel.eventHotelId}
                                style={{ cursor: "pointer" }}
                              >
                                <Row>
                                  <Col md={3}>Hotel Name:</Col>
                                  <Col className="mr-auto">
                                    {hotel.hotelName}
                                  </Col>
                                  {userInfo &&
                                    (userInfo.userRole === "systemAdmin" ||
                                      userInfo.userRole === "admin") && (
                                      <Col className="ml-auto" md={2} xs={4}>
                                        {/* Create Room */}
                                        <CreateRoom
                                          hotelId={hotel.eventHotelId}
                                          createRoom={createRoom}
                                        />

                                        {/* Hotel Delete */}
                                        <OverlayTrigger
                                          overlay={
                                            <Tooltip id="tooltip-disabled">
                                              Delete Hotel!
                                            </Tooltip>
                                          }
                                        >
                                          <span
                                            onClick={() =>
                                              deleteHotelHandler(
                                                hotel.eventHotelId
                                              )
                                            }
                                          >
                                            <i
                                              className="fas fa-trash action ml-2"
                                              style={{
                                                color: "red",
                                                cursor: "pointer",
                                              }}
                                            ></i>
                                          </span>
                                        </OverlayTrigger>
                                      </Col>
                                    )}
                                </Row>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey={hotel.eventHotelId}>
                                <Card.Body>
                                  <Card.Body>
                                    <Row>
                                      <Col>About Hotel :</Col>
                                      <Col>{hotel.hotelDescription}</Col>
                                    </Row>
                                    <Row>
                                      <Col>Address :</Col>
                                      <Col>{hotel.hotelAddress}</Col>
                                    </Row>
                                    <Row>
                                      <Col>Website :</Col>
                                      <Col>
                                        <Card.Link
                                          href={hotel.hotelSiteLink}
                                          target="blank"
                                        >
                                          {hotel.hotelSiteLink}
                                        </Card.Link>
                                      </Col>
                                    </Row>
                                  </Card.Body>
                                  {hotel.eventHotelRooms &&
                                  hotel.eventHotelRooms.length !== 0
                                    ? hotel.eventHotelRooms.map((room) => (
                                        <Card.Body
                                          key={room.eventHotelRoomId}
                                          className="mb-2"
                                        >
                                          <ListGroup variant="flush">
                                            <ListGroup.Item>
                                              <Row>
                                                <Col md={3}>Room Type:</Col>
                                                <Col className="mr-auto">
                                                  {room.roomType}
                                                </Col>

                                                {userInfo &&
                                                  (userInfo.userRole ===
                                                    "systemAdmin" ||
                                                    userInfo.userRole ===
                                                      "admin") && (
                                                    <Col
                                                      className="ml-auto text-center"
                                                      md={2}
                                                      xs={2}
                                                    >
                                                      <OverlayTrigger
                                                        overlay={
                                                          <Tooltip id="tooltip-disabled">
                                                            Delete Room Type!
                                                          </Tooltip>
                                                        }
                                                      >
                                                        <span
                                                          onClick={() =>
                                                            deleteRoomHandler(
                                                              room.eventHotelRoomId
                                                            )
                                                          }
                                                        >
                                                          <i
                                                            className="fas fa-trash action ml-2"
                                                            style={{
                                                              color: "red",
                                                              cursor: "pointer",
                                                            }}
                                                          ></i>
                                                        </span>
                                                      </OverlayTrigger>
                                                    </Col>
                                                  )}
                                              </Row>
                                            </ListGroup.Item>

                                            <ListGroup.Item>
                                              <Row>
                                                <Col md={3}>Description :</Col>
                                                <Col>
                                                  {room.roomDescription}
                                                </Col>
                                              </Row>
                                            </ListGroup.Item>

                                            <ListGroup.Item>
                                              <Row>
                                                <Col md={3}>Accomodation :</Col>
                                                <Col>{room.accomodation}</Col>
                                              </Row>
                                            </ListGroup.Item>

                                            <ListGroup.Item>
                                              <Row>
                                                <Col md={3}>Rent :</Col>
                                                <Col>{room.rent}</Col>
                                              </Row>
                                            </ListGroup.Item>
                                          </ListGroup>
                                          <hr />
                                        </Card.Body>
                                      ))
                                    : null}
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                          ))}
                      </Accordion>
                    </>
                  )}

                  {/* Buttons */}
                  <Row className="mb-1">
                    {userInfo &&
                    (userInfo.userRole === "systemAdmin" ||
                      userInfo.userRole === "admin") ? (
                      <>
                        <Col className="m-0 p-1">
                          {/* <Card.Footer className='text-muted'> */}
                          {editEvent ? (
                            <Button
                              className="btn btn-outline-info btn-sm btn-block  rounded"
                              variant="transparent"
                              onClick={updateEventHandler}
                            >
                              Update Event
                            </Button>
                          ) : (
                            <Button
                              className="btn btn-outline-info btn-sm btn-block  rounded"
                              variant="transparent"
                              onClick={editEventHandler}
                            >
                              Edit Event
                            </Button>
                          )}

                          {/* </Card.Footer> */}
                        </Col>
                        <Col className="m-0 p-1">
                          {/* <Card.Footer className='text-muted'> */}
                          {editEvent ? (
                            <Button
                              className="btn btn-outline-danger btn-sm btn-block  rounded"
                              variant="transparent"
                              onClick={editEventHandler}
                            >
                              Cancel
                            </Button>
                          ) : !event.eventStatus ? (
                            <Button
                              className="btn btn-outline-warning btn-sm btn-block rounded"
                              variant="transparent"
                              onClick={publishEventHandler}
                            >
                              Publish
                            </Button>
                          ) : (
                            <Button
                              className="btn btn-outline-warning btn-sm btn-block rounded"
                              variant="transparent"
                              onClick={unpublishEventHandler}
                            >
                              Un-Publish
                            </Button>
                          )}

                          {/* </Card.Footer> */}
                        </Col>
                      </>
                    ) : event && event.eventStatus ? (
                      <Col className="m-0 p-0">
                        {event.eventType &&
                        (event.eventType === "donation" ||
                          event.eventType === "fund-raising") ? (
                          <>
                            <Button
                              className="btn btn-outline-info btn-sm btn-block rounded"
                              variant="transparent"
                              onClick={EventRegisterHandler}
                            >
                              Donate
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              className="btn btn-info btn-sm btn-block rounded custom-btn"
                              // variant='transparent'
                              onClick={EventRegisterHandler}
                            >
                              Register
                            </Button>
                          </>
                        )}
                      </Col>
                    ) : null}
                  </Row>

                  {/* details event Images for admin */}
                  {event.eventImageGalleries &&
                    event.eventImageGalleries.length !== 0 && (
                      <>
                        <Row>
                          <Col className="m-0 p-0">
                            <Card className="text-center mb-2" border="info">
                              <Card.Header as="h3" className="text-info">
                                Event Images
                              </Card.Header>
                              <>
                                <ImageLightBox
                                  images={event.eventImageGalleries}
                                />
                              </>
                            </Card>
                          </Col>
                        </Row>
                      </>
                    )}
                </Col>

                {/* Right side collumn */}
                <Col md={5} className="m-0 p-1">
                  <Card border="info">
                    {event.eventFlyers && event.eventFlyers.length !== 0 ? (
                      <>
                        {event.eventFlyers.map((flyer, i) => (
                          <div key={i}>
                            {flyer &&
                              (flyer.flyer.split(".").pop() === "pdf" ? (
                                <iframe
                                  // id='viewer'
                                  title="#"
                                  style={{ width: "100%", height: "300px" }}
                                  src={flyer.flyer}
                                ></iframe>
                              ) : (
                                <Card.Img src={flyer.flyer} variant="top" />
                              ))}
                            {userInfo &&
                              (userInfo.userRole === "systemAdmin" ||
                                userInfo.userRole === "admin") && (
                                <div className="text-center">
                                  <span
                                    onClick={() =>
                                      deleteFlyerHandler(flyer.flyerId)
                                    }
                                  >
                                    <i className="fas fa-trash action"></i>
                                  </span>
                                </div>
                              )}
                          </div>
                        ))}
                      </>
                    ) : null}

                    {editEvent && (
                      <Card.Header>
                        <Form.Group as={Col}>
                          <Form.Label>Upload New Flyer ?</Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} controlId="eventFlyer">
                          <Form.File
                            id="image-file"
                            // label='Choose File'
                            // custom
                            multiple
                            onChange={uploadFileHandler}
                          ></Form.File>
                          {uploading && <Loader />}
                        </Form.Group>
                        {message.length > 0 && (
                          <div className="alert alert-secondary" role="alert">
                            <ul>
                              {message.map((item, i) => {
                                return <li key={i}>{item}</li>;
                              })}
                            </ul>
                          </div>
                        )}
                      </Card.Header>
                    )}
                    {userInfo &&
                      (userInfo.userRole === "systemAdmin" ||
                        userInfo.userRole === "admin") && (
                        <>
                          <Card.Header className="text-center" as="h5">
                            {event?.eventType !== "election" && (
                              <>
                                <CreateProgram createProgram={createProgram} />
                                <CreateHotel createHotel={createHotel} />
                              </>
                            )}
                            <Link
                              className="btn btn-outline-info btn-sm btn-block  rounded"
                              to="#"
                              onClick={addNewEventContact}
                            >
                              Add Contact
                            </Link>
                          </Card.Header>

                          {addEventContact
                            ? (eventContactNewError && (
                                <Message variant="danger">
                                  {eventContactNewError}
                                </Message>
                              )) ||
                              (eventContactNewLoading && <Loader />) ||
                              (success ? (
                                <Message variant="success">{success}</Message>
                              ) : (
                                <>
                                  <Form onSubmit={submitHandler}>
                                    <Form.Group controlId="memberId">
                                      <Form.Label>Member Id</Form.Label>
                                      <Form.Control
                                        as="select"
                                        onChange={memberIdHandler}
                                      >
                                        <option>Select member Id</option>
                                        {checkChapter
                                          .replace("www.", "")
                                          .split(".")[0] === "aabea"
                                          ? allUserList &&
                                            allUserList.length !== 0 &&
                                            allUserList.map((user) => (
                                              <option
                                                key={user.memberId}
                                                value={[
                                                  user.memberId,
                                                  user.primaryEmail,
                                                  user.primaryPhone,
                                                ]}
                                              >
                                                {user.memberId}
                                              </option>
                                            ))
                                          : users &&
                                            users.length !== 0 &&
                                            users.map((user) => (
                                              <option
                                                key={user.memberId}
                                                value={[
                                                  user.memberId,
                                                  user.primaryEmail,
                                                  user.primaryPhone,
                                                ]}
                                              >
                                                {user.memberId}
                                              </option>
                                            ))}
                                      </Form.Control>
                                    </Form.Group>

                                    <Form.Group controlId="title">
                                      <Form.Label>Contact Email</Form.Label>
                                      <Form.Control
                                        required
                                        type="email"
                                        placeholder="Email"
                                        value={contactEmail}
                                        onChange={(e) =>
                                          setContactEmail(e.target.value)
                                        }
                                      ></Form.Control>
                                    </Form.Group>

                                    <Form.Group controlId="title">
                                      <Form.Label>Contact Phone</Form.Label>
                                      <Form.Control
                                        required
                                        type="tel"
                                        placeholder="Phone Number"
                                        value={contactPhone}
                                        onChange={(e) =>
                                          setContactPhone(e.target.value)
                                        }
                                      ></Form.Control>
                                    </Form.Group>

                                    {editEventContact ? (
                                      <Button
                                        type="submit"
                                        variant="info"
                                        block
                                      >
                                        <i className="fas fa-plus" /> Update
                                      </Button>
                                    ) : (
                                      <Button
                                        type="submit"
                                        variant="info"
                                        block
                                      >
                                        <i className="fas fa-plus" /> Add
                                      </Button>
                                    )}
                                  </Form>
                                </>
                              ))
                            : null}
                        </>
                      )}

                    <Card.Header className="text-info text-center">
                      Contacts:
                    </Card.Header>

                    {eventContactsLoading ? (
                      <Loader />
                    ) : eventContactsError ? (
                      <Message variant="danger">{eventContactsError}</Message>
                    ) : (
                      <Card.Body className="p-0">
                        <ListGroup variant="flush">
                          {eventContacts && eventContacts.length !== 0
                            ? eventContacts.map((eventContact, index) => (
                                <ListGroup.Item key={index}>
                                  <Row>
                                    <Col md={4}>Name :</Col>
                                    <Col>
                                      {eventContact.contactName.replace(
                                        "null",
                                        ""
                                      )}
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col md={4}>Email :</Col>
                                    <Col>
                                      <a
                                        href={`mailTo: ${eventContact.contactEmail}`}
                                      >
                                        {eventContact.contactEmail}
                                      </a>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col md={4}>Phone :</Col>
                                    <Col>
                                      <a
                                        href={`tel: ${eventContact.contactPhone}`}
                                      >
                                        {eventContact.contactPhone}
                                      </a>
                                    </Col>
                                  </Row>

                                  {userInfo &&
                                    (userInfo.userRole === "systemAdmin" ||
                                      userInfo.userRole === "admin") && (
                                      <div className="text-center">
                                        <span
                                          onClick={() =>
                                            editEventContactHandler(
                                              eventContact.eventContactId
                                            )
                                          }
                                        >
                                          <i className="far fa-edit action mr-2"></i>
                                        </span>

                                        <span
                                          onClick={() =>
                                            deleteEventContactHandler(
                                              eventContact.eventContactId
                                            )
                                          }
                                        >
                                          <i className="fas fa-trash action"></i>
                                        </span>
                                      </div>
                                    )}
                                </ListGroup.Item>
                              ))
                            : null}{" "}
                        </ListGroup>
                      </Card.Body>
                    )}
                  </Card>
                </Col>
              </Row>
            </Card>

            {/* Registration details / dolation details */}
            {userInfo &&
            (userInfo.userRole === "systemAdmin" ||
              userInfo.userRole === "admin") ? (
              <>
                {event.eventType &&
                event.eventType &&
                (event.eventType === "donation" ||
                  event.eventType === "fund-raising") ? (
                  <>
                    <Card className="text-center p-0" border="info">
                      <Card.Header as="h5" className="text-info">
                        Donor List
                      </Card.Header>
                      <Row>
                        <Col className="p-0">
                          {event.eventRegistrations &&
                          event.eventRegistrations.length !== 0 ? (
                            <RTable
                              users={event.eventRegistrations}
                              COLUMNS={columnsAdmin}
                            />
                          ) : (
                            <span>No Donation found</span>
                          )}
                        </Col>
                      </Row>
                    </Card>
                  </>
                ) : event.eventType === "election" ? (
                  <>
                    <Card className="text-center p-0" border="info">
                      <Card.Header as="h5" className="text-info">
                        Registration details
                      </Card.Header>
                      <Row>
                        <Col className="p-0">
                          {event.eventRegistrations &&
                          event.eventRegistrations.length !== 0 ? (
                            <RTable
                              users={event.eventRegistrations}
                              COLUMNS={nominationTableColumns}
                            />
                          ) : (
                            <span>No Record found</span>
                          )}
                        </Col>
                      </Row>
                    </Card>
                  </>
                ) : (
                  <>
                    <Card className="text-center p-0" border="info">
                      <Card.Header as="h5" className="text-info">
                        Registered List
                      </Card.Header>
                      {/* <Row>
                        <Col className='p-0'>
                          {event.eventRegistrations &&
                          event.eventRegistrations.length !== 0 ? (
                            <RTable
                              users={event.eventRegistrations}
                              COLUMNS={columnsAdmin}
                            />
                          ) : (
                            <span>No registration found</span>
                          )}
                        </Col>
                      </Row> */}

                      <Row>
                        <Col className="p-0">
                          {event.eventRegistrations &&
                          event.eventRegistrations.length !== 0 ? (
                            <>
                              <ModTableData
                                data={event.eventRegistrations}
                                // COLUMNS={columnsAdmin}
                              />
                            </>
                          ) : (
                            <span>No registration found</span>
                          )}
                        </Col>
                      </Row>
                    </Card>
                  </>
                )}
              </>
            ) : null}
          </Container>
        ) : (
          <>
            <CentralEvent2022 history={history} />
          </>
        )
      ) : null}
    </>
  );
};

export default EventByIdScreen;
