import React, { useCallback, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Media,
  ListGroup,
  Container,
  Accordion,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import Sidebar from "../../components/Sidebar/Sidebar";
import RTable from "../../components/Table/RTable";
import ColumnFilter from "../../components/Table/ColumnFilter";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import {
  newCMember,
  allCMembers,
  deleteCMember,
  updateCMemberById,
  newSession,
  deleteSession,
  updateSessionById,
} from "../../actions/committeeActions";
import {
  COMMITTEE_MEMBER_BY_ID_RESET,
  COMMITTEE_MEMBER_NEW_RESET,
  COMMITTEE_MEMBER_UPDATE_BY_ID_RESET,
  COMMITTEE_SESSION_DELETE_RESET,
  COMMITTEE_SESSION_NEW_RESET,
} from "../../constants/committeeConstants";
import swal from "sweetalert";
import parse from "html-react-parser";
import { listAllUsers, listUsers } from "../../actions/userActions";
import CreateSession from "../../components/Committee/CreateSession";
import AddCommitteeMember from "../../components/Committee/AddCommitteeMember";
import EditSession from "../../components/Committee/EditSession";
import EditCommitteeMember from "../../components/Committee/EditCommitteeMember";

const CommitteeScreen = ({ history }) => {
  const dispatch = useDispatch();

  const [columnsAdmin, setColumnsAdmin] = useState([]);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const sessionNew = useSelector((state) => state.sessionNew);
  const { error: sessionNewError, success: sessionNewSuccess } = sessionNew;

  const sessionUpdate = useSelector((state) => state.sessionUpdate);
  const { error: sessionUpdateError, success: sessionUpdateSuccess } =
    sessionUpdate;

  const sessionDelete = useSelector((state) => state.sessionDelete);
  const { error: sessionDeleteError, success: sessionDeleteSuccess } =
    sessionDelete;

  const userList = useSelector((state) => state.userList);
  const { users } = userList;

  const userAllList = useSelector((state) => state.userAllList);
  const { users: allUserList } = userAllList;

  const cMemberAll = useSelector((state) => state.cMemberAll);
  const { loading, error, cMembers } = cMemberAll;

  const cMemberNew = useSelector((state) => state.cMemberNew);
  const { error: cMemberNewError, success } = cMemberNew;

  const cMemberById = useSelector((state) => state.cMemberById);
  const { cMember } = cMemberById;

  const cMemberUpdate = useSelector((state) => state.cMemberUpdate);
  const { success: cMemberUpdateSuccess, error: cMemberUpdateError } =
    cMemberUpdate;

  const cMemberDelete = useSelector((state) => state.cMemberDelete);
  const { success: successDelete } = cMemberDelete;

  const checkChapter = window.location.host;

  let subDomain;
  if (checkChapter.split(".")[0] === "www") {
    subDomain = checkChapter.split(".")[1];
  } else {
    subDomain = checkChapter.split(".")[0];
  }

  const deleteCMemberHandler = useCallback(
    (rowData) => {
      if (window.confirm("Are You Sure?")) {
        dispatch(deleteCMember(rowData.cId));
      }
    },
    [dispatch]
  );

  const updateCommitteeMember = useCallback(
    (id, cMemberId, position, bio) => {
      dispatch(updateCMemberById(id, cMemberId, position, bio));
    },
    [dispatch]
  );

  // const updateCommitteeMember = (id, cMemberId, position, bio) => {
  //   dispatch(updateCMemberById(id, cMemberId, position, bio));
  // };

  useEffect(() => {
    if (userInfo) {
      dispatch({ type: COMMITTEE_MEMBER_NEW_RESET });
      if (subDomain === "aabea") {
        dispatch(listAllUsers());
      } else {
        dispatch(listUsers(checkChapter));
      }

      if (
        userInfo.userRole === "admin" ||
        userInfo.userRole === "systemAdmin"
      ) {
        setColumnsAdmin([
          {
            Header: "Tenure",
            accessor: "tenure",
            Filter: ColumnFilter,
          },
          {
            Header: "Position",
            accessor: "position",
            Filter: ColumnFilter,
            Cell: ({ value }) => {
              return value.charAt(0).toUpperCase() + value.slice(1);
            },
          },
          {
            Header: "Member Id",
            accessor: "memberId",
            Filter: ColumnFilter,
          },
          {
            Header: "First Name",
            accessor: "member.firstName",
            Filter: ColumnFilter,
          },
          {
            Header: "Last Name",
            accessor: "member.lastName",
            Filter: ColumnFilter,
          },
          {
            Header: "Phone",
            accessor: "member.primaryPhone",
            Filter: ColumnFilter,
          },
          {
            Header: "Email",
            accessor: "member.primaryEmail",
            Filter: ColumnFilter,
          },

          {
            Header: "Actions",
            accessor: "actions",
            Cell: (props) => {
              const rowData = props.row.original;
              return (
                <>
                  <EditCommitteeMember
                    rowData={rowData}
                    updateCommitteeMember={updateCommitteeMember}
                  />

                  <span onClick={() => deleteCMemberHandler(rowData)}>
                    <i
                      className="fas fa-trash action ml-2"
                      style={{ color: "red", cursor: "pointer" }}
                    ></i>
                  </span>
                </>
              );
            },
          },
        ]);
      }
    }
    dispatch(allCMembers(checkChapter));

    if (
      sessionNewSuccess ||
      sessionUpdateSuccess ||
      sessionDeleteSuccess ||
      success ||
      cMemberUpdateSuccess
    ) {
      swal(
        "Success!",
        sessionNewSuccess ||
          sessionUpdateSuccess ||
          sessionDeleteSuccess ||
          success ||
          cMemberUpdateSuccess,
        "success"
      ).then(() => {
        dispatch({ type: COMMITTEE_MEMBER_BY_ID_RESET });

        dispatch({ type: COMMITTEE_SESSION_NEW_RESET });
        dispatch({ type: COMMITTEE_SESSION_DELETE_RESET });
        dispatch({ type: COMMITTEE_MEMBER_UPDATE_BY_ID_RESET });
      });
    } else if (
      sessionNewError ||
      sessionUpdateError ||
      sessionDeleteError ||
      cMemberNewError ||
      cMemberUpdateError
    ) {
      swal(
        "Error!",
        sessionNewError ||
          sessionUpdateError ||
          sessionDeleteError ||
          cMemberNewError ||
          cMemberUpdateError,
        "error"
      );
    }
  }, [
    dispatch,
    deleteCMemberHandler,
    updateCommitteeMember,
    history,
    userInfo,
    checkChapter,
    sessionNewSuccess,
    sessionNewError,
    sessionUpdateSuccess,
    sessionUpdateError,
    sessionDeleteSuccess,
    sessionDeleteError,
    success,
    cMemberNewError,
    cMemberUpdateError,
    cMember,
    cMemberUpdateSuccess,
    successDelete,
  ]);

  const createSession = (tenure, active) => {
    dispatch(newSession(tenure, active));
  };

  const updateSession = (sessionId, updatedTenure, current) => {
    dispatch(updateSessionById(sessionId, updatedTenure, current));
  };

  const deleteSessionHandler = (sessionId) => {
    if (
      window.confirm(
        "The session and all of its committee members will be deleted! Are You Sure? "
      )
    ) {
      dispatch(deleteSession(sessionId));
    }
  };

  const addCommitteeMember = (sessionId, cMemberId, position, bio) => {
    dispatch(newCMember(sessionId, cMemberId, position, bio));
  };

  return (
    <Row className="content">
      {userInfo &&
      (userInfo.userRole === "admin" ||
        userInfo.userRole === "systemAdmin" ||
        userInfo.userRole === "member") ? (
        <>
          {/* Sidebar */}
          <Col
            md={{ span: 3, order: 1 }}
            lg={{ span: 3, order: 1 }}
            id="sidebar-wrapper"
            className="m-0 p-1"
          >
            <Sidebar />
          </Col>
          {/* Sidebar End */}
          <Col
            md={{ span: 9, order: 12 }}
            lg={{ span: 9, order: 12 }}
            id="page-content-wrapper"
            className="m-0 p-1"
          >
            <>
              {/* <CardColumns> */}

              <Row>
                {userInfo &&
                (userInfo.userRole === "admin" ||
                  userInfo.userRole === "systemAdmin") ? (
                  <>
                    <Col
                      md={{ span: 12, order: 1 }}
                      lg={{ span: 12, order: 1 }}
                      // style={{ padding: 0 }}
                      className="mb-2 p-0"
                    >
                      <Card border="info">
                        <Card.Header className="text-center text-info" as="h3">
                          <CreateSession createSession={createSession} />
                        </Card.Header>
                      </Card>
                    </Col>

                    <Col
                      md={{ span: 12, order: 12 }}
                      lg={{ span: 12, order: 12 }}
                      className="mb-2 p-0"
                      id="all-chapter"
                    >
                      <Card border="info">
                        <Card.Header as="h3" className="text-center text-info">
                          Committee Members
                        </Card.Header>

                        <>
                          {loading ? (
                            <Loader />
                          ) : error ? (
                            <Message variant="danger">{error}</Message>
                          ) : (
                            <>
                              <Accordion defaultActiveKey="0">
                                {cMembers &&
                                  cMembers.length !== 0 &&
                                  cMembers.map((cMember, index) => (
                                    <Card
                                      key={index}
                                      // className='text-center'
                                      border="info"
                                    >
                                      <Accordion.Toggle
                                        as={Card.Header}
                                        eventKey={cMember.cSessionId}
                                        className="text-info h5"
                                        style={{ cursor: "pointer" }}
                                      >
                                        <Row>
                                          <Col className="mr-auto">
                                            {cMember.tenure}
                                          </Col>
                                          {userInfo &&
                                            (userInfo.userRole ===
                                              "systemAdmin" ||
                                              userInfo.userRole ===
                                                "admin") && (
                                              <Col
                                                className="ml-auto"
                                                md={2}
                                                xs={4}
                                              >
                                                {/* Add Committee Member */}
                                                {subDomain === "aabea"
                                                  ? allUserList &&
                                                    allUserList.length !==
                                                      0 && (
                                                      <AddCommitteeMember
                                                        sessionId={
                                                          cMember.cSessionId
                                                        }
                                                        users={allUserList}
                                                        addCommitteeMember={
                                                          addCommitteeMember
                                                        }
                                                        subDomain={subDomain}
                                                      />
                                                    )
                                                  : users &&
                                                    users.length !== 0 && (
                                                      <AddCommitteeMember
                                                        sessionId={
                                                          cMember.cSessionId
                                                        }
                                                        users={users}
                                                        addCommitteeMember={
                                                          addCommitteeMember
                                                        }
                                                      />
                                                    )}

                                                {/* Session Edit */}
                                                <EditSession
                                                  sessionId={cMember.cSessionId}
                                                  tenure={cMember.tenure}
                                                  active={cMember.active}
                                                  updateSession={updateSession}
                                                />
                                                {/* Session Delete */}
                                                <OverlayTrigger
                                                  overlay={
                                                    <Tooltip id="tooltip-disabled">
                                                      Delete Committee Session!
                                                    </Tooltip>
                                                  }
                                                >
                                                  <span
                                                    onClick={() =>
                                                      deleteSessionHandler(
                                                        cMember.cSessionId
                                                      )
                                                    }
                                                  >
                                                    <i
                                                      className="fas fa-trash action ml-2"
                                                      style={{
                                                        color: "red",
                                                        cursor: "pointer",
                                                      }}
                                                    ></i>
                                                  </span>
                                                </OverlayTrigger>
                                              </Col>
                                            )}
                                        </Row>
                                      </Accordion.Toggle>
                                      <Accordion.Collapse
                                        eventKey={cMember.cSessionId}
                                      >
                                        <>
                                          <RTable
                                            users={cMember.committees}
                                            COLUMNS={columnsAdmin}
                                          />
                                        </>
                                      </Accordion.Collapse>
                                    </Card>
                                  ))}
                              </Accordion>
                            </>
                          )}
                        </>
                      </Card>
                    </Col>
                  </>
                ) : (
                  <Col
                    md={{ span: 12, order: 1 }}
                    lg={{ span: 12, order: 1 }}
                    className="mb-2 p-0"
                  >
                    {loading ? (
                      <Loader />
                    ) : error ? (
                      <Message variant="danger">{error}</Message>
                    ) : (
                      <>
                        <Accordion>
                          {cMembers.map((cMember, index) => (
                            <Card
                              key={index}
                              // className='text-center'
                              border="info"
                            >
                              <Accordion.Toggle
                                as={Card.Header}
                                eventKey={cMember.cSessionId}
                                className="text-center text-info h5"
                                style={{ cursor: "pointer" }}
                              >
                                {cMember.tenure}
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey={cMember.cSessionId}>
                                <>
                                  <ListGroup variant="flush">
                                    {cMember.committees.map(
                                      (committee, index) => (
                                        <div key={index}>
                                          {committee.position ===
                                            "president" && (
                                            <ListGroup.Item>
                                              <Media>
                                                <img
                                                  width={164}
                                                  height={164}
                                                  className="mr-3"
                                                  src={
                                                    committee.member
                                                      .profilePicture
                                                  }
                                                  alt={
                                                    committee.member.firstName
                                                  }
                                                />
                                                <Media.Body>
                                                  <h5 className="text-info">
                                                    {committee.position.toUpperCase()}
                                                  </h5>
                                                  <h5 className="text-info">
                                                    {committee.member.mInit}{" "}
                                                    {committee.member.firstName}{" "}
                                                    {committee.member.lastName}
                                                  </h5>
                                                  <p className="text-justify">
                                                    {parse(
                                                      committee.bio.substring(
                                                        0,
                                                        100
                                                      )
                                                    )}
                                                    ...
                                                    <Link
                                                      to={`/committee/${committee.cId}`}
                                                    >
                                                      Read more
                                                    </Link>
                                                  </p>
                                                </Media.Body>
                                              </Media>
                                            </ListGroup.Item>
                                          )}
                                        </div>
                                      )
                                    )}

                                    {cMember.committees.map(
                                      (committee, index) => (
                                        <div key={index}>
                                          {committee.position ===
                                            "president-elect" && (
                                            <ListGroup.Item>
                                              <Media>
                                                <img
                                                  width={164}
                                                  height={164}
                                                  className="mr-3"
                                                  src={
                                                    committee.member
                                                      .profilePicture
                                                  }
                                                  alt={
                                                    committee.member.firstName
                                                  }
                                                />
                                                <Media.Body>
                                                  <h5 className="text-info">
                                                    {committee.position.toUpperCase()}
                                                  </h5>
                                                  <h5 className="text-info">
                                                    {committee.member.mInit}{" "}
                                                    {committee.member.firstName}{" "}
                                                    {committee.member.lastName}
                                                  </h5>
                                                  <p className="text-justify">
                                                    {parse(
                                                      committee.bio.substring(
                                                        0,
                                                        100
                                                      )
                                                    )}
                                                    ...
                                                    <Link
                                                      to={`/committee/${committee.cId}`}
                                                    >
                                                      Read more
                                                    </Link>
                                                  </p>
                                                </Media.Body>
                                              </Media>
                                            </ListGroup.Item>
                                          )}
                                        </div>
                                      )
                                    )}

                                    {cMember.committees.map(
                                      (committee, index) => (
                                        <div key={index}>
                                          {committee.position ===
                                            "secretary" && (
                                            <ListGroup.Item>
                                              <Media>
                                                <img
                                                  width={164}
                                                  height={164}
                                                  className="mr-3"
                                                  src={
                                                    committee.member
                                                      .profilePicture
                                                  }
                                                  alt={
                                                    committee.member.firstName
                                                  }
                                                />
                                                <Media.Body>
                                                  <h5 className="text-info">
                                                    {committee.position.toUpperCase()}
                                                  </h5>
                                                  <h5 className="text-info">
                                                    {committee.member.mInit}{" "}
                                                    {committee.member.firstName}{" "}
                                                    {committee.member.lastName}
                                                  </h5>
                                                  <p className="text-justify">
                                                    {parse(
                                                      committee.bio.substring(
                                                        0,
                                                        100
                                                      )
                                                    )}
                                                    ...
                                                    <Link
                                                      to={`/committee/${committee.cId}`}
                                                    >
                                                      Read more
                                                    </Link>
                                                  </p>
                                                </Media.Body>
                                              </Media>
                                            </ListGroup.Item>
                                          )}
                                        </div>
                                      )
                                    )}

                                    {cMember.committees.map(
                                      (committee, index) => (
                                        <div key={index}>
                                          {committee.position ===
                                            "treasurer" && (
                                            <ListGroup.Item>
                                              <Media>
                                                <img
                                                  width={164}
                                                  height={164}
                                                  className="mr-3"
                                                  src={
                                                    committee.member
                                                      .profilePicture
                                                  }
                                                  alt={
                                                    committee.member.firstName
                                                  }
                                                />
                                                <Media.Body>
                                                  <h5 className="text-info">
                                                    {committee.position.toUpperCase()}
                                                  </h5>
                                                  <h5 className="text-info">
                                                    {committee.member.mInit}{" "}
                                                    {committee.member.firstName}{" "}
                                                    {committee.member.lastName}
                                                  </h5>
                                                  <p className="text-justify">
                                                    {parse(
                                                      committee.bio.substring(
                                                        0,
                                                        100
                                                      )
                                                    )}
                                                    ...
                                                    <Link
                                                      to={`/committee/${committee.cId}`}
                                                    >
                                                      Read more
                                                    </Link>
                                                  </p>
                                                </Media.Body>
                                              </Media>
                                            </ListGroup.Item>
                                          )}
                                        </div>
                                      )
                                    )}

                                    {cMember.committees.map(
                                      (committee, index) => (
                                        <div key={index}>
                                          {committee.position ===
                                            "executive-member" && (
                                            <ListGroup.Item>
                                              <Media>
                                                <img
                                                  width={164}
                                                  height={164}
                                                  className="mr-3"
                                                  src={
                                                    committee.member
                                                      .profilePicture
                                                  }
                                                  alt={
                                                    committee.member.firstName
                                                  }
                                                />
                                                <Media.Body>
                                                  <h5 className="text-info">
                                                    {committee.position.toUpperCase()}
                                                  </h5>
                                                  <h5 className="text-info">
                                                    {committee.member.mInit}{" "}
                                                    {committee.member.firstName}{" "}
                                                    {committee.member.lastName}
                                                  </h5>
                                                  <p className="text-justify">
                                                    {parse(
                                                      committee.bio.substring(
                                                        0,
                                                        100
                                                      )
                                                    )}
                                                    ...
                                                    <Link
                                                      to={`/committee/${committee.cId}`}
                                                    >
                                                      Read more
                                                    </Link>
                                                  </p>
                                                </Media.Body>
                                              </Media>
                                            </ListGroup.Item>
                                          )}
                                        </div>
                                      )
                                    )}
                                    {cMember.committees.map(
                                      (committee, index) => (
                                        <div key={index}>
                                          {committee.position !==
                                            "executive-member" &&
                                            committee.position !==
                                              "treasurer" &&
                                            committee.position !==
                                              "secretary" &&
                                            committee.position !==
                                              "president-elect" &&
                                            committee.position !==
                                              "president" &&
                                            subDomain === "aabea" && (
                                              <ListGroup.Item>
                                                <Media>
                                                  <img
                                                    width={164}
                                                    height={164}
                                                    className="mr-3"
                                                    src={
                                                      committee.member
                                                        .profilePicture
                                                    }
                                                    alt={
                                                      committee.member.firstName
                                                    }
                                                  />
                                                  <Media.Body>
                                                    <h5 className="text-info">
                                                      {committee.position.toUpperCase()}
                                                    </h5>
                                                    <h5 className="text-info">
                                                      {committee.member.mInit}{" "}
                                                      {
                                                        committee.member
                                                          .firstName
                                                      }{" "}
                                                      {
                                                        committee.member
                                                          .lastName
                                                      }
                                                    </h5>
                                                    <p className="text-justify">
                                                      {parse(
                                                        committee.bio.substring(
                                                          0,
                                                          100
                                                        )
                                                      )}
                                                      ...
                                                      <Link
                                                        to={`/committee/${committee.cId}`}
                                                      >
                                                        Read more
                                                      </Link>
                                                    </p>
                                                  </Media.Body>
                                                </Media>
                                              </ListGroup.Item>
                                            )}
                                        </div>
                                      )
                                    )}
                                  </ListGroup>
                                </>
                              </Accordion.Collapse>
                            </Card>
                          ))}
                        </Accordion>
                      </>
                    )}
                  </Col>
                )}
              </Row>
            </>
          </Col>
        </>
      ) : (
        <>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant="danger">{error}</Message>
          ) : (
            <Container>
              <Accordion defaultActiveKey="0">
                {cMembers.map((cMember, index) => (
                  <Card key={index} border="info">
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey={cMember.cSessionId}
                      // eventKey={index}
                      className="text-center text-info h5"
                      style={{ cursor: "pointer" }}
                    >
                      {cMember.tenure}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={cMember.cSessionId}>
                      <>
                        <ListGroup variant="flush">
                          {cMember.committees.map((committee, index) => (
                            <div key={index}>
                              {committee.position === "president" && (
                                <ListGroup.Item>
                                  <Media>
                                    <img
                                      width={164}
                                      height={164}
                                      className="mr-3"
                                      src={committee.member.profilePicture}
                                      alt={committee.member.firstName}
                                    />
                                    <Media.Body>
                                      <h5 className="text-info">
                                        {committee.position.toUpperCase()}
                                      </h5>
                                      <h5 className="text-info">
                                        {committee.member.mInit}{" "}
                                        {committee.member.firstName}{" "}
                                        {committee.member.lastName}
                                      </h5>
                                      <p className="text-justify">
                                        {parse(committee.bio.substring(0, 100))}
                                        ...
                                        <Link
                                          to={`/committee/${committee.cId}`}
                                        >
                                          Read more
                                        </Link>
                                      </p>
                                    </Media.Body>
                                  </Media>
                                </ListGroup.Item>
                              )}
                            </div>
                          ))}

                          {cMember.committees.map((committee, index) => (
                            <div key={index}>
                              {committee.position === "president-elect" && (
                                <ListGroup.Item>
                                  <Media>
                                    <img
                                      width={164}
                                      height={164}
                                      className="mr-3"
                                      src={committee.member.profilePicture}
                                      alt={committee.member.firstName}
                                    />
                                    <Media.Body>
                                      <h5 className="text-info">
                                        {committee.position.toUpperCase()}
                                      </h5>
                                      <h5 className="text-info">
                                        {committee.member.mInit}{" "}
                                        {committee.member.firstName}{" "}
                                        {committee.member.lastName}
                                      </h5>
                                      <p className="text-justify">
                                        {parse(committee.bio.substring(0, 100))}
                                        ...
                                        <Link
                                          to={`/committee/${committee.cId}`}
                                        >
                                          Read more
                                        </Link>
                                      </p>
                                    </Media.Body>
                                  </Media>
                                </ListGroup.Item>
                              )}
                            </div>
                          ))}

                          {cMember.committees.map((committee, index) => (
                            <div key={index}>
                              {committee.position === "secretary" && (
                                <ListGroup.Item>
                                  <Media>
                                    <img
                                      width={164}
                                      height={164}
                                      className="mr-3"
                                      src={committee.member.profilePicture}
                                      alt={committee.member.firstName}
                                    />
                                    <Media.Body>
                                      <h5 className="text-info">
                                        {committee.position.toUpperCase()}
                                      </h5>
                                      <h5 className="text-info">
                                        {committee.member.mInit}{" "}
                                        {committee.member.firstName}{" "}
                                        {committee.member.lastName}
                                      </h5>
                                      <p className="text-justify">
                                        {parse(committee.bio.substring(0, 100))}
                                        ...
                                        <Link
                                          to={`/committee/${committee.cId}`}
                                        >
                                          Read more
                                        </Link>
                                      </p>
                                    </Media.Body>
                                  </Media>
                                </ListGroup.Item>
                              )}
                            </div>
                          ))}

                          {cMember.committees.map((committee, index) => (
                            <div key={index}>
                              {committee.position === "treasurer" && (
                                <ListGroup.Item>
                                  <Media>
                                    <img
                                      width={164}
                                      height={164}
                                      className="mr-3"
                                      src={committee.member.profilePicture}
                                      alt={committee.member.firstName}
                                    />
                                    <Media.Body>
                                      <h5 className="text-info">
                                        {committee.position.toUpperCase()}
                                      </h5>
                                      <h5 className="text-info">
                                        {committee.member.mInit}{" "}
                                        {committee.member.firstName}{" "}
                                        {committee.member.lastName}
                                      </h5>
                                      <p className="text-justify">
                                        {parse(committee.bio.substring(0, 100))}
                                        ...
                                        <Link
                                          to={`/committee/${committee.cId}`}
                                        >
                                          Read more
                                        </Link>
                                      </p>
                                    </Media.Body>
                                  </Media>
                                </ListGroup.Item>
                              )}
                            </div>
                          ))}

                          {cMember.committees.map((committee, index) => (
                            <div key={index}>
                              {committee.position === "executive-member" && (
                                <ListGroup.Item>
                                  <Media>
                                    <img
                                      width={164}
                                      height={164}
                                      className="mr-3"
                                      src={committee.member.profilePicture}
                                      alt={committee.member.firstName}
                                    />
                                    <Media.Body>
                                      <h5 className="text-info">
                                        {committee.position.toUpperCase()}
                                      </h5>
                                      <h5 className="text-info">
                                        {committee.member.mInit}{" "}
                                        {committee.member.firstName}{" "}
                                        {committee.member.lastName}
                                      </h5>
                                      <p className="text-justify">
                                        {parse(committee.bio.substring(0, 100))}
                                        ...
                                        <Link
                                          to={`/committee/${committee.cId}`}
                                        >
                                          Read more
                                        </Link>
                                      </p>
                                    </Media.Body>
                                  </Media>
                                </ListGroup.Item>
                              )}
                            </div>
                          ))}

                          {cMember.committees.map((committee, index) => (
                            <div key={index}>
                              {committee.position !== "executive-member" &&
                                committee.position !== "treasurer" &&
                                committee.position !== "secretary" &&
                                committee.position !== "president-elect" &&
                                committee.position !== "president" &&
                                subDomain === "aabea" && (
                                  <ListGroup.Item>
                                    <Media>
                                      <img
                                        width={164}
                                        height={164}
                                        className="mr-3"
                                        src={committee.member.profilePicture}
                                        alt={committee.member.firstName}
                                      />
                                      <Media.Body>
                                        <h5 className="text-info">
                                          {committee.position.toUpperCase()}
                                        </h5>
                                        <h5 className="text-info">
                                          {committee.member.mInit}{" "}
                                          {committee.member.firstName}{" "}
                                          {committee.member.lastName}
                                        </h5>
                                        <p className="text-justify">
                                          {parse(
                                            committee.bio.substring(0, 100)
                                          )}
                                          ...
                                          <Link
                                            to={`/committee/${committee.cId}`}
                                          >
                                            Read more
                                          </Link>
                                        </p>
                                      </Media.Body>
                                    </Media>
                                  </ListGroup.Item>
                                )}
                            </div>
                          ))}
                        </ListGroup>
                      </>
                    </Accordion.Collapse>
                  </Card>
                ))}
              </Accordion>
            </Container>
          )}
        </>
      )}
    </Row>
  );
};

export default CommitteeScreen;
