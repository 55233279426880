import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";

import { Col, Row, Card, Container } from "react-bootstrap";
import ImageCarousel from "../../components/ImageCarousel/ImageCarousel";
import HomeAnnouncements from "../../components/Home/HomeAnnouncements";
import HomeEvents from "../../components/Home/HomeEvents";
import HomeHelp from "../../components/Home/HomeHelp";
import HomeQuickLinks from "../../components/Home/HomeQuickLinks";
import HomeRecentActivities from "../../components/Home/HomeRecentActivities";
import HomeExecutiveCom from "../../components/Home/HomeExecutiveCom";
import HomeBanner from "../../components/Advertisement/Display/HomeBanner";
import VideoPlayerBoxHome from "../../components/Advertisement/Display/VideoPlayerBoxHome";
import HomeUpcomingEvents from "../../components/Home/HomeUpcomingEvents";
import HomePastEvents from "../../components/Home/HomePastEvents";

const HomeScreen = () => {
  const [playVideoAdv, setPlayVideoAdv] = useState(false);
  const [advType] = useState("home-main-banner");

  const chapterBySubDomain = useSelector((state) => state.chapterBySubDomain);
  const { chapterByDomain } = chapterBySubDomain;

  const playVideo = (playVideo) => {
    setPlayVideoAdv(playVideo);
  };

  const checkChapter = window.location.host;

  let subDomain;

  if (process.env.NODE_ENV === "development") {
    subDomain = process.env.REACT_APP_SUBDOMAIN; // at dev only
  } else {
    // Production
    subDomain = checkChapter.split(".")[1];
    if (checkChapter.split(".")[0] === "www") {
    } else {
      subDomain = checkChapter.split(".")[0];
    }
  }

  return (
    <>
      <Container fluid>
        <Row>
          {chapterByDomain && chapterByDomain.advertisement ? (
            <HomeBanner playVideo={playVideo} advType={advType} />
          ) : null}
          <Col md={{ span: 6, order: 1 }} lg={{ span: 3, order: 1 }}>
            {chapterByDomain &&
            chapterByDomain.advertisement &&
            playVideoAdv ? (
              <div>
                <VideoPlayerBoxHome />
              </div>
            ) : null}
            {/* <HomeAnnouncements /> */}
            {subDomain !== "aabea" && (
              <Route
                render={({ match }) => <HomeAnnouncements match={match} />}
              />
            )}

            {subDomain === "aabea" ? (
              <>
                <HomeUpcomingEvents />
                <Route
                  render={({ match }) => <HomeAnnouncements match={match} />}
                />
                <HomePastEvents />
              </>
            ) : (
              <HomeEvents />
            )}

            {/* Help */}
            {<HomeHelp />}
            {/* Quick Links */}
            <HomeQuickLinks />
          </Col>

          {subDomain === "aabea" ? (
            <>
              <Col md={{ order: 12 }} lg={{ span: 9, order: 2 }}>
                <Card className="text-center mb-2 home-carousal">
                  <ImageCarousel subDomain={subDomain} />
                </Card>

                <HomeRecentActivities subDomain={subDomain} />
              </Col>
            </>
          ) : (
            <>
              <Col md={{ order: 12 }} lg={{ span: 6, order: 2 }}>
                <Card className="text-center mb-2 home-carousal">
                  <ImageCarousel />
                </Card>

                <HomeRecentActivities />
              </Col>

              <Col md={{ span: 6, order: 2 }} lg={{ span: 3, order: 12 }}>
                {/* Executive committee */}
                {subDomain !== "aabea" && <HomeExecutiveCom />}
              </Col>
            </>
          )}
        </Row>
      </Container>
    </>
  );
};

export default HomeScreen;
