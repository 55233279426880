import {
  ACTIVITY_ALL_FAIL,
  ACTIVITY_ALL_REQUEST,
  ACTIVITY_ALL_SUCCESS,
  ACTIVITY_BY_ID_FAIL,
  ACTIVITY_BY_ID_REQUEST,
  ACTIVITY_BY_ID_RESET,
  ACTIVITY_BY_ID_SUCCESS,
  ACTIVITY_DELETE_FAIL,
  ACTIVITY_DELETE_REQUEST,
  ACTIVITY_DELETE_SUCCESS,
  ACTIVITY_NEW_FAIL,
  ACTIVITY_NEW_REQUEST,
  ACTIVITY_NEW_RESET,
  ACTIVITY_NEW_SUCCESS,
  ACTIVITY_UPDATE_BY_ID_FAIL,
  ACTIVITY_UPDATE_BY_ID_REQUEST,
  ACTIVITY_UPDATE_BY_ID_RESET,
  ACTIVITY_UPDATE_BY_ID_SUCCESS,
} from '../constants/activityConstant';

export const activityNewReducer = (state = {}, action) => {
  switch (action.type) {
    case ACTIVITY_NEW_REQUEST:
      return { loading: true };
    case ACTIVITY_NEW_SUCCESS:
      return { loading: false, success: action.payload };
    case ACTIVITY_NEW_FAIL:
      return { loading: false, error: action.payload };
    case ACTIVITY_NEW_RESET:
      return {};
    default:
      return state;
  }
};

export const activityAllReducer = (state = { activities: [] }, action) => {
  switch (action.type) {
    case ACTIVITY_ALL_REQUEST:
      return { loading: true };
    case ACTIVITY_ALL_SUCCESS:
      return {
        loading: false,
        totalItems: action.payload.totalItems,
        activities: action.payload.items,
        totalPages: action.payload.totalPages,
        currentPage: action.payload.currentPage,
      };
    case ACTIVITY_ALL_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const activityByIdReducer = (state = { activity: {} }, action) => {
  switch (action.type) {
    case ACTIVITY_BY_ID_REQUEST:
      return { ...state, loading: true };
    case ACTIVITY_BY_ID_SUCCESS:
      return { loading: false, success: true, activity: action.payload };
    case ACTIVITY_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    case ACTIVITY_BY_ID_RESET:
      return {};
    default:
      return state;
  }
};

export const activityUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case ACTIVITY_UPDATE_BY_ID_REQUEST:
      return { loading: true };
    case ACTIVITY_UPDATE_BY_ID_SUCCESS:
      return { loading: false, success: true, activity: action.payload };
    case ACTIVITY_UPDATE_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    case ACTIVITY_UPDATE_BY_ID_RESET:
      return {};
    default:
      return state;
  }
};

export const activityDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ACTIVITY_DELETE_REQUEST:
      return { loading: true };
    case ACTIVITY_DELETE_SUCCESS:
      return { loading: false, success: true };
    case ACTIVITY_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
