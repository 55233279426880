import {
  CART_ADD_HOTEL_ROOM,
  CART_ADD_PROGRAM_GROUP,
  CART_NEW_FAIL,
  CART_NEW_REQUEST,
  CART_NEW_SUCCESS,
  CART_REMOVE_HOTEL_ROOM,
  CART_REMOVE_PROGRAM_GROUP,
  CART_RESET,
  CART_RESET_HOTEL_ROOM,
  CART_RESET_PROGRAM_GROUP,
} from '../constants/cartConstants';

export const cartReducer = (
  state = { cartItems: {}, programs: [], hotels: [] },
  action
) => {
  switch (action.type) {
    case CART_NEW_REQUEST:
      return { ...state, loading: true };
    case CART_NEW_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        cartItems: action.payload,
        programs: [],
        hotels: [],
      };
    case CART_NEW_FAIL:
      return { ...state, loading: false, error: action.payload };

    case CART_RESET:
      return {};

    /////////////new///////////////////

    //////////// Program Group///////////////
    case CART_ADD_PROGRAM_GROUP:
      const programItem = action.payload;
      return {
        ...state,
        programs: [...state.programs, programItem],
      };
    case CART_REMOVE_PROGRAM_GROUP:
      const programToRemove = action.payload;
      return {
        ...state,
        programs: state.programs.filter(
          (x) =>
            x.groupId !== programToRemove.groupId &&
            x.participantName !== programToRemove.participantName
        ),
      };
    case CART_RESET_PROGRAM_GROUP:
      return { programs: [] };

    //////////// Hotel Room///////////////
    case CART_ADD_HOTEL_ROOM:
      const hotelRoom = action.payload;

      const hotelExists = state.hotels.find(
        (x) => x.eventHotelRoomId === hotelRoom.eventHotelRoomId
      );

      // console.log(hotelExists);

      if (hotelExists) {
        return {
          ...state,
          hotels: state.hotels.map((x) =>
            x.eventHotelRoomId === hotelExists.eventHotelRoomId ? hotelRoom : x
          ),
        };
      } else {
        return {
          ...state,
          hotels: [...state.hotels, hotelRoom],
        };
      }

    case CART_REMOVE_HOTEL_ROOM:
      const hotelToRemove = action.payload;
      return {
        ...state,
        hotels: state.hotels.filter(
          (x) =>
            x.eventHotelRoomId !== hotelToRemove.eventHotelRoomId &&
            x.roomType !== hotelToRemove.roomType
        ),
      };
    case CART_RESET_HOTEL_ROOM:
      return { hotels: [] };

    default:
      return state;
  }
};
