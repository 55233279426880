export const ADVERTISEMENT_NEW_REQUEST = 'ADVERTISEMENT_NEW_REQUEST';
export const ADVERTISEMENT_NEW_SUCCESS = 'ADVERTISEMENT_NEW_SUCCESS';
export const ADVERTISEMENT_NEW_FAIL = 'ADVERTISEMENT_NEW_FAIL';
export const ADVERTISEMENT_NEW_RESET = 'ADVERTISEMENT_NEW_RESET';

export const ADVERTISEMENT_ALL_REQUEST = 'ADVERTISEMENT_ALL_REQUEST';
export const ADVERTISEMENT_ALL_SUCCESS = 'ADVERTISEMENT_ALL_SUCCESS';
export const ADVERTISEMENT_ALL_FAIL = 'ADVERTISEMENT_ALL_FAIL';

export const ADVERTISEMENT_BY_EVENT_REQUEST = 'ADVERTISEMENT_BY_EVENT_REQUEST';
export const ADVERTISEMENT_BY_EVENT_SUCCESS = 'ADVERTISEMENT_BY_EVENT_SUCCESS';
export const ADVERTISEMENT_BY_EVENT_FAIL = 'ADVERTISEMENT_BY_EVENT_FAIL';
export const ADVERTISEMENT_BY_EVENT_RESET = 'ADVERTISEMENT_BY_EVENT_RESET';

export const ADVERTISEMENT_BY_ID_REQUEST = 'ADVERTISEMENT_BY_ID_REQUEST';
export const ADVERTISEMENT_BY_ID_SUCCESS = 'ADVERTISEMENT_BY_ID_SUCCESS';
export const ADVERTISEMENT_BY_ID_FAIL = 'ADVERTISEMENT_BY_ID_FAIL';
export const ADVERTISEMENT_BY_ID_RESET = 'ADVERTISEMENT_BY_ID_RESET';

export const ADVERTISEMENT_UPDATE_BY_ID_REQUEST =
  'ADVERTISEMENT_UPDATE_BY_ID_REQUEST';
export const ADVERTISEMENT_UPDATE_BY_ID_SUCCESS =
  'ADVERTISEMENT_UPDATE_BY_ID_SUCCESS';
export const ADVERTISEMENT_UPDATE_BY_ID_FAIL =
  'ADVERTISEMENT_UPDATE_BY_ID_FAIL';
export const ADVERTISEMENT_UPDATE_BY_ID_RESET =
  'ADVERTISEMENT_UPDATE_BY_ID_RESET';

export const ADVERTISEMENT_DELETE_REQUEST = 'ADVERTISEMENT_DELETE_REQUEST';
export const ADVERTISEMENT_DELETE_SUCCESS = 'ADVERTISEMENT_DELETE_SUCCESS';
export const ADVERTISEMENT_DELETE_FAIL = 'ADVERTISEMENT_DELETE_FAIL';
