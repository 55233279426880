import axios from 'axios';
import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { allEvents } from '../../actions/eventActions';
import { allSponsors } from '../../actions/sponsorAction';
import { ADVERTISEMENT_NEW_RESET } from '../../constants/advertisementConstant';
import Loader from '../Loader';
import Message from '../Message';
import { newAdv } from '../../actions/advAction';

const Advertisement = ({ history }) => {
  const dispatch = useDispatch();
  const checkChapter = window.location.host;

  const [addImage, setAddImage] = useState(false);
  const [advType, setAdvType] = useState('');
  const [eventId, setEventId] = useState('');
  const [sponsorId, setSponsorId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [expireDate, setExpireDate] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState([]);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const advNew = useSelector((state) => state.advNew);
  const { loading: advNewLoading, error: advNewError, success } = advNew;

  const advDelete = useSelector((state) => state.advDelete);
  const { success: successDelete } = advDelete;

  const eventAll = useSelector((state) => state.eventAll);
  const { events } = eventAll;
  const sponsorAll = useSelector((state) => state.sponsorAll);
  const { sponsors } = sponsorAll;

  useEffect(() => {
    if (
      userInfo &&
      (userInfo.userRole === 'systemAdmin' || userInfo.userRole === 'admin')
    ) {
      dispatch(allEvents(checkChapter));
      dispatch(allSponsors(checkChapter));
      dispatch({ type: ADVERTISEMENT_NEW_RESET });
    } else {
      history.push('/login');
    }
    if (success) {
      swal('Success!', success, 'success').then((value) => {
        setAddImage((addImage) => !addImage);
        setAdvType('');
        setEventId('');
        setSponsorId('');
        setStartDate('');
        setExpireDate('');
        setUploadedFiles([]);
        setMessage([]);
        dispatch({ type: ADVERTISEMENT_NEW_RESET });
      });
    }
    if (advNewError) {
      swal('Error!', advNewError, 'error');
    }
  }, [
    dispatch,
    history,
    userInfo,
    checkChapter,
    success,
    advNewError,
    successDelete,
  ]);

  const selectEventHandeler = (e) => {
    e.preventDefault();

    console.log(e.target.value);
    setEventId(e.target.value.split(',')[0]);
    setStartDate(
      new Date(e.target.value.split(',')[1]).toISOString().split('T')[0]
    );
    setExpireDate(
      new Date(e.target.value.split(',')[2]).toISOString().split('T')[0]
    );
  };
  // new Date(eventFrom).toISOString().split('T')[0]
  // eventFrom={event.eventDate[0].value}
  // eventTo={event.eventDate[1].value}

  const addNewImage = (e) => {
    e.preventDefault();

    setAddImage(!addImage);
  };

  const uploadFileHandler = async (e) => {
    const file = e.target.files;

    if (file && file.length !== 0) {
      var extension = file[0].type;
      console.log(extension);
      if (
        advType === 'home-video-box' &&
        extension.split('/').pop() !==
          ('mp4' || 'webm' || 'ogv' || 'mp3' || 'wav' || 'FLV')
      ) {
        window.alert(
          'File does not support. You must use .mp4 or .wav or .webm or .ogv or .FLV '
        );
        return false;
      }
      for (let i = 0; i < file.length; i++) {
        extension = file[i].type;
        if (
          advType !== 'home-video-box' &&
          extension.split('/').pop() ===
            ('mp4' || 'webm' || 'ogv' || 'mp3' || 'wav' || 'FLV')
        ) {
          window.alert('File does not support. You must use image type file');
          return false;
        }

        const formData = new FormData();
        formData.append(`image`, file[i]);

        console.log(formData);
        setUploading(true);

        try {
          const config = {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          };

          const { data } = await axios.post('/api/upload', formData, config);
          console.log(data);
          uploadedFiles.push(data);
          setUploading(false);

          setMessage((prevMessage) => [
            ...prevMessage,
            'Uploaded the file successfully: ' + file[i].name,
          ]);
        } catch (error) {
          console.error(error);
          setUploading(false);
          setMessage((prevMessage) => [
            ...prevMessage,
            'Could not upload the file: ' + file[i].name,
          ]);
        }
      }
    }
  };

  // const deleteImageHandler = (id) => {
  //   if (window.confirm('Are You Sure?')) {
  //     dispatch(deleteImage(id));
  //   }
  // };

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(
      newAdv({
        startDate: startDate,
        expireDate: expireDate,
        advType: advType,
        eventId: eventId,
        sponsorId: sponsorId,
        images: uploadedFiles,
        checkChapter: checkChapter,
      })
    );
  };

  return (
    <>
      <Row>
        <Col
          md={{ span: 12, order: 1 }}
          lg={{ span: 12, order: 1 }}
          // style={{ padding: 0 }}
          className='mb-2 p-0'
        >
          <Card border='info'>
            <Card.Header className='text-center' as='h2'>
              <Button
                variant='transparent'
                className='btn btn-outline-info btn-sm btn-block rounded'
                onClick={addNewImage}
                to=''
              >
                Add Image For Advertisement
              </Button>
            </Card.Header>
            <Card.Body>
              {addImage
                ? (advNewError && (
                    <Message variant='danger'>{advNewError}</Message>
                  )) ||
                  (advNewLoading && <Loader />) ||
                  (success ? (
                    <Message variant='success'>{success}</Message>
                  ) : (
                    <Form onSubmit={submitHandler}>
                      <Form.Group controlId='advType'>
                        <Form.Label>Advertisement Type</Form.Label>
                        <Form.Control
                          as='select'
                          type='text'
                          placeholder='Please Enter A Title..'
                          value={advType}
                          onChange={(e) => setAdvType(e.target.value)}
                        >
                          <option>Please Select an advertisement type</option>

                          {/* <option value='logo'>Logo</option> */}

                          <option value='event-sponsor'>Event Ads</option>
                          <option value='home-main-banner'>Main Ads</option>
                          <option value='home-video-box'>Video Ads</option>
                          {/* <option value='home-image-box'>
                            Home Screen Image Box
                          </option> */}
                        </Form.Control>
                      </Form.Group>

                      {advType && advType === 'event-sponsor' ? (
                        <Form.Group controlId='advType'>
                          <Form.Label>Event</Form.Label>
                          <Form.Control
                            as='select'
                            onChange={selectEventHandeler}
                          >
                            <option value={[0, new Date(), new Date()]}>
                              Select event
                            </option>
                            {events &&
                              events.length !== 0 &&
                              events.map((event, index) => (
                                <option
                                  key={index}
                                  value={[
                                    event.eventId,
                                    event.eventDate[0].value,
                                    event.eventDate[1].value,
                                  ]}
                                >
                                  {event.eventName}
                                </option>
                              ))}
                          </Form.Control>
                        </Form.Group>
                      ) : null}

                      {/* eventFrom={event.eventDate[0].value} */}
                      {/* eventTo={event.eventDate[1].value} */}

                      <Form.Group controlId='sponsor'>
                        <Form.Label>Sponsor</Form.Label>
                        <Form.Control
                          as='select'
                          onChange={(e) => setSponsorId(e.target.value)}
                        >
                          <option>Select a sponsor</option>
                          {sponsors &&
                            sponsors.length !== 0 &&
                            sponsors.map((sponsor, index) => (
                              <option key={index} value={sponsor.sponsorId}>
                                {sponsor.sponsorName}
                              </option>
                            ))}
                        </Form.Control>
                      </Form.Group>

                      <Form.Row>
                        <Form.Group as={Col} controlId='startDate'>
                          <Form.Label>Start Date:</Form.Label>
                          <Form.Control
                            // {advType === 'eventsponsor' ? disabled : null}
                            disabled={
                              advType === 'event-sponsor' ? true : false
                            }
                            required
                            type='date'
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            min={new Date().toISOString().split('T')[0]}
                          ></Form.Control>
                        </Form.Group>

                        <Form.Group as={Col} controlId='expireDate'>
                          <Form.Label>End Date:</Form.Label>
                          <Form.Control
                            disabled={
                              advType === 'event-sponsor' ? true : false
                            }
                            required
                            type='date'
                            value={expireDate}
                            onChange={(e) => setExpireDate(e.target.value)}
                            min={startDate}
                          ></Form.Control>
                        </Form.Group>
                      </Form.Row>

                      <Form.Group controlId='image'>
                        {advType === 'home-video-box' ? (
                          <Form.Label>Video</Form.Label>
                        ) : (
                          <Form.Label>Image/s</Form.Label>
                        )}
                        <Form.Control
                          required
                          type='text'
                          placeholder='Enter image url..'
                          value={uploadedFiles}
                          onChange={(e) => setUploadedFiles(e.target.value)}
                        ></Form.Control>
                        <Form.File
                          id='image-file'
                          label='Choose File'
                          custom
                          multiple={advType === 'home-video-box' ? false : true}
                          onChange={uploadFileHandler}
                        ></Form.File>
                        {uploading && <Loader />}
                      </Form.Group>
                      {message.length > 0 && (
                        <div className='alert alert-secondary' role='alert'>
                          <ul>
                            {message.map((item, i) => {
                              return <li key={i}>{item}</li>;
                            })}
                          </ul>
                        </div>
                      )}

                      <Button type='submit' variant='info' block>
                        <i className='fas fa-plus' /> Load
                      </Button>
                    </Form>
                  ))
                : null}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Advertisement;
