import styled from '@emotion/styled';

export const CardDeck = styled.div`
  /* background: gainsboro; */
  /* align-content: center center; */
  /* align-items: center; */
  /* align-self: center; */
  height: 100%;
  /* display: flex; */
`;
