import React from "react";
import CentralEventRegistration2022 from "../../components/CentralEvent/BiAnnualEvent2022/CentralEventRegistration2022";
import CentralEventRegistration2024 from "../../components/CentralEvent/BiAnnualEvent2024/CentralEventRegistration2024";
import GeneralEventRegistrationScreen from "./GeneralEventRegistrationScreen";

const EventRegisterScreen = ({ match, history }) => {
  const { id } = match.params;

  if (id === "42141") {
    return <CentralEventRegistration2022 history={history} match={match} />;
  } else if (id === "42042") {
    return <CentralEventRegistration2024 history={history} match={match} />;
  } else {
    return <GeneralEventRegistrationScreen history={history} match={match} />;
  }
};

export default EventRegisterScreen;
