import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
const zelleIcon = process.env.REACT_APP_PUBLIC_URL + "/icons/zelle1.jpg";

const ZellePayment = ({ ZellePayment }) => {
  const [showInputField, setShowInputField] = useState(false);

  const [confirmationNumber, setConfirmationNumber] = useState("");

  const handleButtonClick = () => {
    setShowInputField(!showInputField);
  };

  const handleSubmit = () => {
    ZellePayment({
      paymentType: "zelle",
      confirmationNumber: confirmationNumber,
    });
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Button
        block
        onClick={handleButtonClick}
        style={{ background: "#6c1cd3" }}
      >
        {!showInputField ? "" : "Enter Zelle Confirmation number"}
        <img
          src={zelleIcon}
          alt="Zelle Logo"
          style={{
            width: "80px",
            height: "40px",
          }}
        />{" "}
      </Button>

      {showInputField && (
        <div>
          <Row>
            <Col className="m-2">
              Please pay by Zelle first and then enter the Zelle confirmation
              number below and submit.
              <hr />
              Zelle Here: <b>aabea.central@gmail.com</b>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group controlId="confirmationNumber">
                <Form.Control
                  type="text"
                  placeholder="Enter Zelle Confirmation number"
                  value={confirmationNumber}
                  onChange={(e) => setConfirmationNumber(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Button
            variant="info"
            block
            onClick={handleSubmit}
            disabled={confirmationNumber.trim() === ""}
            style={{ background: "#6c1cd3" }}
          >
            Submit
          </Button>
        </div>
      )}
    </div>
  );
};

export default ZellePayment;
