import React, { useEffect, useState } from 'react';
import { Card, Pagination } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import { allLinks } from '../../actions/linkActions';
import Paginate3 from './Paginate3';

const HomeQuickLinks = () => {
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(0);

  const linkAll = useSelector((state) => state.linkAll);
  const {
    loading: linkAllLoading,
    error: linkAllError,
    links,
    totalPages,
    currentPage,
  } = linkAll;

  const checkChapter = window.location.host;
  // const subDomain = window.location.host.split('.')[0];

  useEffect(() => {
    dispatch(allLinks(checkChapter, '', pageNumber)); // done
  }, [dispatch, checkChapter, pageNumber]);

  const changePageNumber = (changePageNumber) => {
    setPageNumber(changePageNumber);
  };

  return (
    <Card className='mb-2'>
      <Card.Header className='text-info text-center' as='h4'>
        Quick Links
      </Card.Header>
      {linkAllLoading ? (
        <Loader />
      ) : linkAllError ? (
        <Message variant='danger'>{linkAllError}</Message>
      ) : links && links.length !== 0 ? (
        <>
          <Card.Body>
            {links.map((linku, index) => (
              <div key={index}>
                <Card.Link href={linku.link}>{linku.linkTitle}</Card.Link>
                <hr />
              </div>
            ))}
          </Card.Body>
          {/* {totalPages > 1 && (
            <Pagination size='sm' className='justify-content-center'>
              {[...Array(totalPages).keys()].map((x) => (
                <Link
                  key={x}
                  onClick={() => {
                    setPageNumber(x);
                  }}
                  to='#'
                >
                  <Pagination.Item
                    active={x === currentPage}
                    className='outline-info text-info'
                  >
                    {x + 1}
                  </Pagination.Item>
                </Link>
              ))}
            </Pagination>
          )} */}
          <div className='align-middle'>
            {totalPages > 1 && (
              <Pagination size='sm' className='justify-content-center my-1'>
                <Paginate3
                  totalPages={totalPages}
                  currentPage={currentPage}
                  changePageNumber={changePageNumber}
                />
              </Pagination>
            )}
          </div>
        </>
      ) : null}
    </Card>
  );
};

export default HomeQuickLinks;
