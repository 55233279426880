import {
  ADVERTISEMENT_ALL_FAIL,
  ADVERTISEMENT_ALL_REQUEST,
  ADVERTISEMENT_ALL_SUCCESS,
  ADVERTISEMENT_BY_EVENT_FAIL,
  ADVERTISEMENT_BY_EVENT_REQUEST,
  ADVERTISEMENT_BY_EVENT_RESET,
  ADVERTISEMENT_BY_EVENT_SUCCESS,
  ADVERTISEMENT_BY_ID_FAIL,
  ADVERTISEMENT_BY_ID_REQUEST,
  ADVERTISEMENT_BY_ID_RESET,
  ADVERTISEMENT_BY_ID_SUCCESS,
  ADVERTISEMENT_DELETE_FAIL,
  ADVERTISEMENT_DELETE_REQUEST,
  ADVERTISEMENT_DELETE_SUCCESS,
  ADVERTISEMENT_NEW_FAIL,
  ADVERTISEMENT_NEW_REQUEST,
  ADVERTISEMENT_NEW_RESET,
  ADVERTISEMENT_NEW_SUCCESS,
  ADVERTISEMENT_UPDATE_BY_ID_FAIL,
  ADVERTISEMENT_UPDATE_BY_ID_REQUEST,
  ADVERTISEMENT_UPDATE_BY_ID_RESET,
  ADVERTISEMENT_UPDATE_BY_ID_SUCCESS,
} from '../constants/advertisementConstant';

export const advNewReducer = (state = {}, action) => {
  switch (action.type) {
    case ADVERTISEMENT_NEW_REQUEST:
      return { loading: true };
    case ADVERTISEMENT_NEW_SUCCESS:
      return { loading: false, success: action.payload };
    case ADVERTISEMENT_NEW_FAIL:
      return { loading: false, error: action.payload };
    case ADVERTISEMENT_NEW_RESET:
      return {};
    default:
      return state;
  }
};

export const advAllReducer = (state = {}, action) => {
  switch (action.type) {
    case ADVERTISEMENT_ALL_REQUEST:
      return { loading: true };
    case ADVERTISEMENT_ALL_SUCCESS:
      return {
        loading: false,
        advs: action.payload.advs,
        advType: action.payload.advType,
      };
    case ADVERTISEMENT_ALL_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const advsByEventReducer = (state = { advs: [] }, action) => {
  switch (action.type) {
    case ADVERTISEMENT_BY_EVENT_REQUEST:
      return { loading: true };
    case ADVERTISEMENT_BY_EVENT_SUCCESS:
      return { loading: false, advs: action.payload };
    case ADVERTISEMENT_BY_EVENT_FAIL:
      return { loading: false, error: action.payload };
    case ADVERTISEMENT_BY_EVENT_RESET:
      return {};
    default:
      return state;
  }
};

export const advByIdReducer = (state = { adv: {} }, action) => {
  switch (action.type) {
    case ADVERTISEMENT_BY_ID_REQUEST:
      return { ...state, loading: true };
    case ADVERTISEMENT_BY_ID_SUCCESS:
      return { loading: false, success: true, adv: action.payload };
    case ADVERTISEMENT_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    case ADVERTISEMENT_BY_ID_RESET:
      return {};
    default:
      return state;
  }
};

export const advUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case ADVERTISEMENT_UPDATE_BY_ID_REQUEST:
      return { loading: true };
    case ADVERTISEMENT_UPDATE_BY_ID_SUCCESS:
      return { loading: false, success: true, adv: action.payload };
    case ADVERTISEMENT_UPDATE_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    case ADVERTISEMENT_UPDATE_BY_ID_RESET:
      return {};
    default:
      return state;
  }
};

export const advDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ADVERTISEMENT_DELETE_REQUEST:
      return { loading: true };
    case ADVERTISEMENT_DELETE_SUCCESS:
      return { loading: false, success: true };
    case ADVERTISEMENT_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
