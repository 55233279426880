export const CART_NEW_REQUEST = 'CART_NEW_REQUEST';
export const CART_NEW_SUCCESS = 'CART_NEW_SUCCESS';
export const CART_NEW_FAIL = 'CART_NEW_FAIL';
export const CART_RESET = 'CART_RESET';

/////////////EVENT//////////////////////////
export const CART_ADD_EVENT = 'CART_ADD_EVENT';
export const CART_EVENT_RESET = 'CART_EVENT_RESET';

////////////////PROGRAM/////////////////////////
export const CART_ADD_PROGRAM_GROUP = 'CART_ADD_PROGRAM_GROUP';
export const CART_REMOVE_PROGRAM_GROUP = 'CART_REMOVE_PROGRAM_GROUP';
export const CART_RESET_PROGRAM_GROUP = 'CART_RESET_PROGRAM_GROUP';

/////////////////HOTEL////////////////////
export const CART_ADD_HOTEL_ROOM = 'CART_ADD_HOTEL_ROOM';
export const CART_REMOVE_HOTEL_ROOM = 'CART_REMOVE_HOTEL_ROOM';
export const CART_RESET_HOTEL_ROOM = 'CART_RESET_HOTEL_ROOM';

export const CART_SAVE_PAYMENT_ADDRESS = 'CART_SAVE_PAYMENT_ADDRESS';
