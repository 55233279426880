/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';

const NumberInput = ({ roomNumber, room, hotelName, registerRoom }) => {
  const [qty, setQty] = useState(roomNumber);

  useEffect(() => {
    setQty(Number(roomNumber));
  }, [roomNumber]);

  useEffect(() => {
    const eventHotelRoomId = room.eventHotelRoomId;
    const eventHotelId = room.eventHotelId;
    const eventId = room.eventId;
    const roomType = room.roomType;
    const roomDescription = room.roomDescription;
    const accomodation = room.accomodation;
    const rent = room.rent;

    registerRoom({
      hotelName,
      eventHotelRoomId,
      eventHotelId,
      eventId,
      roomType,
      roomDescription,
      accomodation,
      rent,
      qty,
    });
  }, [qty]);

  // console.log('room number from Number Input:', qty);
  // console.log('Number Input:', roomNumber);

  const increment = (e) => {
    e.preventDefault();

    setQty(qty + 1);
  };

  const decrement = (e) => {
    e.preventDefault();

    setQty(qty - 1);
  };

  return (
    <Row className='m-0 p-0'>
      <Col md={6} className='m-0 p-0'>
        <InputGroup className='mb-3'>
          <InputGroup.Prepend>
            <Button
              className='btn btn-info btn-sm rounded mr-2'
              variant='transparent'
              onClick={decrement}
              disabled={qty <= 0}
            >
              <i
                className='fas fa-minus action'
                style={{
                  cursor: 'pointer',
                }}
              ></i>
            </Button>
          </InputGroup.Prepend>
          <Form.Control
            required
            type='number'
            value={qty}
            onChange={(e) => setQty(Number(e.target.value))}
            min={roomNumber}
            className='text-center font-weight-bold'
          ></Form.Control>
          <InputGroup.Append>
            <Button
              className='btn btn-info btn-sm rounded ml-2'
              variant='transparent'
              onClick={increment}
            >
              <i
                className='fas fa-plus action'
                style={{
                  cursor: 'pointer',
                }}
              ></i>
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </Col>
    </Row>
  );
};

export default NumberInput;
