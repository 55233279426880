import React from "react";
import { useSelector } from "react-redux";

import { LinkContainer } from "react-router-bootstrap";
import { Accordion, Card, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

const Sidebar = ({
  eventKey = "0",
  customMatch,
  jobPortalKey = "0",
  jobPortalMatch,
}) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const chapterBySubDomain = useSelector((state) => state.chapterBySubDomain);
  const { chapterByDomain } = chapterBySubDomain;

  const checkChapter = window.location.host;
  let subDomain;
  if (checkChapter.split(".")[0] === "www") {
    subDomain = checkChapter.split(".")[1];
  } else {
    subDomain = checkChapter.split(".")[0];
  }

  // console.log(chapterByDomain);

  return (
    <Card className="text-center" border="info">
      <Card.Body>
        {/* Chapter */}
        {userInfo && userInfo.userRole === "systemAdmin" && (
          <Card.Title>
            <LinkContainer to="/chapter">
              <Link
                className="btn btn-outline-info btn-sm btn-block rounded"
                to="/chapter"
              >
                Chapter
              </Link>
            </LinkContainer>
          </Card.Title>
        )}

        {userInfo &&
          (userInfo.userRole === "systemAdmin" ||
            userInfo.userRole === "admin") && (
            <>
              {chapterByDomain && chapterByDomain.advertisement ? (
                <Card.Title>
                  <Accordion defaultActiveKey={eventKey}>
                    <Card className="border border-info">
                      <LinkContainer to="/advertisement">
                        <Accordion.Toggle
                          // onClick={() => {
                          //   history.push('/advertisement');
                          // }}
                          as={Card.Header}
                          eventKey="1"
                          className="btn btn-outline-info border-0 btn-sm btn-block rounded p-1"
                        >
                          {/* <Link to='/advertisement?eventKey=1'> */}
                          Advertisement
                          {/* </Link> */}
                        </Accordion.Toggle>
                      </LinkContainer>
                      <Accordion.Collapse eventKey="1">
                        <Navbar className="justify-content-center">
                          <Nav
                            defaultActiveKey={customMatch}
                            className="flex-column"
                          >
                            <LinkContainer to="/advertisement/manage-sponsor">
                              <Nav.Link className="btn btn-outline-warning btn-sm btn-block p-1 pl-5 pr-5">
                                Manage Sponsor
                              </Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/advertisement/event-sponsor">
                              <Nav.Link className="btn btn-outline-warning btn-sm btn-block p-1 pl-5 pr-5">
                                Event Ads
                              </Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/advertisement/home-main-banner">
                              <Nav.Link className="btn btn-outline-warning btn-sm btn-block p-1">
                                Main Ads
                              </Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/advertisement/home-video-box">
                              <Nav.Link className="btn btn-outline-warning btn-sm btn-block p-1">
                                Video Ads
                              </Nav.Link>
                            </LinkContainer>
                            {/* <LinkContainer to='/advertisement/home-image-box'>
                              <Nav.Link className='btn btn-outline-warning btn-sm btn-block p-1'>
                                Image-Box
                              </Nav.Link>
                            </LinkContainer> */}
                          </Nav>
                        </Navbar>
                        {/* <Card.Body>
                      <LinkContainer to='/event-sponsor'>
                      <Link
                        className='btn btn-outline-warning btn-sm btn-block rounded'
                        to='/event-sponsor'
                      >
                        Event Sponsor
                      </Link>
                      </LinkContainer>
                      <LinkContainer to='/home-main-banner'>
                      <Link
                        className='btn btn-outline-warning btn-sm btn-block rounded'
                        to='/home-main-banner'
                      >
                        Main Banner
                      </Link>
                      </LinkContainer>
                      <LinkContainer to='/home-side-box'>
                      <Link
                        className='btn btn-outline-warning btn-sm btn-block rounded'
                        to='/home-side-box'
                      >
                        Side-Box
                      </Link>
                      </LinkContainer>
                    </Card.Body> */}
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </Card.Title>
              ) : null}

              {chapterByDomain && chapterByDomain.jobPortal ? (
                <Card.Title>
                  <Accordion defaultActiveKey={jobPortalKey}>
                    <Card className="border border-info">
                      <LinkContainer to="/career">
                        <Accordion.Toggle
                          // onClick={() => {
                          //   history.push('/advertisement');
                          // }}
                          as={Card.Header}
                          eventKey="1"
                          className="btn btn-outline-info border-0 btn-sm btn-block rounded p-1"
                        >
                          {/* <Link to='/career/all-pending-posts'> */}
                          Career Portal
                          {/* </Link> */}
                        </Accordion.Toggle>
                      </LinkContainer>
                      <Accordion.Collapse eventKey="1">
                        <Navbar className="justify-content-center">
                          <Nav
                            defaultActiveKey={jobPortalMatch}
                            className="flex-column"
                          >
                            <LinkContainer to="/career/approved">
                              <Nav.Link className="btn btn-outline-warning btn-sm btn-block p-1 pl-5 pr-5">
                                All approved posts
                              </Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/career/rejected">
                              <Nav.Link className="btn btn-outline-warning btn-sm btn-block p-1 pl-5 pr-5">
                                All rejected posts
                              </Nav.Link>
                            </LinkContainer>
                          </Nav>
                        </Navbar>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </Card.Title>
              ) : null}

              <Card.Title>
                <LinkContainer to="/activity">
                  <Link
                    className="btn btn-outline-info btn-sm btn-block rounded"
                    to="/activity"
                  >
                    Activity
                  </Link>
                </LinkContainer>
              </Card.Title>

              <Card.Title>
                <LinkContainer to="/events">
                  <Link
                    className="btn btn-outline-info btn-sm btn-block rounded"
                    to="/events"
                  >
                    Events
                  </Link>
                </LinkContainer>
              </Card.Title>

              <Card.Title>
                <LinkContainer to="/emails">
                  <Link
                    className="btn btn-outline-info btn-sm btn-block rounded"
                    to="/emails"
                  >
                    Email
                  </Link>
                </LinkContainer>
              </Card.Title>

              {/* Announcement */}
              <Card.Title>
                <LinkContainer to="/announcement">
                  <Link
                    className="btn btn-outline-info btn-sm btn-block rounded"
                    to="/announcement"
                  >
                    Announcement
                  </Link>
                </LinkContainer>
              </Card.Title>

              {subDomain && subDomain === "aabea" ? (
                <>
                  {/* Mission */}
                  <Card.Title>
                    <LinkContainer to="/mission">
                      <Link
                        className="btn btn-outline-info btn-sm btn-block rounded"
                        to="/mission"
                      >
                        Mission
                      </Link>
                    </LinkContainer>
                  </Card.Title>

                  {/* Vission */}
                  <Card.Title>
                    <LinkContainer to="/vission">
                      <Link
                        className="btn btn-outline-info btn-sm btn-block rounded"
                        to="/vission"
                      >
                        Vision
                      </Link>
                    </LinkContainer>
                  </Card.Title>

                  {/* History */}
                  <Card.Title>
                    <LinkContainer to="/history">
                      <Link
                        className="btn btn-outline-info btn-sm btn-block rounded"
                        to="/history"
                      >
                        History
                      </Link>
                    </LinkContainer>
                  </Card.Title>
                </>
              ) : null}

              {/* Member types */}
              <Card.Title>
                <LinkContainer to="/memberType">
                  <Link
                    className="btn btn-outline-info btn-sm btn-block rounded"
                    to="/memberType"
                  >
                    Manage Member Type
                  </Link>
                </LinkContainer>
              </Card.Title>

              {/* Payment types */}
              <Card.Title>
                <LinkContainer to="/paymentType">
                  <Link
                    className="btn btn-outline-info btn-sm btn-block rounded"
                    to="/paymentType"
                  >
                    Manage Payment Type
                  </Link>
                </LinkContainer>
              </Card.Title>

              {/* Donation types */}
              <Card.Title>
                <LinkContainer to="/donationType">
                  <Link
                    className="btn btn-outline-info btn-sm btn-block rounded"
                    to="/donationType"
                  >
                    Manage Donation Type
                  </Link>
                </LinkContainer>
              </Card.Title>

              {/* Images */}
              <Card.Title>
                <LinkContainer to="/images">
                  <Link
                    className="btn btn-outline-info btn-sm btn-block rounded"
                    to="/images"
                  >
                    Images
                  </Link>
                </LinkContainer>
              </Card.Title>

              {/* Help Contact */}
              <Card.Title>
                <LinkContainer to="/help">
                  <Link
                    className="btn btn-outline-info btn-sm btn-block rounded"
                    to="/help"
                  >
                    Help Contact
                  </Link>
                </LinkContainer>
              </Card.Title>

              {/* Quick Links */}
              <Card.Title>
                <LinkContainer to="/links">
                  <Link
                    className="btn btn-outline-info btn-sm btn-block rounded"
                    to="/links"
                  >
                    Quick Links
                  </Link>
                </LinkContainer>
              </Card.Title>
            </>
          )}

        {userInfo &&
        (userInfo.userRole === "systemAdmin" ||
          userInfo.userRole === "admin") ? (
          <Card.Title>
            <LinkContainer to="/documents">
              <Link
                className="btn btn-outline-info btn-sm btn-block rounded"
                to="/documents"
              >
                Uploads
              </Link>
            </LinkContainer>
          </Card.Title>
        ) : userInfo && userInfo.userRole === "member" ? (
          <Card.Title>
            <LinkContainer to="/documents">
              <Link
                className="btn btn-outline-info btn-sm btn-block rounded"
                to="/documents"
              >
                Downloads
              </Link>
            </LinkContainer>
          </Card.Title>
        ) : null}

        <Card.Title>
          <LinkContainer to="/members">
            <Link
              className="btn btn-outline-info btn-sm btn-block rounded"
              to="/members"
            >
              Members
            </Link>
          </LinkContainer>
        </Card.Title>

        {/* <Card.Title>
          <LinkContainer to='/training'>
            <Link
              className='btn btn-outline-info btn-sm btn-block rounded'
              to='/training'
            >
              Training
            </Link>
          </LinkContainer>
        </Card.Title> */}

        <Card.Title>
          <LinkContainer to="/committiees">
            <Link
              className="btn btn-outline-info btn-sm btn-block rounded"
              to="/committiees"
            >
              Committee
            </Link>
          </LinkContainer>
        </Card.Title>

        <Card.Title>
          <LinkContainer to="/payment">
            <Link
              className="btn btn-outline-info btn-sm btn-block rounded"
              to="/payment"
            >
              Payment
            </Link>
          </LinkContainer>
        </Card.Title>

        <Card.Title>
          <LinkContainer to="/donate">
            <Link
              className="btn btn-outline-info btn-sm btn-block rounded"
              to="/donate"
            >
              Donation
            </Link>
          </LinkContainer>
        </Card.Title>
      </Card.Body>

      {userInfo &&
        (userInfo.userRole === "systemAdmin" ||
          userInfo.userRole === "admin") && (
          <Card.Footer className="text-muted">
            <Link
              className="btn btn-outline-warning btn-sm btn-block rounded"
              to="/settings"
            >
              Chapter Settings
            </Link>
          </Card.Footer>
        )}
    </Card>
  );
};

export default Sidebar;
